/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import UnloadColumnchart from './columnunloadingdashboard';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import label from 'material-ui/svg-icons/action/label';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class SNDUnloadingDashboard extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:moment.parseZone(new Date().setMonth(new Date().getMonth() - 3)).format("YYYY-MM-DD"),
            endDate:moment.parseZone().format("YYYY-MM-DD"),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            rowData: [],
            paginationPageSize:50,
            district:'',
            districtsList:[],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
            sndtab:'show-n',
            prttab:'show-n',
            tnptab:'show-n',
            sndtabactive:'',
            prttabactive:'',
            tnptabactive:'',
            overly : "show-n",
            loadshow : "show-n",
            show: false,
            basicType : "default",
            basicTitle : "",
            series:[],
            city_uld_data : [],
            district_uld_data:[],
            district_dealer_uld_data:[],
            cluster_uld_data : [],
            top_10_dealer_data : [],
            cityWiseSeriesData : [],
            districtWiseSeriesData:[],
            cityWiseCategory : [],
            unloaddrilldown : {},
            rowData : [],
            plantRowData : [],
            plantGridData:[],
            showMapData : "show-n",
            lat : "",
            lng : "",
            distancefromdealer : "",
            no_of_unloads : "",
            showFirstLevel : "show-n",
            filteredLocationData : [], // custom drop down
            showLocationList:"show-n", // custom drop down
            filteredPlantData : [], // custom drop down
            showPlantList:"show-n", // custom drop down
            statesList : [],
            selectedState : '',
            cityData : [],
            transporterData : [],
            possibleUnloadData : [],
            original_district_list:[],
            district_list:[],
            frameworkComponents : {
                ArrivalMap : ArrivalMap
            },
            breaksSlider:"",
            selectedRow : "",
            selectedConsigneeName : "",
            dept_code : "",
            selectedTnpPlant : {"value" : "all","label":"ALL"},
            dealer_uld_data : [],
            selectedDataType : {label : "ALL",value:"ALL"},
            orginal_cities_list:[],
            uniqueCitesAndDealer:[],
            uniqueDistrictAndDealer:[],
            is_show_charts:false
        }
        this.formatChartData = this.formatChartData.bind(this);
    }
    componentWillReceiveProps(newprops)
    {
        if(newprops.transporters != this.state.transporters)
        {
            this.setState({
                transporters : newprops.transporters
            })
        }
    }
    componentDidMount(){
        // this.setState({
        //     loadshow : "show-m",
        //     overly : "show-m"
        // })
        if(this.props.match.path == "/sndunloadingdashboard")
        {
            var dept_code = "SNDG";
        }
        if(this.props.match.path == "/prtunloadingdashboard")
        {
            var dept_code = "LOG-PRT";
        }
        if(this.props.match.path == "/tnpunloadingdashboard")
        {
            var dept_code = "LOG-TNP";
        }
        this.setState({
            dept_code : dept_code
        })
        redirectURL.post("/dashboard/getInitialData").then((response)=>{
            console.log(response.data,"123")
            var districts = response.data.districts 
            var district_list = []
            district_list.push({label: "ALL", value: "all"})
            districts.map(e => {
                if (e._id.consignee_district != null && e._id.consignee_district!="")
                district_list.push({label:e._id.consignee_district , value : e._id.consignee_district})
            })


            var states = response.data.states;
            var states_list = []
            states_list.push({label: "ALL", value: "all"})
            states.map(e => {
                states_list.push({label:e , value : e})
            })
            var cities = response.data.cities

            var cities_list = []
            cities_list.push({label: "ALL", value: "all"})
            cities.map(e => {
                    cities_list.push({label:e._id.consignee_city , value : e._id.consignee_city})
            })
            console.log(cities_list,"1234")
            var transporters = response.data.consignees;
            var transporter_list = []
            transporter_list.push({label: "ALL", value: "all"})
            transporters.map(e => {
                if (e._id.consignee_name != null && e._id.consignee_name!="" && e._id.consignee_code!=null && e._id.consignee_code!="")
                transporter_list.push({label:e._id.consignee_name , value : e._id.consignee_code})
            })
            var uniqueCitesAndDealer = response.data.uniqueCitesAndDealer
            var startDate = "2022-12-01 00:00:00";
            var endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
            var reqparams = {
                from_date : startDate,
                to_date : endDate,
                consignee_codes : "",
                consignee_district:""
            }
            this.setState({
                statesList : states_list, 
                locations : cities_list,
                transporters : transporter_list,
                cityData : response.data.cities,
                transporterData : response.data.consignees,
                overly : "show-n",
                loadshow : "show-n",
                district_list:district_list,
                original_district_list : districts,
                orginal_cities_list : cities,
                uniqueCitesAndDealer:uniqueCitesAndDealer
            })
            if(dept_code != "LOG-TNP")
            {
                var reqparams = {
                    from_date : startDate,
                    to_date : endDate,
                    dept_code : dept_code,

                }
            }
            else
            {
                var reqparams = {
                    from_date : startDate,
                    to_date : endDate,
                    dept_code : dept_code
                }
            }
            
            redirectURL.post("/dashboard/getSndUnloadChartData",{"formdata":reqparams}).then(async(resp)=>{
                console.log(resp.data , "1729");
                if(dept_code != "LOG-TNP")
                {
                    var city_dealer_uld_data = JSON.parse(resp.data.chartdata.city_dealer_uld_data);
                    var city_uld_data = JSON.parse(resp.data.chartdata.city_uld_data);
                    var top_10_dealer_data = JSON.parse(resp.data.chartdata.top_10_dealer_data);
                    var uniqueRecords = [];
                    if(top_10_dealer_data.length > 0)
                    {
                        uniqueRecords = await distinctArrayBy(top_10_dealer_data,"consignee_name","consignee_pincode");
                    }
                    console.log(top_10_dealer_data,"top_10_dealer_data")
                    console.log(uniqueRecords,"uniqueRecords")
                    var possibleUnloadData = resp.data.possibleUnloadData;
                    this.setState({
                        city_uld_data : city_uld_data,
                        city_dealer_uld_data : city_dealer_uld_data,
                        // cluster_uld_data : cluster_uld_data,
                        rowData : uniqueRecords,
                        possibleUnloadData : possibleUnloadData,
                        // startDate : startDate.split(" ")[0],
                        // endDate : endDate.split(" ")[0]
                    })
                    // this.formatChartData("")
                }
                else
                {
                    var dealer_uld_data = JSON.parse(resp.data.chartdata.dealer_uld_data);
                    var top_10_dealer_data = JSON.parse(resp.data.chartdata.top_10_dealer_data);
                   
                    this.setState({
                        dealer_uld_data : dealer_uld_data,
                        rowData : top_10_dealer_data
                    });
                    // this.formatTNPData();
                }
                
            }).catch((e)=>{
                console.log(e)
            })
            
        }).catch((e)=>{
            console.log(e)
        })
    }

    
    
    formatChartData(transporter)
    {
        var cityWiseData = this.state.city_uld_data;
        var cityWiseSeriesData = [];
        var cityWiseCategory=[];
        var drilldownseries = [];
        if(transporter == "")
        {
            
            cityWiseData.map(function(e){
                cityWiseSeriesData.push({
                    "name":e.consignee_district,
                    "y":parseFloat(e.unloading_time_in_hrs.toFixed(2)),
                    "drilldown":e.consignee_district,
                    "trips":e.no_of_trips
                })
                cityWiseCategory.push(e.consignee_district)
            })
            cityWiseSeriesData =  cityWiseSeriesData.sort(function(a,b){
                return b.y - a.y ;
            })
            var dealer_wise_data = this.state.city_dealer_uld_data;
            // console.log(cityWiseSeriesData);
            
            cityWiseSeriesData.map(function(e){
                var district = e.name;
    
                var matchedplants = dealer_wise_data.filter(function(k){
                    if(k.consignee_district == district)
                    {
                        return k;
                    }
                });
                // console.log(matchedplants);
                var data=[]
                matchedplants.map(function(j){
                    data.push([j.consignee_name,parseFloat(j.unloading_time_in_hrs.toFixed(2)),Number(j.no_of_trips)])
                });
                data = data.sort( (a, b) => {
                    return b[1] - a[1]
                  })
                drilldownseries.push({
                    "name" : district,
                    "id": district,
                    "data" : data 
                })
            })
           
        }
        else{
           console.log(cityWiseData);
           cityWiseData.map(function(e){
            cityWiseSeriesData.push({
                "name":transporter,
                "y":parseFloat(e.unloading_time_in_hrs.toFixed(2)),
                "drilldown":e.consignee_district,
                "trips":e.no_of_trips
            })
            cityWiseCategory.push(e.consignee_district)
            });
        }
        // if(this.state.rowData.length > 0)
        // {
            this.setState({
                showFirstLevel : "show-unloading-dashboard",
            })
        // }
        this.setState({
            cityWiseSeriesData : cityWiseSeriesData,
            cityWiseCategory : cityWiseCategory,
            unloaddrilldown : {"series":drilldownseries},
            loadshow:'show-n',
            overly:"show-n",
        });
    }
    formatTNPData(){
        var cityWiseData = this.state.dealer_uld_data;
        var cityWiseSeriesData = [];
        var drilldownseries = [];
        
        cityWiseData.map(function(e){
            cityWiseSeriesData.push({
                "name":e.consignee_district,
                "y":parseFloat(e.unloading_time_in_hrs.toFixed(2)),
                "trips":e.no_of_trips
            });
        });
        console.log(cityWiseData);
        this.setState({
            cityWiseSeriesData : cityWiseSeriesData,
            unloaddrilldown : {"series":drilldownseries},
            loadshow:'show-n',
            overly:"show-n",
            showFirstLevel : "show-unloading-dashboard",
            possibleUnloadData : []
        });
    }
    
    onClickLoadDept(deptcode){
        this.setState({
            dept_code:deptcode
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }

    onShowRouteDiv(params)
    {
        console.log(params);
        this.setState({
            breaksSlider : "slider-translate-60p",
            selectedRow : params,
            selectedConsigneeName : params.consignee_name,
            overly : "show-m",
            lat : "",
            lng : ""
        });
        this.renderMapWeather()
    }
    

    renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
    }
    initMapWeather = () => 
    {
        try
        {
        
            var coordsvalues;
            var map = new window.google.maps.Map(document.getElementById('plantmap'), {
                zoom: 8,
                center: new window.google.maps.LatLng(28.497236,77.062363),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
            });
            var bounds = new window.google.maps.LatLngBounds();
            var plantRowData = this.state.selectedRow;
            var plant = {
                url: require("../../assets/icons/business.png"), // url
                scaledSize: new window.google.maps.Size(32,32), // scaled size
                origin: new window.google.maps.Point(0,0), // origin
                anchor: new window.google.maps.Point(0, 0) // anchor
              };
            // plantRowData.map(function(e){
                console.log(plantRowData);
                var coords = JSON.parse(plantRowData.consignee_coordinates);
                var plantmarker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(coords.lat,coords.lng),
                map: map,
                icon: plant,
                title : plantRowData.consignee_name
                });
                var markerLatlng1;
                markerLatlng1 = new window.google.maps.LatLng(coords.lat, coords.lng);
                //console.log("markerLatlng", markerLatlng1)
                var circle = new window.google.maps.Circle({
                    strokeColor: '#71caab',
                    strokeOpacity: 0.7,
                    strokeWeight: 1,
                    fillColor: '#71caab',
                    fillOpacity: 0.25,
                    map: map,
                    center: markerLatlng1,
                    radius: 40000
                });
                var circle = new window.google.maps.Circle({
                    strokeColor: '#e35649',
                    strokeOpacity: 0.7,
                    strokeWeight: 1,
                    fillColor: '#e35649',
                    fillOpacity: 0.25,
                    map: map,
                    center: markerLatlng1,
                    radius: 15000
                });
                var infowindow2 = new window.google.maps.InfoWindow({
                content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Consignee Code : </label> "+plantRowData.consignee_code+"</div><div class='address'> <label class='forecast-labels'>Consignee Name :</label> "+plantRowData.consignee_name+"</div></div>"
                });
                window.google.maps.event.addListener(plantmarker, 'mouseover', function() {
                infowindow2.open(map,plantmarker);
                });
                window.google.maps.event.addListener(plantmarker, 'mouseout', function() {
                infowindow2.close();
                });
                bounds.extend(new window.google.maps.LatLng(coords.lat,coords.lng));
            // });
            var breakIcon = {
                url: require("../../assets/icons/delivery_truck.png"), // url
                scaledSize: new window.google.maps.Size(38, 38), // scaled size
                origin: new window.google.maps.Point(0,0), // origin
                anchor: new window.google.maps.Point(0, 0) // anchor
            };
            
            var breaks = plantRowData.frequent_break_locations;
            var markersArr=[];
            breaks.map(function(e,index){

                console.log(e)
                markersArr[index]  = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.lat,e.lng),
                    map: map,
                    icon: breakIcon,
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                });
                try{
                    let infowindow3 = new window.google.maps.InfoWindow({
                        content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> No. of Unloads : </label> "+e.no_of_breaks+"</div><div class='address'> <label class='forecast-labels'>Distance From Dealer :</label> "+e.distance_from_dealer_location_km.toFixed(2)+"</div></div>"
                        });
                    window.google.maps.event.addListener(markersArr[index], 'mouseover', function() {
                    infowindow3.open(map,markersArr[index]);
                    });
                    window.google.maps.event.addListener(markersArr[index], 'mouseout', function() {
                    infowindow3.close();
                    });
                }catch(e){
                    console.log(e);
                }
                
                bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
            })
                
            if(this.state.lat != "" && this.state.lng != "")
            {
                
                // var breakmarker = new window.google.maps.Marker({
                //     position: new window.google.maps.LatLng(this.state.lat,this.state.lng),
                //     map: map,
                //     icon: breakIcon,
                //     // title : ""+params.address+","+params.forecast_date+","+params.description
                // });
                // var infowindow = new window.google.maps.InfoWindow({
                //     content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> No. of Unloads : </label> "+this.state.no_of_unloads+"</div><div class='address'> <label class='forecast-labels'>Distance From Dealer :</label> "+this.state.distancefromdealer+"</div></div>"
                //     });
                // window.google.maps.event.addListener(breakmarker, 'mouseover', function() {
                // infowindow.open(map,breakmarker);
                // });
                // window.google.maps.event.addListener(breakmarker, 'mouseout', function() {
                // infowindow.close();
                // });
                var bounds = new window.google.maps.LatLngBounds();
                // bounds.extend(new window.google.maps.LatLng(coords.lat,coords.lng));
                bounds.extend(new window.google.maps.LatLng(this.state.lat,this.state.lng));
            }
            // this.setState({
            //     breaksSlider : "slider-translate-70p",
            // })
            map.fitBounds(bounds)
            map.setZoom(5);
        }catch(e){
            console.log(e);
        }
          
    }
    onSelectDataType(params)
    {
        this.setState({
            selectedDataType : params
        })
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onSelectState = state =>{
        console.log(state);
		var originalDistricts = this.state.original_district_list 
        var districts = []
        districts.push({label:'ALL' , value : "all"})
        originalDistricts.map((e)=>{
            if(state.label!='ALL'){
                if(e._id.consignee_state==state.label){
                    districts.push({label:e._id.consignee_district,value:e._id.consignee_district})
                }
            }
            else{
                districts.push({label:e._id.consignee_district,value:e._id.consignee_district})
            }
        })
        console.log(districts,"districts")
        this.setState({
            selectedState : state,
            district_list:districts
        });
    }
    onSelectTNPPlant = plant =>{
        console.log(plant);
        if(plant.value != "all")
        {
            var plant = plant;
        }
        else
        {
            var plant = "";
        }
        this.setState({
            selectedTnpPlant : plant
        })
    }
    onSelectLocation= district =>{
		this.setState(
			{ district },
			() => console.log(`Transporter Option selected:`, this.state.district)
          );
          this.setState({
            filteredPlantData : []
          });
        console.log(district);
        
        var uniqueCitesAndDealer = this.state.uniqueCitesAndDealer
        console.log(uniqueCitesAndDealer,"uniqueCitesAndDealer")
        var transporters = []
        transporters.push({label: "ALL" , value : "all"})
        uniqueCitesAndDealer.map(e=>{
            if (e._id.consignee_name != null && e._id.consignee_name!="" && e._id.consignee_code!=null && e._id.consignee_code!=""){
                if (district.label!='ALL'){
                    if (e._id.consignee_district == district.value){
                        transporters.push({label:e._id.consignee_name , value : e._id.consignee_code})
                    }
                }else{
                    transporters.push({label:e._id.consignee_name , value : e._id.consignee_code})
                }
            }
            
        })
        this.setState({
            transporters : transporters
        })
    }
    onSelectTransporter (transporter){
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
          })
    }
    stateOptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.statesList;
        //console.log("locations ",locations)
        items.push({value:"all",label:"All"})
        if(locations.length > 0){
            locations.map(item =>{
                if(item != "" && item != null)
                {
                    items.push({value:item,label:item});
                }
                 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    tnpPlantOptions(){
        var items=[];
        items.push({"value":"all",label:"ALL"});
        items.push({"value":"MSIL-G",label:"Gurgaon"});
        items.push({"value":"MSIL-M",label:"Manesar"});
        items.push({"value":"MSILMC",label:"Casting"});
        items.push({"value":"MSILME",label:"Power Train"});
        items.push({"value":"SMG",label:"Gujarat"});
        items.push({"value":"MUL",label:"SMIPL"});
        return items;
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        items.push({"value":"all",label:"All"})
        if(locations.length > 0){
            locations.map(item =>{
                if(item != "" && item != null)
                {
                    items.push({value:item.for_city,label:item.for_city});
                }
                 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item,label:item}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            breaksSlider : "",
            selectedRow : [],
		});
		
    }
    onClickGetLoadingData(event){
        event.preventDefault();
        if(this.state.startDate != undefined && this.state.startDate != "")
			{
				if(this.state.endDate == undefined || this.state.endDate == "")
				{
                    this.setState({
                        show:'show-m',
                        basicType:"warning",
                        basicTitle:"Please Select End Date"
                    })
				}
			}
		if(this.state.endDate != undefined && this.state.endDate != "")
			{
				if(this.state.startDate == undefined || this.state.startDate == "")
				{
					this.setState({
                        show:'show-m',
                        basicType:"warning",
                        basicTitle:"Please Select Start Date"
                    })
				}
			}
		if(this.state.startDate != undefined && this.state.startDate != "" && this.state.endDate != undefined && this.state.endDate != ""){
				if(this.state.startDate > this.state.endDate)
				{
                    this.setState({
                        show:'show-m',
                        basicType:"warning",
                        basicTitle:"Start Date should be less than End Date"
                    })
				}
			}
        
        if(this.state.startDate <= this.state.endDate){
            this.setState({
                loadshow:'show-m',
                overly:"show-m",
                lat : "",
                lng : "",
                distancefromdealer : "",
                no_of_unloads : "",
                plantRowData : [],
                rowData : [],
                unloaddrilldown : [],
                is_show_charts:true
            });
        
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        console.log(this.state.selectedState);
        var states=[]
        if(this.state.selectedState != null && this.state.selectedState.value != "all")
        {
            if(this.state.selectedState != "")
            {
                states.push(this.state.selectedState.value);
                states = JSON.stringify(states);
            }
        } 
        else
        {
            states = ""
        }
        var district=[]
        if(this.state.district != "" && this.state.district != null && this.state.district.value != "all")
        {
            district.push(this.state.district.value);
        }
        
        if(district.length == 0)
        {
            district = ""
        }
        else{
            district = JSON.stringify(district);
        }
        var transporter=[]
        if(this.state.transporter != "" && this.state.transporter != null && this.state.transporter.value != "all")
        {
                transporter.push(this.state.transporter.value);
        }  
        if(transporter.length == 0)
        {
            transporter = ""
        }
        else
        {
            transporter = JSON.stringify(transporter); 
        }
        var data_type = this.state.selectedDataType.value;
            if(data_type == "ALL")
            {
                data_type = "";
            }
        console.log(this.state.dept_code);
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
            consignee_districts : district,
            consignee_codes : transporter,
            consignee_states : states,
            dept_code : this.state.dept_code,
            data_type : data_type
        }
        console.log(reqparams,"reqparamsss");
        redirectURL.post("/dashboard/getSndUnloadChartData",{"formdata":reqparams,
                                                            "consignee_state":this.state.selectedState.value,
                                                            "consignee_district" : this.state.district.value,
                                                            "consignee_code":this.state.transporter.value}).then((resp)=>{
            
            var city_dealer_uld_data = JSON.parse(resp.data.chartdata.city_dealer_uld_data);
            var city_uld_data = JSON.parse(resp.data.chartdata.city_uld_data);
            var top_10_dealer_data = JSON.parse(resp.data.chartdata.top_10_dealer_data);
            var possibleUnloadData  = resp.data.possibleUnloadData
            this.setState({
                city_uld_data : city_uld_data,
                city_dealer_uld_data : city_dealer_uld_data,
                rowData : top_10_dealer_data,
                plantRowData : [],
                possibleUnloadData : possibleUnloadData
            })
                if(this.state.transporter != "" && this.state.transporter.value != "all")
                {
                    this.formatChartData(this.state.transporter.value);
                }
                else{
                    this.formatChartData("");
                }
                
            // }
            
        }).catch((e)=>{
            console.log(e);
        })
    }}
    onClickGetTNPData()
    {
        this.setState({
            rowData : [],
            cityWiseSeriesData : [],
            loadshow : "show-m",
            overly : "show-m"
        });
        var consignee_codes=[];
        console.log(this.state.selectedTnpPlant);
        if(this.state.selectedTnpPlant.value != 'all' && this.state.selectedTnpPlant != null && this.state.selectedTnpPlant != "")
        {
            consignee_codes.push(this.state.selectedTnpPlant.value);
            consignee_codes = JSON.stringify(consignee_codes);
        }
        else{
            consignee_codes = ""
        }
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
            consignee_codes : consignee_codes,
            dept_code : this.state.dept_code
        }
        redirectURL.post("/dashboard/getSndUnloadChartData",{"formdata":reqparams}).then((resp)=>{
            var dealer_uld_data = JSON.parse(resp.data.chartdata.dealer_uld_data);
            var top_10_dealer_data = JSON.parse(resp.data.chartdata.top_10_dealer_data);
            this.setState({
                dealer_uld_data : dealer_uld_data,
                rowData : top_10_dealer_data,
                loadshow : "show-n",
                overly : "show-n"
            });
            this.formatTNPData();
        }).catch((e)=>{
            console.log(e);
        })
    }
    showLocation(params){
        this.setState({
            lat : params.data.lat,
            lng : params.data.lng,
            distancefromdealer : params.data.distance_from_dealer_location_km.toFixed(2)+" KM",
            no_of_unloads : params.data.no_of_breaks,
        });
        this.initMapWeather()
    }
    // CUSTOM AUTOMCOMPLETE DROPDOWN
    initialList =(event)=>{
        console.log("intital List")
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.locations;
        dataset = dataset.slice(0,100);
        console.log(dataset);
        this.setState({
            filteredLocationData : dataset,
            showLocationList : "show-m",
        })
    }
    initialPlantList=(event)=>{
        $(".transporter-dropdown").removeClass("show-n");
        var dataset = this.state.transporters;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredPlantData : dataset,
            showPlantList : "show-m",
        })
    }
    handlerForm = (event) => {
        console.log("handler form");
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        try
        {
            $(".trucks-dropdown").removeClass("show-n");
            var dataset = this.state.locations;
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    return e.toString().toLowerCase().includes($("#inputLocation").val().toLocaleLowerCase());
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            if(filteredResult.length > 0 )
            {
                this.setState({
                    filteredLocationData : filteredResult.sort(),
                    showLocationList : "show-m",
                })
            }
            else{
                this.setState({
                    filteredLocationData : filteredResult.sort(),
                    showLocationList : "show-n",
                })
            }
            
        }catch(e){}
        
    }
    
    handlerPlantForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        try
        {
            $(".transporter-dropdown").removeClass("show-n");
            var dataset = this.state.transporters;
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    return e.toString().toLowerCase().includes($("#inputTransporter").val().toLocaleLowerCase());
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            if(filteredResult.length > 0 )
            {
                this.setState({
                    filteredPlantData : filteredResult.sort(),
                    showPlantList : "show-m",
                })
            }
            else{
                this.setState({
                    filteredPlantData : filteredResult.sort(),
                    showPlantList : "show-n",
                })
            }
            
        }catch(e){}
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    // END
    render(){
        //console.log("this.state.locations ",this.state.locations)
   
        var taburl = "/snddashboardtemplate";
        var loadingurl='';
        var transiturl='';
        var transporturl='';
        if(this.state.dept_code == "SNDG")
        {
            taburl="/snddashboardtemplate";
            loadingurl=`${process.env.PUBLIC_URL}/sndloadingdashboard`
            transiturl="/sndtransitanalytics";
            transporturl="/sndtransportdashboard";
        }
        if(this.state.dept_code == "LOG-TNP")
        {
            taburl="/tnpdashboardtemplate";
            loadingurl="/tnploadingdashboard";
            transiturl="/tnptransitanalytics";
            transporturl="/tnptransportdashboard";
        }
        if(this.state.dept_code == "LOG-PRT")
        {
            taburl="/prtdashboardtemplate";
            loadingurl="/prtloadingdashboard";
            transiturl="/prttransitanalytics";
            transporturl="/prttransportdashboard";
        }
        if(this.state.dept_code == "LOG-SC")
        {
            taburl="/scdashboardtemplate";
        }
        
        var  hideCity = (this.state.dept_code == "LOG-TNP")?true:false;
        const columnwithDefs=[
            {
                headerName : "Customer Code",
                field :"consignee_code",
                // width :100,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Customer Name",
                field : "consignee_name",
                // width:250,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Customer District",
                field :"consignee_district",
                // width :100,
                filter : true,
                editable : false,
                resizable : true,
                hide : hideCity
            },
            {
                headerName : "No. of Trips",
                field : "no_of_trips",
                // width :80,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Unloading Time (Hours)",
                field : "unloading_time_in_hrs",
                filter : true,
                // width:130,
                editable : false,
                resizable : true,
                valueGetter : function(params)
                {
                    return parseFloat(params.data.unloading_time_in_hrs.toFixed(2));
                }
            }
        ]
        const dStyles={
            width:'100%',
            height:'450px',
            // marginTop : "20px"
        }
        const plantColumnsDefs = [
            {
                headerName : "Address",
                field : "address",
                width:370,
                filter : true,
                editable : false,
                resizable : true,
               
            },
            {
                headerName : "Area",
                field : "area",
                width:100,
                filter : true,
                editable : false,
                resizable : true,
               
            },
            {
                headerName : "State",
                field : "state",
                width:100,
                filter : true,
                editable : false,
                resizable : true,
               
            },
           
            {
                headerName : "City",
                field : "city",
                width:100,
                filter : true,
                editable : false,
                resizable : true,
               
            },
           
            {
                headerName : "Pincode",
                field : "pincode",
                width:100,
                filter : true,
                editable : false,
                resizable : true,
               
            },
            {
                headerName : "No. of Unloads",
                field :"no_of_breaks",
                width :100,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Distance From Dealer Location (KM)",
                field : "distance_from_dealer_location_km",
                width:200,
                filter : true,
                editable : false,
                resizable : true,
                valueGetter : function(params){
                    return parseFloat(params.data.distance_from_dealer_location_km.toFixed(2))
                }
            },
           

            
        ]
        const possibleDataColumnDefs=[
            {
                headerName : "",
                field : "_id",
                cellRenderer : "ArrivalMap",
                width : 70
            }
            ,
            {
                headerName : "Customer Code",
                field :"consignee_code",
                width :100,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Customer Name",
                field :"consignee_name",
                width :190,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Customer State",
                field :"consignee_state",
                width :150,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Customer District",
                field :"consignee_district",
                width :160,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Total Unloads",
                field :"total_no_of_breaks",
                width :100,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Avg. Unloading Distance (KM)",
                field :"average_break_dist_from_dealer",
                width :160,
                filter : true,
                editable : false,
                resizable : true
            },
            {
                headerName : "Nearest Unload Location (KM)",
                field :"closest_break_distance",
                width :160,
                filter : true,
                editable : false,
                resizable : true,
                valueGetter : function(params)
                {
                    if(params.data.closest_break_distance != null || params.data.closest_break_distance != "" && params.data.closest_break_distance != undefined)
                    {
                        return params.data.closest_break_distance.toFixed(2);
                    }
                }
            },
            {
                headerName : "Farthest Unload Location (KM)",
                field :"farthest_break_distance",
                width :160,
                filter : true,
                editable : false,
                resizable : true,
                valueGetter : function(params)
                {
                    if(params.data.farthest_break_distance != null || params.data.farthest_break_distance != "" && params.data.farthest_break_distance != undefined)
                    {
                        return params.data.farthest_break_distance.toFixed(2);
                    }
                }
            },
        ]
        // AUTOCOMPLETE 
        var listData = [] 
        try{
            this.state.filteredLocationData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        var plantListData = [] 
        try{
            this.state.filteredPlantData.map(function(e){
                plantListData.push(<li key={e} className="dropdown-transporter-no">{e}</li>)
                })
        }catch(e){}
        //END


        if(this.state.dept_code == "LOG-PRT")
        {
         var dailyurl = "/prtdailyrunningdashboard";
        }
        else
        {
          var dailyurl = "/dailyrunningdashboard";
        }
        return (
            <div className="col-xl-12 col-lg-12">
                <SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"} style={{minHeight:'200px'}}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>Unloading Dashboard</span>

                        </h5>
                    </div>
                    <div className=" col-xl-12 col-lg-12 mt-35p">
                        {(this.state.dept_code != "LOG-TNP") ?
                            <div className="row col-xl-12 col-lg-12">
                                {(this.state.dept_code == "LOG-PRT") ?
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Data Type </label>
                                        <Select placeholder={"All"}
                                            isMulti={false}
                                            value={this.state.selectedDataType}
                                            className="border-radius-0"
                                            onChange={this.onSelectDataType.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={[{ "label": "ALL", "value": "ALL" }, { "label": "Local Loads", "value": "LOCAL" },
                                            { "label": "Inter Plant", "value": "INTER" }, { "label": "Dealer Loads", "value": "DEALER" }]}
                                            required />
                                    </div>
                                    : ""}

                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Start Date </label>
                                    <Datetime value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off',readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>End Date </label>
                                    <Datetime value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off',readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="endDate"
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                </div>
                                <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select State  </label>
                                    <Select placeholder={"All"}
                                        isMulti={false}
                                        value={this.state.selectedState}
                                        className="border-radius-0"
                                        onChange={this.onSelectState.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.statesList}
                                        required />
                                </div>
                                {/* <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select District </label>
                                    <Select placeholder={"All"}
                                        isMulti={false}
                                        value={this.state.district}
                                        className="border-radius-0"
                                        onChange={this.onSelectLocation.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.district_list}
                                        required />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Choose Dealer  </label>
                                    <Select
                                        placeholder={"All"}
                                        isMulti={false}
                                        value={this.state.transporter}
                                        className="border-radius-0"
                                        onChange={this.onSelectTransporter.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.transporters} required />

                                </div> */}
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                                </div>
                            </div>
                            :
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Start Date </label>
                                    <Datetime value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>End Date: </label>
                                    <Datetime value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="endDate"
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                </div>
                                <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select Destination  </label>
                                    <Select placeholder={"All"}
                                        isMulti={false}
                                        value={this.state.selectedTnpPlant}
                                        className="border-radius-0"
                                        onChange={this.onSelectTNPPlant.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.tnpPlantOptions()}
                                        required />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickGetTNPData.bind(this)}>Submit</button>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.is_show_charts ?
                    <>
                     <div className='card' style={{margin:'20px'}}>
                        <div className={"row mt-40p " + this.state.showFirstLevel}>
                            {(this.state.dept_code != "LOG-TNP") ?
                                <div className="col-xl-12">
                                    {(Object.values(this.state.unloaddrilldown).length > 0) ?
                                        <SndUnloadingColumnChart
                                            title={"Avg. Unloading Time / Location"}
                                            subtitle={this.state.cityWiseSeriesData.length > 0 ? "":"No Data Found"}
                                            context={this}
                                            series={this.state.cityWiseSeriesData}
                                            categories={this.state.cityWiseCategory}
                                            seriesName="Unloading Time in Hrs"
                                            yaxistitle="Avg. Unloading Time (Hrs)"
                                            drilldownseries={this.state.unloaddrilldown}
                                        // dept_code={this.state.dept_code}
                                        />
                                        : ""}
                                </div>
                                :

                                <div className="col-xl-12">
                                    {(this.state.cityWiseSeriesData.length > 0) ?
                                        <SndUnloadingColumnChart
                                            title={"Avg. Unloading Time / Plant"}
                                            subtitle={""}
                                            context={this}
                                            seriesName="Unloading Time in Hrs"
                                            series={this.state.cityWiseSeriesData}
                                            categories={[]}
                                            yaxistitle="Avg. Unloading Time (Hrs)"
                                            drilldownseries={this.state.unloaddrilldown}
                                        // dept_code={this.state.dept_code}
                                        />
                                        : ""}
                                </div>

                            }

                        </div>

                    </div>
                    <div className='card' style={{margin:'30px',padding:'10px'}}>
                        {(this.state.dept_code != "LOG-TNP") ?

                            <div className={"row mt-40p " + this.state.showFirstLevel}>
                                <div className="col-xl-12">
                                <div className="col-xl-12 col-lg-12" style={{display:'flex',marginBottom:'15px'}}>
                                <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Top 10 Slowest Unloading Dealers</span>

                                    </h5>
                                </div>
                                    
                                    <div id="myGrid" style={{ width: "100%", height: "360px" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={true}
                                            sideBar={false}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            suppressRowClickSelection={true}
                                        />

                                    </div>
                                </div>
                            </div> : ""}

                    </div>
                    {/* {(this.state.possibleUnloadData.length>0)? */}
                   <div className='card' style={{margin:'30px',padding:'5px'}}>
                   <div>
                        {(Object.values(this.state.unloaddrilldown).length > 0) ?
                            <div className={"row mt-40p col-xl-12 " + this.state.showFirstLevel}>
                               <div className="col-xl-12 col-lg-12" style={{display:'flex',marginBottom:'20px'}}>
                               <h5 style={{marginBottom:'5px'}}>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Frequent Unload Locations</span>

                                </h5>
                               </div>

                                <div id="myGrid" style={{ width: "100%", height: "360px" }} className={"ag-theme-balham col-xl-12 col-lg-12"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={possibleDataColumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.possibleUnloadData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        sideBar={false}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                            : ""}
                    </div>

                   </div></>:""}

                   
                    {/* :""} */}
                    {this.state.breaksSlider != '' ?
                        <div className={"slide-r " + (this.state.breaksSlider)} style={{ overflow: "auto", width: "75%" }}>
                            <div className="slide-r-title" style={{ background: "cornflowerblue", textAlign: "center" }}>
                                <h5 className="" style={{ paddingTop: "6px" }}>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> Frequent Unload Locations ( {this.state.selectedConsigneeName} )
                                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                                </h5>
                                
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                {/* <div class="row col-xl-12 col-lg-12" style={{textAlign:"right"}}>
                                    <h5 style={{marginBottom:"19px"}}>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Transporter Unloading Data</span>
                                        
                                    </h5>
                                </div> */}
                                <div className={"row col-xl-12 mt-20p"} style={{ paddingBottom: "8em" }}>
                                    <div className="col-xl-12 col-lg-12">
                                        <div id="plantmap" style={dStyles}></div>
                                    </div>
                                    <div className="col-xl-12 mt-15p">
                                        {/* <span className="col-xl-12"> */}
                                        <div id="myGrid" style={{ width: "100%", height: "200px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={plantColumnsDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.selectedRow.frequent_break_locations}
                                                // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                rowSelection="single"
                                                onRowSelected={this.showLocation.bind(this)}
                                                statusBar={true}
                                                sideBar={false}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                suppressRowClickSelection={false}
                                            />
                                        </div>
                                        {/* </span> */}
                                    </div>
                                </div>
                                <div class={"row col-xl-12"}>


                                </div>
                            </div>
                        </div> : ""}
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
		}
}



function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }

//   $(document).ready(function(){
//     $(document).on("click",".dropdown-truck-no",function(){
//         var vals = $(this).text();
//          $("#inputLocation").val(vals);
//          $(".trucks-dropdown").removeClass("show-m");
//          $(".trucks-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputLocation");
//         var plant = $("#inputTransporter");
//         if(!container.is(e.target) && !plant.is(e.target))
//         {
//             try{
//                 if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
//                 {
//                     $(".trucks-dropdown").removeClass("show-m");
//                     $(".trucks-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })

// $(document).ready(function(){
//     $(document).on("click",".dropdown-transporter-no",function(){
//         var vals = $(this).text();
//          $("#inputTransporter").val(vals);
//          $(".transporter-dropdown").removeClass("show-m");
//          $(".transporter-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputTransporter");
//         var location = $("#inputLocation");
//         if(!container.is(e.target) && !location.is(e.target))
//         {
//             try{
//                 if($(".transporter-dropdown").attr("class").includes("show-m") || $(".transporter-dropdown").attr("class") == "transporter-dropdown")
//                 {
//                     $(".transporter-dropdown").removeClass("show-m");
//                     $(".transporter-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })

function distinctArrayBy(arr, propName,propName1) {

    var result = arr.reduce(function (arr1, e1) {
    
    var matches = arr1.filter(function (e2) {
    
    return e1[propName] == e2[propName] && e1[propName1] == e2[propName1];
    
    })
    
    if (matches.length == 0)

        arr1.push(e1)
        
        return arr1;
    }, []);
    return result;    
}

