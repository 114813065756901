

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
export default class DashboardDelayResponse extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:"",
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        filtered_data:"",
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"",
        eventGridAction: "gridAction",
        tolls : []
        //components : {datepicker:getDatePicker()}
    }
    this.onClickForceClose = this.onClickForceClose.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
    this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
    this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.onClickMarkDelivered = this.onClickMarkDelivered.bind(this);
  }

	componentDidMount(){
    loadDateTimeScript();
    this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
    var userDeptCode = localStorage.getItem('dept_code');
    var userEmailId = localStorage.getItem("email");
    var dupDeptCode = "";
    this.setState({
      // rowData : filteredData,
      loadshow : "show-m",
      overly:'show-m'
    })
    var pagetitle='';
    if(userDeptCode != '["AD"]'){
      userDeptCode = userDeptCode.replace(/"/g,"");
      //console.log(" userDeptCode ", userDeptCode)
    }
    else{
      userDeptCode = userDeptCode.slice(2,4);
     dupDeptCode = userDeptCode;
    }
    
    //console.log(userDeptCode,userEmailId);
    if(userEmailId == 'sivakumar.t@maruti.co.in')
    {
      userDeptCode = "LOG-PRT (Courier)";
      pagetitle="Critical Delays LOG-PRT (Courier)";
    }
    else{
      if(userDeptCode == "AD"){
        if(this.props.match.path == "/sndcriticaldelayreasons")
        {
          userDeptCode="SNDG"
          pagetitle="Critical Delay (Sales and Dispatch)";
        }
        if(this.props.match.path == "/couriercriticaldelayreasons"){
          userDeptCode = "LOG-PRT (Courier)";
          pagetitle="Critical Delay (LOG-PRT (Courier))";
        }
        if(this.props.match.path == "/prtcriticaldelayreasons")
        {
          userDeptCode="LOG-PRT"
          pagetitle="Critical Delay (Spare Parts)";
          
        }
        if(this.props.match.path == "/tnpcriticaldelayreasons")
        {
          userDeptCode="LOG-TNP"
          pagetitle="Critical Delay (Train and Production)";
        }
        if(this.props.match.path == "/sccriticaldelayreasons")
        {
          userDeptCode="LOG-SC"
          pagetitle="Critical Delay (Supply Chain)";
        }
      }
    }
    this.setState({
      pagetitle:pagetitle
    });
    var reqParams = {
      dept_code : userDeptCode
    }

    var userrols = localStorage.getItem("roles");
    //var splitrole = userrols.split(",")
			//console.log("splitrole ",splitrole)
			
      
    //console.log("reqParams ",reqParams);
    redirectURL.post("/consignments/summarysecondleveldata",reqParams)
	    .then(async (res) =>{
        console.log("res.data.message[0] ",res.data)
        var criticaldata=res.data.message[0].critical_data;
        console.log(criticaldata);
       // console.log(dupDeptCode);
        var filteredData = "";
        if(dupDeptCode != "AD"){
          userEmailId = userEmailId.replace("[at]","@")
          userEmailId = userEmailId.replace("[dot]",".")
          filteredData  = criticaldata.filter(x=>x.spoc_email_id == userEmailId);
        }
        else{
          filteredData = criticaldata;
        }
        //console.log(filteredData.length);
        console.log("filteredData ",filteredData);

        this.setState({
          rowData : filteredData,
          filtered_data : filteredData,
          loadshow : "show-n",
          overly:"show-n"
        })
      })
      .catch(function(e){
        console.log("Error ",e)
      })
    this.setState({
      rowData : [],
      dept_code : userDeptCode,
      modified_by:userEmailId,
      loadshow: "show-n",
      overly:"show-n"
    })


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
    })
    
  };
  logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
onShowCommentsDiv = (e) =>{
  if (googleAnalytics.page.enableGA) {
    let pageTitle = this.state.pagetitle;
    let eventOptions = {
      "category": pageTitle,
      "action": this.state.eventGridAction,
      "label": "Show Comments",
    }
    googleAnalytics.logEvent(eventOptions);
  }

  //console.log(e);
  var reqparams = {
    consignment_code : e.consignment_no,
    truck_no : e.consignment_code,
    dept_code : this.state.dept_code,
    type : "Delay Reason",
    screen : "Delay Reason"
  }
  //console.log(reqparams);
  //console.log("HERE");
  redirectURL.post("/consignments/getCommentLogs",reqparams).then((response)=>{
    console.log(response.data);
    this.setState({
      commentsRowData : response.data.data
    })
  }).catch(function(error){
    console.log(error);
  })
  this.setState({
    sliderCommentTranslate:"slider-translate-60p",
    showDiv:'show-m',
    loadshow:'show-n',
    overly:'show-m',
  });
}
resetUpload = () => {
  this.setState({
    uploadDivWidth:'0%',
    bulkslide:'',
    showDiv:'show-n',
    overly:'show-n',
    file:''
  });
  document.getElementById("upform").reset();
}

changeFileHandler = async (e) => {
  //console.log(e.target.files[0])
  var dt = '';
  // const config = {
  // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
  // }

  const config = {
    headers: [
      { 
        name: 'consignment_code',
        inputName: 'consignment_code',
        required: true,
        requiredError: function (headerName, rowNumber, columnNumber) {
          return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        }
      },{ 
        name: 'dept_code',
        inputName: 'dept_code',
        required: true,
        requiredError: function (headerName, rowNumber, columnNumber) {
          return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        }
      },{ 
        name: 'reason',
        inputName: 'reason',
        required: true,
        requiredError: function (headerName, rowNumber, columnNumber) {
          return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        }
      },
    ]
  }
  // var filecontent="";
  // console.log(e.target.files[0]);
  var data = CSVFileValidator(e.target.files[0], config)
  .then((csvData) => {
    this.setState({
      csvcontent:csvData.data
    });
  })
  .catch(err => {
    console.log("Error");
  })
  
  this.setState({
    file:e.target.files[0]
  });
  //console.log("e.target.files[0].type ", e.target.files[0].type);

  if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
  {
    // console.log(filecontent);
  }
  else{
    e.target.value = null;
    this.setState({
      uploadFile:'',
      show: true, 
      basicType:'danger', 
      basicTitle:'Please upload file having extensions .csv only.',
    });
  }
  
}

uploadBulkFormHandler(event)
{
  event.preventDefault();
  
  if(this.state.file != '')
  {
    //console.log("this.state.file ",this.state.file)
    var csvdd = this.state.csvcontent;
    //console.log(csvdd);
    var flag = 0
    csvdd.map(function(e){
      // console.log(e);
      var keys = Object.keys(e);
      if(keys.length > 0){
        if(keys[0] != "consignment_code" && keys[1] != "dept_code" && keys[2] != 'reason')
        {
          flag = 1;
        }
      }
    });
    if(flag == 1){
      this.setState({
        csvcontent:'',
        file:"",
        show: true, 
        basicType:'danger', 
        basicTitle:'Headers Not Found',
      });
    }
    else
    {
      var fileContents = this.state.csvcontent;
      var inputData = []
      fileContents.map(function(e){
        var temp = [];
        var keys = Object.keys(e);
        if(keys.length > 0){
          temp.push(e.consignment_code);
          temp.push(e.dept_code);
          temp.push(e.reason);
          inputData.push(temp);
        }
      });
      //console.log(inputData);
    }
    
    // console.log("event ",event.target.uploadFile.value);
    // var formData = new FormData();
    // formData.append('uploadFile',this.state.file);
    // formData.append("userId", localStorage.getItem('userid'));
    // formData.append("csvcontent",this.state.csvcontent);
    // var rparams = {
    //   uploadFile:this.state.file,
    //   userId:localStorage.getItem('userid'),
    //   csvcontent:this.state.csvcontent
    // }
    // //console.log('file',formData);
    // //console.log('rparams',rparams);
    // this.setState({
    //   loadshow:'show-m'
    // });
  }
}
  onGridReady = params => {
		this.gridApi = params.api;  
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }

    getRelatedDeptData(params){
      if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
          "category": pageTitle,
          "action": this.state.eventGridAction,
          "label": "Critical Delays Data",
        }
        googleAnalytics.logEvent(eventOptions);
      }
  
      this.setState({
        loadshow : "show-m",
        dept_code : params.value
      })
      console.log(params);
      var dept_code = params.value;
      var rqparams={
        dept_code:dept_code
      }
      var criticaldata = [];
      redirectURL.post("/consignments/summarysecondleveldata",rqparams)
	    .then(async (res) =>{
        //console.log("res.data.message[0] ",res.data.message[0])
        criticaldata=res.data.message[0].critical_data;
        console.log(criticaldata);

        this.setState({
          rowData : criticaldata,
          loadshow : "show-n"
        })
      })
      .catch(function(e){
        console.log("Error ",e)
      })

    }
    onShowTimelineDiv = (e) => {
      //console.log("Props data ", e)
      this.setState({
        loadshow:'show-m'
      });
      var reqData = {
              consignment_code:e.consignment_code
          }
          //console.log("reqData: ", reqData);
      redirectURL.post('/consignments/timeline',reqData, {
              headers:{
                  'content-type':'application/json'
              }
          })
      .then((response) => {
        var records = response.data;
        //console.log("records", records);
      
        this.setState({
          uploadDivWidth:'30%',
          sliderTranslate:"slider-translate-50p",
          showDiv:'show-m',
          timelinedata:records,
          loadshow:'show-n',
          overly:'show-m'
        });
      })
      .catch(function (error) {
      console.log(error);
      });
      
    }


    handleInput(val) {
      this.setState({ reasonforceclose: val });
      }
    closeAlert = () => {
      this.setState({
          show: false
      });
  }
    selectDept(){
      let deptArr=[];
      deptArr.push({value:"SNDG",label:"Sales & Dispatch"});
      deptArr.push({value:"LOG-PRT",label:"Spare Parts (Domestic)"});
      deptArr.push({value:"LOG-PRT (Courier)",label:"Spare Parts (Courier)"});
      return deptArr;
    }

    onCellUpdateData = (params) => {

      if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
          "category": pageTitle,
          "action": this.state.eventGridAction,
          "label": "Critical Data Updation",
        }
        googleAnalytics.logEvent(eventOptions);
      }

      
     // console.log("as ", params)
      if(params.colDef.field == 'delay_reason')
		  {
        var e = params.data;
                
        // if(oldDataFlag == 0)
        // {
        if(e.delay_reason.replace(/\s/g,"").length)
        {
                if(this.state.dept_code == 'LOG-SC')
                {
                  var reqParams = {
                    dept_code : this.state.dept_code,
                    container_no : e.container_no,
                    spoc_delay_reason : e.delay_reason,
                    status : e.status,
                    modified_by : this.state.modified_by,
                  }
                }
                else{
                  var reqParams = {
                    dept_code : this.state.dept_code,
                    // editedDataArr : editedDataArr,
                    truck_no : e.consignment_code,
                    spoc_delay_reason : e.delay_reason,
                    consignment_no : e.consignment_no,
                    status : e.status,
                    modified_by : this.state.modified_by
                  }
                }
                
              var userrols = localStorage.getItem("roles");
              
              //console.log("splitrole ",splitrole)
              try{
                var splitrole = userrols.split(",")
                if(splitrole.length > 0)
                {
                  if(splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true)
                  {
                    reqParams.transporter="yes"		
                  }
                }
              }
              catch(e){
                console.log("Error ",e)
              }      
              
                console.log(reqParams);
                
                redirectURL.post("/consignments/updateDelayReason",reqParams).then((response)=>{
                
                }).catch(function(e){
                  console.log("Error ",e);
                })
          // }
          // else{
          //   console.log("Empty");
          // }
        }   
        else
        {
          // this.setState({
          //   basicTitle : "Old Reason Updated",
          //   basicType : "danger",
          //   show : true
          // })
        } 
      }
      
    }


    formAssignHandler(params){
      // var deptcode = params.data.value;
      var itemsToUpdate = [];
		  this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
          var data = rowNode.data;
          itemsToUpdate.push(data);
        });
        // console.log(itemsToUpdate);
        var editedDataArr = [];
        itemsToUpdate.map(function(e){
            // console.log(cleanTruckNo(e.new_truck_no),getDDMMYYYYHHMMSS(e.new_rake_dispatch_date));
            var temp = {
                truck_no : e.consignment_code,
                spoc_delay_reason : e.delay_reason,
                consignment_no : e.consignment_no,
                status : e.status
                // new_truck_no : cleanTruck/No(e.new_truck_no),
            };
            editedDataArr.push(temp);
        });
        var reqParams = {
          dept_code : this.state.dept_code,
          editedDataArr : editedDataArr
        }
        console.log(reqParams);
        redirectURL.post("/consignments/updateDelayReason",reqParams).then((response)=>{
          // console.log(response);
            this.setState({
              show:true,
              basicTitle:"Updated Successfully.",
              basicType:"success",
          });
        }).catch(function(e){
          console.log("Error ",e);
        })
    }
    onClickShowBulkUpload(){
      this.setState({
        bulkslide:"slider-translate",
        overly:'show-m'
      });
    }
    onClickMarkDelivered = (params) =>{
      this.setState({
         markDeliverRowNode : params,
         markDeliverData:params.data,
         sliderMarkDeliverTranslate:"slider-translate",
         overly:'show-m'
       })
    }
    onClickHideAll(){
      this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        sliderCommentTranslate:"",
        sliderRouteTranslate:'',
        tabsliderTranslate:'',
        sliderTranslatesidebar:'',
        sliderForceCloseTranslate:'',
        sliderMarkDeliverTranslate :""
      });
      
    }
    showGoogleRouteAlert = () => {
      this.setState({
        show: true,
        basicTitle:"No google routes found",
        basicType:"danger"
      });
    }

    onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
      //console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
      if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
          "category": pageTitle,
          "action": this.state.eventGridAction,
          "label": googleAnalytics.page.action.viewRoute,
        }
        googleAnalytics.logEvent(eventOptions);
      }
  
      var reqData = {
        consignment_code:consignmentcode,
        consignee_code:consigneecode,
        leg_no:legno,
        truck_no:truck_no
      }
      redirectURL.post('/consignments/googlemaproutes',reqData, {
              headers:{
                  'content-type':'application/json'
              }
          })
      .then((response) => {
       //console.log("Google ", response)
        //var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
        this.setState({
          googelRoutes:response.data.googleroute
        })
      })
      .catch(function(e){
        console.log(e)
      })
    }
    onShowRouteDiv = (e) => {
      if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
          "category": pageTitle,
          "action": this.state.eventGridAction,
          "label": googleAnalytics.page.action.viewRoute,
        }
        googleAnalytics.logEvent(eventOptions);
      }
  
      //console.log("ee ",e)
      if (googleAnalytics.page.enableGA) {
              let eventOptions = {
          "category": this.state.pagetitle,
          "action": this.state.eventGridAction,
          "label": googleAnalytics.page.action.viewRoute,
        }
        googleAnalytics.logEvent(eventOptions);
      }
      
      // console.log("IN onShowRouteDiv, Route params", e)
      this.setState({
        loadshow:'show-m',
        overly:'show-m'
      });
  
      // If gateouttime is current time - It shows "No Route data available"
      if(e.invoice_time)
      {       
        if(e.invoice_date.includes("T") == true)
        {
          var gateouttime = moment.parseZone(e.invoice_date).format('YYYY-MM-DD HH:mm:ss');
        }
        else
        {
          var gateouttime = e.invoice_date;
          gateouttime = gateouttime.split("-")[2]+"-"+gateouttime.split("-")[1]+"-"+gateouttime.split("-")[0];
          gateouttime = gateouttime+" 00:00:00";
        }
        console.log(gateouttime,"gateouttime - invoice_time")
      }
      else if(e.gate_out_time)
      {
        gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
        console.log(gateouttime,"gateouttime - gate_out_time")
        
      }
      else {
        gateouttime = moment.parseZone(e.mgpa_date_time).format("YYYY-MM-DD HH:mm:ss")
        console.log(gateouttime,"gateouttime - gate_out_time last")
      }
      
      // if (e.gate_out_time) {
      // 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
      // }
      
      var edate ='';
      var end_date;
      if(e.status <= 4)
      {
        //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
        //var edatesecond = new Date(ms);
        end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
        
        edate = getHyphenDDMMYYYYHHMMSS(end_date);
      }
      
      var reqData = {
        truck_no:e.consignment_code,
        consignment_codes:[e.consignment_no],
        //consignee_code:e.consignee_code,
        fromdate:gateouttime,
        todate:end_date,
        email:localStorage.getItem("email")
        // screen:"consignment"
          }
          console.log("reqData: ", reqData);
          redirectURL.post('/trucks/truckmap',reqData)
          .then((response) => {
            var records = response.data;
            console.log("Props data ", response.data)
      
            this.setState({
              loadshow:'show-m'
            });
            try
            {
              if(records.coords.length == 0)
              {
                //console.log("records", records.coords);
                this.setState({
                  show: true,
                  basicTitle:'No Route data available',
                  basicType:"danger",
                  loadshow:'show-n',
                  overly:'show-n'
                });
              }
              else{
                if(records != '')
                {
                  
                  this.setState({
                    sliderRouteTranslate:"slider-translate-60p",
                    showDiv:'show-m',
                    mapinfo:records,
                    dealer:"",
                    consignment_code:"Truck NO : "+e.consignment_code,
                    maptruckno:e.truck_no,
                    routeTruck:	{"truck_no":e.consignment_code,"startTime":response.data.route_details.start_time,"endTime": response.data.route_details.end_time},
                    loadshow:'show-n',
                    sidebarSubHeader:"Truck Route Details",
                    overly:'show-m',
                    rownode:e,
                    consigner_coordinates:records.consigner_coordinates,
                    consignee_coordinates:records.consignee_coordinates,
                    tolls : records.tolls
        
                  });
                  //this.renderMap();
                }
              }
            }
            catch(e){
              if(records.message == "no data found")
              {
                  this.setState({
                      show: true,
                      basicTitle:'No Route data available',
                      basicType:"danger",
                      loadshow:'show-n',
                      loadshow:'show-n',
                      overly:'show-n'
                  });
              }
              else{
                  console.log(e);
              }
            }
          
            
            
            
          })
          .catch(function (error) {
            console.log(error);
          });
    }


    onCloseUploadDiv = () => {
      this.setState({
        uploadDivWidth:'0%',
        // tabsliderTranslate:'',
        sliderRouteTranslate : "",
        showDiv:'show-n',
        loadshow:'show-n'
      });
    }
    onCloseRouteDiv = () => {
      this.setState({
        sliderRouteTranslate:"",
        showDiv:'show-n',
        sliderCommentTranslate : "",
        overly:'show-n'
      });
    }

    formForceCloseHandler(event){
      event.preventDefault()
      var consignmentId = this.state.forceclosedata.consignment_code;
      var forceCloseReason = $("#forceClosureResonID").val();
      var email = localStorage.getItem("email");
      // console.log(forceCloseReason,email);
      var reqParams = {
        consignmentId : consignmentId,
        forceCloseReason : forceCloseReason,
        email : email
      }
      //console.log(reqParams);
      if(forceCloseReason.replace(/\s/g,"").length)
      {
        redirectURL.post("/consignments/updateCourierForceClosure",reqParams).then((response)=>{
          this.setState({
            sliderForceCloseTranslate : "",
            loadshow : "show-n",
            overly:"show-n",
            basicTitle : "Force Closed Successfully",
            basicType : "success",
            show : true,
            forceCloseRowNode : "",
            forceclosedata:"",
          });
          // window.location.reload();
        }).catch((e)=>{
          console.log(e)
        })
      }
      else
      {
        this.setState({
          basicTitle : "Reason Should not be Empty",
          basicType : "danger",
          show : true,
        });
      }
      
  
    }
    formMarkDeliverHandler(event)
    {
      event.preventDefault()
      var consignmentId = this.state.markDeliverData.consignment_code;
      var delivery_date_time = $("#delivery_date_time").val();
      var email = localStorage.getItem("email");
      var reqParams = {
        consignmentId : consignmentId,
        delivery_datetime  : delivery_date_time,
        email : email
      }
      if(delivery_date_time != "__-__-____")
      {
        // console.log(reqParams);
      redirectURL.post("/consignments/courierMarkDelivered",reqParams).then((response)=>{
        this.setState({
          sliderMarkDeliverTranslate : "",
          loadshow : "show-n",
          overly:"show-n",
          basicTitle : "Marked as Delivered",
          basicType : "success",
          show : true,
          markDeliverRowNode : "",
          markDeliverRowNode:"",
        })
        // window.location.reload();
      }).catch((e)=>{
        console.log(e)
      })
      }
      
      else
		{
			this.setState({
				basicTitle : "Delivery Date is Not Selected.",
				basicType : "danger",
				show : true
			})
		}
    }




    getFile = (params) =>{
      console.log(params);
    }
    onClickForceClose = (params) =>{
      //console.log("Pareas ", params)
      this.setState({
       forceCloseRowNode : params,
        forceclosedata:params.data,
        sliderForceCloseTranslate:"slider-translate",
        overly:'show-m'
      })
    }


  
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';
    var userDeptCode = localStorage.getItem('dept_code');
    var userEmailId = localStorage.getItem("email");
    if(userEmailId == 'sivakumar.t@maruti.co.in')
    {
      screenpage="Critical Delay LOG-PRT (Courier)";
    }
    else{
      if(userDeptCode == "AD"){
        if(this.props.match.path == "/sndcriticaldelayreasons")
        {
          screenpage="Critical Delay (Sales and Dispatch)";
        }
        if(this.props.match.path == "/couriercriticaldelayreasons"){
          screenpage="Critical Delay (LOG-PRT (Courier))";
        }
        if(this.props.match.path == "/prtcriticaldelayreasons")
        {
          screenpage="Critical Delay (Spare Parts)";
          
        }
        if(this.props.match.path == "/tnpcriticaldelayreasons")
        {
          screenpage="Critical Delay (Train and Production)";
        }
        if(this.props.match.path == "/sccriticaldelayreasons")
        {
          screenpage="Critical Delay (Supply Chain)";
        }
      }
    }
    console.log("screenpage ", screenpage)
		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

  render() {
    var header="";
    var field = "";
    var hidden = "";
    var courier_Headers;
    var hideTruckNo;
    var consginmentHeader={}
    if(this.state.dept_code == "SNDG"){
      header = "Truck No";
      hidden = true;
      courier_Headers = true;
    }
    else if(this.state.dept_code == "LOG-PRT" || this.state.dept_code == "LOG-PRT (Courier)"){
      header = "Truck No";
      hidden = false;
      if(this.state.dept_code == "LOG-PRT (Courier)"){
        courier_Headers = false;
        hideTruckNo = true;
      }
      else{
        courier_Headers = true;
        hideTruckNo = false;
      }
      
    }
    else if(this.state.dept_code == "LOG-SC"){
      header = "Container No";
      hidden = false;
      courier_Headers = true;
    }
    else if(this.state.dept_code == "LOG-TNP"){
      header = "Truck No";
      hidden = false;
      courier_Headers = true;
    }
    else{
      header = "Consignment Code";
      hidden = true;
      courier_Headers = true;
    }
    var hideForceClose;
    var transporterCodeHide;
    if(this.state.dept_code == "LOG-PRT (Courier)")
    {
      hideForceClose = false
      transporterCodeHide = true;
    }
    else{
      hideForceClose = true;
      transporterCodeHide = false;
    }
    var hiddencols = false;
    if(this.props.match.path == "/sccriticaldelayreasons" || this.state.dept_code == 'LOG-SC')
    {
      hiddencols = true;
    }
    if(this.props.match.path == "/sccriticaldelayreasons" || this.state.dept_code == 'LOG-SC')
    {
    
        var columnwithDefs = [         
        {
            headerName: header,
            field: "container_no",
            width:150,
            pinned : "left",
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "POL/Origin Country",
            field: "country_code",
            // width:100,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Supplier",
            field: "supplier_code",
            // width:100,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Status",
            field: "status",
            // width:100,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Critical MSIL ETA",
            field: "critical_msil_eta",
            editable:true,
            width:150,
            filter: true,
            pinned : "left",
            resizable: true,
            editable:false,
            valueGetter : function(params){
              if(params.data.critical_msil_eta != ""){
                return getHyphenDDMMMYYYYHHMM(params.data.critical_msil_eta);
              }
              else{
                return "";
              }
            }
            
        },
        {
            headerName: "Transit Delay (Days)",
            field: "transit_delay_days",
            editable:true,
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "SPOC Name",
            field: "spoc_contact_name",
            editable:true,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "SPOC Contact No.",
            field: "spoc_contact_number",
            editable:true,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Delay Reason",
            field: "delay_reason",
            width:245,
            editable:true,
            filter: true,
            resizable: true,
        }
      ]
    }
    else{
      var urlName=this.props.match.path;     
      var columnwithDefs = [
          {
            headerName: "",
            field: "",
            width:50,
            pinned : "left",
            cellRenderer:'consignmentActions',
            filter: true,
            resizable: true,
            editable:false
        },
        {
          headerName : "",
          field:"_id",
          pinned : "left",
          hide : hideTruckNo,
          width:40,
          cellRenderer : "commentActions"
        },
        {
            headerName: header,
            field: "consignment_code",
            width:130,
            pinned : "left",
            filter: true,
            resizable: true,
            hide : hideTruckNo,
            editable:false
        },
        
        {
          headerName: "Consignment code",
          field: "consignment_no",
          width:120,
          pinned : "left",
          filter: true,
          hide : hidden,
          resizable: true,
          editable:false
        },
        {
          headerName: "MGPA Date",
          field: "",
          width:150,
          pinned : "left",
          filter: true,
          resizable: true,
          editable:false,
          hide : courier_Headers,
          valueGetter : function(params){
            if(params.data.mgpa_date_time != "" && params.data.mgpa_date_time != null && params.data.mgpa_date_time != undefined){
              return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time);
            }
            else{return "";}
          }
        },
        {
            headerName: "Consigner Code",
            width:150,
            // width:100,
            // pinned : "left",
            field: "consigner_code",
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:150,
            // pinned : "left",
            filter: true,
            resizable: true,
            editable:false
        },
        {
          headerName: "Courier Status",
          field: "last_status",
          width:150,
          // pinned : "left",
          filter: true,
          resizable: true,
          hide : hideForceClose,
          editable:false
      },
        {
          headerName: "Transporter Code",
          field: "transporter_code",
          width:105,
          filter: true,
          resizable: true,
          // pinned : "left",
          editable:false,
          hide : transporterCodeHide,
      },
        {
            headerName: "Transporter Name",
            field: "transporters_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
	  
				{
					headerName : "TPT Confirmed Delivery",
					field : "tpt_confirm_delivery",
					width:125,
					resizable : true,
					filter:true,
					valueGetter:function(params){
						if(params.data.tpt_confirm_delivery == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
        },
				{
				  headerName : "TPT Delivered On",
				  field : "tpt_delivery_date",
				  resizable : true,
				  filter : true,
				  width:150,
				  valueGetter : function(params){
            if(params.data.tpt_delivery_date != ""){
              return getDDMMYYYYHHMMSS(params.data.tpt_delivery_date);
            }
				  }
				},
        
        {
          headerName: "Mode",
          field: "vehicle_mode",
          width:150,
          filter: true,
          resizable: true,
          editable:false
      },
        
      {
        headerName: "Transit Time/MSIL (Days)",
        field: "expected_transit_days",
        width:150,
        filter: true,
        resizable: true,
        editable:false
      },
        {
            headerName: "Destination City",
            field: "consignee_city",
            // width:100,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Original ETA",
            field: "expected_trip_end",
            editable:true,
            width:150,
            filter: true,
            pinned : "left",
            resizable: true,
            editable:false,
            valueGetter : function(params){
              if(params.data.expected_trip_end != ""){
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
              }
              else{
                return "";
              }
            },
          //filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
          //   }
          // }
            
        },
        {
            headerName: "Transit Delay (Days)",
            field: "transit_delay_days",
            editable:true,
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "SPOC Name",
            field: "spoc_contact_name",
            editable:true,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "SPOC Contact No.",
            field: "spoc_contact_number",
            editable:true,
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
          headerName: "Last City / Area.",
          field: "area",
          editable:true,
          width:150,
          filter: true,
          resizable: true,
          editable:false
        },
        {
          headerName: "Last State",
          field: "state",
          editable:true,
          width:150,
          filter: true,
          resizable: true,
          editable:false
      },
      {
        headerName: "Last Packet Time",
        field: "timestamp",
        editable:true,
        width:150,
        filter: true,
        resizable: true,
        editable:false,
        valueGetter : function(params){
          if(urlName == "/sndcriticaldelayreasons" || urlName == "/criticaldelayreasons")
          {
            if(params.data.manual_timestamp == undefined || params.data.manual_timestamp == '')
            {
              if(params.data.timestamp != ''){
                return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
              }
              else{
                return "";
              }
            }
            else
            {
              var s1 = moment.parseZone(params.data.timestamp).format("x");
              var s2 = moment.parseZone(params.data.manual_timestamp).format("x");
              if(s1>s2)
              {
                return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
              }
              else
              {
                var tstamp = getHyphenDDMMMYYYYHHMM(params.data.manual_timestamp);
                return tstamp+" (M)";
              }
            }
          }
          else
          {
            if(params.data.timestamp != ''){
              return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
            }
            else{
              return "";
            }
          }

        },
        //filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
          //   }
          // }
      },
        {
            headerName: "Delay Reason",
            field: "delay_reason",
            width:245,
            editable:true,
            filter: true,
            resizable: true,
        },
        {
          headerName: "Force Close",
          field: "consignment_code",
          width: 120,
          hide : hideForceClose,
          cellRendererSelector:function(params){			
            // console.log(params);		
            var rendComponent = {
              component: 'consignmentforceclose'
            };
            return rendComponent;
          },
          filter: true,resizable: true, 
        },
        {
          headerName: "Mark Deliver",
          field: "consignment_code",
          width: 120,
          hide : hideForceClose,
          cellRendererSelector:function(params){			
            // console.log(params);		
            var rendComponent = {
              component: 'MarkDeliver'
            };
            return rendComponent;
          },
          filter: true,resizable: true, 
        },
      ]
    }
    const commentColumns = [
      {
        headerName : "Truck No",
        field : "truck_no",
        resizable : true,
        width:100,
        filter : true
      },
      {
        headerName : "Consignment Code",
        field : "consignment_code",
        resizable : true,
        width:120,
        filter : true
      },
      {
        headerName : "User Type",
        field : "user_type",
        width:100,
        resizable : true,
        filter : true,
        valueGetter : function(params){
        if(params.data.user_type == "ORGUSER"){
          return "MSIL"
        }
        if(params.data.user_type == "DCTUSER"){
          return "DCT"
        } 
        if(params.data.user_type == "TRANSPORTER"){
          return "TRANSPORTER"
        } 
        }
      },
      {
        headerName : "Comment",
        field : "comment",
        width:250,
        cellClass: ['wraptext'],
        resizable : true,
        filter : true
      },
      {
        headerName : "Commented By",
        field : "username",
        width:150,
        resizable : true,
        filter:true
      },
      
      {
        headerName : "Commented Date",
        field : "comment_date",
        resizable : true,
        filter : true,
        width:150,
        valueGetter : function(params){
        if(params.data.comment_date != ""){
          return getDDMMYYYYHHMMSS(params.data.comment_date);
        }
        }
      }
    
      ]
    return (
      <div class="container-fluid">
      <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
      >
      </SweetAlert>
      <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h5>
                          <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Update Remarks (Critical Delays) </span>    
                        </h5>

                      <span className="layoutbtns float-right">
												<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
													<i className="icofont icofont-save"></i> Save Grid Layout
												</button>
												<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
													<i className="icofont icofont-refresh"></i> Reset Default Layout
												</button>
											</span>


                    </div>				   			
                    <div className="card-body pt-15px" >
                        <div className="row">
                          <div className="row col-xl-12 col-lg-12">
                             
                              {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                  <div className="form-group col-xl-3 col-lg-3">
                                      <label>Select Department : </label>
                                      <Select 
                                      placeholder={"Delivery Point"}
                                      closeMenuOnSelect={true}
                                      // isMulti="true"
                                      onChange={this.getRelatedDeptData.bind(this)} 
                                      className={"border-radius-0"}
                                      style={{borderRadius:"0px"}}
                                      options={this.selectDept()} />            
                                  </div>                   
                              </form> */}
                          </div>
                        
                        <div className="col-xl-12 col-lg-12 ml-0p n-p-0 road-pick-up-grid">
                            {/* <div className="col-xl-2 col-lg-2 form-group"> */}
                                {/* <label className="replacement-time"><br /></label> */}
                                {/* <button type="submit" className="btn btn-warning " id="replacement-submit">Update</button> */}
                                {/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
                            {/* </div>  */}
                            {/* <input type="file" onChange={this.getFile.bind(this)} /> */}
                            
                        </div> 
                        <div id="myGrid" style={{width:"100%",height:"580px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                enableRangeSelection={false}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                  context: { componentParent: this }
                                }}
                                enableRangeSelection={false}
                                components={this.state.components}
                                enableRangeSelection= {true}
                                onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />

                        </div>
                        {this.state.sliderCommentTranslate != ''?
                            <div className={"slide-r "+(this.state.sliderCommentTranslate)} style={{overflow:"auto"}}>
                              <div className="slide-r-title">
                                <h4>
                                  Consignment Comments
                                  <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>	
                                </h4>
                              </div>
                              <div className="slide-r-body" style={{position:"relative"}}>
                                <div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                  <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={commentColumns}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.commentsRowData}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    enableRangeSelection= {true}
                                    // sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                
                                  />
                                </div>
                              </div>
                              
                            </div>
                          :""	
                          }
                        {/* <div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                          <div className="slide-r-title">
                            <h4>
                                Force Closure
                                <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>            
                            </h4>
                          </div>
                          <div className="slide-r-body" style={{position:"relative"}}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                              <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
                                <ForceCloseSideBar context={this} handleInput = {this.handleInput}/>
                              </form>
                            </div>
                          </div>
                        </div> */}
                        <div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                          <div className="slide-r-title">
                              <h4>
                                  Force Closure
                                  <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                          
                              </h4>
                          </div>
                          <div className="slide-r-body" style={{position:"relative"}}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
                              <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
                                
                                <div className="col-xl-12 col-lg-12 row form-group">
                                  <label className="col-xl-12 col-lg-12">Reason for closure</label>
                                  <textarea name="reasonforceclose" id="forceClosureResonID" rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                  <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                              </form>
                                          </div>
                            </div>
                          </div>

                          <div className={"slide-r "+(this.state.sliderMarkDeliverTranslate)} style={{overflow:"auto"}}>
                                      <div className="slide-r-title">
                                          <h4>
                                            Mark Delivered
                                              <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                                      
                                          </h4>
                                      </div>
                                      <div className="slide-r-body" style={{position:"relative"}}>
                                          
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
                              <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDeliverHandler.bind(this)}>
                                
                                <div className="col-xl-12 col-lg-12 row form-group">
                                  <label className="col-xl-12 col-lg-12">Delivery Time</label>
                                                  <input type="text" autoComplete="off" class="form-control datetimepicker_date" id="delivery_date_time" />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                  <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                              </form>
                                          </div>
                            </div>
                          </div>
                        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                            <h3 className="subH">Bulk Update</h3>
                                
                            <div className="slide-r-body" style={{position:"relative"}}>
                              
                              <div className="container-fluid">
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                <div className="form-group mt-20p">
                                  <label className="">Upload File</label> 
                                  <input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-success">Submit</button>
                                  <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                </form>
                                
                                {/* <div className="form-group">
                                  <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a>
                                </div>		 */}
                              </div>
                            </div>
                          </div>
                          {console.log(this.state.sliderRouteTranslate)}
                          {this.state.sliderRouteTranslate != ''?
                            <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
                              <div className="slide-r-title">
                                <h4>
                                  {(this.state.consignment_code)}
                                  <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                                      
                                </h4>
                              </div>
                              <div className="slide-r-body" style={{position:"relative"}}>
                                
                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                    <DrawMap mapFor={"truck"} tolls={this.state.tolls} consignercords={this.state.consigner_coordinates} consigneecords={this.state.consignee_coordinates} rownode={this.state.rownode} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
                                    <div className="col-xl-12 col-lg-12 n-p-0">
                                                <div className="crm-numbers pb-0">
                                                    <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                                    <div className="col-xl-12 col-lg-12 row">
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Truck No</label>
                                                            <div>{this.state.routeTruck.truck_no}</div>
                                                        </div>											
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Start Time</label>
                                                            <div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
                                                        </div>
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">End Time</label>
                                                            <div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
                                                        </div>
                                                        
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Distance</label>
                                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
                                                        </div>
                                                    </div>
                                                </div>
								                </div>
                                 
                                </div>
                              </div>
                              
                            </div>
                          :""	
                          }
                        <div className={"dataLoadpage " +(this.state.loadshow)}>
                          </div>
                          <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                          </div>  
                          <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

    </div>
    );
  }
}

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
} 

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}