import React, { Component } from 'react';
const Simtrackinglogs = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
         props.context.componentParent.onClickShowSimTrackingLegs(props);
    };

    return (
        <div className="map-icon-component">
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="Show Logs"><i style={{color:"#FF0000"}} className="icofont icofont-history f25"></i> </button>
       
        </div>
    );
};

export default Simtrackinglogs;
