/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
// import LoadingAreaLegs from "./loadingarealegs";
import CountUp from 'react-countup';
import LoadingAreaLegs from "./loadingarealegs";
import LegRouteMap from "../manage/legroutemap";
import ShipmentLegs from "./shipmentlegs";
import deviationIcon from '../../assets/icons/detour.png';
import Viewsharablelink from "../layouts/viewsharablelink";
import Counters from '../layouts/consignmentkpicountersComponent';
import DateFormater from '../layouts/dateComponent';
import {
	getDDMMYYYYHHMMSS,
	getYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMMYYYY,
    getHyphenDDMMYYYY,
    getHyphenHHMM
} from '../common/utils';
import DrawMap from '../common/drawmap';
import DrawRouteMap from "./drawroutemap";

import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiDeviationMapActions from '../manage/kpiDeviationMapActionsComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapenrouteAction from '../manage/routemapenroute';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import Sendemailbtn from "./sendEmailBtn"
import SendemailtoCustomerbtn from "./sendemailtocustomer"
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gpsdata from '../manage/gpsdataaction.js';
import NoGps from '../manage/nogpsActionComponent.js';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import UpdateShipments from '../manage/updateShipments'
var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class AllTrucks extends Component {
	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pagetitle: "All Trips",
			deptcode: "SNDG",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			sliderTranslate: '',
			mapinfo: '',
			new_mobile_no:"",
			new_driver_name:"",
			mobile_no:"",
			driver_name:"",
			truck_no:"",
			shipment_id:"",
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			drop_coordinates: '',
			check_domain: /enmovil\.in/,
			email: localStorage.getItem("email"),
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			original_districts: [],
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			filterDistricts: {},
			districtsList: [],
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			initialcheck: false,
			routeTruck: {},
			sidebarSubHeader: '',
			filteredDistricts: [],
			googelRoutes: '',
			plants: [],
			bulkPrioritySlide: "",
			hideTransporterBtns: "show-m",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: false,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				dateFormater: DateFormater,
				LoadingAreaLegs: LoadingAreaLegs,
				LegRouteMap: LegRouteMap,

				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				SegmentAction: SegmentAction,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				KpiDeviationMapActions: KpiDeviationMapActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				dateFormater: DateFormater,
				gps: Gpsdata,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				RoutemapenrouteAction: RoutemapenrouteAction,
				consignmentforceclose: Consignmentforceclose,
				ShipmentLegs: ShipmentLegs,
				Viewsharablelink: Viewsharablelink,
				Sendemailbtn: Sendemailbtn,
				SendemailtoCustomerbtn: SendemailtoCustomerbtn,
				UpdateShipments : UpdateShipments,

			},
			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 50,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					// {
					// 	statusPanel: "agTotalRowCountComponent",
					// 	align: "center"
					// },
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			geofence_coordinates: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			allplants: [],
			consigner: [{ "value": "ALL", "label": "All" }],
			consignee: [{ "value": "ALL", "label": "All" }],
			filterCities: [],
			filterStates: [],
			filterDistricts: [],
			filterClusters: [],
			filterTransporters: [],
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			consigner_coordinates: [],
			consignee_coordinates: [],
			map_type_of_tracking: "",
			filterNames: "Truck / LPS(Delivery No)/ Invoice No",
			filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "LPS(Delivery No)", value: "shipment_id" }, { label: "Invoice", value: "sales_order" }],
			filterCustomers: [],
			flCustomer: [],
			is_check: 0,
			sliderRouteTranslateDeviation: "",
			mapinvoice_no:"",
			adjcoords2:"",
			adjcoords1:"",
			counter_title:"Trucks",
			tripDetails:{
				trip_ended_at:"",
				drop_coords_dist:0,
			},
		}
		this.onLoadDeliveredTrucks = this.onLoadDeliveredTrucks.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowShipmentLegRouteMap = this.onShowShipmentLegRouteMap.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onShowEnrouteStoppagesKpi = this.onShowEnrouteStoppagesKpi.bind(this);
		this.onShowTransitKpi = this.onShowTransitKpi.bind(this);
		this.onShowOverspeedKpi = this.onShowOverspeedKpi.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
		this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.onClickLink = this.onClickLink.bind(this)
		this.onClicksendemailtocustomer1 = this.onClicksendemailtocustomer1.bind(this)
		this.onClicksendemailtotransporter1 = this.onClicksendemailtotransporter1.bind(this)
		this.onClickUpdateBtn = this.onClickUpdateBtn.bind(this);
	}
	componentDidCatch(error, info) {
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		loadDateTimeScript();
		if (localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0) {
			this.setState({
				sideBar: {
					toolPanels: [
						{
							id: "columns",
							labelDefault: "Columns",
							labelKey: "columns",
							iconKey: "columns",
							toolPanel: "agColumnsToolPanel"
						},
						{
							id: "filters",
							labelDefault: "Filters",
							labelKey: "filters",
							iconKey: "filter",
							toolPanel: "agFiltersToolPanel"
						}
					]
				}
			});
		}
		if (localStorage.getItem("user_type") == "CUSTOMER") {
			this.setState({
				filterNames: "Truck / Invoice No",
				filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "Invoice", value: "sales_order" }]
			});
		}
		var edate = moment.parseZone().format('YYYY-MM-DD');
		if (this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments")) {
			var sdate = moment.parseZone().subtract(6, 'months').format('YYYY-MM-DD');
		}
		else {
			var sdate = moment.parseZone().subtract(6, 'months').format('YYYY-MM-DD');
		}
		// var sdate = '2020-03-01';
		var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow: 'show-n',
			// startDate:sdate,
			// endDate:edate,
			defaultsdate: sdate,
			defaultedate: edate,
			//startDate:sdatetime,
			//endDate:edatetime
		});
		var parameters = {
			startDate: sdate,
			endDate: edate,
			// consigner:this.state.consigner
		}
		try {
			if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
				parameters.plant_code = localStorage.getItem("pc");
				// var consigner = [{ value: localStorage.getItem("pc"), label: localStorage.getItem("pc") }];
				// this.setState({
				// 	consigner: consigner
				// });
			}
			else {
				parameters.consigner = [{ "value": "ALL", "label": "All" }];

			}
		} catch (e) {

		}
		var transportercode = localStorage.getItem('transportercode');
		if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
			parameters.transporter_code = transportercode;
		}
		if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
			parameters.customer_code = localStorage.getItem("customer_code")
		}
		//  this.onLoadDeliveredTrucks(parameters);
		redirectURL.post("/consignments/allplants",).then(async (response) => {
			let responseData = response.data
			if (responseData.length > 0) {
				responseData = await distinctArrayBy(responseData, "plant_code");
			}
			this.setState({
				allplants: responseData
			})
		})
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: "SNDG", email: localStorage.getItem('email'), role: localStorage.getItem('roles'), customer_code: localStorage.getItem('customer_code') })
			.then((resp) => {
				var cities = resp.data.cities;
				var filterCities = [];
				filterCities.push({
					"value": "All",
					"label": "All"
				})
				if (cities != undefined) {
					if (cities.length > 0) {
						cities.map(function (c) {
							filterCities.push({
								label: c,
								value: c,
							});
						});
					}
				}
				var customers = resp.data.customers;
				var filterCustomers = [];
				if (customers.length > 0) {
					filterCustomers.push({
						label: "All",
						value: "",
					});
					customers.map(function (c) {
						filterCustomers.push({
							label: c.customer_code + " - " + c.customer_name,
							value: c.customer_code,
						});
					});
				}
				var districts = resp.data.districts
				var filteredDistricts = [];
				var actual_districts=[]
				if (districts.length > 0) {
					filteredDistricts.push({
						label: "All",
						value: ""
					})
					districts.map(function (d) {
						actual_districts.push({
							label: d._id.consignee_district,
							value: d._id.consignee_district
						})
					})
				}
				sortByLabel(actual_districts)
				filteredDistricts.push(...actual_districts)
				var states = resp.data.states;
				var filterStates = [];
				if (states != undefined) {
					filterStates.push({
						label: "All",
						value: "",
					});
					if (states.length > 0) {
						states.map(function (s) {
							filterStates.push({
								label: s,
								value: s,
							});
						});
					}
				}
				var clusters = resp.data.clusters;
				var filterClusters = [];
				if (clusters != undefined) {
					if (clusters.length > 0) {
						clusters.map(function (cl) {
							filterClusters.push({
								label: cl,
								value: cl,
							});
						});
					}
				}
				var transporters = resp.data.transporters;
				console.log(transporters, "transporters")
				var filterTransporters = [];
				filterTransporters.push({
					"value": "All",
					"label": "All",
				})
				if (transporters != undefined) {
					if (transporters.length > 0) {
						transporters.map(function (t) {
							filterTransporters.push({
								label: t.transporter_code + " - " + t.transporter_name,
								value: t.transporter_name,
							});
						});
					}
				}
				var transportersZones = resp.data.transportersZonesList;
				let filterTransportersZones = [];
				filterTransportersZones.push({
					"value": "",
					"label": "All",
				});
				if (transportersZones != undefined && transportersZones != "") {
					transportersZones.map((t) => {
						if (t != "" && t != undefined && t != "null" && t != null) {
							filterTransportersZones.push({
								"value": t,
								"label": t,
							});
						}
					});
				}
				this.setState({
					loadshow: "show-n",
					overly: "show-n",
					filterCities: filterCities,
					filterCustomers: filterCustomers,
					filterStates: filterStates,
					districtsList: filteredDistricts,
					original_districts: districts,
					filterClusters: filterClusters,
					filterTransporters: filterTransporters,
					filterTransportersZones: filterTransportersZones,
				});
			});
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				console.log("resp.data ", resp.data)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			});
	}
	onLoadDeliveredTrucks(parameters) {
		parameters.user_type = localStorage.getItem("user_type");
		console.log(parameters, "566")
		redirectURL.post("/chettinad/alltrucks", parameters)
			.then((response) => {
				console.log("response ", response.data);
				var records = response.data.trucks;
				var pagecounters = response.data.counters;
				console.log("records ", records);
				var gridData = records;
				var selectedCity = $("#selectedCity").text();
				var selectedState = $("#selectedState").text();
				var selectedCluster = $("#selectedCluster").text();
				var selectedTransporter = $("#selectedTransporter").text();
				if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select...") {
					gridData = gridData.filter(e => e.consignee_city == selectedCity);
				}
				if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
					gridData = gridData.filter(e => e.consignee_state == selectedState);
				}
				if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
					gridData = gridData.filter(e => e.cluster == selectedCluster);
				}
				if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select...") {
					gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
				}
				if (localStorage.getItem("user_type") == "CUSTOMER") {
				}
				this.setState({
					loadshow: "show-n",
					overly: "show-n",
					rowData: gridData,
					allRowData: gridData,
					originalData: gridData,
					beforeNewFilterRowData: gridData,
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					beforeNewFiltercountersjson: pagecounters,
					consBtn: "btn-danger",
					returnConsBtn: "btn-default",
				})
			})
			// redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			// .then(async (resp) => {
			// 	console.log("resp.data ", resp.data)
			// 	await this.setState({
			// 		usergridstate: resp.data
			// 	});
			// 	await this.restoreGridStates();
			// });
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}
	onShowRouteDiv = (e) => {
		console.log(e, "clicked row data")
		//console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no,
			mapinvoice_no: e.invoice_no,
			unloading_address: e.unloading_address,
		});
		if (e.parking_in_time != "" && e.parking_in_time != undefined) {
			var gateouttime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
		}
		else {
			var gateouttime = moment.parseZone().format('YYYY-MM-DD') + " 00:00:00";
		}

		if (e.status == "9" || e.status == "10" || e.status == 9 || e.status == 10) {
			var end_date = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
		}
		else if (e.status == "11" || e.status == 11 || e.status == 12 || e.status == "12"  || e.status == "13" || e.status == 13 || e.status == 14 || e.status == "14"|| e.status == 15 || e.status == "15" || e.status == 16 || e.status == "16" || e.status == 17 || e.status == "17"|| e.status == 18 || e.status == "18"|| e.status == 19 || e.status == "19"|| e.status == 20 || e.status == "20" || e.status == 21 || e.status == "21") {
			if(localStorage.getItem("email")=="sk@enmovil.in"){
				var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
				console.log(end_date,"before")
				// var end_date = addOneDay(end_date)
				var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
			}
			else{
				var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
			}
		}
		else {
			if ((e.status == 6 || e.status == "6") && e.force_closure_time) {
				var end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}

		}
		var drop_coordinates = [];
		if(e.status > 10){
				drop_coordinates =  e.drop_coordinates;
		}
		this.setState({
			drop_coordinates: drop_coordinates,
			tripDetails:{
				trip_ended_at:e.recent_dealer_reported || "",
				drop_coords_dist:e.drop_coords_to_customer_loc_dist_km || 0,
			}
		})
		var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		var legs = JSON.parse(e.leg_details);
		legs.map((item) => {
			consignmentcodes.push(item.consignment_code)
		})

		var reqData = {
			truck_no: e.truck_no,
			shipment_id: e.shipment_id,
			consignment_codes: consignmentcodes,
			fromdate: gateouttime,
			todate: end_date,
			screen: "all_trips",
			email:localStorage.getItem("email")
		}
		if(localStorage.getItem("email") == "manojtest@enmovil.in"){
			reqData.for_missing_coordinates = 1
		}
		console.log("reqData: ", reqData);
		// redirectURL.post('/consignments/maproutes',reqData, {
		redirectURL.post('/trucks/truckmap', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				console.log("Props data ", records)
				console.log(records.status, "status")

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.status == "failure" || records.status == "Failure") {
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
					else {
						if (records.coords.length == 0) {
							this.setState({
								show: true,
								basicTitle: 'No Route data available',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}
						else {
							if (records != '') {
								var sdate = e.gateouttime;


								try {
								}
								catch (e) {

								}
								this.setState({
									sliderRouteTranslate: "slider-translate-75p",
									showDiv: 'show-m',
									mapinfo: records,
									dealer: e.consignee_code,
									consignment_code: "Truck : " + e.truck_no,
									maptruckno: e.truck_no,
									routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
									loadshow: 'show-n',
									sidebarSubHeader: "Truck Information",
									overly: 'show-m',
									rownode: e,
									leg_no: 0,
									consigner_coordinates: records.consigner_coordinates,
									consignee_coordinates: records.consignee_coordinates,
									tolls: records.tolls,
									map_type_of_tracking: e.type_of_tracking,
									baseRouteCoords: records.base_route
								});
							}
						}
					}
				}
				catch (e) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
			var params = {
				shipment_id: e.shipment_id
			}
			redirectURL.post('/trucks/getadjcoords',params).then((resp)=>{
				var data = resp.data 
				var adjcoords =[]
				var adjcoords1= data[0].adj_coords_1  
				var adjcoords2= data[0].adj_coords_2 
				this.setState({
					adjcoords1:adjcoords1,
					adjcoords2:adjcoords2
	
				})
			})
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderRouteTranslateDeviation: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}

	onClickEditDriverDetails(event) {
		event.preventDefault();
		this.setState({loadshow:'show-m',overly:'show-m'})
		var params = {
			shipment_id:this.state.shipment_id,
            new_driver_name : this.state.new_driver_name,
            new_mobile_no : this.state.new_mobile_no,
            truck_no : this.state.truck_no,
            originalValue : this.state.mobile_no,
            dept_code : localStorage.getItem("dept_code"),
			updated_by : localStorage.getItem('email')
		}

		if (this.state.new_mobile_no != "" &&  this.state.new_driver_name != "") {
			redirectURL.post("/consignments/editDriverDetails", params).then((resp) => {
				if (resp.data.statusMessage == "success") {
					this.setState({
						show: true,
						basicTitle: "Successfully Updated",
						basicType: "success",
						loadshow: 'show-n',
						overly: 'show-n',
					})
				}
				else {
					this.setState({
						show: true,
						basicTitle: "Not Updated",
						basicType: "danger",
						loadshow: 'show-n',
						overly: 'show-n'
					})
				}
			}).catch(function (error) {
				console.log(error);
			});
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Enter Required Fields",
				basicType: "danger",
				loadshow: 'show-n',
				overly: 'show-n'
			})
		}
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}
	};
	onRowSelection(event) {
		var containerslist = [];
		var rwCount = event.api.getSelectedNodes();
		var rowCount = event.data
		rwCount.map((item) => {
			containerslist.push(item.data.shipment_id)
		});
		this.setState({
			containerslist: containerslist
		})
		console.log(containerslist, "containerslist")
	}
	onGridState = () => {
		this.colState = this.gridColumnApi.getColumnState();
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}
	onGridReady1 = params => {
		this.gridApi1 = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi1 = params.columnApi;
	};
	onGridState1 = () => {
		this.colState1 = this.gridColumnApi1.getColumnState();
		this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
		this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		window.filterState1 = this.gridApi1.getFilterModel();
		this.gridApi1.setFilterModel(window.filterState1);
	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderRouteTranslateDeviation: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
			sliderEditDriver : ""
		});
	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onClickUpdateBtn = (rowData) => {
		this.setState({ loadshow: 'show-m', overly: 'show-m' })
		var row = rowData.data
		// console.log(row, "row123")

		this.setState({
			sliderEditDriver: 'slider-translate',
			overly: "show-m",
			show: false,
			showDiv: 'show-m',
			loadshow: 'show-n',
			truck_no: row.truck_no,
			shipment_id: row.shipment_id,
			driver_name: row.driver_name,
			mobile_no: row.driver_mobile_no,
			new_driver_name: row.driver_name,
			new_mobile_no: row.driver_mobile_no
		})
	}
	

	onClickCounterShowDataTEST(params) {
	}
	onClickCounterShowData(params) {

		this.setState({
			loadshow: "show-m"
		})
		console.log("IN onClickCounterShowData, params = ", params);
		if (params == "all") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData,
				counter_title:"Trucks"
			})
		}
		if(params=="simcount"){
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e =>e.type_of_tracking!=1),
				counter_title:"Trucks - SIM"
			})
		}
		if(params=="gpscount"){
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e =>e.type_of_tracking==1),
				counter_title:"Trucks - GPS"
			})
		}
		if (params == "is_gps_working") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.is_gps_working == "NOT_WORKING"),
				counter_title:"GPS Not Available"
			})
		}
		if (params == "no_gps_data_lifetime") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.no_gps_data_lifetime == 1)
			})
		}
		if (params == "transit_delay") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.transit_delay == 1),
				counter_title:"Transit Delay"
			})
		}
		if (params == "enroute_exception") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.enroute_exception == 1),
				counter_title:"Stoppages"
			})
		}
		if (params == "overspeeding_exception") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.overspeeding_exception == 1),
				counter_title:"Overspeeding"
			})
		}
		if (params == "nightdriving_exception") {
			this.setState({
				loadshow: "show-n",
				rowData: this.state.allRowData.filter(e => e.nightdriving_exception == 1),
				counter_title:"Night Driving"
			})
		}
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != "" && startdate > this.state.endDate) {
			this.setState({
				show: true,
				basicTitle: 'From date should be less than To date',
				basicType: "danger",
				loadshow: 'show-n',
				startDate: "",
			})
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.startDate != "" && edate < this.state.startDate) {
			this.setState({
				show: true,
				basicTitle: 'To date should be greater than From date',
				basicType: "danger",
				loadshow: 'show-n',
				endDate: "",
			})
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onShowShipmentLegRouteMap = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});
		// console.log("e.data.parking_in_time ", e.parking_in_time)
		if (e.first_inside_fence != "" && e.first_inside_fence != undefined) {
			var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
		else {
			var ptime = moment.parseZone().format('YYYY-MM-DD') + " 00:00:00";
		}
		if (e.first_outside_fence != "" && e.first_outside_fence != undefined) {
			var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
		else {
			var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		}
		var reqData = {
			truck_no: e.truck_no,
			// consignment_code:e.consignment_code,
			consignee_code: e.consignee_code,
			gate_out_time: ptime,
			seconddate: end_date,
			screen: "truck"
		}
		// console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				// try{
				if (records.status == "failure" || records.status == "Failure") {
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					if (records.coords.length == 0) {
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
					else {
						if (records != '') {

							this.setState({
								sliderRouteTranslate: "slider-translate-75p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: e.consignee_code,
								consignment_code: "Truck : " + e.truck_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": ptime, "endTime": end_date },
								loadshow: 'show-n',
								sidebarSubHeader: "Truck Information",
								overly: 'show-m',
								rownode: e,
								leg_no: 0,
								tolls: records.tolls,
								// baseRouteCoords: baseRouteCoords
							});

							// this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
							// this.renderMap();

						}
					}
				}
				// }
				// catch(e){
				//     console.log("here")
				// 	this.setState({
				// 		show: true,
				// 		basicTitle:'No Route data available',
				// 		basicType:"danger",
				// 		loadshow:'show-n'
				// 	});
				// }

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onShowRouteMap(rownode) {
		// console.log("IN onShowRouteMap, params = ",rownode);
		// console.log("rownode", rownode);
		// console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}
		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {
				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-60p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					rownode: rownode,
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onShowTransitRouteMap(rownode) {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {
				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-75p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls
					}); 
					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();
				}
			})
			.catch(function (error) {
				console.log(error);
		});
	}
	onShowTransitKpi = async (params) => {
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if (params != 0) {
				var transitedelaydata = [];
				await this.setState({
					detailCellRendererParams: {
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight: 50,
							columnDefs: [
								{
									headerName: "", field: "_id",
									cellRendererFramework: RoutemapTransitAction,
									width: 50,
									suppressSizeToFit: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 100, resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: Constant.COL_LEGT_START_TIME, field: "leg_start", width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_LEGT_END_TIME, field: "leg_end_eta",
									width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_EXP_LEG_DISTANCE,
									field: "leg_expected_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_ACTUAL_LEG_DISTANCE_COVERED,
									field: "actual_leg_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
											return Math.round(params.data.actual_leg_distance);
										}
										else {
											return 0;
										}
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_EXP_DISTANCE_FROM_TRIP_START,
									field: "expected_distance_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else {
											return 0;
										}
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_ACTUAL_DISTANCE_FROM_TRIP_START,
									field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else {
											return 0;
										}
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_GOOGLE_DISTANCE_FROM_START,
									field: "actual_start_to_leg_google_distance",
									width: 140,
									resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_GPS_AVAILABLE,
									field: "is_gps_working",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										if (params.data.is_gps_working == "NOT_WORKING") {
											return "Not Available";
										}
										if (params.data.is_gps_working == "WORKING") {
											return "Available";
										}
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: Constant.COL_TRIP_COMPLETED,
									field: "trip_completed",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										if (params.data.trip_completed == 1) {
											return "Yes";
										}
										if (params.data.trip_completed == 2) {
											return "";
										}
										if (params.data.trip_completed == 0) {
											return "";
										}
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								}
							],
							overlayNoRowsTemplate: 'No rows to show',
						},
						getDetailRowData: function (params) {
							redirectURL.post('/consignments/transitdelay', {
								consignment_code: params.data.consignment_codes,
								consignee_code: params.data.consignee_code,
								truck_no: params.data.truck_no
							})
								.then(async (response) => {
									transitedelaydata = response.data;
									var legsarr = []
									transitedelaydata.map((item) => {
										legsarr.push(item)
										//}
									})
									// console.log('transitedelaydata ', transitedelaydata);

									params.successCallback(legsarr);
									//console.log("Step 2 ",response.data)

								});
						}
					}
				});
				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if (params.node.expanded == false) {
						transitedelaydata = []
					}
				}
				else {

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			}
			else {
				this.setState({

					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{ headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
								{ headerName: Constant.COL_SPEED_KMPH, field: "speed" },
								{ headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
							]
						},
						getDetailRowData: function (params) {
							//console.log("Child ", params);
							params.successCallback([]);
						}
					}
				});
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Transit for this consignment has not yet started",
				basicType: "info"
			});
		}
	}
	/*NO GPS DATA*/
	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var transitedelaydata = [];
			await this.setState({
				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RoutemapNoGPSAction,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}
								}
							},
							{
								headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}
								}
							},
							{
								headerName: Constant.COL_LAST_DATA_RECEIVED,
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}
								}
							},
							{
								headerName: Constant.COL_DATA_RECEIVED_ON,
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/nogpsdata', {
							consignment_code: params.data.consignment_codes,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
							{ headerName: Constant.COL_SPEED_KMPH, field: "speed" },
							{ headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
							{
								headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/overspeed', {
							consignment_code: param.data.consignment_code,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowNoGPSRouteMap(rownode) {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-75p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNightDriveKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNightDriveKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({
				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								cellRendererFramework: RouteNightDrivemap,
								width: 50,
								suppressSizeToFit: true
							},
							{ headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 150, resizable: true },
							{
								headerName: Constant.COL_TRAVELLED_FROM, field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: Constant.COL_TRAVELLED_UNTIL, field: "recent_instance", resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: Constant.COL_DISTANCE_TRAVELLED_KM, field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code: params.data.consignment_codes,
							gate_out_time: params.data.gate_out_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			//if(params.column.colId == 'nightdriving_exception')
			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
							{ headerName: Constant.COL_SPEED_KMPH, field: "speed" },
							{ headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowLoadingDelayKpi = async (params) => {

		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
			var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: Constant.COL_GATE_IN_TIME,
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {

									if (params.data.gate_in_time) {
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},

							{
								headerName: Constant.COL_GATE_OUT_TIME,
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {
									//	console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},

							{
								headerName: Constant.COL_LOADING_TIME,
								field: "loading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',


					},
					getDetailRowData: function (params) {
						// console.log("Step 4 ",params)
						if (hideonTNP != true) {
							// console.log("Inner here")
							if (params.data.gate_in_time != undefined) {
								var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
								//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
								var gdate1 = new Date(params.data.gate_in_time);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
							}
							else {

								var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var gdate1 = new Date(params.data.inside_fence);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

							}
							//console.log("g1 ", g1)//console.log("Step 4 ",params)
							if (params.data.gate_out_time != undefined) {
								var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								var gdate2 = new Date(params.data.gate_out_time);
								var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								//console.log("gatein ", gatein)
								//console.log("gateout ", gateout)
								//var total =0 ;
								var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

							}
							else {
								var c = new Date();
								var todaydate = moment(new Date(c)).format("x");
								var g2 = getDDMMYYYYHHMMSS(todaydate);
								var gdate2 = new Date(todaydate);
								var toField = getDDMMYYYYHHMMSS(new Date());
								var secondsgate2 = todaydate

							}
						}
						else {
							var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
							var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
							var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
						}

						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");


						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2 - secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						// console.log(diffDays+" "+diffHrs+" "+diffMins);
						let loadingTimeDifference = null;

						var dataarr = []

						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							"loading_time": secondsToString(diffMs / 1000)
						})
						// console.log("dataarr ", dataarr)
						params.successCallback(dataarr);
					}

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if (params.column.colDef.field == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: Constant.COL_FROM_DATE,
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: Constant.COL_TO_DATE,
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: Constant.COL_UNLOADING_TIME,
								field: "unloading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }
						loadingTimeDifference = secondsToString(diffMs / 1000)
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}
	onShowNightDriveRouteMap(rownode) {
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata",response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-60p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});


	}


	onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							// { 
							//     headerName: "",
							//     field: "", 
							// 	width:50,
							//     cellRendererFramework:RoutemapenrouteAction,
							// },

							{
								headerName: "Address",
								field: "address",
								width: 250,
								resizable: true
							},
							{
								headerName: Constant.COL_BREAK_START,
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							// { 
							// 	headerName: "Break End",
							// 	field: "reset_start_time", 
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}

							// 	}
							// },

							{
								headerName: Constant.COL_EXP_CLOSED_ON,
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1 && params.data.modified_date != "Total") {

										if (params.data.modified_date != undefined) {
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else {
											return '';
										}
									}
									else {
										if (params.data.modified_date == "Total") {
											return 'Total';
										}
										else {
											return '';
										}
									}

								}
							},
							{
								headerName: Constant.COL_BREAK_TIME,
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1 && params.data.modified_date != "Total") {
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600)); 
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										if (params.data.elaped_time_from_last_moved_seconds != "" && params.data.elaped_time_from_last_moved_seconds != undefined) {
											return secondsToString(params.data.elaped_time_from_last_moved_seconds);
										}
										else {
											var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
											if (typeof params.data.reset_datetime == 'undefined') {
												var edate = moment.parseZone().format("x");
											}
											else {
												var edate = moment.parseZone(params.data.reset_datetime).format("x");
											}
											var diff = (edate - sdate) / 1000;
											return secondsToString(diff);
										}

									}
									else {
										if (params.data.modified_date == "Total") {
											return secondsToString(params.data.elaped_time_from_last_moved_seconds);
										}
										else {
											return '';
										}
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						if (params.data.is_transshipment == 1) {
							var oldtruck = params.data.old_truck_no
						}
						else {
							var oldtruck = ''
						}
						redirectURL.post('/consignments/enroutes', {
							consignment_code: params.data.consignment_codes,
							truck_no: params.data.truck_no,
							old_truck_no: oldtruck
						})
							.then(async (response) => {
								console.log("Step 1 ", response.data)
								overspeedData = response.data;
								var summaryrow = {};
								var elaped_time_from_last_moved_seconds = 0;
								console.log("elaped_time_from_last_moved_seconds Step1", elaped_time_from_last_moved_seconds)
								if (overspeedData.length > 0) {
									overspeedData.map((it) => {
										console.log(" elaped_time_from_last_moved_seconds Step2", it.elaped_time_from_last_moved_seconds)
										elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds + it.elaped_time_from_last_moved_seconds;
									})
								}
								console.log("elaped_time_from_last_moved_seconds Step3	", elaped_time_from_last_moved_seconds)
								if (params.data.enroute_exception == 1) {
									summaryrow.stoppage_start_time = "";
									summaryrow.modified_date = "Total";
									summaryrow.elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds;
									overspeedData.push(summaryrow)
								}
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
							{ headerName: Constant.COL_SPEED_KMPH, field: "speed" },
							{ headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowEnrouteRouteMap(rownode) {
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if (rownode.reset_datetime) {
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		}
		else {
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				let records = response.data
				// console.log("Inner Grid ",records);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
						//edate=rownode.leg_end_eta;
					}

					if (response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined) {
						sdate = records.route_details.start_time;

					}
					if (response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined) {
						edate = records.route_details.end_time;
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-75p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowLoadingDelayKpi = async (params) => {

		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
			var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: Constant.COL_GATE_IN_TIME,
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {

									if (params.data.gate_in_time) {
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},

							{
								headerName: Constant.COL_GATE_OUT_TIME,
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {
									//	console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},

							{
								headerName: Constant.COL_LOADING_TIME,
								field: "loading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',


					},
					getDetailRowData: function (params) {
						// console.log("Step 4 ",params)
						if (hideonTNP != true) {
							// console.log("Inner here")
							if (params.data.gate_in_time != undefined) {
								var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
								//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
								var gdate1 = new Date(params.data.gate_in_time);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
							}
							else {

								var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var gdate1 = new Date(params.data.inside_fence);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

							}
							//console.log("g1 ", g1)//console.log("Step 4 ",params)
							if (params.data.gate_out_time != undefined) {
								var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								var gdate2 = new Date(params.data.gate_out_time);
								var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								//console.log("gatein ", gatein)
								//console.log("gateout ", gateout)
								//var total =0 ;
								var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

							}
							else {
								var c = new Date();
								var todaydate = moment(new Date(c)).format("x");
								var g2 = getDDMMYYYYHHMMSS(todaydate);
								var gdate2 = new Date(todaydate);
								var toField = getDDMMYYYYHHMMSS(new Date());
								var secondsgate2 = todaydate

							}
						}
						else {
							var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
							var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
							var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
						}

						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");


						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2 - secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						// console.log(diffDays+" "+diffHrs+" "+diffMins);
						let loadingTimeDifference = null;

						var dataarr = []

						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							"loading_time": secondsToString(diffMs / 1000)
						})
						// console.log("dataarr ", dataarr)
						params.successCallback(dataarr);
					}

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if (params.column.colDef.field == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: Constant.COL_FROM_DATE,
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: Constant.COL_TO_DATE,
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: Constant.COL_UNLOADING_TIME,
								field: "unloading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }
						loadingTimeDifference = secondsToString(diffMs / 1000)
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	selectConsignerOptionsItems() {
		let items = [];
		var consigners = this.state.allplants;

		//console.log("consigners ", consigners)
		items.push({ value: 'ALL', label: 'All' });
		if (consigners.length > 0) {
			consigners.forEach(item => {
				items.push({ value: item.plant_code, label: item.plant_name });
			});
		}

		return items;

	}
	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onGetConsigners = async (event) => {
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				console.log("resp.data ", resp.data)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			});
		let consigneeCity = this.state.flCity
		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			// var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
			// var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		var selectconsigners = []
		if (this.state.consigner != null && this.state.consigner != "null" && this.state.consigner != "undefined" && this.state.consigner != undefined) {
			var cons = this.state.consigner;
			cons.map((cn) => {
				selectconsigners.push(cn.value);
			})
		}
		else {
			selectconsigners = ["ALL"]
		}
		console.log(selectconsigners, "selectconsigners", this.state.consigner)
		var selectconsignees = []
		if (this.state.consignee == null) {
			selectconsignees = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsignees = this.state.consignee
		}

		var mvmtype = []
		if (this.state.movementtype == null) {
			mvmtype = [{ "value": 'all', "label": 'All' }]
		}
		else {
			mvmtype = [this.state.movementtype]
		}
		var selecttrip = []
		selecttrip = [this.state.triptype]
		let consignmentState = this.state.flState
		var datechk = 1;
		var errpopmsg = "";
		if (this.state.deptcode == 'SNDG') {
			if (sDate != undefined && sDate != "") {
				console.log(eDate, "eDate")
				if (eDate == undefined || eDate == "") {
					datechk = 0;
					errpopmsg = "Please select LPS To Date"
				}
			}
			if (eDate != undefined && eDate != "") {
				if (sDate == undefined || sDate == "") {
					console.log(sDate, "sDate")
					datechk = 0;
					errpopmsg = "Please select LPS From Date"
				}
			}
			console.log(sDate, "12345")
			if (sDate != undefined && sDate != "" && eDate != undefined && eDate != "") {
				if (sDate > eDate) {
					datechk = 0;
					errpopmsg = "LPS From Date should be less than LPS To Date"
					console.log(sDate, "ss", eDate)
				}
			}
			else{
				datechk = 0;
				errpopmsg = "Please select LPS From Date and LPS To Date to proceed"
			}
			let cluster = this.state.flCluster
			var requestparams = {
				dept_code: this.state.deptcode,
				startDate: sDate,
				endDate: eDate
			}
			let district = this.state.flDistrict
			if (district !== '' && district !== undefined) {
				requestparams['consignment_district'] = district.value
			}
			if (consignmentState !== '' && consignmentState !== undefined) {
				requestparams['consignment_state'] = consignmentState.value
			}
			else if (cluster !== '' && cluster !== undefined) {
				requestparams['cluster'] = cluster.value
			}
			else if (consigneeCity !== '' && consigneeCity !== undefined) {
				requestparams['consigneeCity'] = consigneeCity.value
			}
			let flCustomer = this.state.flCustomer
			if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
				var flList = [];
				flCustomer.map((fl) => {
					flList.push(fl.value);
				});
				if (flList.length > 0) {
					requestparams['flCustomer'] = flList
				}
			}
		}
		else if (this.state.deptcode == 'LOG-PRT') {
			var requestparams = {
				dept_code: this.state.deptcode,
				startDate: sDate,
				endDate: eDate,
				movement: mvmtype
			}
			if (consignmentState !== '' && consignmentState !== undefined) {
				requestparams['consignment_state'] = consignmentState.value
			}
			else if (consigneeCity !== '' && consigneeCity !== undefined) {
				requestparams['consigneeCity'] = consigneeCity.value
			}
		}
		else {
			var requestparams = {
				dept_code: this.state.deptcode,
				startDate: sDate,
				endDate: eDate,
				triptype: selecttrip
			}
		}
		if (localStorage.getItem("is_sales_type") == 1) {
			requestparams.sales_type = localStorage.getItem("sales_type");
			requestparams.region = localStorage.getItem("region");
		}
		let transporter = this.state.flTransporter

		if (transporter !== "" && transporter !== undefined && transporter !== null) {
			if (transporter.value != "") {
				requestparams['transporter'] = transporter.value
			}
		}
		console.log(requestparams.startDate, "3383")
		let transporter_zone = this.state.flTransporterZone
		if (transporter_zone !== '' && transporter_zone !== undefined) {
			if (transporter_zone.value !== '' && transporter_zone.value !== undefined) {
				requestparams['transporter_zone'] = transporter_zone.value
			}
		}

		var transportercode = localStorage.getItem('transportercode');
		if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
			requestparams.transporter_code = transportercode;
		}
		try {
			if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
				requestparams.plant_code = localStorage.getItem("pc");
			}
		} catch (e) {
			requestparams.consigner = selectconsigners;
		}

		if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
			requestparams.customer_code = localStorage.getItem("customer_code")
		}
		// console.log("requestparamsCheck", requestparams)

		if (localStorage.getItem("email") == "dcp_chettinad@enmovil.in") {
			this.setState({
				is_check: 1
			})
		}


		var urlpath = '';

		urlpath = '/consignments/allsndconsignments';
		// this.onLoadDeliveredTrucks(requestparams)
		console.log(requestparams, "requestparams")
		if (datechk == 1) {
			if (sDate != undefined && sDate != "" && eDate != undefined && eDate != "") {
				this.setState({initialcheck:true})
				this.onLoadGetConsignmentsList(urlpath, requestparams)
			}
			console.log(requestparams, "requestparams123")
		}
		else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: errpopmsg
			})
		}
	}
	onClickLink = (params) => {
		var truckNo = params.data.invoice_no 
		if(!["" , undefined , null , "undefined"].includes(params.data.invoice_no)){
			window.open('/connect/'+truckNo, '_blank');
		}else{
			window.open('/connect/no_invoice', '_blank');

		}
	}

	onLoadGetConsignmentsList(urlpath, parameters) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != "") {
			parameters.region = localStorage.getItem("region");
			parameters.sales_type = localStorage.getItem("sales_type")
		}
		if (parameters.flCustomer != undefined && parameters.flCustomer != "undefined" && parameters.flCustomer != "") {
			parameters.customer_code = JSON.stringify(parameters.flCustomer);
		}

		// console.log("parameters",parameters);
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				console.log("response.data ", response.data)
				var records = response.data.consignments;
				console.log(this.state.is_check, "123")
				var pagecounters = response.data.counters;
				pagecounters['deviated_routes'] = records.filter(e => e.deviation_flag == 1)
				var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
				pagecounters.cancelled_count = "Not Applicable"
				// console.log("records", records);
				var cancelled_data = []
				var pod_received_data = [];
				var trip_closed_by_pod = [];
				//console.log("counters", pagecounters);
				records = records.sort(GetSortDescOrder("_id"));
				// Commented By Yeswanth
				// if(records.length > 0)
				// {
				// 	records = records.filter(rec => rec.status > 2)			
				// }

				var gridData = records;
				console.log(gridData, "griddd")

				var selectedCity = $("#selectedCity").text();
				var selectedState = $("#selectedState").text();
				var selectedCluster = $("#selectedCluster").text();
				var selectedTransporter = $("#selectedTransporter").text();
				console.log(selectedState)
				if (selectedState != undefined && selectedState != '' && selectedState != "Select..." && selectedState != 'All') {
					gridData = gridData.filter(e => e.consignee_state == selectedState);
				}
				console.log(gridData, "griddd")
				if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
					gridData = gridData.filter(e => e.cluster == selectedCluster);
				}
				console.log(gridData, "griddd")
				this.setState({
					rowData: gridData,
					allRowData: gridData,
					originalData: gridData,
					beforeNewFilterRowData: gridData,
					loadshow: 'show-n',
					overly: 'show-n',
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					beforeNewFiltercountersjson: pagecounters,
					consBtn: "btn-danger",
					returnConsBtn: "btn-default"
				});
				// if(this.state.urlpath == '/consignments/filterTNPActiveConsignments')
				// {

				// }
				// else
				// {
				// 	this.setState({

				// 	})
				// }


			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onClickShowShipmentLegs = async (rownode) => {
		// console.log("rownode", rownode)
		var commentHeaders = [
			{
				headerName: "",
				field: "",
				resizable: true,
				width: 60,
				filter: true,
				cellRendererFramework: LegRouteMap,
			},
			{
				headerName: Constant.COL_TRUCK_NO,
				field: "truck_no",
				resizable: true,
				width: 120,
				filter: true
			},
			//   {
			// 	headerName : Constant.COL_CONSIGNMENT_CODE,
			// 	field : "consignment_code",
			// 	resizable : true,
			// 	width:140,
			// 	filter : true
			//   },
			{
				headerName: Constant.COL_LOADING_AREA_NAME,
				field: "fence_name",
				resizable: true,
				width: 200,
				filter: true
			},
			{
				headerName: Constant.COL_LEGT_START_TIME,
				field: "first_inside_fence",
				resizable: true,
				width: 160,
				filter: true,
				sortable: true,
				valueGetter: function (params) {
					try {
						if (params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
						}
						else {
							return "";
						}
					}
					catch (e) { }
				}
			},
			{
				headerName: Constant.COL_LEGT_END_TIME,
				field: "first_outside_fence",
				resizable: true,
				width: 160,
				filter: true,
				sortable: true,
				valueGetter: function (params) {
					try {
						if (params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
						}
						else {
							return "";
						}
					}
					catch (e) { }
				}
			},
			{
				headerName: Constant.COL_TIME_SPEND,
				field: "time_spent",
				resizable: true,
				width: 200,
				filter: true,
				sortable: true,
				valueGetter: function (params) {
					try {

						return params.data.time_spent;

					}
					catch (e) { }
				}
			},

		]
		await this.setState({
			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: commentHeaders,
					overlayNoRowsTemplate: 'No rows to show',

				},
				getDetailRowData: async function (param) {
					param.successCallback([]);
					// console.log("Params ", param);
					if (param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined) {
						var startdate = param.data.tpa_entry_time;
					}
					else {
						var startdate = param.data.parking_in_time;
					}
					var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
					var enddate = moment.parseZone(new Date(cdate + ":00")).utcOffset("+05:30")._d;

					if (param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11) {
						var enddate = param.data.recent_dealer_reported;
					}
					else {
						if (param.data.status == 6 || param.data.status == "6") {
							var enddate = param.data.force_closure_time;
						}
						else {
							var enddate = moment.parseZone(new Date(cdate + ":00")).utcOffset("+05:30")._d;
						}

					}

					var parameters = {
						consignment_code: param.data.consignment_code,
						truck_no: param.data.truck_no,
						startdate: startdate,
						enddate: enddate
					}
					await redirectURL.post("/chettinad/loadingarealegsdata", parameters).then(async (response) => {
						//console.log("Step 1 ",response.data)
						// console.log(response.data.data);
						var comments = response.data;
						var output = []
						if (comments.length > 0) {
							comments.map((item) => {
								try {
									if (item.first_inside_fence != "" && item.first_inside_fence != undefined) {
										var ste = item.first_inside_fence;
										console.log("ste ", ste);
										var stsec = moment.parseZone(ste).format("x");
										//    item.orderpos = stsec;
									}
									if (item.first_outside_fence != "" && item.first_outside_fence != undefined) {
										var etsec = moment.parseZone(item.first_outside_fence).format("x")
									}
									else {
										var c = moment.parseZone().format("YYYY-MM-DD HH:mm");
										var d = moment.parseZone(new Date(c + ":00")).utcOffset("+05:30")._d;
										var etsec = moment.parseZone(d).format("x");
									}
									// console.log("stsec ", stsec);
									// console.log("etsec ", etsec);
									var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
									// console.log("timespent diff ", diff);
									var timespent = secondsToDhms(diff);
									// console.log("timespent ", timespent);
									item.time_spent = timespent;

								}
								catch (e) {

								}
								output.push(item);
							})
						}
						output.sort(GetSortASCOrder("orderpos"));
						param.successCallback(output);


						//console.log("Step 2 ",response.data)

					}).catch(function (error) {
						console.log(error);
					})

				},
				masterDetail: true
			}
		});
		// console.log(propsdata);
		if (rownode.column.colDef.field == 'shipmentlegs') {

			rownode.node.setExpanded(!rownode.node.expanded);
		}
		else {

			rownode.node.setExpanded(false);
		}
	}

	onClickShowLoadingAreaLegs = async (rownode) => {
		console.log("rownode", rownode)

		if (localStorage.getItem('user_type') == "MARKETING") {
			var hideforsales = true;
		}
		else {
			var hideforsales = false;
		}
		var hideData = "";
		if (localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) {
			var hideData = false;
		}
		else {
			var hideData = true;
		}
		var hideForCustomer = "";
		var hideForNotCustomer = "";
		if (localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
			var hideForCustomer = true;
			var hideData = true;
			var hideforsales = true;
			var hideForNotCustomer = false;
		}
		else {
			var hideForCustomer = false;
			var hideForNotCustomer = true;
		}
		var commentHeaders = [
			{
				headerName: Constant.COL_CONSIGNMENT_CODE,
				field: "consignment_code",
				resizable: true,
				width: 140,
				filter: true
			},
			{
				headerName: Constant.COL_STATUS,
				field: "status",
				resizable: true,
				width: 140,
				filter: true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if (params.data.status == 1) {
						return Constant.INSIDE_TPA;
					}
					else if (params.data.status == 2) {
						return Constant.INSIDE_MY_PMILL;
					}

					if (params.data.status == 1 || params.data.status == "1") {
						return Constant.INSIDE_TPA;
					}
					if (params.data.status == 2 || params.data.status == "2") {
						return Constant.INTRANSIT_TO_MY;
					}
					if (params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5") {
						return Constant.INSIDE_MY_PMILL;
					}
					else if (params.data.status == 6) {
						return Constant.FORCE_CLOSE;
					}
					else if (params.data.status == 7) {
						return Constant.INSIDE_TPA_AFTER_LOADING;
					}
					else if (params.data.status == 8) {
						return Constant.INTRANSIT;
					}
					else if (params.data.status == 9) {
						return Constant.NEAR_CUSTOMER;
					}
					else if (params.data.status == 10) {
						return Constant.REACHED_CUSTOMER;
					}
					else if (params.data.status == 11) {
						return Constant.LEFT_CUSTOMER;
					}
					else {
						return ""
					}
				}
			},
			{
				headerName: Constant.COL_DELIVERY_NO,
				field: "delivery_no",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: Constant.COL_SALE_ORDER,
				field: "sales_order",
				width: 120,
				resizable: true,
				filter: true,
			},

			{
				headerName: Constant.COL_ITEM_NO,
				field: "item_no",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: Constant.COL_ITEM_DESC,
				field: "item_desc",
				width: 200,
				resizable: true,
				filter: true,
			},
			{
				headerName: Constant.COL_ITEM_QTY,
				field: "item_quantity",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: Constant.MATERIAL_INFO,
				field: "material_desc",
				resizable: true,
				filter: true,
				width: 120,
			},
			{
				headerName: Constant.SR_GRADE,
				field: "sr_grade",
				resizable: true,
				filter: true,
				width: 120,
			},
			{
				headerName: Constant.COL_CUSTOMER_NAME,
				field: "consignee_name",
				width: 200,
				resizable: true,
				filter: true,
			},
			{
				headerName: Constant.COL_CUSTOMER_CODE,
				field: "consignee_code",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Customer State",
				field: "consignee_state",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Customer District",
				field: "consignee_district",
				width: 120,
				resizable: true,
				filter: true,
			},
			// {
			//     headerName:Constant.COL_CUSTOMER_CITY,
			//     field:"consignee_city",
			//     width:120,
			//     resizable : true,
			//     filter : true,
			// },
			{
				headerName: Constant.COL_CONSIGNEE_ADDRESS,
				field: "consignee_address",
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				width: 200,
				resizable: true,
			},
			{
				headerName: Constant.COL_ORIGINAL_ETA,
				field: "expected_trip_end",
				colId: "expected_trip_end",
				id: "expected_trip_end",
				width: 120,
				//pinned: 'left',              
				resizable: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				valueGetter: function (params) {
					if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}
					else {
						return "N/A";
					}


				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,

			},
			{
				headerName: Constant.COL_REVISED_ETA,
				field: "revised_trip_end",
				colId: "revised_trip_end",
				width: 120,
				//pinned: 'left',              
				resizable: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				// cellClass : "dateFormat",
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
							return "N/A"
						}
						else {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}

					}
					else {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}

				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,


				cellClass: function (params) {
					let hourDifference = 0;
					if (params.data.revised_trip_end && params.data.expected_trip_end) {
						let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
						let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (hourDifference >= 1) {
						return 'bgColorDangerMedium'
					}

					if (hourDifference <= -1) {
						return 'bgColorSuccessMedium'
					}
					else {
						return ''
					}
				}
			},
			{
				headerName: Constant.COL_TRANSIT_DELAY_HOURS,
				field: "consignment_codes",
				colId: "consignment_codes",
				width: 170, filter: true,
				cellRenderer: '',
				resizable: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				valueGetter: function (params) {
					var expected = params.data.expected_trip_end;
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						var revised = params.data.expected_trip_end;
					}
					else {
						var revised = params.data.revised_trip_end;
					}
					//console.log("Exp ", expected)
					//console.log("revised ", revised)
					var s = moment.parseZone(expected).format("x");
					var ed = moment.parseZone(revised).format("x");
					//console.log("Exp ", s)
					//console.log("revised ", ed)
					var diff = (ed - s)
					// console.log("Diff",diff/1000)
					var days = secondsToDays(diff)
					//console.log("Days ", Math.round(days/1000))
					var totaldays = Math.round(days / 1000);
					if (totaldays > 0) {
						return totaldays;
					}
					else {
						return 0;
					}
					//return Math.round(days/1000);

				}
			},

			{
				headerName: Constant.COL_REACHED_DESTINATION_AT,
				field: "recent_dealer_reported",
				colId: "recent_dealer_reported",
				width: 180,
				//cellRenderer:'dateFormater',
				valueGetter: function (params) {
					if (params.data.status == 10 || params.data.status == 11) {
						return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
					}
					else {
						return "N/A";
					}

				},
				resizable: true
			},
			{
				headerName: Constant.COL_LEFT_DESTINATION_AT,
				field: "left_dealer_marked",
				colId: "left_dealer_marked",
				width: 180,
				//filter: "agDateColumnFilter",
				//cellRenderer:'dateFormater',
				valueGetter: function (params) {
					if (params.data.status == 11) {
						return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
					}
					else {
						return "N/A";
					}

				},
				resizable: true
			},
			{
				headerName: Constant.COL_EXPECTED_DISTANCE_KM,
				field: "distance_in_km",
				width: 180,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Remaining Distance To Customer (KM)",
				field: "distance_from_dealer_location",
				colId: "distance_from_dealer_location",
				width: 180,
				hide:true,
				filter: true,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {
					//console.log("params ", params.data);
					if (typeof params.data.distance_from_dealer_location == 'undefined') {

					}
					else {
						return Math.round(params.data.distance_from_dealer_location);
					}

				},
			},
			{
				headerName: "Transit Time",
				field: "transit_time",
				colId: "transit_time",
				width: 200,
				filter: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {

					return params.data.transit_time;

				}
			},
			{
				headerName: "IOD Time",
				field: "first_2_km_entry",
				width: 200,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				hide: hideForNotCustomer,
				suppressColumnsToolPanel: hideForNotCustomer,
				valueGetter: function (params) {
					try {
						if (params.data.first_2_km_entry == "No Data Available") {
							return "No Data Available"
						}
						else if (typeof (params.data.first_2_km_entry) == "string") {
							return getHyphenDDMMMYYYYHHMM(params.data.first_2_km_entry);
						}
						else {
							return "Not Available";
						}
					}
					catch (e) { }
				},

				// filter: "agDateColumnFilter",
				// comparator: dateComparator,
			},
			{
				headerName: "Reached Customer Vicinity(IOD)",
				field: "first_2_km_entry",
				width: 200, filter: true,
				cellRenderer: '', resizable: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel: hideForCustomer,
				valueGetter: function (params) {
					//console.log(params.data.transporter);
					try {
						if (params.data.first_2_km_entry != "" && params.data.first_2_km_entry != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.first_2_km_entry);
						}
						else {
							return "No Data Available";
						}
					}
					catch (e) { }

				},
			},
			{
				headerName: Constant.COL_POD_DATE_TIME,
				field: "customer_pod_date",
				width: 200,
				editable: false,
				// cellEditor: DateEditor,
				// //filter: "agDateColumnFilter",
				valueGetter: function (params) {
					// console.log("params ", params);
					if (params.data.customer_pod_date != undefined && params.data.customer_pod_date != "") {
						return getHyphenDDMMMYYYYHHMM(params.data.customer_pod_date);
					}
					else {
						return ""
					}
				},
				resizable: true,
				// comparator: dateComparator1
			},
			{
				headerName: Constant.COL_POD_REMARKS,
				field: "customer_pod_remarks",
				width: 200,
				editable: false,
				resizable: true,
			},
			{
				headerName: Constant.COL_POD_DONE_BY,
				field: "customer_pod_done_by",
				width: 140,
				editable: false,
				resizable: true,
			},
			{
				headerName: "ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				hide: hideForNotCustomer,
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
							return "N/A"
						}
						else {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
					}
					else {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}
				},
			},
		]
		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: commentHeaders,
					overlayNoRowsTemplate: 'No rows to show',

				},
				getDetailRowData: async function (param) {
					param.successCallback([]);
					if (param.data.leg_details != "" && param.data.leg_details != undefined) {
						try {
							var output = JSON.parse(param.data.leg_details);
							output.map((e) => e.type_of_tracking = param.data.type_of_tracking);
							if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
								var customer_code = JSON.parse(localStorage.getItem("customer_code"));
								// console.log(customer_code,"customer_code--")
								if (customer_code.length > 0) {
									var output1 = [];
									var otpt = await output.map(async (e) => {
										var customer_codes = e.consignee_code;
										customer_codes = parseInt(customer_codes).toString();
										// console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
										if (customer_code.indexOf(customer_codes) >= 0) {
											output1.push(e);
										}
									})
									var output = output1;
								}
							}
							var selectedFilter = $("#selectedFilter").text();
							if (selectedFilter == "Invoice") {
								var inputTruckConsignment = $("#inputTruckConsignment").val();
								if (inputTruckConsignment != undefined && inputTruckConsignment != "") {
									output = output.filter((e) => e.sales_order == inputTruckConsignment)
								}
							}
							await param.successCallback(output);
						}
						catch (e) { }
					}

				},
				masterDetail: true
			}
		});
		// console.log(propsdata);
		if (rownode.column.colDef.field == 'loadingarealegs') {

			rownode.node.setExpanded(!rownode.node.expanded);
		}
		else {

			rownode.node.setExpanded(false);
		}
	}

	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter
		console.log("selectedFilter", selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		var inputTruckConsignment = inputTruckConsignment.toUpperCase()
		console.log("selFilter", inputTruckConsignment)
		if (selectedFilter == "" || inputTruckConsignment == "" || selectedFilter == undefined) {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			const { originalData, pagecounters } = this.state
			// console.log("allRowData",allRowData,"pagecounters",pagecounters )	
			if (selectedFilter == "Truck") {
				let filteredData = originalData.filter(item => item.truck_no.includes(inputTruckConsignment))
				console.log("filteredData", filteredData)
				// this.gridApi.setRowData(filteredData);
				// this.gridApi.onFilterChanged();
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});

			}
			if (selectedFilter == "GR No") {
				let filteredData = originalData.filter(item => item.consignment_codes.includes(inputTruckConsignment))

				// console.log("Consingments", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"

				});
			}
			if (selectedFilter == "Invoice") {
				let filteredData = originalData.filter(item => item.invoice_no.indexOf(inputTruckConsignment) >= 0)
				console.log("Invoice", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
			if (selectedFilter == "LPS(Delivery No)") {
				let filteredData = originalData.filter(item => item.shipment_id.includes(inputTruckConsignment))
				console.log("LPS(Delivery No)", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
		}
	}
	onClickTripButton = async () => {

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		// console.log("consigner",selectconsigners)
		var selectconsigners = []
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = [this.state.consigner]
		}
		// console.log("consignee",selectconsignees)
		var selectconsignees = []
		if (this.state.consignee == null) {
			selectconsignees = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsignees = this.state.consignee
		}

		var selecttrip = []
		if (this.state.triptype == null) {
			selecttrip = { "value": 'all', "label": 'All' }
		}
		else {
			selecttrip = this.state.triptype
		}

		var requestparams = {
			consigner: selectconsigners,
			consignee: selectconsignees,
			dept_code: this.state.deptcode,
			startDate: sDate,
			endDate: eDate,
			triptype: selecttrip,
			returnConsignments: this.state.tnpReturnConsignmentsVisibilty
		}
		if (localStorage.getItem("is_sales_type") == 1) {
			requestparams.sales_type = localStorage.getItem("sales_type");
			requestparams.region = localStorage.getItem("region");
		}
		var urlpath = '';
		if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				requestparams.transporter_code = tptCode[0];
			}
		}
		var transportercode = localStorage.getItem('transportercode');
		if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
			requestparams.transporter_code = transportercode;
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
		}

		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}
		console.log("requestparams", requestparams)

		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}

	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0], deliveryDate: val[2] });
		// console.log(val)
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeMobileHandler = (e) => {
		const { value } = e.target;
		const mobilePattern = /^\d{0,10}$/;

		if (mobilePattern.test(value)) {
			this.setState({
				new_mobile_no: value
			});
		}
	}
	
	formForceCloseHandler(event) {
		event.preventDefault()
		var forcecloserow = this.state.forceCloseRowNode;
		console.log("forceCloseRowNode ", this.state.forceCloseRowNode);
		var consignemnt = this.state.forceCloseRowNode.data;
		var consignment_code = consignemnt.shipment_id;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = "SNDG";
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		var reasonforceclose = this.state.reasonforceclose;
		var others_Reason = this.state.selectedReason;
		let deliveryDate = this.state.deliveryDate
		//    if(this.state.deptcode == "SNDG")
		// 	{
		var reqdata = {
			dept_code: dept_code,
			shipment_id: [consignment_code],
			truck_no: truck_no,
			userid: userid,
			reasonforceclose: reasonforceclose,
			others_reason: others_Reason,
			deliveryDate: deliveryDate,
			consignee_code: consignee_code
		}
		// }
		// else
		// {
		//    var reqdata = {
		// 	   dept_code:dept_code,
		// 	   consignment_code:[consignment_code],
		// 	   truck_no:truck_no,
		// 	   userid:userid,
		// 	   screen : "TMS",
		// 	   others_Reason : others_Reason,
		// 	   reasonforceclose:reasonforceclose,
		//    }
		// }
		var flag = 0;
		if (others_Reason == "" && reasonforceclose == "Others") {
			flag = 1;
		}
		if (flag == 0 && reasonforceclose == "Others") {
			//    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
			if (others_Reason.replace(/\s/g, "").length == 0) {
				flag = 1;
			}
		}
		console.log("reqdata ", reqdata)
		if (flag == 0) {
			redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
				.then((response) => {
					console.log("Resposne updateConsignmentForceClose ", response)
					if (response.data.status == 'Success') {
						// document.getElementById("reached_dealer_time").value='';
						// document.getElementById("left_dealer_time").value='';
						this.setState({
							show: true,
							basicTitle: "Success",
							basicType: "success",
							loadshow: 'show-n',
							overly: 'show-n',
							left_dealer_time: '',
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: "",
							openforceclose: false,
							sliderForceCloseTranslate: ''
						});
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Error",
							basicType: "danger",
							loadshow: 'show-n',
							left_dealer_time: '',
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: '',
							openforceclose: false,
							sliderForceCloseTranslate: '',
							overly: 'show-n'
						});
					}

				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'Reason for closue should not be empty',
				basicType: "danger",
			});
		}
		event.target.reset();
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType,selOption)
		this.setState({ [filterType]: selOption });
	}
	onSelectState = state => {
		console.log(state, "1234");
		var districtsList = this.state.original_districts
		console.log(districtsList, "districtss")
		var districts = [{ label: 'ALL', value: '' }]
		var actual_districts=[]
		districtsList.map((e) => {
			if (state.label != 'All') {
				if (e._id.consignee_state == state.label) {
					actual_districts.push({ label: e._id.consignee_district, value: e._id.consignee_district })
				}
			}
			else {
				actual_districts.push({ label: e._id.consignee_district, value: e._id.consignee_district })
			}
		})
		sortByLabel(actual_districts)
		districts.push(...actual_districts)
		this.setState({
			selectState: state,
			districtsList: districts

		})

	}

	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "alltrips",
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = 'alltrips';
			
			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: "alltrips"
			}
			console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}
	restoreGridStates() {
		if (this.state.usergridstate.length !== 0) {
			console.log(this.state.usergridstate,"this.state.usergridstate")
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			}
			catch (e) {

			}
		}
	}

	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};

	reloadPageData = () => {
		window.location.reload();
	}
	onClickExportBtn() {
		this.gridApi.exportDataAsExcel();

	}
	onClicksendemailtocustomer1(rowdata){
		var row = rowdata.data 
		var containerslist = []
		containerslist.push(row.shipment_id)
		var params = {
			shipment_id: containerslist,
			check: 2
		}
		this.sendemail(params)
	}
	onClicksendemailtotransporter1(rowdata){
		var row = rowdata.data 
		var containerslist = []
		containerslist.push(row.shipment_id)
		var params = {
			shipment_id: containerslist,
			check: 1
		}
		this.sendemail(params)
	}
	onClicksendemailtotrans() {
		var containerslist = this.state.containerslist
		var params = {
			shipment_id: containerslist,
			check: 1
		}
		if(containerslist.length>0){
			this.sendemail(params)
		}

	}
	onClicksendemailtocusto() {
		var containerslist = this.state.containerslist
		var params = {
			shipment_id: containerslist,
			check: 2
		}
		if(containerslist.length>0){
			this.sendemail(params)
		}
	}
	sendemail(params) {
		var selectword = params.check==1?"Transporter":"Customer"
		redirectURL.post("/chettinad/sendemail", params).then((resp) => {
			if (resp.data.message == "Success") {
				this.setState({
					show: true,
					basicTitle: "Successfully Sent to"+' '+selectword,
					basicType: "success",
					loadshow: 'show-n'
				})
			}
		})
	}
	onShowDeviate = async (params) => {
		if (params != 0) {
			var overspeedData = [];
			await this.setState({
				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								cellRendererFramework: KpiDeviationMapActions,
								width: 50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Deviation On", field: "deviation_timestamp", width: 180, resizable: true,sortable: true,
								valueGetter: function (params) {
									return getHyphenDDMMMYYYYHHMM(params.data.deviation_timestamp);
								}
							},
							{ headerName: "Address", field: "deviation_address", resizable: true, },
							{ headerName: "Lattitude", field: "deviation_lat", resizable: true, sortable: true,},
							{ headerName: "Longtitude", field: "deviation_lng", resizable: true, sortable: true,},
						],
					},
					getDetailRowData: function (params) {
						redirectURL.post('/chettinad/getConsignmentDeviationData', {
							shipment_id: params.data.shipment_id,
							consignment_code: params.data.consignment_code,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								overspeedData = response.data;
								// console.log(overspeedData,"overspeed123")
								const filteredData = overspeedData.reduce((result, current) => {
									const existingLocation = result.findIndex(
									  (item) =>
										item.deviation_lng === current.deviation_lng && item.deviation_lat === current.deviation_lat
									);
								  
									if (existingLocation === -1) {
									  result.push({ ...current });
									}
								  
									return result;
								  }, []);
								  
								//   console.log(filteredData);
								params.successCallback(filteredData);
							});
					}
				}
			});

			if (params.column.colId == 'deviation_lifetime') {
				params.node.setExpanded(!params.node.expanded);
			}
			else {
				params.node.setExpanded(false);
			}
		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}
	}
	onClickShowDeviationMap = (params) => {
		console.log(params, "params")
		params.deviation = 1;
		params.address = params.deviation_address;
		var params1 = {
			"consigner_code": params.consigner_code,
			"consignee_pincode": params.consignee_pincode,
		}
		redirectURL.post("/chettinad/getBaseRoutes",
			params1).then((resp) => {
				var route_coords = [];
				var baseRouteCoords = resp.data;
				if (baseRouteCoords != "" && baseRouteCoords != null && baseRouteCoords != undefined) {
					route_coords = eval(baseRouteCoords.base_route);
				}
				console.log(route_coords, "route_coords")
				this.setState({
					maptruckno: params.truck_no,
					baseRouteCoords: route_coords,
					mapinfo: params,
					location_coords: { lat: params.deviation_lat, lng: params.deviation_lng },
					overly: "show-m",
					mapfor: "show_location",
					sliderRouteTranslateDeviation: "slider-translate-60p",
					loadshow: 'show-n',
				})

			})
	}
	render() {
		console.log(this.state.mapinfo,"mapInfooo")
		const modalStyles = {
			width: '1300px !important',
		}
		const { open, openforceclose, usermanualncrmodal, usermanualmovemodal } = this.state;

		if (localStorage.getItem('user_type') == "MARKETING") {
			var hideforsales = true;
		}
		else {
			var hideforsales = false;
		}
		var hideData = "";
		if (localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) {
			var hideData = false;
		}
		else {
			var hideData = true;
		}
		var hideForCustomer = "";
		var hideForNotCustomer = "";
		if (localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
			var hideForCustomer = true;
			var hideData = true;
			var hideforsales = true;
			var hideForNotCustomer = false;
		}
		else {
			var hideForCustomer = false;
			var hideForNotCustomer = true;
		}
		var hideForbgs = "";
		if (localStorage.getItem("pc") != "HZ" || localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
			hideForbgs = true;
		}
		if (localStorage.getItem('email') == "dcp_chettinad@enmovil.in") {
			hideData = true
		}

		var enable_update_btn = (this.state.check_domain.test(this.state.email.trim().toLowerCase()) || ["jayakumar.n@chettinadcement.com","elangovan.m@chettinadcement.com"].includes(this.state.email)) ? false : true
		
		var columnwithDefs = [
			{
				headerName: "View Route",
				field: "_id",
				width: 90,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				filter: false,
				resizable: true,
				// headerCheckboxSelection: true,
				// headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
			},
			{
				headerName: "",
				field: "shipmentlegs",
				width: 50,
				pinned: 'left',
				cellRenderer: 'ShipmentLegs',
				filter: false, resizable: true
			},
			{
				pinned: "left",
				headerName: 'Deviation',
				colId: 'deviation_lifetime',
				field: 'deviation_lifetime',
				width: 40,
				cellStyle: { color: "#464855", fontWeight: "500", display: "flex", alignItems: "center" },
				cellRenderer: 'kpiDeviationActions',
				valueGetter: function (params) {
					if(params.data.deviation_lifetime != undefined && params.data.deviation_lifetime != null && params.data.deviation_lifetime != ""){
						if ([1,1.0,"1","1.0"].includes(params.data.deviation_lifetime))
						{
						   return "Deviated";
					   }
					   else {
						   return "Not Deviated";
					   }
					}else{
						return "Not Deviated";
					}
					
				}
			},
			{
				headerName: "Truck No (API)",
				field: "truck_no",
				width: 120,
				pinned: "left",
				valueGetter: function (params) {
					if (params.data.truck_no != undefined && params.data.truck_no != "") {
						return params.data.truck_no;
					}
					else {
						return "No Data From API";
					}
				}
			},
			{
				headerName: "LPS(Delivery No) (API)",
				field: "shipment_id",
				width: 140,
				hide: hideforsales,
				suppressColumnsToolPanel: hideforsales,
			},
			{
                headerName:"LPS Date (API)",
                field:"shipment_time",
                width:100,
                valueGetter:function(params){
                    try{
                        if(params.data.shipment_time == "Yet to Invoice"){
                            return "Yet to Invoice"
                        }
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.shipment_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
                // comparator: dateComparator,
            },
            {
                headerName:"LPS Time (API)",
                field:"shipment_time",
                width:100,
                valueGetter:function(params){
                    try{
                        if(params.data.shipment_time == "Yet to Invoice"){
                            return "Yet to Invoice"
                        }
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenHHMM(params.data.shipment_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
                // comparator: dateComparator,
            },
			{
                headerName:"Plant Name (API)",
                field:"consigner_name",
                width:140,
                
            },
			{
				headerName: "Transporter Code (API)",
				field: "transporter_code",
				width: 150,
			},
			{
				headerName: "Transporter Name (API)",
				field: "transporter_name",
				width: 200,
			},
			{
				headerName: "Invoice No (API)",
				field: "invoice_no",
				width: 150,
			},
			{
				headerName: "Driver Name (API)",
				field: "driver_name",
				width: 140,
			},
			{
				headerName: "Driver Mobile No (API)",
				field: "driver_mobile_no",
				width: 140,
				hide: hideforsales,
				suppressColumnsToolPanel: hideforsales,
			},
            {
				headerName: "Freight Type (API)",
				field: "freight_type_desc",
				width: 120,
				resizable: true,
				colId:"freight_type_desc",
			},
            {
				headerName: "Segment (API)",
				field: "invoice_segment",
				width: 120,
				resizable: true,
				colId:"invoice_segment",
			},
            {
				headerName: "Freight Segment Code (API)",
				field: "freight_segment",
				width: 150,
				resizable: true,
				colId:"freight_segment",
			},
            {
				headerName: "Freight Distance (API)",
				field: "distance_in_km",
				width: 130,
				resizable: true,
				colId:"distance_in_km",
			},
            {
				headerName: "Freight cost PMT (API)",
				field: "freight_rs_pmt",
				width: 130,
				resizable: true,
				colId:"freight_rs_pmt"
			},
			{
				headerName: "Destination Name (API)",
				field: "consignee_destination_name",
				width: 130,
				resizable: true,
			},
            {
				headerName: "Destination Code (API)",
				field: "consignee_destination_code",
				width: 130,
				resizable: true,
				colId:"consignee_destination_code"
			},
			{
				headerName: "Customer Code (API)",
				field: "consignee_code",
				width: 120
			},
			{
				headerName: "Customer Name (API)",
				field: "consignee_name",
				width: 200,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Delivery State (API)",
				field: "consignee_state",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Delivery District (API)",
				field: "consignee_district",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Customer Address (API)",
				field: "consignee_address",
				width: 200,
				valueGetter: function (params) {
					try {
						if (params.data.consignee_address != "" && params.data.consignee_address != undefined) {
							return params.data.consignee_address;
						}
						else {
							return "No Data Available";
						}

					}
					catch (e) { }
				},
				resizable: true,
			},
			{
				headerName: "Delivery Pincode (API)",
				field: "consignee_pincode",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Tracking Mode",
				field: "type_of_tracking",
				width: 120,
				pinned: "left",
				valueGetter: function (params) {
					if (params.data.type_of_tracking != undefined && params.data.type_of_tracking != "") {
						if (params.data.type_of_tracking == 0) {
							return "Waiting For GPS";
						}
						else if (params.data.type_of_tracking == 1) {
							return "GPS Tracking";
						}
						else if (params.data.type_of_tracking == 2) {
							return "SIM Tracking";
						}
						else if (params.data.type_of_tracking == 3) {
							return "GPS & SIM Tracking";
						}
						else if (params.data.type_of_tracking == 4) {
							return "SIM Tracking Consent Pending";
						}
					}
					else {
						return "Waiting For GPS";
					}
				},

			},
			{
				headerName: "Sim Tracking Status",
				field: "sim_tracking_state",
				width: 140,
				hide: true,

			},
            {
                headerName:"Sim Tracking Service P",
                field:"sim_tracking_service_provider",
                width:140,
                hide:true,
                
            },
			{
				headerName: "Status Value",
				field: "status",
				width: 150,
				hide:true
			},
			// {
			// 	headerName:Constant.COL_CUSTOMER_CODE,
			// 	field:"consignee_code",
			// 	width:120,
			// 	resizable : true,
			// 	filter : true,
			// },
			// {
			// 	headerName: Constant.COL_CUSTOMER_CITY,
			// 	field: "consignee_city",
			// 	width: 120,
			// 	resizable: true,
			// 	filter: true,
			// },
			// {
			// 	headerName: Constant.COL_CONSIGNEE_STATE,
			// 	field: "consignee_state",
			// 	width: 140,
			// 	valueGetter: function (params) {
			// 		try {
			// 			if (params.data.consignee_state != "" && params.data.consignee_state != undefined) {
			// 				return params.data.consignee_state;
			// 			}
			// 			else {
			// 				return "No Data Available";
			// 			}

			// 		}
			// 		catch (e) { }
			// 	},
			// 	resizable: true,
			// },
			
			{
				headerName: Constant.COL_STATUS,
				field: "status",
				width: 150,
				pinned: 'left',
				valueGetter: function (params) {
					try {
						var status = Constant.INSIDE_TPA;
						if (params.data.status == 1 || params.data.status == "1") {
							status = Constant.INSIDE_TPA;
						}
						if (params.data.status == 2 || params.data.status == "2") {
							status = Constant.INTRANSIT_TO_MY;
						}
						if (params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5") {
							status = Constant.INSIDE_MY_PMILL;
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = 'Force Close By '+params.data.force_closure_performed_by
						}
						if (params.data.status == 7 || params.data.status == "7") {
							status = Constant.INSIDE_TPA_AFTER_LOADING;
						}
						if (params.data.status == 8 || params.data.status == "8") {
							status = Constant.INTRANSIT;
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Reached 40 KM Fence";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Reached 20 KM Fence";
						} 
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Left from 20 KM Fence ";
						} 
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left from 40 KM Fence";
						}
						if (params.data.status == 13 || params.data.status == "13" || params.data.status == 14 || params.data.status == "14" ) {
							status = "Unloaded in 40 KM Fence";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Unloaded in 20 KM Fence";
						}
						if (params.data.status == 16 || params.data.status == "16" || params.data.status == 17 || params.data.status == "17" ) {
							status = "Unloaded in the Enroute";
						}
						if (params.data.status == 18 || params.data.status == "18" || params.data.status == 20 || params.data.status == "20" ) {
							status = "Trip closure Equal to Fencing";
						}
						if (params.data.status == 19 || params.data.status == "19") {
							status = "Trip closure Before Fencing";
						}
						if (params.data.status == 21 || params.data.status == "21") {
							status = "Trip closure After Fencing ";
						}
						if (params.data.status == 22 || params.data.status == "22") {
							status = "Consent Stopped / withdrawn by Driver";
						}
						if (params.data.type_of_tracking == 0) {
							if (params.data.type_of_tracking == 0) {
								return "Waiting For GPS";
							}
							// else {
							// 	return "SIM Consent Pending";
							// }
						}
						else {
							return status;
						}
					}
					catch (e) { }
				}
			},
			{
				headerName: "Unloaded Status",
				field: "unloaded_status",
				colId: "unloaded_status",
				width: 140
			},
			{
				headerName: "Unloading Coords",
				field: "unloading_coords",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.unloading_coords && params.data.unloading_coords !== "") {
							var unloading_coords = JSON.parse(params.data.unloading_coords);
							if (unloading_coords && typeof unloading_coords.lat === 'number' && typeof unloading_coords.lng === 'number') {
								return `${unloading_coords.lat},${unloading_coords.lng}`;
							} else {
								return "";
							}
						} else {
							return "";
						}
					} catch (e) {
						console.error('Error parsing unloading_coords:', e);
						return "";
					}
				}				
			},
			// {
			// 	headerName: "Consignment Code",
			// 	field: "consignment_code",
			// 	width: 140,

			// },
			// {
			// 	headerName: "Consignment Time",
			// 	field: "consignment_time",
			// 	width: 140,
			// 	valueGetter: function (params) {
			// 		if (params.data.consignment_time != "" && params.data.consignment_time != undefined) {
			// 			return getHyphenDDMMMYYYYHHMM(params.data.consignment_time);
			// 		}
			// 		else {
			// 			return "Not Available";
			// 		}
			// 	}
			// },
			{
				headerName: "Truck Type",
				field: "truck_type",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.truck_type == "M") {
							return "Market";
						}
						else if (params.data.truck_type == "O") {
							return "Own";
						}
						else {
							return params.data.truck_type;
						}
					}
					catch (e) { }
				},
			},
			{
				headerName: "Item Description (API)",
				field: "item_description",
				width: 140,

			},
			{
				headerName: "Item Quantity (API)",
				field: "item_quantity",
				width: 140,

			},     
            {
				headerName: "Last Packet Date",
				field: "timestamp",
				width: 140,				
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp != "" && params.data.timestamp != undefined)
						{
							return getHyphenDDMMYYYY(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},     
            {
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 140,				
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp != "" && params.data.timestamp != undefined)
						{
							return getHyphenHHMM(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Expected Trip End Date",
				field: "expected_trip_end ",
				width: 150,
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined)
						{
							return getHyphenDDMMMYYYY(params.data.expected_trip_end);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Expected Trip End Time",
				field: "expected_trip_end ",
				width: 150,
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined)
						{
							return getHyphenHHMM(params.data.expected_trip_end);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Revised Trip End Date",
				field: "revised_trip_end ",
				width: 150,
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.revised_trip_end != "" && params.data.revised_trip_end != undefined)
						{
							return getHyphenDDMMMYYYY(params.data.revised_trip_end);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Revised Trip End Time",
				field: "revised_trip_end ",
				width: 150,
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.revised_trip_end != "" && params.data.revised_trip_end != undefined)
						{
							return getHyphenHHMM(params.data.revised_trip_end);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Reached Customer Fence Date",
				field: "recent_dealer_reported",
				width: 150,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined) {
						return getHyphenDDMMMYYYY(params.data.recent_dealer_reported);
					}
					else {
						return "Not Available";
					}
				}

			},
			{
				headerName: "Reached Customer Fence Time",
				field: "recent_dealer_reported",
				width: 150,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined) {
						return getHyphenHHMM(params.data.recent_dealer_reported);
					}
					else {
						return "Not Available";
					}
				}

			},
			{
				headerName: "Left Customer Fence Date",
				field: "left_dealer_marked",
				width: 140,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.left_dealer_marked != "" && params.data.left_dealer_marked != undefined) {
						return getHyphenDDMMMYYYY(params.data.left_dealer_marked);
					}
					else {
						return "Not Available";
					}
				}
			},
			{
				headerName: "Left Customer Fence Time",
				field: "left_dealer_marked",
				width: 140,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.left_dealer_marked != "" && params.data.left_dealer_marked != undefined) {
						return getHyphenHHMM(params.data.left_dealer_marked);
					}
					else {
						return "Not Available";
					}
				}
			},
			{
				headerName: "Dist From Plant To Customer Location",
				field: "distance_in_km",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Distance Travelled",
				field: "total_travelled_trip_dist_km",
				width: 120,
				hide:true,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Remaining Distance To Customer (KM)",
				field: "distance_from_dealer_location",
				width: 180,
				cellRenderer: '', resizable: true,
				filter: true,
				hide:true,
				// hide:hideForCustomer,
				// suppressColumnsToolPanel:hideForCustomer,  
				valueGetter: function (params) {
					//console.log("params ", params.data);
					if (typeof params.data.distance_from_dealer_location == 'undefined') {

					}
					else {
						return Math.round(params.data.distance_from_dealer_location);
					}
				},
			},
			// {
			// 	headerName: "Near Customer",
			// 	field: "first_15_km_entry",
			// 	width: 120,
			// 	resizable: true,
			// 	filter: true,
			// 	valueGetter: function (params) {
			// 		if (params.data.first_15_km_entry != "" && params.data.first_15_km_entry != undefined) {
			// 			return getHyphenDDMMMYYYYHHMM(params.data.first_15_km_entry);
			// 		}
			// 		else {
			// 			return "Not Available";
			// 		}
			// 	}
			// },
			{
				headerName: "Expected Packets Per KM On Trip",
				field: "expected_packets_per_km_on_trip",
				width: 200,
				resizable: true,
				filter: true,
				hide: true
			},
			{
				headerName: "No Of Packets Received",
				field: "no_of_packets_received",
				width: 120,
				resizable: true,
				filter: true,
				hide: true
			},
			{
				headerName: "Packets Received(%)",
				field: "packets_received_percentage",
				width: 150,
				resizable: true,
				filter: true,
				hide: true
			},
			{
				headerName: "Distance Between 2 Packets(km)",
				field: "dist_btw_2_pts_gt_x_km",
				width: 150,
				resizable: true,
				filter: true,
				hide: true
			},
			{
				headerName: "Distance Between 2 Packets(%)",
				field: "dist_btw_2_pts_gt_x_km_percentage",
				width: 150,
				resizable: true,
				filter: true,
				hide: true
			},
			{
				headerName: "Health Percentage(%)",
				field: "health_percentage",
				width: 150,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Unloaded Location Accuracy",
				field: "unloading_location_accuracy",
				width: 150,
				resizable: true,
				filter: true
			},
			{
				headerName: "Trip Deviation Status",
				field: "geofence_deviation",
				width: 140,
				valueGetter: function(params){
					if(params.data.geofence_deviation == 1)
					{
						return "Deviated";
					}
					else
					{
						return "Not Deviated";
					}
				}
			},
			// {
			// 	headerName: "Data Accuracy",
			// 	field: "data_accuracy",
			// 	width: 150,
			// 	resizable: true,
			// 	filter: true,
			// },
			{
				headerName: "Health Perc Packets",
				field: "health_percentage_wrt_packets",
				width: 150,
				hide:true
			},
			{
				headerName: "Health Perc Unload",
				field: "health_percentage_wrt_unloading",
				width: 150,
				hide:true
			},
			{
				headerName: "Freight Cost(FM)",
				field: "fm_cost",
				width: 150,
			},
			{
				headerName: "Freight Pay Type(FM)",
				field: "fm_pay_type",
				width: 150,
			},
			{
				headerName: "Freight Segment(FM)",
				field: "fm_segment",
				width: 150,
			},
			{
				headerName: "Freight Segment Code(FM)",
				field: "fm_material_segment_code",
				width: 150,
			},
			{
				headerName: "Freight Type(FM)",
				field: "fm_type",
				width: 150,
			},
			{
				headerName: "Freight Distance(FM)",
				field: "fm_distance_in_kms",
				width: 150,
			},
			{
				headerName: "Pincode in Freight(FM)",
				field: "pincode_available_in_fm",
				width: 150,
				hide:true
			},
			{
				headerName: "Dist from Freight Pincode(FM)",
				field: "min_dist_from_fm_pincode",
				width: 150,
				hide:true
			},
			{
				headerName: "Freight Dest Name(FM)",
				field: "fm_dest_name",
				width: 150,
			},
			{
				headerName: "Freight Dest Code(FM)",
				field: "fm_dest_code",
				width: 150,
			},
			{
				headerName: "Freight District(FM)",
				field: "fm_district",
				width: 150,
			},
			{
				headerName: "Freight Pincode(FM)",
				field: "fm_pincode",
				width: 150,
			},
			{
				headerName: "Freight Differnce(FM)",
				field: "fm_freight_difference",
				colId: "fm_freight_difference",
				width: 150,
			},
			{
				headerName: "Cost Differnce(FM)",
				field: "fm_cost_difference",
				colId: "fm_cost_difference",
				width: 150,
			},
			{
				headerName: "Total Toll Cost (in rupees)",
				field: "total_toll_cost",
				width: 120,
			},
			{
				headerName: "No Of Tolls Crossed",
				field: "no_of_tolls_crossed",
				width: 120,
			},
			{
				headerName: "Unloaded Pincode",
				field: "unloading_pincode",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Unloaded Address",
				field: "unloading_address",
				width: 200,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Unloaded District",
				field: "unloading_district",
				width: 170,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Unloaded City",
				field: "unloading_city",
				width: 120,
				resizable: true,
				filter: true,
			},
			// {
			// 	headerName: "Force Closure Performed By",
			// 	field: "force_closure_performed_by",
			// 	width: 120,
			// 	resizable: true,
			// 	filter: true,
			// },
			{
				headerName: "Force Closure Done Time",
				field: "force_closure_time",
				width: 120,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.force_closure_time != "" && params.data.force_closure_time != undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.force_closure_time);
					}
					else {
						return "";
					}
				}
			},
			{
				headerName: "Force Closure Reason",
				field: "force_closure_reason",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: 'Send Email To Transporter',
				field: '',
				width: 140,
				filter: true,
				cellRendererSelector: function (params) {
					if ([1,1.0,"1","1.0"].includes(params.data.deviation_lifetime)) {
						var rendComponent = {
							component: 'Sendemailbtn'
						};
						return rendComponent;
					}
				},
			},
			{
				headerName: 'Send Email To Customer',
				field: '',
				width: 140,
				filter: true,
				cellRendererSelector: function (params) {
					if ([1,1.0,"1","1.0"].includes(params.data.deviation_lifetime)) {
						var rendComponent = {
							component: 'SendemailtoCustomerbtn'
						};
						return rendComponent;
					}
				},
			},
			{
				headerName: "View Link",
				field: "viewsharablelink",
				colId: "viewsharablelink",
				width: 150,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'Viewsharablelink'
					};
					return rendComponent;

				},
				filter: true, resizable: true,
				valueGetter: function (params) {
					var truckNo = params.data.invoice_no
					return window.location.origin + '/connect/' + truckNo;
				}
			},
			{
				headerName:Constant.DESTINATION_DEV,
				field:"destination_deviation",
				width:140,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (!["undefined", undefined, null, ""].includes(params.data.destination_deviation)) {
						if (params.data.destination_deviation == 1) {
							return "Deviated"
						}
						else if (params.data.destination_deviation == 0){
							return "Not Deviated"
						}
						else{
							return ""
						}
					}
					else {
						return ""
					}

				}
			},
			{
                headerName:"Billed Destination Pincode (API)",
                field:"billed_destination_pincode",
                width:150,
				filter: true,
				resizable: true,   
            },   
			{
                headerName:'Customer Deviation',
                field:"customer_destination_deviation",
                width:140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (!["undefined", undefined, null, ""].includes(params.data.customer_destination_deviation)) {
                        if (params.data.customer_destination_deviation == 1) {
                            return "Deviated"
                        }
                        else if (params.data.customer_destination_deviation == 0){
                            return "Not Deviated"
                        }
                        else{
                            return ""
                        }
                    }
                    else {
                        return ""
                    }

                }
            },
            {
                headerName:"Cust Regd Pincode ",
                field:"customer_pincode",
                width:150,
                filter: true,
                resizable: true,   
            }, 
			{
				headerName: "Edit Driver Mobile No",
				field: "_id",
				width: 120,
				hide:enable_update_btn,
				cellRenderer: 'UpdateShipments'

			},   
			{
				headerName: 'GPS Integration Status',
				field: "gps_integration_status",
				width: 140,
				filter: true,
				resizable: true,
                valueGetter: function (params) {
                    if (!["undefined", undefined, null, ""].includes(params.data.gps_integration_status)) {
                        if (params.data.gps_integration_status == "integrated") {
                            return "Integrated"
                        }
                        else if (params.data.gps_integration_status == "not_integrated"){
                            return "Not Integrated"
                        }
                        else{
                            return ""
                        }
                    }
                    else {
                        return ""
                    }

                }
			},
			{
				headerName: 'GPS Working Status',
				field: "gps_working_status",
				width: 140,
				filter: true,
				resizable: true,
                valueGetter: function (params) {
                    if (!["undefined", undefined, null, ""].includes(params.data.gps_working_status)) {
                        if (params.data.gps_working_status == "working") {
                            return "Working"
                        }
                        else if (params.data.gps_working_status == "not_working"){
                            return "Not Working"
                        }
                        else{
                            return ""
                        }
                    }
                    else {
                        return ""
                    }

                }
			},
			{
                headerName: 'GPS Service Provider',
                field: "gps_service_provider",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (!["undefined", undefined, null, ""].includes(params.data.gps_service_provider)) {
                        return params.data.gps_service_provider
                            .split('_')         
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
                            .join(' ');
                    }
                    else {
                        return ""
                    }

                }
            }, 
		]
		if(localStorage.getItem('roles').indexOf("SITE_ADMIN") >=0 ){
			columnwithDefs.push({
				headerName: "Plant In Time (API)",
				field: "plant_in_time",
				width: 120,
				resizable: true,
				hide:true,
				colId:"plant_in_time",
			},
			{
				headerName: "Deviation Lifetime",
				field: "deviation_lifetime",
				width: 120,
				resizable: true,
				hide:true,
				colId:"deviation_lifetime",
			},
			{
				headerName: "Deviation Identified",
				field: "deviation_identified",
				width: 120,
				resizable: true,
				hide:true,
				colId:"deviation_identified",
			},)
		}
		

		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		const commentColumns = [
			{
				headerName: Constant.COL_TRUCK_NO,
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: Constant.COL_CONSIGNMENT_CODE,
				field: "consignment_codes",
				resizable: true,
				width: 120,
				filter: true
			},
			{
				headerName: Constant.COL_USER_TYPE,
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return Constant.USER_TYPE_MSIL;
					}
					if (params.data.user_type == "DCTUSER") {
						return Constant.USER_TYPE_DCT;
					}
					if (params.data.user_type == "TRANSPORTER") {
						return Constant.USER_TYPE_TPT;
					}
				}
			},
			{
				headerName: Constant.COL_COMMENT,
				field: "comment",
				width: 250,
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: Constant.COL_COMMENT_BY,
				field: "username",
				width: 150,
				resizable: true,
				filter: true
			},

			{
				headerName: Constant.COL_COMMENT_DATE,
				field: "comment_date",
				resizable: true,
				filter: true,
				width: 150,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getDDMMYYYYHHMMSS(params.data.comment_date);
					}
				}
			}
		]
		var baseRouteCoordsData = this.state.baseRouteCoords;
		var { initialcheck } = this.state
		var divclassname = initialcheck ? 'col-xl-8 col-lg-8' : 'col-xl-12 col-lg-12'
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>

				{/* <div className="row" style={{marginBottom:"1%"}}>
					<div className="col-xl-12 col-lg-12">
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
					</div>
				</div> */}
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h5 className='highlight-heading'>
							<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>All Trips</span>

						</h5>
						{this.state.initialcheck ?
							<button onClick={this.reloadPageData} className="btn btn-info" style={{ float: "right" }}>Reset Filters</button> : ""}

					</div>
				</div>
				<div className="row">
					{this.state.initialcheck ?
						<div className="col-xl-4 col-lg-4">
							<div className="card" >
								<div className="row card-body" style={{ padding: "10px 30px" }}>
									<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
										<div className={"col-sm-12"}>
											<label>Select Type   </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedFilter"
												style={{ borderRadius: "0px" }}
												options={this.state.filterOptions}
												onChange={e => { this.setState({ selectedFilter: e.label }) }}
												required />
										</div>
										<div className={'col-sm-12 mt-20p'}>
											<label className="reason">{this.state.filterNames}</label>
											<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
										</div>
										<div className="form-group col-xl-12 col-lg-12 mt-20p" style={{ textAlign: "left" }}>
											<button type="submit" className="btn btn-success">Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div> : ""}
					<div className={divclassname}>
						<div className="card" style={{ minHeight: "253px" }}>
							<div className="row card-body" style={{ padding: '10px' }}>
								<div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>LPS From Date</label>
										{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
										<Datetime
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: 'true',value : this.state.startDate }}
											dateFormat="YYYY-MM-DD"
											timeFormat={false} 
											name="startDate"
											onChange={this.handlerStartDateTime.bind(this)} />
									</div>
									<div className="col-xl-3 col-lg-3 form-group">
										<label>LPS To Date</label>
										{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
										<Datetime										
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: 'true',value : this.state.endDate}}
											dateFormat="YYYY-MM-DD"
											timeFormat={false} 
											onChange={this.handlerEndDateTime.bind(this)} />
									</div>
								</div>
								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									{(localStorage.getItem("roles") != "CUSTOMER") ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Customers   </label>
											<Select
												className="border-radius-0"
												closeMenuOnSelect={true}
												isMulti={true}
												id="selectedCustomer"
												style={{ borderRadius: "0px" }}
												value={this.state.flCustomer}
												onChange={this.newFilter.bind(this, "flCustomer")}
												options={this.state.filterCustomers}
											/>
										</div>
										: ""}
									{(localStorage.getItem("roles") != "CUSTOMER") ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Delivery State</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												value={this.state.selectState}
												style={{ borderRadius: "0px" }}
												// value={this.state.flState}
												onChange={this.onSelectState.bind(this)}
												options={this.state.filterStates}
											/>

										</div> : ""}
									{(localStorage.getItem("roles") != "CUSTOMER") ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Delivery District </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedDistrict"
												style={{ borderRadius: "0px" }}
												value={this.state.flDistrict}
												onChange={this.newFilter.bind(this, "flDistrict")}
												options={this.state.districtsList}
											/>
										</div>
										: ""}
									{(localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "" || localStorage.getItem("roles") == "CUSTOMER") ? "" :
										<div className="form-group col-xl-3 col-lg-3">
											<label>Transporter  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedTransporter"
												style={{ borderRadius: "0px" }}
												value={this.state.flTransporter}
												onChange={this.newFilter.bind(this, "flTransporter")}
												options={this.state.filterTransporters}
											/>
										</div>
									}
									{/* {(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")== "CUSTOMER") ? "":
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transportation Zone:  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporterZone"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporterZone}
											onChange={this.newFilter.bind(this, "flTransporterZone")}
											options={this.state.filterTransportersZones}
										/>
									</div>
									} */}
								</div>

								<div className="form-group col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.initialcheck ?
					(localStorage.getItem('roles') != "CUSTOMER") ?
						<Counters
							context={this}
							countersjson={this.state.countersjson}
							gridData={this.state.allRowData}
							deptcode={"SNDG"}
							triptype={this.state.tripvalue}
							counterScreen={this.state.counter_screen}
							path={this.props.match.path}
						// returnConsignments={this.state.tnpReturnConsignmentsVisibilty}
						// priority_counter_display={this.state.priority_counter_display}
						// truckReportedDate = {this.state.truckReportedDate.length}
						/> :
						<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
							<div className="card">
								<div className="card-body" id="counterCardBody">
									<div className="crm-numbers pb-0">
										<div className="row">
											<div className="col cirlce-d cursorPointer" onClick={this.onClickCounterShowData.bind(this, "all")}>
												<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> {Constant.COUNTER_TOTAL_TRUCKS}</span>
												<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.allRowData != '') ? this.state.allRowData.length : 0} /></span></h4>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					: ""}

				{this.state.initialcheck ? 
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
								<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.counter_title} </span>

									<span className=""  style={{display:"flex" ,justifyContent:"right"}}>
									<button onClick={this.onClickExportBtn.bind(this)} className="float-right custom-btn white btn-warning" style={{ marginRight: "10px", float: "right" }}>Export Data</button>
										<button className="float-right custom-btn white btn-danger"  style={{ marginRight: "10px"}} onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
										{/* <button onClick={this.onClicksendemailtotrans.bind(this)} className="float-right custom-btn white btn-success" style={{ marginRight: "10px", float: "right" }}>Send Email To Transporter</button>
										<button onClick={this.onClicksendemailtocusto.bind(this)} className="float-right custom-btn white btn-info" style={{ marginRight: "10px", float: "right" }}>Send Email To Customer</button> */}

									</span>
								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
										<span className="float-right f12">
											<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route &nbsp;
											<i style={{ color: "#FF0000" }} className="icofont icofont-site-map f25"></i> - Shipment Legs &nbsp;
											<img src={deviationIcon} style={{ width: "22px", marginTop: "-13px" }} /> - Deviation
										</span>
									</div>

								</div>

								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										onRowSelected={this.onRowSelection.bind(this)}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										// rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
									/>

								</div>
							</div>
						</div>
					</div>

				</div>:""}


				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									context={this}
									rownode={this.state.rownode}
									consignercords={this.state.consigner_coordinates}
									mapFor={"truck"}
									adjcoords2={this.state.adjcoords2}
									adjcoords1={this.state.adjcoords1}
									tolls={this.state.tolls}
									consigneecords={this.state.consignee_coordinates}
									googleroutes={this.state.googelRoutes}
									truckno={this.state.maptruckno}
									drop_coordinates={this.state.drop_coordinates}
									unloading_address={this.state.unloading_address}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									deptcode={this.state.deptcode}
									defTransitCoords={this.state.defTransitCoords}
									baseRouteCoords={baseRouteCoordsData}
									geofence_coordinates={this.state.geofence_coordinates}
									tripDetails={this.state.tripDetails}
								/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Invoice No</label>
												<div>{this.state.mapinvoice_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Trip Closure Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "-"}</div>
											</div>
											{
												this.state.mapinfo &&
													this.state.mapinfo.ext_trip_end_time &&
													!['', null, undefined, 'undefined'].includes(this.state.mapinfo.ext_trip_end_time) ? (
													<div className="col route-block">
														<label className="sidebar-label"> End Time</label>
														<div>{getDDMMYYYYHHMMSS(this.state.mapinfo.ext_trip_end_time)}</div>
													</div>
												) : ""
												
											}

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Trip Distance</label>
												<div>
													{(this.state.mapinfo.trip_distance) ? Math.round(parseInt(this.state.mapinfo.trip_distance) / 1000) : "0"} Kms
												</div>
											</div>
											<div className="col route-block"><label className="sidebar-label">Tracking Type</label><br/>
											{(this.state.mapinfo.type_of_tracking == 1)?
												<div>GPS Tracking</div>
                                                :
												<div>SIM Tracking</div>
                                                }
											</div>
											{/* {(localStorage.getItem('roles')!="CUSTOMER")?
											<div className="col route-block">
												<label className="sidebar-label">Tracking Type</label>
                                                {(this.state.map_type_of_tracking == 1)?
												<div>GPS Tracking</div>
                                                :
												<div>SIM Tracking</div>
                                                }
											</div>
											:""} */}


										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}

				{this.state.sliderRouteTranslateDeviation != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslateDeviation)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Route Deviation
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawRouteMap
									context={this}
									truckno={this.state.maptruckno}
									mapfor={this.state.mapfor}
									mapinfo={this.state.mapinfo}
									location_coords={this.state.location_coords}
									dealers={[]}
									currentStatus={""}
									baseRouteCoords={baseRouteCoordsData}
								/>
							</div>
						</div>

					</div>
					: ""
				}

				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady1}
									onGridState={this.onGridState1}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}

				<div className={"slide-r " + (this.state.sliderEditDriver)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Edit Driver Details -  {this.state.shipment_id}
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickEditDriverDetails.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Shipment ID</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Code"  value={this.state.shipment_id} id="shipment_id" className="form-control" readOnly />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Truck No</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Code"  value={this.state.truck_no} id="truck_no" className="form-control" readOnly />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Old Driver Name</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Name"  value={this.state.driver_name} id="driver_name" className="form-control" readOnly />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Old Driver Mobile Number</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Mobile Number"  value={this.state.mobile_no} id="mobile_no" className="form-control" readOnly />
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Name*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Name" onChange={this.changeHandler.bind(this)} value={this.state.new_driver_name} id="new_driver_name" name="new_driver_name" className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Mobile Number*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Mobile Number" onChange={this.changeMobileHandler.bind(this)} value={this.state.new_mobile_no} id="new_mobile_no" name="new_mobile_no" className="form-control" required />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

						</div>
					</div>
				</div>



				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input readonly type="text" placeholder="Reached Dealer " value={this.state.startDatetime} id="reached_dealer_time" className="datetimepicker_mask form-control" />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input readonly type="text" placeholder="Left Dealer " value={this.state.endDatetime} id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
								<ForceCloseSideBar context={this} handleInput={this.handleInput} deptcode={this.state.deptcode} />
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
							</form>
						</div>
					</div>
				</div>
			</div>

		);
	}
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}



function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function sortByLabel(arr) {
	arr.sort((obj1, obj2) => {
		const label1 = obj1.label
		const label2 = obj2.label

		if (label1 < label2) {
			return -1;
		} else if (label1 > label2) {
			return 1;
		}
		return 0;
	});
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function GetSortASCOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
	var result = arr.reduce(function (arr1, e1) {
		var matches = arr1.filter(function (e2) {
			return e1[propName] == e2[propName];
		})
		if (matches.length == 0)
			arr1.push(e1)
		return arr1;
	}, []);

	return result;
}
function getDistinctObjects(arr) {
	function isEqual(obj1, obj2) {
		return obj1.label === obj2.label && obj1.value === obj2.value;
	}
	return arr.filter((item, index) => {
		return index === arr.findIndex((obj) => isEqual(obj, item));
	});
}
function addOneDay(dateString) {
	return new Date(new Date(dateString).getTime() + 86400000).toISOString().slice(0, 19).replace("T", " ");
  }
