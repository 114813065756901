import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import Select from 'react-select';
import { 
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
} from '../common/utils'; 

import ViewShipmentData from './viewshipmentitemsaction';

import DrawMap from '../common/drawmap';
import { isNull } from 'lodash';
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
export default class chettinadViewShipments extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
			rowModelType: "serverSide",
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                ViewShipmentData:ViewShipmentData
			},
            sliderRouteTranslate:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            
			detailCellRendererParams:{},
            totalcount:[],
            intransitmy:[],
            insidemytpa:[],
            loadingstarted:[],
            loadingcompleted:[],
            insidetpaafterloading:[],
            intransittocustomer:[],
            nearcustomer:[],
            reachedcustomer:[],
            leftcustomer:[],
            forceclose:[],
            insidetpa:[],
            
        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
		
    }
    componentDidMount(){
        // var parameters = {
            
        // }
        // redirectURL.post("/chettinad/loadingarealegsdata",parameters).then(async (response) =>{
        //     console.log("response ", response.data);
        // })

        
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            var customer_code = localStorage.getItem("customer_code");
        }
        else{
            var customer_code = "";
        }
        if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined" && localStorage.getItem("pc") != isNull)
        {
            var consigner_code = localStorage.getItem("pc");
        }
        else{
            var consigner_code = "";
        }
        var parameters = {
            customer_code:customer_code,
            consigner_code:consigner_code,
        }
        this.onLoadGetShipments(parameters);

    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/chettinad/customershipments",parameters)
        .then((response) => {
            if(response.data.remark == "SUCCESS")
            {
                var records = response.data.shipments;
                let newArray = [];
                let totuniqueObject = {}; 
                var totobjTitle;
                // Loop for the array elements 
                var lists  = [];
                for (let i in records) { 
                    
                    // Extract the title 
                    totobjTitle = records[i]['shipment_id']; 
    
                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = records[i]; 
                } 
    
                // Loop to push unique object into array 
                for (var a in totuniqueObject) { 
                    newArray.push(totuniqueObject[a]); 
                } 
                // console.log("newArray ", newArray)
                var newrowdata = []
                if(newArray.length > 0){
                    newArray.map((item) => {
                        var l = parseInt(item.no_of_lrs);
                        var s = parseInt(item.no_of_shipments);
                        var r = s-l;
                        if(r > 0)
                        {
                            newrowdata.push(item)
                        }
                        
                    })
                }
                this.setState({
                    rowData:newrowdata,
                    loadshow:"show-n"
                })
            }
            
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
    
    onClicCloseCounterBlock(){
        this.setState({
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
        })
    }
    render(){
        var maptransporter='';
		
        var columnwithDefs = [
            {
                headerName: "",
                field: "_id",
                width: 100,
                pinned: 'left',
                cellRenderer:'ViewShipmentData',
                filter: false,resizable: true
                
            },
            {
                headerName:Constant.COL_SHIPMENT_DOC_NO,
                field:"shipment_id",
                width:120,
            },
            {
                headerName:Constant.COL_SHIPMENT_DATE,
                field:"shipment_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
           
            {
                headerName:Constant.COL_SHIPMENT_MODE,
                field:"shipment_mode",
                width:80
            },
            {
                headerName:Constant.COL_TRUCK_NO,
                field:"truck_no",
                width:120,
            },
            {
                headerName:Constant.COL_TPT_NAME,
                field:"transporter_name",
                width:200,
            },
            
            {
                headerName:Constant.COL_DRIVER_CODE,
                field:"driver_code",
                width:180,
            },
            {
                headerName:Constant.COL_REMAINING_TOTAL_LRNO,
                field:"no_of_shipments",
                width:180,
                valueGetter:function(params){
                    try{
                        var a = (parseInt(params.data.no_of_shipments)-parseInt(params.data.no_of_lrs))+"/"+params.data.no_of_shipments;
                        return a;
                    }
                    catch(e){}
                }
            },
            
            // {
            //     headerName:Constant.COL_REMAINING_TOTAL_LRNO,
            //     field:"no_of_shipments",
            //     width:180,
                
            // },
            
            // {
            //     headerName:Constant.COL_REMAINING_TOTAL_LRNO,
            //     field:"no_of_lrs",
            //     width:180,
                
            // },
           
            
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
              
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>{Constant.PAGE_EPOD}</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
            </div>
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
