import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
// import Select from 'react-select';
import { 
    // getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	// getYYYYMMDD, 
	// getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	// getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	// getHyphenDDMMYYYY,
	// getDDMMMYYYYHHMMDefault 
    
	
} from '../common/utils'; 

import MapRoutes from './maproute';
import LegRouteMap from "./legroutemap";
import LoadingAreaLegs from "./loadingarealegs";

import DrawMap from '../common/drawmap';
import { CONDITION_PIPEMILL1, CONDITION_PLATEMILL1 } from '../common/Constant';

var moment = require('moment');
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class chettinadShipment extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
			rowModelType: "serverSide",
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                MapRoutes:MapRoutes,
                LoadingAreaLegs:LoadingAreaLegs,
                LegRouteMap:LegRouteMap
			},
            sliderRouteTranslate:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            
			detailCellRendererParams:{},
            totalcount:[],
            intransitmy:[],
            insidemytpa:[],
            loadingstarted:[],
            loadingcompleted:[],
            insidetpaafterloading:[],
            intransittocustomer:[],
            nearcustomer:[],
            reachedcustomer:[],
            leftcustomer:[],
            forceclose:[],
            insidetpa:[],
            dsc:[],
            crm:[],
            platemill:[],
            pipemill:[],
            mys:[],
            dsclc:[],
            crmlc:[],
            platemilllc:[],
            myslc:[],
            loadingarea:[],
            nonloadingarea:[],
            ma:[],
            mb:[],
            mc:[],
            md1:[],
            md2:[],
            md3:[],
            md4:[],
            me:[],
            nma:[],
            dsc1:[],
            dsc2:[],
            dsc3:[],
            dsc4:[],
            dsc5:[],
            dsc6:[],
            dsc7:[],
            dsc8:[],
            crm1:[],
            crm2:[],
            crm3:[],
            crm4:[],
            crm5:[],
            crm6:[],  
            crm7:[],            
            platemill1:[],
            platemill2:[],
            platemill3:[],
            platemill4:[],            
            pipemill1:[],
            pipemill2:[],
            pipemill3:[],
            pipemill4:[],
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
            emptyload:[],
            partialload:[],
            completeload:[]
        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
		
    }
    componentDidMount(){
        this.onLoadGetShipments();
        var parameters = {
            
        }
        redirectURL.post("/chettinad/loadingarealegsdata",parameters).then(async (response) =>{
            console.log("response ", response.data);
        })
    }
    onLoadGetShipments(){
        this.setState({
            loadshow:"show-m"
        })
        var parameters = {
        }
        redirectURL.post("/chettinad/shipmenttrucks",parameters)
        .then((response) => {
            if(response.data.remark == "SUCCESS")
            {
                var shipments = response.data.shipments;
                
                var totalcount=[];
                var insidetpa = [];
                var intransitmy = [];
                var insidemytpa=[];
                var loadingstarted=[];
                var loadingcompleted=[]
                var insidetpaafterloading=[];
                var intransittocustomer = [];
                var nearcustomer = [];
                var reachedcustomer = [];
                var leftcustomer = [];
                var forceclose = [];
                var dsc=[];
                var crm=[];
                var platemill = [];
                var mys = [];
                
                var dsclc=[];
                var crmlc=[];
                var platemilllc = [];
                var myslc = [];
                var ma = [];
                var mb = [];
                var mc = [];
                var md1 = [];
                var md2 = [];
                var md3 = [];
                var md4 = [];
                var me = [];
                var nma = [];
                var loadingarea=[];
                var nonloadingarea=[];
                var pipemill =[];
                
                var dsc1= [];
                var dsc2= [];
                var dsc3= [];
                var dsc4= [];
                var dsc5= [];
                var dsc6= [];
                var dsc7= [];
                var dsc8= [];
                var crm1= [];
                var crm2= [];
                var crm3= [];
                var crm4= [];    
                var crm5= [];    
                var crm6= [];    
                var crm7= [];            
                var platemill1= [];
                var platemill2= [];
                var platemill3= [];
                var platemill4= [];            
                var pipemill1= [];
                var pipemill2= [];
                var pipemill3= [];
                var pipemill4= [];
                var emptyload=[];
                var partialload=[];
                var completeload=[];
                // console.log("shipments ", shipments)
                if(shipments.length > 0){
                    shipments.map((item)=> {
                        
                        totalcount.push(item);
                        // console.log("item.status ",item.status)
                        if(item.status == 1 || item.status == "1")
                        {
                            insidetpa.push(item);
                        }
                        if(item.status == 2 || item.status == "2")
                        {
                            intransitmy.push(item);
                        }
                        
                        if( item.status == 4 || item.status == "4")
                        {
                            insidemytpa.push(item);
                            if(item.loading_area_name == Constant.CONDITION_MA && item.loading_area == 1)
                            {
                                mys.push(item)
                                ma.push(item);
                                // partialload.push(item);
                                loadingarea.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_MB && item.loading_area == 1)
                            {
                                mys.push(item)
                                mb.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_MC && item.loading_area == 1)
                            {
                                mys.push(item)
                                mc.push(item);
                                loadingarea.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_MD1 && item.loading_area == 1)
                            {
                                mys.push(item)
                                md1.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_MD2 && item.loading_area == 1)
                            {
                                mys.push(item)
                                md2.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_MD3 && item.loading_area == 1)
                            {
                                mys.push(item)
                                md3.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_MD4 && item.loading_area == 1)
                            {
                                mys.push(item)
                                md4.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_ME && item.loading_area == 1)
                            {
                                mys.push(item)
                                me.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_NMA && item.loading_area == 1)
                            {
                                mys.push(item)
                                nma.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM1 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm1.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM2 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm2.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM3 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm3.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM4 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm4.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM5 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm5.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_CRM6 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm6.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_CRM7 && item.loading_area == 1)
                            {
                                crm.push(item);
                                crm7.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC1 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc1.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC2 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc2.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC3 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc3.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC4 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc4.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC5 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc5.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_DSC6 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc6.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            if(item.loading_area_name == Constant.CONDITION_DSC7 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc7.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_DSC8 && item.loading_area == 1)
                            {
                                dsc.push(item);
                                dsc8.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            
                            
                            
                            if(item.loading_area_name == Constant.CONDITION_PLATEMILL1 && item.loading_area == 1)
                            {
                                platemill.push(item);
                                platemill1.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PLATEMILL2 && item.loading_area == 1)
                            {
                                platemill.push(item);
                                platemill2.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PLATEMILL3 && item.loading_area == 1)
                            {
                                platemill.push(item);
                                platemill3.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PLATEMILL4 && item.loading_area == 1)
                            {
                                platemill.push(item);
                                platemill4.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PIPEMILL1 && item.loading_area == 1)
                            {
                                pipemill.push(item);
                                pipemill1.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PIPEMILL2 && item.loading_area == 1)
                            {
                                pipemill.push(item);
                                pipemill2.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PIPEMILL3 && item.loading_area == 1)
                            {
                                pipemill.push(item);
                                pipemill3.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            if(item.loading_area_name == Constant.CONDITION_PIPEMILL4 && item.loading_area == 1)
                            {
                                pipemill.push(item);
                                pipemill4.push(item);
                                loadingarea.push(item);
                                // partialload.push(item);
                            }
                            // if(item.loading_area == 0)
                            // {
                            //     if(item.loading_area_name == Constant.CONDITION_FG)
                            //     {
                            //         completeload.push(item);
                            //     }
                            //     else{
                            //         emptyload.push(item)
                            //     }
                            //     nonloadingarea.push(item);
                            // }
                        }
                        if(item.status == 3 || item.status == "3" || item.status == 5 || item.status == "5")
                        {
                            nonloadingarea.push(item);
                            if(item.status == 3 || item.status == "3")
                            {
                                if(item.loading_area_name != Constant.CONDITION_FG)
                                {
                                    emptyload.push(item)
                                }                                
                            }
                            if(item.status == 5 || item.status == "5")
                            {
                                if(item.loading_area_name != Constant.CONDITION_FG)
                                {
                                    partialload.push(item);
                                }
                                else
                                {
                                    completeload.push(item);
                                }   
                            }
                        }
                        if(item.status == 7 || item.status == "7")
                        {
                            insidetpaafterloading.push(item);
                        }
                        
                        if(item.status == 8 || item.status == "8")
                        {
                            intransittocustomer.push(item);
                        }
                        if(item.status == 9 || item.status == "9")
                        {
                            nearcustomer.push(item);
                        }
                        if(item.status == 10 || item.status == "10")
                        {
                            reachedcustomer.push(item);
                        }
                        if(item.status == 11 || item.status == "11")
                        {
                            leftcustomer.push(item);
                        }
                        if(item.status == 6 || item.status == "6")
                        {
                            forceclose.push(item);
                        }
                    

                    })
                }
                this.setState({
                    rowData:totalcount,
                    loadshow:"show-n",
                    totalcount:totalcount,
                    insidemytpa:insidemytpa,
                    intransitmy:intransitmy,
                    loadingstarted:loadingstarted,
                    loadingcompleted:loadingcompleted,
                    insidetpaafterloading:insidetpaafterloading,
                    intransittocustomer:intransittocustomer,
                    nearcustomer:nearcustomer,
                    reachedcustomer:reachedcustomer,
                    leftcustomer:leftcustomer,
                    forceclose:forceclose,
                    insidetpa:insidetpa,
                    dsc:dsc,
                    crm:crm,
                    platemill:platemill,
                    mys:mys,
                    dsclc:dsclc,
                    crmlc:crmlc,
                    platemilllc:platemilllc,
                    myslc:myslc,
                    loadingarea:loadingarea,
                    nonloadingarea:nonloadingarea,
                    ma:ma,
                    mb:mb,
                    mc:mc,
                    md1:md1,
                    md2:md2,
                    md3:md3,
                    md4:md4,
                    me:me,
                    nma:nma,
                    pipemill:pipemill,
                    dsc1:dsc1,
                    dsc1:dsc2,
                    dsc3:dsc3,
                    dsc4:dsc4,
                    dsc5:dsc5,
                    dsc6:dsc6,
                    dsc7:dsc7,
                    dsc8:dsc8,
                    crm1:crm1,
                    crm2:crm2,
                    crm3:crm3,
                    crm4:crm4,
                    crm5:crm5,
                    crm6:crm6,  
                    crm7:crm7,            
                    platemill1:platemill1,
                    platemill2:platemill2,
                    platemill3:platemill3,
                    platemill4:platemill4,            
                    pipemill1:pipemill1,
                    pipemill2:pipemill2,
                    pipemill3:pipemill3,
                    pipemill4:pipemill4,
                    emptyload:emptyload,
                    partialload:partialload,
                    completeload:completeload
                    
                })
            }
            
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.parking_in_time != "" && e.parking_in_time != undefined)
        {
            var ptime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        
        var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
         
		
        var reqData = {
            truck_no:e.truck_no,
			consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"consignment"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.consignment_code,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                                // baseRouteCoords: baseRouteCoords
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}

    onShowEnrouteRouteMap = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.first_inside_fence != "" && e.first_inside_fence != undefined)
        {
            var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        if(e.first_outside_fence != "" && e.first_outside_fence != undefined)
        {
            var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");        
        }
        else{
            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
		
        var reqData = {
            truck_no:e.truck_no,
			// consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"truck"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                                // baseRouteCoords: baseRouteCoords
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}

    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
    onClickCounter(parm){
        this.setState({
            loadshow:"show-m"
        })
        if(parm == "0"){
            this.setState({
                rowData:this.state.totalcount,
                loadshow:"show-n"
            })
        }
        
        if(parm == "1"){
            this.setState({
                rowData:this.state.insidetpa,
                loadshow:"show-n"
            })
        } 
        if(parm == "2"){
            this.setState({
                rowData:this.state.intransitmy,
                loadshow:"show-n"
            })
        }
        if(parm == "3"){
            this.setState({
                rowData:this.state.insidemytpa,
                loadshow:"show-n"
            })
        }
        if(parm == "loadingarea"){
            this.setState({
                rowData:this.state.loadingarea,
                loadshow:"show-n"
            })
        }
        
        if(parm == "nonloadingarea"){
            this.setState({
                rowData:this.state.nonloadingarea,
                loadshow:"show-n"
            })
        }
        
        if(parm == "mys"){
            this.setState({
                rowData:this.state.mys,
                loadshow:"show-n",
                showmys:"show-m",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
            })
        }
        if(parm == "ma"){
            this.setState({
                rowData:this.state.ma,
                loadshow:"show-n"
            })
        }
        
        if(parm == "mb"){
            this.setState({
                rowData:this.state.mb,
                loadshow:"show-n"
            })
        }
        if(parm == "mc"){
            this.setState({
                rowData:this.state.mc,
                loadshow:"show-n"
            })
        }
        if(parm == "md1"){
            this.setState({
                rowData:this.state.md1,
                loadshow:"show-n"
            })
        }
        if(parm == "md2"){
            this.setState({
                rowData:this.state.md2,
                loadshow:"show-n"
            })
        }
        if(parm == "md3"){
            this.setState({
                rowData:this.state.md3,
                loadshow:"show-n"
            })
        }
        if(parm == "md4"){
            this.setState({
                rowData:this.state.md4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "me"){
            this.setState({
                rowData:this.state.me,
                loadshow:"show-n"
            })
        }
        
        if(parm == "nma"){
            this.setState({
                rowData:this.state.nma,
                loadshow:"show-n"
            })
        }

        if(parm == "dsc"){
            this.setState({
                rowData:this.state.dsc,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-m",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
            })
        }
        if(parm == "dsc1"){
            this.setState({
                rowData:this.state.dsc1,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc2"){
            this.setState({
                rowData:this.state.dsc2,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc3"){
            this.setState({
                rowData:this.state.dsc3,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc4"){
            this.setState({
                rowData:this.state.dsc4,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc5"){
            this.setState({
                rowData:this.state.dsc5,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc6"){
            this.setState({
                rowData:this.state.dsc6,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc7"){
            this.setState({
                rowData:this.state.dsc7,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc8"){
            this.setState({
                rowData:this.state.dsc8,
                loadshow:"show-n"
            })
        }
        if(parm == "crm"){
            this.setState({
                rowData:this.state.crm,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-m",
                showplatemill:"show-n",
                showpipemill:"show-n",
            })
        }
        if(parm == "crm1"){
            this.setState({
                rowData:this.state.crm1,
                loadshow:"show-n"
            })
        }
        if(parm == "crm2"){
            this.setState({
                rowData:this.state.crm2,
                loadshow:"show-n"
            })
        }
        if(parm == "crm3"){
            this.setState({
                rowData:this.state.crm3,
                loadshow:"show-n"
            })
        }
        if(parm == "crm4"){
            this.setState({
                rowData:this.state.crm4,
                loadshow:"show-n"
            })
        }
        if(parm == "crm5"){
            this.setState({
                rowData:this.state.crm5,
                loadshow:"show-n"
            })
        }
        if(parm == "crm6"){
            this.setState({
                rowData:this.state.crm6,
                loadshow:"show-n"
            })
        }
        if(parm == "crm7"){
            this.setState({
                rowData:this.state.crm7,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill"){
            this.setState({
                rowData:this.state.platemill,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
            })
        }
        
        if(parm == "platemill1"){
            this.setState({
                rowData:this.state.platemill1,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill2"){
            this.setState({
                rowData:this.state.platemill2,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill3"){
            this.setState({
                rowData:this.state.platemill3,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill4"){
            this.setState({
                rowData:this.state.platemill4,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill"){
            this.setState({
                rowData:this.state.pipemill,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-m",
            })
        }
        if(parm == "pipemill1"){
            this.setState({
                rowData:this.state.pipemill1,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill2"){
            this.setState({
                rowData:this.state.pipemill2,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill3"){
            this.setState({
                rowData:this.state.pipemill3,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill4"){
            this.setState({
                rowData:this.state.pipemill4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "7"){
            this.setState({
                rowData:this.state.insidetpaafterloading,
                loadshow:"show-n"
            })
        }

        if(parm == "8"){
            this.setState({
                rowData:this.state.intransittocustomer,
                loadshow:"show-n"
            })
        }
        if(parm == "emptyload"){
            this.setState({
                rowData:this.state.emptyload,
                loadshow:"show-n"
            })
        }
        if(parm == "partialload"){
            this.setState({
                rowData:this.state.partialload,
                loadshow:"show-n"
            })
        }
        if(parm == "completeload"){
            this.setState({
                rowData:this.state.completeload,
                loadshow:"show-n"
            })
        }
        
        
        // if(parm == "6"){
        //     this.setState({
        //         rowData:this.state.forceclose,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "7"){
        //     this.setState({
        //         rowData:this.state.nearcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "8"){
        //     this.setState({
        //         rowData:this.state.reachedcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "9"){
        //     this.setState({
        //         rowData:this.state.leftcustomer,
        //         loadshow:"show-n"
        //     })
        // }
    }
    onClickShowLoadingAreaLegs= async (rownode) =>  {
        // console.log("rownode", rownode)
        var commentHeaders = [
			{
				headerName : "",
				field : "",
				resizable : true,
				width:60,
				filter : true,
                cellRendererFramework:LegRouteMap,
			  },
            {
				headerName : Constant.COL_TRUCK_NO,
				field : "truck_no",
				resizable : true,
				width:120,
				filter : true
			  },
			//   {
			// 	headerName : Constant.COL_CONSIGNMENT_CODE,
			// 	field : "consignment_code",
			// 	resizable : true,
			// 	width:140,
			// 	filter : true
			//   },
			  {
				headerName : Constant.COL_LOADING_AREA_NAME,
				field : "fence_name",
				resizable : true,
				width:200,
				filter : true
			  },
			  {
				headerName : Constant.COL_LEGT_START_TIME,
				field : "first_inside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_LEGT_END_TIME,
				field : "first_outside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_TIME_SPEND,
				field : "time_spent",
				resizable : true,
				width:200,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined && params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
                        {
                            return params.data.time_spent;
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    if(param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined){
                        var startdate = param.data.tpa_entry_time;
                    }
                    else{
                        var startdate = param.data.parking_in_time;
                    }
                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
                    var parameters = {
                        consignment_code : param.data.consignment_code,
                        truck_no : param.data.truck_no,
                        startdate:startdate,
                        enddate:enddate
                    }
                    await redirectURL.post("/chettinad/loadingarealegsdata",parameters).then(async (response) =>{
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments=response.data;
                        var output=[]
                        if(comments.length > 0){
                            comments.map((item) => {
                                try{
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        var ste = item.first_inside_fence;
                                        var stsec = moment.parseZone(ste).format("x");
                                        item.orderpos = stsec;
                                    }
                                    if(item.first_outside_fence != "" && item.first_outside_fence != undefined){
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else{
                                        var etsec = moment.parseZone().format("x");
                                    }
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        // console.log("etsec ", etsec)
                                        // console.log("stsec ", stsec)
                                        var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                        var timespent = secondsToDhms(diff);
                                    }
                                    else{
                                        var timespent = "";
                                    }
                                    item.time_spent = timespent;
                                        
                                }
                                catch(e){

                                }
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);
                        
                        
                        //console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'loadingarealegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClicCloseCounterBlock(){
        this.setState({
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
        })
    }
    render(){
        var maptransporter='';
		
        var columnwithDefs = [
            
            {
                headerName: "",
                field: "_id",
                width: 50,
                pinned: 'left',
                cellRenderer:'MapRoutes',
                filter: false,resizable: true
                
            },
            {
                headerName: "",
                field: "loadingarealegs",
                width: 50,
                pinned: 'left',
                cellRenderer:'LoadingAreaLegs',
                filter: false,resizable: true
                
            },
            {
                headerName:Constant.COL_TRUCK_NO,
                field:"truck_no",
                width:120,
                pinned:"left"
            },
            {
                headerName:Constant.COL_STATUS,
                field:"status",
                width:150,
                pinned:'left',
                valueGetter:function(params){
                    try{
                        var status = Constant.INSIDE_TPA;
                        if(params.data.status == 1 || params.data.status == "1")
                        {
                            status = Constant.INSIDE_TPA;
                        }
                        if(params.data.status == 2 || params.data.status == "2")
                        {
                            status = Constant.INTRANSIT_TO_MY;
                        }
                        if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
                        {
                            status = Constant.INSIDE_MY_PMILL;
                        }
                        // if(params.data.status == 4 || params.data.status == "4")
                        // {
                        //     status = Constant.LOADING_STARTED;
                        // }
                        // if(params.data.status == 5 || params.data.status == "5")
                        // {
                        //     status = Constant.LOADING_COMPLETED;
                        // }
                        
                        if(params.data.status == 7 || params.data.status == "7")
                        {
                            status = Constant.INSIDE_TPA_AFTER_LOADING;
                        }
                        if(params.data.status == 8 || params.data.status == "8")
                        {
                            status = Constant.INTRANSIT;
                        }
                        // if(params.data.status == 6 || params.data.status == "6")
                        // {
                        //     status = Constant.FORCE_CLOSE;
                        // }
                        // if(params.data.status == 7 || params.data.status == "7")
                        // {
                        //     // if(params.data.shipment_mode == Constant.SHIPMENT_MODE_ROAD)
                        //     // {
                        //     //     status = Constant.INTRANSIT;
                        //     // }
                        //     // else{
                        //     //     status = Constant.INTRANSIT_TO_LOADING_YARD;
                        //     // }
                        //     status = Constant.NEAR_CUSTOMER;
                        // }
                        
                        // if(params.data.status == 8 || params.data.status == "8")
                        // {
                        //     status = Constant.REACHED_CUSTOMER;
                        // }
                        
                        // if(params.data.status == 9 || params.data.status == "9")
                        // {
                        //     status = Constant.LEFT_CUSTOMER;
                        // }
                        return status;
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constant.COL_GROUP_HEADER_SHIPMENT_DATA,
                field:"transporter_code",
				headerClass:["cellTopHeader"],
                children:[
                    {
                        headerName:Constant.COL_CONSIGNMENT_CODE,
                        field:"consignment_code",
                        width:120
                    },
                    {
                        headerName:Constant.COL_SHIPMENT_DOC_NO,
                        field:"shipment_id",
                        width:120,
                    },
                    {
                        headerName:Constant.COL_SHIPMENT_DATE,
                        field:"shipment_time",
                        width:140,
                        valueGetter:function(params){
                            try{
                                if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_PARKING_ENTRY_NO,
                        field:"parking_id",
                        width:120
                    },
                    
                    {
                        headerName:Constant.COL_PARKING_DATE,
                        field:"parking_in_time",
                        width:140,
                        valueGetter:function(params){
                            try{
                                if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    
                    
                    
                    // {
                    //     headerName:Constant.COL_TRUCK_TYPE,
                    //     field:"vehicle_mode",
                    //     width:80
                    // },
                    {
                        headerName:Constant.COL_SHIPMENT_MODE,
                        field:"shipment_mode",
                        width:80
                    },
                    {
                        headerName:Constant.COL_TPT_NAME,
                        field:"transporter_name",
                        width:200,
                    },
                    
                    {
                        headerName:Constant.COL_DRIVER_CODE,
                        field:"driver_code",
                        width:180,
                    },
                    
                    // {
                    //     headerName:Constant.COL_RAILYARD_SOURCE,
                    //     field:"rake_loading_point_name",
                    //     width:200,
                    // },
                    // {
                    //     headerName:Constant.COL_RAILYARD_DESTINATION,
                    //     field:"rake_delivery_point_name",
                    //     width:200,
                    // },
                    {
                        headerName:Constant.COL_DELIVERY_NO,
                        field:"delivery_no",
                        width:120,
                    },
                    {
                        headerName:Constant.COL_SALE_ORDER,
                        field:"sales_order",
                        width:120,
                    },
                    
                    {
                        headerName:Constant.COL_ITEM_NO,
                        field:"item_no",
                        width:120,
                    },
                    {
                        headerName:Constant.COL_ITEM_DESC,
                        field:"item_desc",
                        width:200,
                    },
                    {
                        headerName:Constant.COL_ITEM_QTY,
                        field:"item_quantity",
                        width:120,
                    },
                    
                    {
                        headerName:Constant.COL_CUSTOMER_NAME,
                        field:"consignee_name",
                        width:200,
                    },
                    {
                        headerName:Constant.COL_CUSTOMER_CODE,
                        field:"consignee_code",
                        width:120,
                    },
                    {
                        headerName:Constant.COL_CUSTOMER_CITY,
                        field:"consignee_city",
                        width:140,
                    },
                    // {
                    //     headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                    //     field:"expected_distance_in_km",
                    //     width:140,
                    // },
                ]
            },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_TPA_TO_MY,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridB","cellTopHeader"],
            //     children:[
            //         // {
            //         //     headerName:Constant.COL_MYLOADING_YARD_TYPE,
            //         //     field:"loading_yard_type",
            //         //     width:140,
            //         //     headerClass:"cellstylegridB",
			// 		// 	cellClass:'cellstylegridB',
            //         //     valueGetter:function(params){
            //         //         try{
            //         //             // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //         //             // {
            //         //             //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //         //             // }
            //         //             // else{
            //         //             //     return "";
            //         //             // }
            //         //         }
            //         //         catch(e){}
            //         //     }

            //         // },
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"loading_yard_entry_time",
            //             width:140,
            //             headerClass:"cellstylegridB",
			// 			cellClass:'cellstylegridB',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_yard_entry_time != "" && params.data.loading_yard_entry_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_entry_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"loading_yard_exit_time",
            //             width:140,
            //             headerClass:"cellstylegridB",
			// 			cellClass:'cellstylegridB',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_yard_exit_time != "" && params.data.loading_yard_exit_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"loading_yard_duration",
            //             width:120,
            //             headerClass:"cellstylegridB",
			// 			cellClass:'cellstylegridB',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_yard_duration != "" && params.data.loading_yard_duration != undefined)
            //                     {
            //                         return secondsToDhms(params.data.loading_yard_duration);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         }
            //     ]
            // },

            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_LOADING_AREA,
            //     field:"",
			// 	headerClass:["cellstylegridG","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_LOADING_AREA_NAME,
            //             field:"loading_area_name",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_LOADING_AREA_ENTRY_TIME,
            //             field:"loading_area_entry_time",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_area_entry_time != "" && params.data.loading_area_entry_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.loading_area_entry_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_LOADING_AREA_EXIT_TIME,
            //             field:"loading_area_exit_time",
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_area_exit_time != "" && params.data.loading_area_exit_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.loading_area_exit_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_LOADING_AREA_DURATION,
            //             field:"loading_area_duration",
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.loading_area_duration != "" && params.data.loading_area_duration != undefined)
            //                     {
            //                         return secondsToDhms(params.data.loading_area_duration);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         }
            //     ]
            // },

            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_TPA,
            //     field:"",
			// 	headerClass:["cellstylegridM","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_TPA_ENTRY_AFTER_LOADING,
            //             field:"tpa_entry_time_after_loading",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.tpa_entry_time_after_loading != "" && params.data.tpa_entry_time_after_loading != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.tpa_entry_time_after_loading);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_TPA_EXIT_AFTER_LOADING,
            //             field:"tpa_exit_time_after_loading",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.tpa_exit_time_after_loading != "" && params.data.tpa_exit_time_after_loading != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.tpa_exit_time_after_loading);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TPA_DURATION_AFTER_LOADING,
            //             field:"tpa_duration_after_loading",
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.tpa_duration_after_loading != "" && params.data.tpa_duration_after_loading != undefined)
            //                     {
            //                         return secondsToDhms(params.data.tpa_duration_after_loading);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
                   
            //     ]
            // },
            
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_UNAUTHORIZED_STOPPAGES,
            //     field:"",
			// 	headerClass:["cellstylegridY","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_WORKSHOP_NAME,
            //             field:"workshop_name",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_ENTRY_TIME,
            //             field:"workshop_entry_time",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_entry_time != "" && params.data.workshop_entry_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.workshop_entry_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_EXIT_TIME,
            //             field:"workshop_exit_time",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_exit_time != "" && params.data.workshop_exit_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.workshop_exit_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_DURATION,
            //             field:"workshop_duration",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_duration != "" && params.data.workshop_duration != undefined)
            //                     {
            //                         return secondsToDhms(params.data.workshop_duration);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
                   
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_DSC,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridG","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_CRM,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridM","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_PLATE_MILL,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridY","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_TPA_TO_PIPE_MILL,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegrid","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             width:120
            //         }
            //     ]
            // },
            // {
            //     headerName:Constant.COL_TPT_CODE,
            //     field:"transporter_code",
            //     width:120,
            // },
            
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12 col-sm-12">
                        <ul className="ul-tabs">
                            <li className="">
                                <a className="active" href={"/shipment"}>Road Shipments</a>
                            </li>
                            <li>
                                <a href={"/railshipments"}>Rail Shipments</a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this,"0")}>
                                            <span className="f13"><i className="icofont icofont-cubes f22 txt-primary"></i><br /> {Constant.COUNTER_TOTAL_CONSIGNMENTS}</span>
                                            <h4 className="txt-primary f45"><span className="counter"><CountUp end={this.state.totalcount.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this,"1")}>
                                            <span className="f13"><i className="icofont icofont-picture f22 txt-success"></i><br /> {Constant.COUNTER_INSIDE_TPA}</span>
                                            <h4 className="txt-success f45"><span className="counter"><CountUp end={this.state.insidetpa.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this,"2")}>
                                            <span className="f13"><i className="icofont icofont-truck-alt f22 txt-secondary"></i><br /> {Constant.COUNTER_INTRANSIT_TO_MY}</span>
                                            <h4 className="txt-secondary f45"><span className="counter"><CountUp end={this.state.intransitmy.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" >
                                            <span className="f13" onClick={this.onClickCounter.bind(this,"3")}><i className="icofont icofont-map-pins f22 txt-danger"></i><br /> {Constant.COUNTER_INSIDE_MY_PM}</span>
                                            <h4 className="txt-danger f45" onClick={this.onClickCounter.bind(this,"3")}><span className="counter"><CountUp end={this.state.insidemytpa.length}/></span></h4>
                                            <div className="row">
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loadingarea")}>
                                                    <span className="f10">{Constant.COUNTER_LOADING_AREA}</span>
                                                    <h4 className="txt-danger f35"><span className="counter"><CountUp end={this.state.loadingarea.length}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nonloadingarea")}>
                                                    <span className="f10">{Constant.COUNTER_NONLOADING_AREA}</span>
                                                    <h4 className="txt-danger f35"><span className="counter"><CountUp end={this.state.nonloadingarea.length}/></span></h4>
                                                </div>
                                            </div>
                                            

                                        </div>
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"4")}>
                                            <span className="f13" ><i className="fa fa-tachometer f22 txt-warning"></i><br /> {Constant.COUNTER_LOADING_STARTED}</span>
                                            <h4 className="txt-warning f40"  ><span className="counter"><CountUp end={this.state.loadingstarted.length}/></span></h4>
                                        </div> */}
                                        
                                        <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this,"7")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-info"></i><br /> {Constant.COUNTER_INSIDE_TPA_AFTER_LOADING}</span>
                                            <h4 className="txt-info f45"><span className="counter"><CountUp end={this.state.insidetpaafterloading.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this,"8")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> {Constant.COUNTER_INSTRANSIT_TO_CUSTOMER}</span>
                                            <h4 className="txt-success f45"><span className="counter"><CountUp end={this.state.intransittocustomer.length}/></span></h4>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8 col-lg-8 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="fbold">Loading Area</h5>
                            </div>
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"mys")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_MYS}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.mys.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_DSC}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.dsc.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_CRM}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.crm.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"platemill")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-primary"></i><br /> {Constant.COUNTER_PLATEMILL}</span>
                                            <h6 className="txt-primary f32"><span className="counter"><CountUp end={this.state.platemill.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pipemill")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_PIPEMILL}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.pipemill.length}/></span></h6>
                                        </div>
                                       {/* empty
                                       partiall
                                       complete loaded (FG) */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="fbold">Non Loading Area</h5>
                            </div>
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"emptyload")}>
                                            <span className="f13"><i className="icofont icofont-truck f20 txt-success"></i><br /> {Constant.COUNTER_EMPTYLOADED}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.emptyload.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"partialload")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_PARTIALLOADED}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.partialload.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"completeload")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_COMPLETELOADED}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.completeload.length}/></span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+(this.state.showmys)}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <a href="javascript:;" className="float-right clx" onClick={this.onClicCloseCounterBlock.bind(this)}>Close</a>
                                        
                                    <div className="row">
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"ma")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_MA}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.ma.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"mb")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_MB}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.mb.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"mc")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_MC}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.mc.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"md1")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_MD1}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.md1.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"md2")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-primary"></i><br /> {Constant.COUNTER_MD2}</span>
                                            <h6 className="txt-primary f32"><span className="counter"><CountUp end={this.state.md2.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"md3")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-secondary"></i><br /> {Constant.COUNTER_MD3}</span>
                                            <h6 className="txt-secondary f32"><span className="counter"><CountUp end={this.state.md3.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"md4")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_MD4}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.md4.length}/></span></h6>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"me")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_ME}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.me.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nma")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.CONDITION_NMA}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.nma.length}/></span></h6>
                                        </div>
                                     
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+(this.state.showdsc)}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <a href="javascript:;" className="float-right clx" onClick={this.onClicCloseCounterBlock.bind(this)}>Close</a>
                                    <div className="row">
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc1")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_DSC1}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.dsc1.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc2")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_DSC2}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.dsc2.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc3")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_DSC3}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.dsc3.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc4")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_DSC4}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.dsc4.length}/></span></h6>
                                        </div>  
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc5")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_DSC5}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.dsc5.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc6")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_DSC6}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.dsc6.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc7")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_DSC7}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.dsc7.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"dsc8")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_DSC8}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.dsc8.length}/></span></h6>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+(this.state.showcrm)}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <a href="javascript:;" className="float-right clx" onClick={this.onClicCloseCounterBlock.bind(this)}>Close</a>
                                    <div className="row">
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm1")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_CRM1}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.crm1.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm2")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_CRM2}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.crm2.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm3")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_CRM3}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.crm3.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm4")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_CRM4}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.crm4.length}/></span></h6>
                                        </div>  
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm5")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_CRM5}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.crm5.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm6")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_CRM6}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.crm6.length}/></span></h6>
                                        </div>     
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"crm7")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_CRM7}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.crm7.length}/></span></h6>
                                        </div>                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+(this.state.showplatemill)}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <a href="javascript:;" className="float-right clx" onClick={this.onClicCloseCounterBlock.bind(this)}>Close</a>
                                    <div className="row">
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"platemill1")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_PLATEMILL1}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.platemill1.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"platemill2")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_PLATEMILL2}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.platemill2.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"platemill3")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_PLATEMILL3}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.platemill3.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"platemill4")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_PLATEMILL4}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.platemill4.length}/></span></h6>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+(this.state.showpipemill)}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <a href="javascript:;" className="float-right clx" onClick={this.onClicCloseCounterBlock.bind(this)}>Close</a>
                                    <div className="row">
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pipemill1")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-success"></i><br /> {Constant.COUNTER_PIPEMILL1}</span>
                                            <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.pipemill1.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pipemill2")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-danger"></i><br /> {Constant.COUNTER_PIPEMILL2}</span>
                                            <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.pipemill2.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pipemill3")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-warning"></i><br /> {Constant.COUNTER_PIPEMILL3}</span>
                                            <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.pipemill3.length}/></span></h6>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pipemill4")}>
                                            <span className="f13"><i className="icofont icofont-truck-loaded f20 txt-info"></i><br /> {Constant.COUNTER_PIPEMILL4}</span>
                                            <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.pipemill4.length}/></span></h6>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Shipments</span>
                                    <a href={"/rail-consignments"} className="btn btn-warning pull-right ml-10p" >Rail Shipments</a>
                                    <a href={"/sndconsignments"} className="btn btn-info pull-right">Road Shipments</a>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
            </div>
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
