/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ChettinadShipmentTrucks from './shipmenttrucksComponent';
import ChettinadReturntoPlant from './returntoplantComponent';

$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');


var googleAnalytics = require("../common/googleanalytics");


export default class Allshipmenttrucks extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
            isRoleExists: 0,
            adaniCheck: 0,
            test: false,
            information_tab_data: {},
            information_tab_data_kia: {},
            defaulttab: "all",
            tpttrucks: [],
            ontrips: [],
            exceptionTrucksNos: [],
            onTripsTrucksData: []
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + "/trucksgpsintegrationdata";
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + "/trucksgpsintegrationdata";
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                // console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }


    toggle(tab) {
        console.log(this.state.active_tab ,"tab", tab, )
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render() {
        return (

            <div className="container-fluid">

                <div className="row">
                    <div className="col-xl-12 col-lg-12">

                        <Nav tabs className={'tabs-color'} style = {{marginLeft: "14px"}} >

                            <NavItem  >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                        Inter Plant Movement
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                    onClick={() => { this.toggle('2'); }} >
                                    Return To Plant
                                </NavLink>
                            </NavItem>
                        </Nav>
    

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <ChettinadShipmentTrucks />
                                    </Col>
                                </Row>
                            </TabPane>
                           
                            {(this.state.active_tab == 2)?
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <Suspense fallback={<div>Loading...</div>}><ChettinadReturntoPlant pageLoad={this.state.active_tab} /></Suspense>
                                    </Col>
                                </Row>
                            </TabPane>
                            :""}
                        </TabContent>
                    </div>
                </div>


            </div>

        );
    }
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}