import React from "react";

import Highcharts from "highcharts";

import drilldown from "highcharts/modules/drilldown.js";

import HighchartsReact from "highcharts-react-official";



drilldown(Highcharts);



class BarChart extends React.Component {

  constructor(props) {

    super(props);

    this.allowChartUpdate = true;

    this.state = { seriesData: [], chart_hit_count: ""  , drilldown_header : "count"};

  }



  componentDidMount() {

    const chart = this.chartComponent.chart;

    this.setState({

      seriesData: this.props.seriesData,

      chart_hit_count: this.props.chart_hit_count,
      

    });

    console.log(this.props.seriesData, this.props.chart_hit_count, "seriesData--did")

  }



  componentWillReceiveProps(newProps) {

    const chart = this.chartComponent.chart;

    this.setState({

      seriesData: newProps.seriesData,
      drilldown_header : this.props.drilldown_header,
      chart_hit_count: this.props.chart_hit_count,

    });

    console.log(newProps.seriesData, newProps.chart_hit_count, "seriesData--will")

  }



  render() {

  var {drilldown_header} = this.state
    return (

      <HighchartsReact

        allowChartUpdate={this.allowChartUpdate}

        ref={(chartComponent) => (this.chartComponent = chartComponent)}

        highcharts={Highcharts}

        options={{

          chart: {

            type: "column",

          },

          series: this.state.seriesData,

          drilldown: {

            series: this.props.drilldowndata,

          },

          xAxis: {

            type: "category",

          },

          yAxis: {

            title: {

              text: "Trip Count",

            },

          },

          title: {

            text: this.props.chartTitle,

          },

          legend: {

            enabled: true,
            itemStyle: {
              fontSize: '14px', // Font size of legend items
          },           
          symbolBackgroundColor: '#034732', // Text color of legend items

          symbolRadius:1
          

          },

          credits: {

            enabled: false,

          },

          plotOptions: {

            pointWidth: "40px",

            column: {

              pointPadding: 0.2,

              borderWidth: 0

            },

            colorByPoint: true,

            series: {

              borderWidth: 0,

              dataLabels: {

                enabled: true,

                format: '{point.y}'

              },

              cursor: 'pointer',

              point: {



              }

            },

          },

          tooltip: {
            // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} {(point.unloadtext)}</b></b><br><span style="color:{point.color}">No of trips</span>: <b>{point.trips}</b>'
            formatter: function () {
              // console.log(this.series)
              if (this.point.showTooltip == 1) {
                return "<span style='font-size:11px'>" + this.series.name + "</span><br>\
                <span style='color:{point.color}'>No of trips</span>: <b>"+ this.point.y + "</b>"

              } else {
                return "<span style='font-size:11px'>" + this.series.name + "</span><br>\
                <span style='color:{point.color}'>"+drilldown_header+"</span>: <b>"+ this.point.y + "</b>"

              }


            }
          },

        }}

      />

    );

  }

}



export default BarChart;