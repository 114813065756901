
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const SendemailtoCustomerbtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClicksendemailtocustomer1(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");  
    return (

        <div>
            <button style={{padding:'7px',width:'75px'}} onClick={handleRouteClick} className="btn btn-info custom-btn f12 label label-success" 
            title="" >Send</button>
        </div>
    );
};

export default SendemailtoCustomerbtn;

