import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import XLSX from "xlsx/dist/xlsx.full.min.js";

import _, { escape } from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import CountUp from 'react-countup';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

var redirectURL = require('../redirectURL');
var moment = require('moment');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class ThirdPartyDevicesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow: 'show-n',
            showDiv: 'show-n',
            overly: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            modules: AllModules,
            sidebarSubHeader: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true

            },
            rowData: [],
            uploadedData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {

            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {

            },
            transporterslist: [],
            container_no: '',
            updatesearatebtn: 'show-n',
            searates: '',
            pipavaContainers: [],
            mundraContainers: [],
            containerslist: [],
            errorMessage: '',
            successMessage: '',
            transporter_code: '',
            alltransporters: [],
            components: { datePicker: getDatePicker() },
            totalpod: [],
            pendingpod: [],
            completedpod: [],
            sliderAddTransporter: "",
            rowDataforHistory: [],
            atplant: [],
            intransit: [],
            atdestination: [],
            unloadingForPickup: [],
            noprocess: [],
            returnInTransit: [],
            misplace: [],
            notAvailable: [],
            nearCustomer: [],
            check: 0,
            sliderForpivottable: "",
            manual_status: "",
            plantList: [],
            totalVehicles: [],
            gpsIntegratedVehicles: [],
            gpsNotIntegratedVehicles: [],
            gpsWorkingVehicles: [],
            gpsNotWorkingVehicles: [],
        }

        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
    }
    componentDidMount = async () => {
        var from_date = moment.parseZone().subtract(3, 'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
        $("#ostoDate").val(to_date);
        var parameters = {
            startdate: from_date,
            enddate: to_date
        }
        this.onLoadGetShipments(parameters);
        loadDateTimeScript();
        if (localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("CB_HEAD") >= 0) {
            redirectURL.post("/consignees/plantsData").then(async (response) => {
                // console.log(response.data,"177")
                let selectedPlant = response.data.filter(e => e.value == localStorage.getItem("pc"))
                var resp = [{ "value": "", "label": "ALL" }];
                var plantList = [...resp, ...response.data]
                this.setState({
                    plantList: plantList,
                })
            })
        }
    }
    onLoadGetShipments(parameters) {
        this.setState({
            loadshow: "show-m"
        })
        redirectURL.post("/chettinad/getThirdPartyDevices").then(async (response) => {
            console.log(response.data, "12121")
            var devicesInfo = response.data || []
            // devicesInfo = devicesInfo.filter(e => e.actual_lspuser == "WHEELSEYE")  // optimise code and write it in node             
            let gpsIntegratedVehicles = devicesInfo.filter(item => item.gps_integration_status && !item.is_from_vehiclelogs && item.gps_integration_status == "integrated")
            let gpsNotIntegratedVehicles = devicesInfo.filter(item => item.is_from_vehiclelogs || item.gps_integration_status == "not_integrated" || !item.gps_integration_status)
            let gpsWorkingVehicles = devicesInfo.filter(item => item.gps_working_status && !item.is_from_vehiclelogs && item.gps_working_status == "working")
            let gpsNotWorkingVehicles = devicesInfo.filter(item => item.gps_working_status == "not_working" || item.is_from_vehiclelogs)
            this.setState({
                rowData: devicesInfo,
                totalVehicles: devicesInfo,
                gpsIntegratedVehicles,
                gpsNotIntegratedVehicles,
                gpsWorkingVehicles,
                gpsNotWorkingVehicles,
                loadshow: "show-n",
            })
        })
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);


    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: ''
        });
    }
    onClickHide() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderAddTransporter: '',
            sliderEditTransporter: ""
        });

    }

    closeAlert = async () => {
        this.setState({
            show: false
        });
        if (this.state.basicTitle === "GPS Records Updated Successfully" || (this.state.basicTitle === "Updated successfully!")) {
            await window.location.reload();
        }
    }


    onCellUpdateData = (params) => {

    }
    onCellValueChanged = (params) => {
        var newValue = params.newValue
        this.setState({ manual_status: newValue })
    }

    onClickBulkUpload() {
        this.setState({
            sliderBulkUpload: "slider-translate",
            overly: 'show-m',
        })
    }

    bulkUploadSubmit = (event) => {
        event.preventDefault();

        const reqData = {
            uploaded_data: this.state.uploadedData,
            uploaded_by: localStorage.getItem('email')
        };

        redirectURL.post('/consignments/bulkuploadgpsdevices', reqData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const records = response.data;
                // console.log("Response data:", records);

                this.setState({
                    loadshow: 'show-m',
                    show: true,
                    basicTitle: records.message || 'Updated successfully!',
                    basicType: "success",
                    loadshow: 'show-n',
                    sliderBulkUpload: "",
                    uploadFile: "",
                    overly: 'show-n',
                });
            })
            .catch((error) => {
                console.error("Error:", error);
                this.setState({
                    show: true,
                    basicTitle: 'An error occurred while updating.',
                    basicType: "danger",
                    loadshow: 'show-n'
                });
            });
    }

    onCloseRouteDiv = () => {
        this.setState({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            sliderBulkUpload: "",
            uploadFile: '',
            file: '',
            overly: 'show-n'
        });
    }

    changeFileHandler1 = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Validate file type (accept only .xlsx and .xls)
        if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
            event.target.value = null; // Reset the input field
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload a file having extensions .xlsx or .xls only.',
            });
            return;
        }

        // Read the file contents using FileReader
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result); // Read file as binary array
            const workbook = XLSX.read(data, { type: 'array' }); // Parse the Excel file

            // Assuming the first sheet is used
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the sheet to JSON format
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Array of arrays
            if (jsonData.length === 0) {
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'The uploaded Excel file is empty.',
                });
                return;
            }

            // Extract header row and data rows
            const [header, ...dataRows] = jsonData;

            // Convert rows to objects based on headers
            const structuredData = dataRows.map((row) => {
                return header.reduce((obj, key, index) => {
                    obj[key] = row[index] || null; // Map header keys to row values
                    return obj;
                }, {});
            });

            // Update state with the structured data
            this.setState({
                uploadedData: structuredData,
            });

            // Optionally log the structured data
            console.log('Parsed Excel Data:', structuredData);
        };

        reader.onerror = (error) => {
            console.error('Error reading the file:', error);
        };

        reader.readAsArrayBuffer(file); // Read the file as an array buffer for binary parsing
    };
    onClickCounterShowData(params) {
        if (params == "totalVehicles") {
            this.setState({
                rowData: this.state.totalVehicles
            });
        }
        if (params == "gpsIntegratedVehicles") {
            this.setState({
                rowData: this.state.gpsIntegratedVehicles
            });
        }
        if (params == "gpsNotIntegratedVehicles") {
            this.setState({
                rowData: this.state.gpsNotIntegratedVehicles
            });
        }
        if (params == "gpsWorkingVehicles") {
            this.setState({
                rowData: this.state.gpsWorkingVehicles
            });
        }
        if (params == "gpsNotWorkingVehicles") {
            this.setState({
                rowData: this.state.gpsNotWorkingVehicles
            });
        }
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 130,
            },
            {
                headerName: "Latitude",
                field: "latitude",
                width: 130,
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
            },
            {
                headerName: "Time Stamp",
                field: "latest_gps_packet",
                width: 130,
                valueGetter: function (params) {
                    try {
                        if (params.data.latest_gps_packet != "No Data Available" && params.data.latest_gps_packet != undefined && params.data.latest_gps_packet != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.latest_gps_packet);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {

                    }
                }
            },
            // {
            //     headerName: "Created Date",
            //     field: "created_date",
            //     width: 130,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.created_date != "No Data Available" && params.data.created_date != undefined && params.data.created_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.created_date);
            //             }
            //             else {
            //                 return "Not Available";
            //             }
            //         }
            //         catch (e) {

            //         }
            //     }
            // },
            // {
            //     headerName: "Last Packet Time",
            //     field: "timestamp",
            //     width: 130,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
            //             }
            //             else {
            //                 return "Not Available";
            //             }
            //         }
            //         catch (e) {

            //         }
            //     }
            // },
            {
                headerName: 'GPS Integration Status',
                field: "gps_integration_status",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (!["undefined", undefined, null, ""].includes(params.data.gps_integration_status)) {
                            if (params.data.is_from_vehiclelogs) {
                                return "Not Integrated"  // Refer Bug ID 5920
                            }
                            else if (params.data.gps_integration_status == "integrated") {
                                return "Integrated"
                            }
                            else if (params.data.gps_integration_status == "not_integrated") {
                                return "Not Integrated"
                            }
                            else {
                                return ""
                            }
                        }
                        else {
                            return ""
                        }
                    } catch (e) {
                        console.log("Error at gps_integration_status", e)
                    }
                }
            },
            {
                headerName: 'GPS Working Status',
                field: "gps_working_status",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (!["undefined", undefined, null, ""].includes(params.data.gps_working_status)) {
                            if (params.data.is_from_vehiclelogs) {
                                return "Not Working"  // Refer Bug ID 5920
                            }
                            else if (params.data.gps_working_status == "working") {
                                return "Working"
                            }
                            else if (params.data.gps_working_status == "not_working") {
                                return "Not Working"
                            }
                            else {
                                return ""
                            }
                        }
                        else {
                            return ""
                        }
                    } catch (e) {
                        console.log("Error At gps_working_status", e)
                    }
                }
            },
            {
                headerName: 'GPS Service Provider',
                field: "gps_service_provider",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (!["undefined", undefined, null, ""].includes(params.data.gps_service_provider)) {
                            return params.data.gps_service_provider
                                .split('_')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ');
                        }
                        else {
                            return ""
                        }
                    } catch (e) {
                        console.log("Error at gps_service_provider", e)
                    }
                }
            },
            {
                headerName: 'Comments',
                field: "updated_comment",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (!["undefined", undefined, null, ""].includes(params.data.updated_comment)) {
                            return params.data.updated_comment
                        }
                        else {
                            return ""
                        }
                    } catch (e) {
                        console.log("Error at updated_comment", e)
                    }
                }
            },
            // {
            //     headerName: "Area",
            //     field: "area",
            //     width: 130,
            // },
            // {
            //     headerName: "City",
            //     field: "city",
            //     width: 130,
            // },
            // {
            //     headerName: "State",
            //     field: "state",
            //     width: 130,
            // },
            // {
            //     headerName: "Address",
            //     field: "last_known_address",
            //     width: 180,
            // },
            // {
            //     headerName: "Last Address Updated On",
            //     field: "last_address_updated",
            //     width: 180,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.last_address_updated != "No Data Available" && params.data.last_address_updated != undefined && params.data.last_address_updated != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.last_address_updated);
            //             }
            //             else {
            //                 return "Not Available";
            //             }
            //         }
            //         catch (e) {

            //         }
            //     }
            // },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 150,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 150,
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card" style={{ width: '100%' }}>
                            <div className="card-body">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "totalVehicles")}>
                                            <span className="f14">Total Vehicles</span>
                                            <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.totalVehicles.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "gpsIntegratedVehicles")}>
                                            <span className="f14">GPS Integrated Vehicles</span>
                                            <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.gpsIntegratedVehicles.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "gpsNotIntegratedVehicles")}>
                                            <span className="f14">GPS Not Integrated Vehicles </span>
                                            <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.gpsNotIntegratedVehicles.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "gpsWorkingVehicles")}>
                                            <span className="f14">GPS Working Vehicles</span>
                                            <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.gpsWorkingVehicles.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "gpsNotWorkingVehicles")}>
                                            <span className="f14"> GPS Not Working Vehicles </span>
                                            <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.gpsNotWorkingVehicles.length} /></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                                    GPS Vehicles
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 mb-10p">
                                        <span className="layoutbtns">
                                            <button className="float-right custom-btn white btn-primary" style={{ marginRight: "45px", borderRadius: "5px", height: "30px" }} onClick={this.onClickBulkUpload.bind(this)}>
                                                Bulk Upload
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12">
                                </div>
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-12 col-lg-12">
                                        <div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                components={this.state.components}
                                                frameworkComponents={this.state.frameworkComponents}

                                                //floatingFilter={true}
                                                onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                                //onCellClicked={this.popmarker}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                onCellClicked={this.handleCellClicked}
                                                onCellValueChanged={this.onCellValueChanged}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                {this.state.sliderBulkUpload != '' ?
                    <div className={"slide-r " + (this.state.sliderBulkUpload)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                Bulk Upload
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.bulkUploadSubmit.bind(this)}>
                                    <div className="col-xl-12 col-lg-12 form-group mt-20p">
                                        <label>Upload File *</label>
                                        <input
                                            type="file"
                                            name="uploadFile"
                                            accept=".csv"
                                            onChange={this.changeFileHandler1}
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <a
                                            className="btn btn-primary"
                                            href={require('../../assets/json/gpsdevices_bulkupload.xlsx')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Sample Template
                                        </a>
                                    </div>
                                    <p><strong style={{color: "red"}}>Note:</strong> Only .xlsx and .xls files are supported for upload.</p>

                                </form>
                            </div>
                        </div>

                    </div>
                    : ""
                }
            </div>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function dateScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist;
    }
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}


function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {

};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {

    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        console.log(date1Number, date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'Y-m-d',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
