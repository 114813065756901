
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import NewDrawMap from './newDrawMap'
import CountUp from 'react-countup';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowTollsList from "./showtollslist";
// import TollGrid from "./deviationtollgrid"
import ApprovalDeviationRoute from "./approvaldeviationroute";
import Implementroute from "./implementroute";
import $ from 'jquery';
import { TimeColsSlatsCoords } from '@fullcalendar/timegrid';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const map_plants = {"DW01": "Dacheppalli","VG01":"Vizag Grinding Unit","GW01":"Kallur Cement Works","SG01":"Solapur Grinding Unit","AW01":"Ariyalur Cement Works","KW01":"Karikkali Cement Works"}

export default class OptimizeDeviationRoutes extends Component {

   state = {
            loadshow: 'show-m',
            overly: 'show-m',
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                autoHeight: true,
                resizable: true
            },
            rowData: [],
            headerHeight: 60,
            rowHeight: 50,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
                MyHeaderComponent: MyHeaderComponent,
                Showtolls: Showtolls,
                ShowTollsList: ShowTollsList,
                ApprovalDeviationRoute: ApprovalDeviationRoute,
                Implementroute: Implementroute
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 1000,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "Route Deviations",
            originaldata: "",
            orignalresponse: "",
            mapinfo: "",
            originoption: { "value": "", "label": "Select Origin" },
            destinationoption: { "value": "", "label": "Select Destination" },
            destinationarr: [],
            selectedorigin: '',
            selecteddestination: '',
            columnDefs: [],
            checkedinputs: [],
            uncheckedinputs: [],
            noofroutes: 3,
            tolls: [],
            tollshow: false,
            plantcoords: "",
            plantname: "",
            sliderTranslate: "",
            viewtolls: [],
            tollrowid: "",
            routeid: "",
            routeapprovestatus: "",
            open: false,
            route_reason: "",
            optimizedroutes: 0,
            identifiedroutes: 0,
            savingrealized: 0,
            estimateannual: 0,
            implemented: 0,
            rejected: 0,
            hold: 0,
            takenimplement: 0,
            implementroutedate: "",
            implementrouteid: '',
            primarydata: [],
            secondarydata: [],
            tabprim: "show-m",
            tabsec: "show-n",
            btnactive: "btn-success",
            btnsecactive: "",
            btnprimactive: "",
            primidentifiedroutes: "",
            primoptimizedroutes: "",
            primtaken_for_implementation: "",
            primimplemented: "",
            primrejected: "",
            primhold: "",
            secidentifiedroutes: "",
            secoptimizedroutes: "",
            sectaken_for_implementation: "",
            secimplemented: "",
            secrejected: "",
            sechold: "",
            primestimateannual: "",
            primsavingrealized: "",
            secestimateannual: "",
            secsavingrealized: "",
            clicktab: "all",
            allidentifiedroutes: "",
            alloptimizedroutes: "",
            alltaken_for_implementation: "",
            allimplemented: "",
            allrejected: "",
            allhold: "",
            allestimateannual: "",
            allsavingrealized: "",
            potential_savings_under_implementation: "",
            primary_potential_savings_under_implementation: "",
            secondary_potential_savings_under_implementation: "",
            potential_savings: "",
            alldata: [],
            originalalldata: [],
            originalprimdata: [],
            originalsecdata: [],
            plant: "",
            plants: [],
            tabOneactive : "activet",
            tabTwoActive : "",
            rowDataForDealer : [],
            tabOneData : "show-m",
            tabTwoData : "show-n",
            originoptions:[],
            destinationOptions: [],
            lspusers_options: [],
            truck_types: [],
            period_types: [],
            selected_provider: {"label": "ALL", "value": "ALL"},
            selected_truck_type: {"label": "ALL", "value": "ALL"},
            selected_period_type: {"label": "ALL", "value": "ALL"},
            originoption: {"label": "ALL", "value": "ALL"},
            destinationoption: {"label": "ALL", "value": "ALL"},
            zonesOptions: [],
            consignee_area_options: [],
            selectedConsArea: {"label": "ALL", "value": "ALL"},
            selectedZone: {"label": "ALL", "value": "ALL"},
            total_optimized_routes : [],
            kilometers_saved : 0,
            btn1: "btn-danger",
            btn2:"btn-default",
            new_base_route_summary_data:[],
            activeBtn:1,
            is_loading:true,
            route_coords1:[]
    };
   
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    componentDidMount() {
        //this.onClickShowRoute()
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        this.setState({
            loadshow: 'show-m',
            overly: "show-m"
        })
        var map_plants = {"DW01": "Dacheppalli","VG01":"Vizag Grinding Unit","GW01":"Kallur Cement Works","SG01":"Solapur Grinding Unit","AW01":"Ariyalur Cement Works","KW01":"Karikkali Cement Works"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutes")
        .then((response) => {
            var plantdata = response.data;
            var originoptions = [{"label": "ALL", "value": "ALL"}];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                })
            }
            console.log("optoins",originoptions)
            redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": ""}).then((response1)=>{
                var items=[{"label": "ALL", "value": "ALL"}];
                var destinationarr = response1.data;
                var check = destinationarr.map((item) =>{
                    items.push({
                        value:item,label:item 
                    })
                })
                redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2)=>{
                    var lspusers_options = [];
                    var truck_types = [];
                    var period_types_options = [];
                    var period_types = response2.data.period_types;
                    response2.data.lsp_users.map((e)=>{
                        if(e != "NA")
                        {
                            var label = e
                            if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
                            lspusers_options.push({"label": label, "value": e})
                        }
                    })
                    response2.data.truck_types.map((e)=>{
                        truck_types.push({"label": e, "value": e})
                    })
                    period_types.map((e)=>{
                        period_types_options.push({"label": e, "value": e})
                    })
                    this.setState({
                        originoptions: originoptions,
                        destinationOptions: items,
                        lspusers_options: lspusers_options,
                        truck_types: truck_types,
                        period_types: period_types_options,
                    })
                    this.selectplants()
                })
                
            })
            
        })
        this.onLoadShowInitialData()
    }
    onLoadShowInitialData = async() => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var query = {
            "truck_type": 'ALL',
            "period_type": 'ALL',
        };
        if(localStorage.getItem("pc")!="" && localStorage.getItem("pc")!=undefined){
            query['consigner_codes'] = [localStorage.getItem("pc")]
        }
        console.log(query,"queryforinitial")
       
        redirectURL.post("/consignments/newalldeviationroutes", query).then((response) => {
                console.log("Consignees ", JSON.parse(response.data.data))
                var rec = JSON.parse(response.data.data);
                console.log(rec.optimized_routes_data,(rec.optimized_routes_data),"323")
                var records = JSON.parse(rec.optimized_routes_data);
                console.log("325", records)
                // data for the counters
                    let total_optimized_routes = []
                    let kilometers_saved = 0
                    records.map(e =>{
                        if(e.base_route_google_distance_difference < 0){
                            total_optimized_routes.push(e)
                            // console.log("rgdd",e.base_route_google_distance_difference, '/n rdgg', typeof(e.base_route_google_distance_difference))
                            kilometers_saved += Math.abs(e.base_route_google_distance_difference)
                        }
                    })
                // end of data for counters
                var identifiedroutes = rec.total_base_routes;
                var optimizedroutes = rec.optimized_routes;
                var taken_for_implementation = rec.taken_for_implementation;
                var implemented = rec.implemented;
                var rejected = rec.rejected;
                var hold = rec.hold;
                // var estimateannual = "--";
                var savingrealized = "--";
                var primidentifiedroutes = rec.primary_trips_count;
                var primoptimizedroutes = rec.primary_trips_count;
                var primtaken_for_implementation = rec.primary_taken_for_implementation;
                var primimplemented = rec.primary_implemented;
                var primrejected = rec.primary_rejected;
                var primhold = rec.primary_hold;
                var secidentifiedroutes = rec.secondary_total_base_routes;
                var secoptimizedroutes = rec.secondary_trips_count;
                var sectaken_for_implementation = rec.secondary_taken_for_implementation;
                var secimplemented = rec.secondary_implemented;
                var secrejected = rec.secondary_rejected;
                var sechold = rec.secondary_hold;
                var potential_savings_under_implementation = rec.potential_savings_under_implementation
                var primary_potential_savings_under_implementation = rec.primary_potential_savings_under_implementation;
                var secondary_potential_savings_under_implementation = rec.secondary_potential_savings_under_implementation;
                var estimateannual = rec.estimated_annual_savings;
                var primestimateannual = rec.primary_estimated_annual_savings;
                var secestimateannual = rec.secondary_estimated_annual_savings
                var all_savings_realized = rec.all_savings_realized;
                var primary_savings_realized = rec.primary_savings_realized;
                var secondary_savings_realized = rec.secondary_savings_realized;
                //console.log("Recr ", records) 
                var recordsarr = []
                var primarydata = [];
                var secondarydata = []
                if (records.length > 0) {
                    records = records.sort(GetSortOrder("base_route_google_distance_difference"));
                    records.map((item) => {
                            item.sourcename = map_plants[item.consigner_code];
                            recordsarr.push(item)
                    })
                }
                this.setState({
                    rowData: recordsarr,
                    originalalldata: recordsarr,
                    alldata: recordsarr,
                    primarydata: primarydata,
                    secondarydata: secondarydata,
                    overly: 'show-n',
                    loadshow: 'show-n',
                    optimizedroutes: optimizedroutes,
                    identifiedroutes: identifiedroutes,
                    takenimplement: taken_for_implementation,
                    implemented: implemented,
                    rejected: rejected,
                    hold: hold,
                    estimateannual: estimateannual,
                    savingrealized: all_savings_realized,
                    alloptimizedroutes: optimizedroutes,
                    allidentifiedroutes: identifiedroutes,
                    alltaken_for_implementation: taken_for_implementation,
                    allimplemented: implemented,
                    allrejected: rejected,
                    allhold: hold,
                    allestimateannual: estimateannual,
                    allsavingrealized: all_savings_realized,
                    primidentifiedroutes: primidentifiedroutes,
                    primoptimizedroutes: primoptimizedroutes,
                    primtaken_for_implementation: primtaken_for_implementation,
                    primimplemented: primimplemented,
                    primrejected: primrejected,
                    primhold: primhold,
                    primestimateannual: primestimateannual,
                    primsavingrealized: primary_savings_realized,
                    secidentifiedroutes: secidentifiedroutes,
                    secoptimizedroutes: secoptimizedroutes,
                    sectaken_for_implementation: sectaken_for_implementation,
                    secimplemented: secimplemented,
                    secrejected: secrejected,
                    sechold: sechold,
                    secestimateannual: secestimateannual,
                    secsavingrealized: secondary_savings_realized,
                    potential_savings_under_implementation: potential_savings_under_implementation,
                    secondary_potential_savings_under_implementation: secondary_potential_savings_under_implementation,
                    primary_potential_savings_under_implementation: primary_potential_savings_under_implementation,
                    potential_savings: potential_savings_under_implementation,
                    'total_optimized_routes' :total_optimized_routes,
                    'kilometers_saved':kilometers_saved

                })
            })
    }

    onLoadShowPageData = async() => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var query = {
            "truck_type": this.state.selected_truck_type.value,
            "period_type": this.state.selected_period_type.value,
        };
        // if(["", undefined, null, "ALL"].includes(this.state.originoption.value) == false)
        // {
        //     query['consigner_codes'] = [this.state.originoption.value]
        // }
        if(localStorage.getItem("pc")!="" && localStorage.getItem("pc")!=undefined){
            query['consignmer_codes'] = [localStorage.getItem('pc')]
        }
        if(["", undefined, null, "ALL"].includes(this.state.destinationoption.value) == false)
        {
            query['consignee_city'] = this.state.destinationoption.value
        }
        console.log(query,"queryforinitial")
       
        redirectURL.post("/consignments/newalldeviationroutes", query).then((response) => {
                console.log("Consignees ", JSON.parse(response.data.data))
                var rec = JSON.parse(response.data.data);
                console.log(rec.optimized_routes_data,(rec.optimized_routes_data),"323")
                var records = JSON.parse(rec.optimized_routes_data);
                console.log("325", records)
                // data for the counters
                    let total_optimized_routes = []
                    let kilometers_saved = 0
                    records.map(e =>{
                        if(e.base_route_google_distance_difference < 0){
                            total_optimized_routes.push(e)
                            // console.log("rgdd",e.base_route_google_distance_difference, '/n rdgg', typeof(e.base_route_google_distance_difference))
                            kilometers_saved += Math.abs(e.base_route_google_distance_difference)
                        }
                    })
                // end of data for counters
                var identifiedroutes = rec.total_base_routes;
                var optimizedroutes = rec.optimized_routes;
                var taken_for_implementation = rec.taken_for_implementation;
                var implemented = rec.implemented;
                var rejected = rec.rejected;
                var hold = rec.hold;
                // var estimateannual = "--";
                var savingrealized = "--";
                var primidentifiedroutes = rec.primary_trips_count;
                var primoptimizedroutes = rec.primary_trips_count;
                var primtaken_for_implementation = rec.primary_taken_for_implementation;
                var primimplemented = rec.primary_implemented;
                var primrejected = rec.primary_rejected;
                var primhold = rec.primary_hold;
                var secidentifiedroutes = rec.secondary_total_base_routes;
                var secoptimizedroutes = rec.secondary_trips_count;
                var sectaken_for_implementation = rec.secondary_taken_for_implementation;
                var secimplemented = rec.secondary_implemented;
                var secrejected = rec.secondary_rejected;
                var sechold = rec.secondary_hold;
                var potential_savings_under_implementation = rec.potential_savings_under_implementation
                var primary_potential_savings_under_implementation = rec.primary_potential_savings_under_implementation;
                var secondary_potential_savings_under_implementation = rec.secondary_potential_savings_under_implementation;
                var estimateannual = rec.estimated_annual_savings;
                var primestimateannual = rec.primary_estimated_annual_savings;
                var secestimateannual = rec.secondary_estimated_annual_savings
                var all_savings_realized = rec.all_savings_realized;
                var primary_savings_realized = rec.primary_savings_realized;
                var secondary_savings_realized = rec.secondary_savings_realized;
                //console.log("Recr ", records) 
                var recordsarr = []
                var primarydata = [];
                var secondarydata = []
                if (records.length > 0) {
                    records = records.sort(GetSortOrder("base_route_google_distance_difference"));
                    records.map((item) => {
                            item.sourcename = map_plants[item.consigner_code];
                            recordsarr.push(item)
                    })
                }
                this.setState({
                    rowData: recordsarr,
                    originalalldata: recordsarr,
                    alldata: recordsarr,
                    primarydata: primarydata,
                    secondarydata: secondarydata,
                    overly: 'show-n',
                    loadshow: 'show-n',
                    optimizedroutes: optimizedroutes,
                    identifiedroutes: identifiedroutes,
                    takenimplement: taken_for_implementation,
                    implemented: implemented,
                    rejected: rejected,
                    hold: hold,
                    estimateannual: estimateannual,
                    savingrealized: all_savings_realized,
                    alloptimizedroutes: optimizedroutes,
                    allidentifiedroutes: identifiedroutes,
                    alltaken_for_implementation: taken_for_implementation,
                    allimplemented: implemented,
                    allrejected: rejected,
                    allhold: hold,
                    allestimateannual: estimateannual,
                    allsavingrealized: all_savings_realized,
                    primidentifiedroutes: primidentifiedroutes,
                    primoptimizedroutes: primoptimizedroutes,
                    primtaken_for_implementation: primtaken_for_implementation,
                    primimplemented: primimplemented,
                    primrejected: primrejected,
                    primhold: primhold,
                    primestimateannual: primestimateannual,
                    primsavingrealized: primary_savings_realized,
                    secidentifiedroutes: secidentifiedroutes,
                    secoptimizedroutes: secoptimizedroutes,
                    sectaken_for_implementation: sectaken_for_implementation,
                    secimplemented: secimplemented,
                    secrejected: secrejected,
                    sechold: sechold,
                    secestimateannual: secestimateannual,
                    secsavingrealized: secondary_savings_realized,
                    potential_savings_under_implementation: potential_savings_under_implementation,
                    secondary_potential_savings_under_implementation: secondary_potential_savings_under_implementation,
                    primary_potential_savings_under_implementation: primary_potential_savings_under_implementation,
                    potential_savings: potential_savings_under_implementation,
                    'total_optimized_routes' :total_optimized_routes,
                    'kilometers_saved':kilometers_saved

                })
            })
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
        });

        this.gridApi.forEachLeafNode((node) => {
        });

    };


    onGridState = () => {
        // console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
            sliderRouteTranslate:'',
            sliderRouteTranslate1:'',
            sliderChokeTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
    }
    

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slidertranslate: ''
        });

    }
    onClickRouteImplement = (params) => {
        //console.log("params ", params.data)
        this.setState({
            slidertranslate: "slider-translate-30p",
            overly: 'show-m',
            implementrouteid: params.data._id
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            slidertranslate: "",
            overly: 'show-n'
        });
    }

    handlerStartDateTime = (event) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) >= 10 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            implementroutedate: startdate
        });
    }

    onClickShowUpdateData() {
        var implementroutedate = this.state.implementroutedate;
        var implementrouteid = this.state.implementrouteid;
        var parameters = {
            effective_date: implementroutedate,
            id: implementrouteid
        }
        //console.log("parameters ", parameters)
        redirectURL.post("/consignments/setrouteimplementation", parameters)
            .then((response) => {
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Successfully update data",
                    slidertranslate: "",
                    overly: 'show-n'
                })
            }).catch((e) => {

            })

    }
    getBaseRoutesForDealer = () =>{
        redirectURL.post("/consignments/alldeviationroutesForDealers",)
        .then(response =>{
            let resData = JSON.parse(response.data.data)
            // console.log("response",resData)
            let optimizedRouteData = JSON.parse(resData.optimized_routes_data)
            // console.log("response",optimizedRouteData)
            let recordsarr = []
            
            if (optimizedRouteData.length > 0) {
                optimizedRouteData = optimizedRouteData.sort(GetSortOrder("base_route_google_distance_difference"));
                optimizedRouteData.map((item) => {
                    // console.log("item ", item)
                    if (parseInt(item.base_route_google_distance_difference) < 0) {
                        item.sourcename = item.consigner_name;
                        recordsarr.push(item)
                    }
                })
            }
            this.setState({
                rowDataForDealer : recordsarr,
                loadshow : "show-n"
            })

        })
    }
    onClickShowClickableData(params) {
        console.log("Params ", params)
        // console.log("clicktab ", this.state.clicktab)

        if (this.state.clicktab == "all") {
            var records = this.state.originalalldata;
            var takenarr = []
            var rejectarr = []
            var holdarr = []
            var implementarr = []
            if (records.length > 0) {
                records.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        takenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        rejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        holdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        implementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.originalalldata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: takenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: rejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: holdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: implementarr,
                })
            }
        }
        if (this.state.clicktab == "prim") {
            var primrecords = this.state.primarydata;
            var primtakenarr = []
            var primrejectarr = []
            var primholdarr = []
            var primimplementarr = []
            if (primrecords.length > 0) {
                primrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        primtakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        primrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        primholdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        primimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.primarydata,
                })
            }
            if (params == "taken") {
                // console.log("primtakenarr ", primtakenarr)
                this.setState({
                    rowData: primtakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: primrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: primholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: primimplementarr,
                })
            }
        }
        if (this.state.clicktab == "second") {
            var secrecords = this.state.secondarydata;
            var sectakenarr = []
            var secrejectarr = []
            var secholdarr = [];
            var secimplementarr = []
            if (secrecords.length > 0) {
                secrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        sectakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        secrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        secholdarr.push(item)
                    }
                    if (params == "hold") {
                        this.setState({
                            rowData: secholdarr,
                        })
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        secimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.secondarydata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: sectakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: secrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: secholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: secimplementarr,
                })
            }
        }
        if(params === 'tor'){
            this.setState({
                rowData: this.state.total_optimized_routes,
            })
        }
    }
    selectplants() {
        var plants = [];
        plants.push({ "value": "all", "label": "All" })
        plants.push({ "value": "B", "label": "TVP Bangalore" })
        plants.push({ "value": "G", "label": "Gujarat" })
        plants.push({ "value": "F", "label": "Gurgaon" })
        plants.push({ "value": "I", "label": "Siliguri" })
        plants.push({ "value": "N", "label": "TVP Nagpur" })
        plants.push({ "value": "M", "label": "Manesar" })
        // plants.push({ "value": "haryana", "label": "Haryana" })
        this.setState({
            plants: plants,
        });
    }
    onChangePlantItem = (plant) =>{
        this.setState(
            { plant },
            // () => console.log(`Plant Option selected:`, this.state.plant)
        );
    }
    formPlantHandler = async (event) => {
        event.preventDefault()
        this.setState({
            "loadshow": "show-m",
            "overly": 'show-m',
        });
        this.onLoadShowPageData();
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": originoption.value}).then((response)=>{
            var items=[{"label": "ALL", "value": "ALL"}];
            var destinationarr = response.data;
            var check = destinationarr.map((item) =>{
                items.push({
                    value:item,label:item 
                })
            })
            Promise.all([check]).then(()=>{
                this.setState({
                    destinationOptions: items
                })
            })
            
        })
    }

    changeDestinationHandler = destinationoption => {
        this.setState(
            { destinationoption }
        );
    
    }
    changeConsigneeArea(selectedConsArea){
        this.setState({selectedConsArea})
    }
    changeZone(selectedZone){
        this.setState({selectedZone})
    }
    changeGpsProviderHandler = selected_provider => {
            this.setState({
                selected_provider: selected_provider,
            })
        // })
    }

    changePeriodTypeHandler = selected_period_type => {
            this.setState({
                selected_period_type: selected_period_type,
            })
        // })
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }
    formHandler(){

    }

    onClickTab=(tab)=>{
        console.log(tab)
        if (tab==1){
            this.setState({
                btn1:"btn-danger",
                btn2:"btn-default",
                activeBtn:1,
            })
        }else{
            this.setState({
                btn2:"btn-danger",
                btn1:"btn-default",
                activeBtn:2,
            })
        }

    }

    onCellClicked=async(e)=>{
        if(e.colDef.field == "base_routes_map")
        {
            console.log("Hit-1")
            console.log(e.data,"1707")
            if (e.data.route_coords==undefined||e.data.route_coords=="undefined"){
                var rowID=e.data.base_route_id
                var tab="base_routes"
            }else{
                var rowID=e.data.route_coords
                var tab="summary"
            }
           console.log(rowID,"1732")
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            })
    
         
                redirectURL.post("dashboard/gettripcoords",{row_id:rowID,tab:tab}).then((resp)=>{
                    console.log(resp.data,"1724")
                    console.log(eval(resp.data.trip_route),"1729")
                    console.log(resp.data.saved_base_route,"1730")
                    // var base_no=(e.data.base_route_no).split(" ")
                    // base_no=base_no[2]
                    var route_coords1 = [
                        {
                            "consignment_code": ["Base Route - "+e.data.base_route_no],
                            "coords": eval(resp.data.trip_route),
                            "breaks": []
                        },
                    ]
                    this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                // }
            })
            // })
        }
    }

    render() {
        const columnDefs = [
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer:  (params) => {
                    var sourval = params.data.consigner_code;
                    var sourcename = params.data.sourcename;
                    var destination = params.data.consignee_city;
                    return '<a href="/deviationanalysisdata?source=' + sourval + '&sourcename=' + sourcename + '&destination=' + destination + '&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value+'" target="_blank" class="btn btn-warning" style="padding:2px;width:7em;" rel="noopener">View Summary</a>'
                }
            },
            {
                headerName: "Source",
                field: "sourcename",
                width: 120,
            }, {
                headerName: "Destination",
                field: "consignee_city",
                width: 140
            },

            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },

            {
                headerName: "Valid Trips",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "CHETTINAD Freight Distance (Kms)(B)",
                field: "",
                width: 120,
                valueGetter: function(params){
                    // console.log(params.data.expected_distance, "params.data.expected_distance")
                    return params.data.expected_distance;
                }
            },
            {
                headerName: "Min. GPS Distance (KM)",
                field: "ref_route_min_google_distance",
                width: 120
            },
            {
                headerName: "Max. GPS Distance (KM)",
                field: "ref_route_max_google_distance",
                width: 120
            },
            {
                headerName: "Average Distance (Kms)(A)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Median GPS Distance (KM)",
                field: "ref_route_median_google_distance",
                width: 120
            },
            {
                headerName: "Min Google Distance (KM)",
                field: "google_distance_via_waypoints",
                width: 120
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            },
            {                
                headerName: "Kilometers Saved",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter : param =>{
                    try {
                        if(param.data.base_route_google_distance_difference < 0) return Math.abs(param.data.base_route_google_distance_difference)
                            return 0
                    }
                    catch (e) {
                        return "";
                    }

                }
            }


        ]

        const columnDefsForDealer = [
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer: function (params) {
                    // var cCode = params.data.consignee_code;                    
                    // return '<a href="/deviationForDealer?consignee_code=' + cCode +'" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                    var sourval = params.data.consigner_code;
                    let sourceName = params.data.consigner_name;                
                    var cCode = params.data.consignee_code;  
                    return '<a href="/deviationForDealer?source=' + sourval +'&sourcename=' + sourceName + '&consignee_code=' + cCode + '" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                }
            },
            {
                headerName: "Source",
                field: "consigner_name",
                width: 120
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 160

            },
            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },
            {
                headerName: "Trips Considered For Analysis",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "CHETTINAD Approved Distance (Kms)(B)",
                field: "expected_distance",
                width: 120,
                valueGetter: (params)=>{
                    return params.data.expected_distance;
                }
            }, {
                headerName: "Average Distance (Kms)(B)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            }
        ]

        const {show,basicType,basicTitle,total_optimized_routes, kilometers_saved,optimizedroutes} = this.state

        var colDefs = [
            // {
            //     headerName: "",
            //     field: "checkbox_selection",
            //     checkboxSelection:true,
            //     width:60,
            //     // cellRenderer: (params)=> {
            //     //     if(params.data.is_marked_base_route == 1)
            //     //     {
            //     //         return params.node.setSelected(true);
            //     //     }
            //     //     else{
            //     //         return params.node.setSelected(false);
            //     //     }
            //     // }
            // },
            {
                headerName: "",
                field: "base_routes_map",
                cellRenderer: (params)=>{
                    return "<i class='icofont icofont-map-pins f20'></i>"
                },
                width:60
            },
            {
                headerName: "Source",
                field: "plant",
                valueGetter: (params)=>{
                    return params.data.plant
                }
            },
            {
                headerName: "Destination",
                field: "consignee_city",
            },
            {
                headerName: "For Code",
                field: "for_code",
            },
            {
                headerName: "Base Route",
                field: "base_route_no",
                valueGetter:(params)=>{
                    return "Base Route "+params.data.base_route_no
                }
               
            },
            {
                headerName: "CHETTINAD Approved Route",
                field: "is_marked_base_route",
                valueGetter: (params)=>{
                    if(params.data.is_marked_base_route == 1)
                    {
                        return "Yes"
                    }
                    else
                    {
                        return 'No'
                    }
                }
            },
            {
                headerName: "No. of Trips",
                field: "no_of_followers",
            },
            {
                headerName: "Route Trip %",
                field: "no_of_followers_percentage",
            },
            {
                headerName: "No of LSP Users",
                field: "Transporters"
            },
            {
                headerName: "MSIL Approved Distance (KM)",
                field: "expected_distance",
            },
            {
                headerName: "Google Distance (KM)",
                field: "google_distance_via_waypoints"
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "distance_difference"
            },
            {
                headerName: "Avg. GPS Distance (KM)",
                field: "avg_google_distance",
            },
            {
                headerName: "Median GPS Distance (KM)",
                field: "median_google_distance",
            },
           
            {
                headerName: "Max. GPS Distance (KM)",
                field: "max_google_distance",
            },
            {
                headerName: "Min. GPS Distance (KM)",
                field: "min_google_distance",
            },
          
           
           
           
            
           
        ]

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                <div className="row col-xl-12 col-lg-12">
                        {/* <div className="form-group col-xl-2 col-lg-2">
                            <label>Origin: </label>
                            <Select 
                                name="originoption"
                                closeMenuOnSelect={true} 
                                value={this.state.originoption} 
                                className="border-radius-0" 
                                onChange={this.changeOriginHandler} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.originoptions} required
                            />
                        </div>                        */}
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Destination: </label>
                            <Select 
                                name="destinationoption"
                                closeMenuOnSelect={true} 
                                value={this.state.destinationoption} 
                                className="border-radius-0" 
                                onChange={this.changeDestinationHandler} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.destinationOptions} required
                            />
                        </div>
                      
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Period: </label>
                            <Select 
                                name="Period"
                                closeMenuOnSelect={true} 
                                value={this.state.selected_period_type} 
                                className="border-radius-0" 
                                onChange={this.changePeriodTypeHandler} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.period_types} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Truck Type: </label>
                            <Select 
                                name="Truck Type"
                                closeMenuOnSelect={true} 
                                value={this.state.selected_truck_type} 
                                className="border-radius-0" 
                                onChange={this.changeTruckTypeHandler} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.truck_types} required
                            />
                        </div>
                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <button style={{marginTop:"30px"}} onClick={this.onLoadShowPageData} type="button" className="btn btn-success">Submit</button>
                        </div>
                    </div>


                    <div className="button-group" style={{marginLeft:"30px"}}>
						{/* <button className={"btn "+this.state.btn1} onClick={() => this.onClickTab(1)}>Optimized Routes</button> */}
					</div>
                        {(this.state.activeBtn==1) ? 
                        <div className="col-xl-12 col-lg-12 col-sm-12">
                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"all")}>
                                            <span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Possible Identified Routes</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(optimizedroutes != '')?optimizedroutes:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"tor")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Total Optimized Routes</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(total_optimized_routes != '')?total_optimized_routes.length:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Kilometers Saved</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(kilometers_saved != '')?kilometers_saved:0}/></span></h4>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Optimized Routes  </span>
                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className={"row col-xl-12 col-lg-12 " + (this.state.tabOneData)}>
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={false}
                                            // enableRangeSelection={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            //onCellClicked={this.popmarker}
                                            headerHeight={this.state.headerHeight}
                                            rowHeight={this.state.rowHeight}
                                        />
                                    </div>                            
                                  
                                </div>
                                <div className={"row col-xl-12 col-lg-12 "  + (this.state.tabTwoData)}>
                                <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefsForDealer}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowDataForDealer}
                                            enableCharts={false}
                                            // enableRangeSelection={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            //onCellClicked={this.popmarker}
                                            headerHeight={this.state.headerHeight}
                                            rowHeight={this.state.rowHeight}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div></div> : <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>New Base Routes Summary</span>
                                </h5>
                            </div>
                            <div className="card-body row">
                               
                                <div className={"row col-xl-12 col-lg-12 "}>
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                {this.state.is_loading ? <div className="ag-overlay-loading" style={{height:"100%",width:"100%",textAlign:"center",fontWeight:"600",fontSize:"30px",paddingTop:"100px"}}>Loading...</div>:

                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={colDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.new_base_route_summary_data}
                                            enableCharts={false}
                                            // enableRangeSelection={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            onCellClicked={this.onCellClicked.bind(this)}
                                            headerHeight={this.state.headerHeight}
                                            rowHeight={this.state.rowHeight}
                                        />}
                                    </div>                            
                                   
                                </div>
                               
                            </div>
                        </div>
                    </div>}
                    
                   
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                {
                    (this.state.sliderRouteTranslate1 != "")?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate1)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>					
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>	
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">								
                                <NewDrawMap 
                                    mapFor={"multiroutedeviation"}
                                    mapinfo={{"coords": this.state.route_coords1,
                                            // "consigner_coordinates": this.state.defaultCompleteTrips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.dealersList,
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    // coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                />
							</div>
						</div>
						
					</div>
				:""}

                <div className={"slide-r " + (this.state.slidertranslate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Implement Route
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Implement Route From:</label>
                                <Datetime
                                    value={this.state.implementroutedate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: '', name: 'implementroutedate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="implementroutedate"
                                    onChange={this.handlerStartDateTime.bind(this)}
                                />
                            </div>

                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{ marginTop: "30px" }} onClick={this.onClickShowUpdateData.bind(this)} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}



function sortPeriodTypes(periods_list)
{
    var quarters_dict = {"4": ["Jan", "Feb", "Mar"], "1": ["Apr", "May", "Jun"], "2": ["Jul", "Aug", "Sep"], "3": ["Oct", "Nov", "Dec"]};
    var final_list = ["ALL"];
    var distinctYears = [];
    periods_list = periods_list.filter((e) => {
        var matches = e.match(/(\d+)/);
        // console.log(matches)
        if(matches != null)
        {
            if(matches.length)
            {
                var year = parseInt(matches[0])
                if(distinctYears.indexOf(year) < 0)
                {
                    distinctYears.push(year)
                }
            }
        }
        
        return e != "ALL"
    })
    distinctYears = distinctYears.sort()
    distinctYears.map((e)=>{
        var filteredData = periods_list.filter(k => k.includes(e.toString()))
        if(filteredData.length)
        {
            var quarters = filteredData.filter(j => j.includes("Q"));
            var resultMonths = filteredData.filter(j => j.includes("Q") == false);
            quarters = quarters.sort()
            if(quarters.length)
            {
                quarters.map(q => {
                    final_list.push(q)
                    var quarter_no = q.split("Q")[1];
                    var quarter_months = quarters_dict[quarter_no];
                    quarter_months.map(m =>{
                        var finalMonth = resultMonths.filter(r => r.includes(m));
                        if(finalMonth.length)
                        {
                            final_list.push(finalMonth[0])
                        }
                    })
                })
            }
        }
    });
    final_list = final_list.filter(e => e != "");
    return final_list
}