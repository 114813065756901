/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils';

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from "jquery";
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var viewMarkersArr = [];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var colorPickers = ["#000000", "blue", "green", "brown"]
var plottedPolylines= []
var route_deviation_polyline_dict = { 1: "route_deviation_polyline_1", 2: "route_deviation_polyline_2", 3: "route_deviation_polyline_3" }
var pathcolor = ["#157254", "blue", "red"]
var directionsDisplays = [];
var waypoints = [];

class NewDrawMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mapinfo: '',
			tolls: [],
			proptruckno: '',
			defTransitCoords: '',
			activeMarker: {},
			selectedPlace: {},
			dealerCode: '',
			timelinesmarkers: [],
			viewtimelinemarkers: false,
			viewgoogleroutes: true,
			googleroutepath: [],
			loadshow: 'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rowData: null,

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			showMarkers: 0,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			data_type: "",
			selectedorigin: "",
			selecteddestination: "",
			returnTripsDashboardRowData: [],
			breakLatLngList:[],
            overly:'show-n',
			changedWaypoints: [],
            addNewWayPoints: [],
		};
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this)
	}
	componentDidMount() {
		this.setState({
			loadshow:'show-m',
            overly:'show-m',
		})
		this.renderMap()
	}
	componentWillReceiveProps(newprops) {
		plottedPolylines = []
		if (newprops.truckno != this.state.proptruckno) {
			this.renderMap()
			this.setState({
				proptruckno: newprops.truckno
			})
		}
		if (newprops.selectedorigin != this.state.selectedorigin && newprops.selecteddestination != this.state.selecteddestination) {
			this.renderMap()
		}

	}
	renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
		this.enableCheckBoxes()

	}

	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}
	};


	onGridState = () => {
		//console.log(this.gridApi);
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();
		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}


	plotMultiColorPolyline(arr2, map, bounds, route_no=null) {
		// console.log(arr2, "check coords")
		arr2 = arr2.filter(e => e.lat != NaN)
		// console.log(arr2, "checking route")
		if (arr2.length) {
			var data_type_list = [...new Set(arr2.map(item => item.type))];
			if (data_type_list.includes("transshipment")) {
				var data_type = "transshipment"
			}
			if (data_type_list.includes("rake")) {
				var data_type = "rake"
			}
			this.setState({
				data_type: data_type
			})
		}

		if (this.props.stepcoords == undefined) {
			if (arr2.length <= 1000) {
				var icon_cnt = 60;
				var icon_divisible = 60;
			}
			else {
				var icon_cnt = 500;
				var icon_divisible = 500;
			}
		}
		else {
			var icon_cnt = 1;
			var icon_divisible = 1;
		}
		// console.log(arr2, "coords")
		var route_polylines = [];
		for (var i = 0; i < arr2.length - 1; i++) {
			var locationLatLng = [];
			locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
			locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
			var lineicon = [];
			if(["multiroutedeviation", "geofence"].includes(this.props.mapFor) == false)
			{
				if (icon_cnt % icon_divisible == 0) {
					var lineicon = [{
						icon: {
							path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							strokeColor: '#FF0000',
							fillColor: '#FF0000',
							fillOpacity: 1,
							strokeWeight: 2
						},
						repeat: '100px',
						path: []
					}];
				} 
			}
			
			icon_cnt = icon_cnt + 1
			var consignments_missing_route_line = new window.google.maps.Polyline({
				map: map,
				path: locationLatLng,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: lineicon
			});
			route_polylines.push(consignments_missing_route_line)
			var is_straight_line = 0
			if(arr2[i].dist_from_prev_point >= 50000)
			{
				is_straight_line = 1
			}
			if (arr2[i].msgtype == "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#452a68",
					strokeWeight: 5.5,
				})
			}
			if (arr2[i].set == undefined && arr2[i].msgtype != "G" && this.props.mapFor != "multiroutedeviation") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#157254"
				})
			}
			else if (arr2[i].set == 1 && arr2[i].msgtype != "G"  && this.props.mapFor != "multiroutedeviation") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#157254"
				})
			}
			else if (arr2[i].set == 2 && arr2[i].msgtype != "G"  && this.props.mapFor != "multiroutedeviation") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#0000ff",
					strokeWeight: 5.5,
				})
			}
			else if (arr2[i].set == 3 && arr2[i].msgtype != "G"  && this.props.mapFor != "multiroutedeviation") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#980000",
					strokeWeight: 5.5,
				})
			}
			else if(this.props.mapFor == "multiroutedeviation")
			{
				consignments_missing_route_line.setOptions({
					strokeColor: colorPickers[route_no],
					strokeWeight: 3.5,
				})
			}
			if(is_straight_line)
			{
				consignments_missing_route_line.setOptions({
					strokeColor: "red",
					strokeWeight: 3.5,
				})
			}
			bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
		}
		if(arr2.length !== 0) plottedPolylines.push(route_polylines)
	}
	plotBoundaryLines(boundaryarr) {
		//console.log("boundaries ",boundaryarr)
		if (boundaryarr.length > 0) {

			boundaryarr.map((item) => {
				if (item.length > 0) {
					var line = new window.google.maps.Polyline(
						{
							map: map,
							strokeColor: '#0ABB50',
							strokeOpacity: 1.0,
							strokeWeight: 2.5
						});
					for (var i = 0; i < item.length; i++) {
						var locationLatLng = [];
						locationLatLng.push(new window.google.maps.LatLng(item[i].lat, item[i].lng));
						var consignments_missing_route_line = new window.google.maps.Polyline({
							map: map,
							path: locationLatLng,
							strokeColor: '#0ABB50',
							strokeOpacity: 1.0,
							strokeWeight: 2.5,
							fillColor: '#1D9567',
							fillOpacity: 0.8
							//icons: lineicon
						});

						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(item[i].lat, item[i].lng);
						path.push(latLng);
						line.setPath(path);
					}


					// var geoFencingArea = new window.google.maps.Polygon({
					// 	//map:map,
					//   paths: item,
					//   editable: true,
					//   strokeColor: '#1D9567',
					//   strokeOpacity: 0.70,
					//   strokeWeight: 0,
					//   fillColor: '#1D9567',
					//   fillOpacity: 0.35,
					//   draggable:true

					// });
					// geoFencingArea.setMap(map);
					// //console.log("test ",geoFencingArea.getPath());
					// //window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);

					// window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
					// window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);




				}
			})
		}
		this.setState({
			loadshow: 'show-n',
			overly: "show-n"
		})
	}

	initMap = () => {
		var currentwindow;
		var dealer = '';
		var tolls = this.props.tolls;
		var parkdealers = this.props.parkdealers;
		var chokepoints = this.props.chokepoints;
		this.setState({
			dealerCode: this.props.dealer
		});
		var coords_list = [];
		var arr = [];
		//console.log("dealerCode ",this.state.dealerCode)
		if (this.props.mapFor == 'geofence' || this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
		}
		if(this.props.mapFor == "multiroutedeviation")
		{
			var jsondata = this.props.mapinfo;
			var arr = jsondata.coords;
			coords_copy = arr;
		}
		if (this.props.mapFor == "route_deviation") {
			var location_lists = this.props.breaks;
			var coords_list = this.props.coords;
			coords_copy = arr;
		}
		if (this.props.mapFor == 'consignment' || this.props.mapFor == 'railconsignments') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;		
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeArr = []
			var routeinfo = this.props.route_details;
			dealer = jsondata.consignee_coordinates;
		}
		if (this.props.mapFor == "load_management") {
			var arr = this.props.coords;
			// console.log("coords",arr)
		}
		if (this.props.mapFor == 'returntripsdashboard' || this.props.mapFor == "returntrips") {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
			var competitor_geofences = this.props.competitor_geofences
		}
		if (this.props.mapFor == 'truck' || this.props.mapFor == "loadattach" || this.props.mapFor == "tpttruck") {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
		}
		if (this.props.mapFor == 'ticket') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			dealer = jsondata.consignee_coordinates;
		}
		if (this.props.mapFor == 'importsconsignment') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.points;
			var arr = jsondata.route;
			coords_copy = arr;
		}

		if (this.props.mapFor == 'importcontainerlegs') {
			var jsondata = this.props.mapinfo;
			var arr = this.props.mapinfo;
			//coords_copy = arr;
		}

		if (this.props.mapFor == 'rakeschedule') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
			var delivery_point_details = jsondata.delivery_point_details;
			var loading_point_details = jsondata.loading_point_details;
		}

		if (this.props.mapFor == 'exportsconsignment') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
		}

		if (this.props.mapFor == 'consignemntSidebarMap') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
		}
		if (this.props.mapFor == "activeTrucksSidebarMap") {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			coords_copy = arr;
			var routeinfo = jsondata.route_details;
			//console.log(routeinfo,"routeinfo");
			// console.log("arrr",arr);
		}

		//console.log("mapFor ",this.props.mapFor)
		if (this.props.mapFor == "deviation") {
			//console.log("this.props.mapinfo ", this.props.mapinfo)
			var jsondata = this.props.mapinfo;
			var arr = jsondata.routes;
			coords_copy = arr;
		}

		if (this.props.mapFor == "routeplan") {
			var jsondata = this.props.mapinfo;
			var arr = jsondata;
			coords_copy = arr;
			console.log("arrCheck",arr)
		}
		//console.log(locations,"breaks")
		//console.log("arr ",arr)
		if(this.props.mapFor == "multiroutedeviation")
		{
			var lt = 28.4519751;
			var ln = 77.0310713;
		}
		else
		{
			if (arr == undefined) {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				if (arr.length > 0 && typeof arr.length != undefined) {
					if (this.props.mapFor == 'ticket' && this.props.consignercords != undefined) {
						var lt = this.props.consignercords.lat;
						var ln = this.props.consignercords.lng;
					}
					else {
						var lt = arr[0].lat;
						var ln = arr[0].lng;
					}
				}
				else {
					if (this.props.defTransitCoords == '' || typeof this.props.defTransitCoords == undefined) {
						var lt = 28.4519751;
						var ln = 77.0310713;
					}
					else {
						try {
							var lt = JSON.parse(this.props.defTransitCoords).lat;
							var ln = JSON.parse(this.props.defTransitCoords).lng;
						}
						catch (e) {
							var lt = 28.4519751;
							var ln = 77.0310713;
						}
	
					}
	
				}
			}
		}

		var routeinfo;
		if (this.props.mapFor == 'consignment' || this.props.mapFor == 'railconsignments' || this.props.mapFor == 'ticket') {
			var routeinfo = jsondata.route_details;
		}
		try {
			var mapOptions = {
				zoom: 8,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				// center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		catch (e) {
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751, 77.0310713),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		try {
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		} catch (e) {
			console.log(e, "map")
		 }

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});
		// console.log(arr, "check arr")
		if (arr == undefined) {

		}
		else {
			try {
				if (arr.length > 0 || coords_list.length > 0) {

					var allpoints = [];
					var arr1 = [];
					//console.log("Total ",ratio);
					if (this.state.timelinesmarkers.length > 0) {
						arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
							return (index % ratio == 0);
						});
					}
					var arr2 = [];
					var darr = [];
					if(this.props.mapFor != "multiroutedeviation")
					{
						if (arr.length > 0) {
							darr = arr.filter(function (value, index, arr) {
								//console.log("Vale ",value)
								return (value.dist_from_prev_point > 0);
							});
							if (darr.length < 2000) {
								var ratio = 1;
							}
							else {
								var ratio = 20;
							}
							//console.log("darr.length ", darr.length)
							arr2 = darr
	
							//var arr1 = this.state.timelinesmarkers;
						}
					}
					else
					{
						var deviationArr = [];
						if (arr.length > 0) {
							// console.log(arr, "arrData");
							arr.map((e)=>{
								var darr = [];
								var temp = e['coords']
								// console.log("temp",e)
								//msilsap route is marked from msil altered routes screen and it stores alteredMSIlSaproute and if altered route is stored from the deviation screen it stores the route_coords from the baseroutescomb so we need to filter the format with which the gps coords we need to plot the map, for alteredMSILSapRoute we use Plot GoogleDirection service. // 
								if(e.consignment_code[0] === 'MSIL Approved Route' && temp.alteredMSILSapRoute !== undefined){
										var msilSapRouteDetails = JSON.parse(decodeURI(temp.alteredMSILSapRoute))
										waypoints = msilSapRouteDetails.request.waypoints
										var origin = new window.google.maps.LatLng(msilSapRouteDetails.request.origin.location.lat, msilSapRouteDetails.request.origin.location.lng);
										// console.log('msilSapRouteDetails',msilSapRouteDetails)
										var destination = temp.consignee_city
										this.plotGoogleDirections(origin,destination)
								}else{
									// console.log("temp",temp)							
									if(e.consignment_code[0] === 'MSIL Approved Route') {
										// console.log("checkingInside temp",eval(temp.route_coords))
										// temp.route_coords is stored as string in database so we are evaling it and reading
										if(temp.route_coords){
											deviationArr.push(eval(temp.route_coords))
											// console.log("check1")
											darr = eval(temp.route_coords).filter(function (value, index,temp) {
												return (value.dist_from_prev_point > 0);
											});
										}else{
											// console.log("check2")
											deviationArr.push(temp)
											darr = (temp).filter(function (value, index,temp) {
												return (value.dist_from_prev_point > 0);
											});
										}
									}else{
										deviationArr.push(temp)
										// console.log("checkingElseTemp")
										if(temp !==  ''){
											darr = temp.filter(function (value, index,temp) {
												return (value.dist_from_prev_point > 0);
											});
										}
									}		
								}
								if (darr.length < 2000) {
									var ratio = 1;
								}
								else {
									var ratio = 20;
								}
								//console.log("darr.length ", darr.length)
								arr2.push(darr.filter(function (value, index, darr) {
									return (index % 1 == 0);
								}));		
								//var arr1 = this.state.timelinesmarkers;
							})
							
						}
						// console.log(arr2, "arr2 checking ")
					}				

					/* ROUTE DEVIATION MAP */
					if (this.props.mapFor == "route_deviation") {
						var routeinfo = [];
						// console.log(coords_list,"coords_list")
						// this.plotMultiColorPolyline(arr,map,bounds)
						var bounds = new window.google.maps.LatLngBounds();

						coords_list.map(function (coord, index) {
							route_deviation_polyline_dict[index + 1] = new window.google.maps.Polyline({
								map: map,
								strokeColor: pathcolor[index],
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '500px',
									path: []
								}]
							});
							for (var i = 0; i < coord.length; i++) {
								var route_deviation_path = route_deviation_polyline_dict[index + 1].getPath().getArray();
								let latLng = new window.google.maps.LatLng(coord[i].lat, coord[i].lng);
								bounds.extend(new window.google.maps.LatLng(coord[i].lat, coord[i].lng));
								// console.log(coord[i].lat)
								route_deviation_path.push(latLng);
								route_deviation_polyline_dict[index + 1].setPath(route_deviation_path);
							}
							// Starting point
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(coord[0].lat, coord[0].lng),
								icon: require('../../assets/icons/track_start.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])

									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Starting Point"
									contentarr.push({ "key": "Route - " + (index + 1).toString(), "value": "Starting Point" })

									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

							// Ending point
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(coord[(coord.length - 1)].lat, coord[(coord.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])

									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "Route - " + (index + 1).toString(), "value": "Ending Point" })

									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						})


						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						/* BREAKS */
						// location_lists.map(function(locations){
						// 	for (l = 0; l < locations.length; l++)
						// 	{
						// 		marker = new window.google.maps.Marker({
						// 		position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
						// 		icon:require('../../assets/icons/cf.png'),
						// 		map: map,
						// 		});

						// 		window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
						// 			return function() {
						// 				//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

						// 			var contentarr = []
						// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// 			var header = "Break";
						// 			try{
						// 				if(typeof this.props.mapFor != 'undefined')
						// 				{
						// 					if(this.props.mapFor == 'truckgps')
						// 					{
						// 						contentarr.push({"key":"Break Start", "value":locations[l].break_start})
						// 						contentarr.push({"key":"Break End", "value":locations[l].break_end})
						// 						contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						// 					}
						// 					else
						// 					{
						// 						contentarr.push({"key":"Break Start", "value":locations[l].break_start})
						// 						contentarr.push({"key":"Break End", "value":locations[l].break_end})
						// 						contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						// 					}
						// 				}
						// 				else
						// 				{
						// 					contentarr.push({"key":"Break Start", "value":locations[l].break_start})
						// 					contentarr.push({"key":"Break End", "value":locations[l].break_end})
						// 					contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						// 				}
						// 			}
						// 			catch(e){
						// 				contentarr.push({"key":"Break Start", "value":locations[l].break_start})
						// 				contentarr.push({"key":"Break End", "value":locations[l].break_end})
						// 				contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						// 			}


						// 			var contentString = infoBox(marker.icon, header, contentarr,'')

						// 			infowindow.setContent(contentString);
						// 			currentwindow = infowindow;
						// 			infowindow.open(map, marker);
						// 			//console.log(marker.position.toJSON());
						// 			console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						// 			}
						// 		})(marker, l));
						// 	}

						// })



						// view markers code
						markersArray = arr2;

					}
					// ACTIVE TRUCKS SIDEBAR MAP
					if (this.props.mapFor == 'activeTrucksSidebarMap') {
						this.plotMultiColorPolyline(arr2, map, bounds)
						// for (let i = 0; i < arr2.length; i++)
						// {
						// 	if(arr2[i].speed > 0)
						// 	{
						// 		var linecolr = "#157254";
						// 	if(arr2[i].set  == 2)
						// 	{
						// 		linecolr = "#0000ff";
						// 	}
						// 	else if(arr2[i].set  == 3)
						// 	{
						// 		linecolr = "#980000"
						// 	}
						// 	else
						// 	{
						// 		linecolr = "#157254";
						// 	}

						// 	line.setOptions({strokeColor: linecolr});

						// 	var path=line.getPath().getArray();
						// 	let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 	path.push(latLng);
						// 	line.setPath(path);

						// 	//console.log("Check ",pointertime[arr[i].stime]);

						// 	//Change line color based on map type
						// 	window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 		if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 		{
						// var line=new window.google.maps.Polyline({
						// 	map:map,
						// 	strokeColor: '#FF0202',
						// 	strokeOpacity: 1.0,
						// 	strokeWeight: 2.5,
						// 	icons: [{
						// 			icon: {
						// 					path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 					strokeColor:'#ff8c52',
						// 					fillColor:'#ff8c52',
						// 					fillOpacity:1,
						// 					strokeWeight: 2
						// 					},
						// 			repeat:'100px',
						// 			path:[]
						// 		}]
						// });
						// 		}
						// 		else
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FF0202',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});

						// 		}

						// 		for (var ia = 0; ia < arr2.length; ia++) {
						// 			if(arr2[ia].speed > 0)
						// 			{
						// 				var path=line.getPath().getArray();
						// 				let latLng=new window.google.maps.LatLng(arr2[ia].lat,arr2[ia].lng);
						// 				path.push(latLng);
						// 				line.setPath(path);
						// 			}

						// 						//map.setCenter(latLng);
						// 			}

						// 		var x = map.getZoom();
						// 		var c = map.getCenter();
						// 		window.google.maps.event.trigger(map, 'resize');
						// 		map.setZoom(x);
						// 		map.setCenter(c);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
						// 	}
						// }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
									contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) })
									contentarr.push({ "key": "Break Duration", "value": timeConvert(locations[l].break_time_seconds) })
									var contentString = infoBox(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*DEALER*/
						if ((dealer.lat != '' || dealer.lat != 0) && (dealer.lng != '' || dealer.lng != 0)) {
							// console.log(dealer.lat+" ==   "+ dealer.lng);
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
								icon: require('../../assets/icons/customer_pin_38.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = ""
									contentarr.push({ "key": "", "value": dealer.name })
									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

							var markerLatlng1;
							markerLatlng1 = new window.google.maps.LatLng(dealer.lat, dealer.lng);
							//console.log("markerLatlng", markerLatlng1)
							var circle = new window.google.maps.Circle({
								strokeColor: '#71caab',
								strokeOpacity: 0.7,
								strokeWeight: 1,
								fillColor: '#71caab',
								fillOpacity: 0.25,
								map: map,
								center: markerLatlng1,
								radius: 25000
							});
							//circle.setRadius(value);
							/*
							window.google.maps.event.addListener(marker, 'click', (function(marker) {
								return function() {
									//	console.log(clusters[0])
									var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Dealer"
								contentarr.push({"key":"Code ", "value":this.state.dealerCode})
								var contentString = infoBox(marker.icon, header, contentarr,'')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
							*/
						}


						/*START ICON*/
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = infoBox(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
								var contentString = infoBox(marker.icon, header, contentarr)

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						// view markers code
						markersArray = arr1;
						// if(arr1.length > 0)
						// {
						// 	for(var a=0;a<arr1.length;a++)
						// 	{
						// 		var marker = new window.google.maps.Marker({
						// 			position: new window.google.maps.LatLng(arr1[a].lat, arr1[a].lng),
						// 			icon: {
						// 				path: window.google.maps.SymbolPath.CIRCLE,
						// 				strokeColor:'#157254',
						// 				fillColor:'#157254',
						// 				fillOpacity:1,
						// 					strokeWeight: 5,
						// 					scale:2,
						// 				},
						// 			map: map,
						// 			content:arr1[a]
						// 		});

						// 		//console.log("Arr ",arr1[a])
						// 		window.google.maps.event.addListener(marker, 'click', (function(marker) {
						// 			return function() {
						// 				//console.log("Arr ",marker)
						// 				var contentarr = []
						// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// 			var header = "Current Status"
						// 			contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
						// 			contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})

						// 			var contentString = infoBox("", header, contentarr,'')


						// 			infowindow.setContent(contentString);
						// 			currentwindow = infowindow;
						// 			infowindow.open(map, marker);
						// 			//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						// 			}
						// 		})(marker));

						// 	}
						// }

						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);

								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}

					// CONSIGNMENTS AND RAIL CONSIGNMENT MAP
					if (this.props.mapFor == 'consignment' || this.props.mapFor == 'railconsignments') {
						//console.log("arr2 ", JSON.stringify(arr2))
						//.log("asda")
						// var setonedata = []
						// var settwodata = []
						// var setthreedata = []
						// for (let s1 = 0; s1 < arr2.length; s1++) {
						// 	if(arr2[s1].set == 1)
						// 	{
						// 		setonedata.push(arr2[s1])
						// 	}
						// 	if(arr2[s1].set == 2)
						// 	{
						// 		settwodata.push(arr2[s1])
						// 	}
						// 	if(arr2[s1].set == 3)
						// 	{
						// 		setthreedata.push(arr2[s1])
						// 	}
						// }
						// console.log(setonedata,settwodata,setthreedata)
						// if(setonedata.length > 0)
						// {
						// 	var ploylin=new window.google.maps.Polyline(
						// 	{
						// 		map:map,
						// 		strokeColor: '#157254',
						// 		strokeOpacity: 1.0,
						// 		strokeWeight: 2.5,
						// 		icons: [{
						// 			icon: {
						// 					path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 					strokeColor:'#FF0000',
						// 					fillColor:'#FF0000',
						// 					fillOpacity:1,
						// 					strokeWeight: 2
						// 			},
						// 			repeat:'100px',
						// 			path:[]
						// 		}]
						// 	});
						// 	for(var ss=0;ss<setonedata.length;ss++)
						// 	{
						// 		var path=ploylin.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(setonedata[ss].lat,setonedata[ss].lng);
						// 		if(setonedata[ss].msgtype === "G")
						// 		{
						// 			console.log("set1",ploylin)
						// 			ploylin.setOptions({strokeColor:"#000"});
						// 		}

						// 		path.push(latLng);
						// 		ploylin.setPath(path);
						// 	}

						// }


						// if(settwodata.length > 0)
						// {
						// 	var ploylin=new window.google.maps.Polyline(
						// 	{
						// 		map:map,
						// 		strokeColor: '#0000ff',
						// 		strokeOpacity: 1.0,
						// 		strokeWeight: 2.5,
						// 		icons: [{
						// 			icon: {
						// 					path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 					strokeColor:'#FF0000',
						// 					fillColor:'#FF0000',
						// 					fillOpacity:1,
						// 					strokeWeight: 2
						// 			},
						// 			repeat:'100px',
						// 			path:[]
						// 		}]
						// 	});
						// 	for(var ss2=0;ss2<settwodata.length;ss2++)
						// 	{
						// 		var path=ploylin.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(settwodata[ss2].lat,settwodata[ss2].lng);
						// 		if(setonedata[ss2].msgtype === "G")
						// 		{
						// 			console.log("set2",ploylin)
						// 			ploylin.setOptions({strokeColor:"#000"});
						// 		}
						// 		path.push(latLng);
						// 		ploylin.setPath(path);
						// 	}

						// }


						// if(setthreedata.length > 0)
						// {
						// 	var ploylin=new window.google.maps.Polyline(
						// 	{
						// 		map:map,
						// 		strokeColor: '#980000',
						// 		strokeOpacity: 1.0,
						// 		strokeWeight: 2.5,
						// 		icons: [{
						// 			icon: {
						// 					path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 					strokeColor:'#FF0000',
						// 					fillColor:'#FF0000',
						// 					fillOpacity:1,
						// 					strokeWeight: 2
						// 			},
						// 			repeat:'100px',
						// 			path:[]
						// 		}]
						// 	});
						// 	for(var ss3=0;ss3<setthreedata.length;ss3++)
						// 	{
						// 		var path=ploylin.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(setthreedata[ss3].lat,setthreedata[ss3].lng);
						// 		if(setonedata[ss3].msgtype === "G")
						// 		{
						// 			console.log("set3",ploylin)
						// 			ploylin.setOptions({strokeColor:"#000"});
						// 		}
						// 		path.push(latLng);
						// 		ploylin.setPath(path);
						// 	}

						// }
						// SET WISE DATA
						this.plotMultiColorPolyline(arr2, map, bounds)


						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
									contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) })
									contentarr.push({ "key": "Break Duration", "value": timeConvert(locations[l].break_time_seconds) })
									var contentString = infoBox(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						// console.log("this.props.mapFor ",this.props.mapFor)

						markersArray = arr2


						if (this.props.mapFor == 'railconsignments') {
							// view markers code
							markersArray = arr2;
							try {
								/*Rail Loading Point*/
								//console.log("jsondata ",jsondata)
								var loadingpoint = jsondata.loading_point_coordinates;
								// console.log("loadingpoint ",loadingpoint)
								var markerl = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(loadingpoint.lat, loadingpoint.lng),
									icon: require('../../assets/icons/train.png'),
									map: map,
								});


								//var markerLatlng1;
								//markerLatlng1l = new window.google.maps.LatLng(loadingpoint.lat,loadingpoint.lng);
								//console.log("markerLatlng", markerLatlng1)
								window.google.maps.event.addListener(markerl, 'click', (function (markerl) {
									return function () {
										//	console.log(clusters[0])
										var contentarr = []
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
										var header = "Loading Point"
										contentarr.push({ "key": "Name ", "value": loadingpoint.name })
										var contentString = infoBox(markerl.icon, header, contentarr, '')

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, markerl);
										//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(markerl));
								/*END*/

								/*Rail Delivery Point*/

								var deliverpoint = jsondata.delivery_point_coordinates;
								var markerd = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(deliverpoint.lat, deliverpoint.lng),
									icon: require('../../assets/icons/traindeliver.png'),
									map: map,
								});
								window.google.maps.event.addListener(markerd, 'click', (function (markerd) {
									return function () {
										//	console.log(clusters[0])
										var contentarr = []
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
										var header = "Delivery Point"
										contentarr.push({ "key": "Name ", "value": deliverpoint.name })
										var contentString = infoBox(markerd.icon, header, contentarr, '')

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, markerd);
										//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(markerd));


								/*END*/
							}
							catch (e) {
								console.log("Error ", e)
							}
						}

						try {
							/*DEALER*/
							if ((dealer.lat != '' || dealer.lat != 0) && (dealer.lng != '' || dealer.lng != 0)) {
								// console.log(dealer.lat+" ==   "+ dealer.lng);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
									icon: require('../../assets/icons/customer_pin_38.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										//	console.log(clusters[0])
										var contentarr = []
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
										var header = "Dealer"
										contentarr.push({ "key": "", "value": dealer.name })
										var contentString = infoBox(marker.icon, header, contentarr, '')

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));

								var markerLatlng1;
								markerLatlng1 = new window.google.maps.LatLng(dealer.lat, dealer.lng);
								//console.log("markerLatlng", markerLatlng1)
								var circle = new window.google.maps.Circle({
									strokeColor: '#71caab',
									strokeOpacity: 0.7,
									strokeWeight: 1,
									fillColor: '#71caab',
									fillOpacity: 0.25,
									map: map,
									center: markerLatlng1,
									radius: 25000
								});

							}
						}
						catch (e) {
							console.log(e);
						}



						/*START ICON*/
						//console.log(arr2);
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
								icon: require('../../assets/icons/track_start.png'),
								map: map,
							});
							// console.log("plant", this.props.mapinfo.consigner_coordinates)


							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Starting Point"
									contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
									var contentString = infoBox(marker.icon, header, contentarr, '')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

							//plant icon
							let plantLat = this.props.mapinfo.consigner_coordinates.lat;
							let plantLng = this.props.mapinfo.consigner_coordinates.lng;
							let plantName = this.props.mapinfo.consigner_coordinates.name;

							let plantMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(plantLat, plantLng),
								icon: require('../../assets/icons/business.png'),
								map
							})
							window.google.maps.event.addListener(plantMarker, 'mouseover', (function (plantMarker, index) {
								return function () {
									var contentarr = []
									var header = "Plant - " + plantName
									contentarr.push({ "key": "Name", "value": plantName })
									// contentarr.push({"key":"Code", "value":consignee_code})
									var contentString = infoBox(plantMarker.icon, header, contentarr)
									infowindow.setContent(contentString);
									var currentwindow = infowindow;
									infowindow.open(map, plantMarker);
								}
							})(plantMarker,));
							window.google.maps.event.addListener(plantMarker, 'mouseout', function () {
								infowindow.close();
							});

							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
						}
						catch (e) { }

						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
								var contentString = infoBox(marker.icon, header, contentarr)

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));



						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}


					//Imports Consingments Maps
					if (this.props.mapFor == 'importsconsignment') {
						// view markers code
						markersArray = arr;

						//console.log("Arr2 ", arr)
						this.plotMultiColorPolyline(arr, map, bounds)
						// for (let i = 0; i < arr.length; i++)
						// {

						// 	var linecolr = "#157254";
						// 	line.setOptions({strokeColor: linecolr});
						// var path=line.getPath().getArray();
						// let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// path.push(latLng);
						// line.setPath(path);




						//       //Change line color based on map type
						//     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						//         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#FFFFFF',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });
						//         }
						//         else
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#157254',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });

						//         }

						//           /*for (i = 0; i < arr.length; i++) {
						//                 var path=line.getPath().getArray();
						//                 let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 				path.push(latLng);
						//       			line.setPath(path);
						//                         //map.setCenter(latLng);
						// 			  }
						// 			*/

						//          var x = map.getZoom();
						//          var c = map.getCenter();
						//          window.google.maps.event.trigger(map, 'resize');
						//          map.setZoom(x);
						// 		 map.setCenter(c);

						// 		 var path=line.getPath().getArray();
						// 		let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));

						// }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						//console.log("locations.length ", locations.length)
						var lastorigin = '';
						if (locations.length > 0) {
							for (l = 0; l < locations.length; l++) {
								if (arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lng) {
									marker = new window.google.maps.Marker({
										position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
										icon: require('../../assets/icons/gmarker.png'),
										map: map,
									});

									window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
										return function () {

											var contentarr = []
											var headerTitle = locations[l].name + ", " + locations[l].country;
											//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
											var contentStringData = '';
											if (locations[l].events.length > 0) {
												contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
												contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
												for (var e = 0; e < locations[l].events.length; e++) {
													var typemode;
													if (locations[l].events[e].type == 1) {
														typemode = 'Rail/Truck';
													}
													if (locations[l].events[e].type == 2) {
														typemode = 'Air';
													}
													if (locations[l].events[e].type == 3) {
														typemode = 'Sea';
													}
													if (locations[l].events[e].type == 4) {
														typemode = 'Rail/Truck';
													}
													var d = locations[l].events[e].event_datetime_ist;
													var dat = d.split(" ");

													contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + dat[0];
													contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";


												}
												contentStringData = contentStringData + "</tbody></table>";

											}
											contentarr.push(contentStringData);
											//lastorigin=contentString;
											//console.log("1", headerTitle)
											var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')


											infowindow.setContent(contentString);
											currentwindow = infowindow;
											infowindow.open(map, marker);
											//console.log(marker.position.toJSON());
											//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
										}
									})(marker, l));
								}
								else {
									marker = new window.google.maps.Marker({
										position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
										icon: require('../../assets/icons/start_port.png'),
										map: map,
									});

									window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
										return function () {
											//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
											var contentarr = []
											var headerTitle = locations[l].name + ", " + locations[l].country;
											var contentStringData = '';
											if (locations[l].events.length > 0) {
												contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
												contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
												for (var e = 0; e < locations[l].events.length; e++) {
													var typemode;
													if (locations[l].events[e].type == 1) {
														typemode = 'Rail/Truck';
													}
													if (locations[l].events[e].type == 2) {
														typemode = 'Air';
													}
													if (locations[l].events[e].type == 3) {
														typemode = 'Sea';
													}
													if (locations[l].events[e].type == 4) {
														typemode = 'Rail/Truck';
													}
													var d = locations[l].events[e].event_datetime_ist;
													var dat = d.split(" ");

													contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + dat[0];
													contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";

												}
												contentStringData = contentStringData + "</tbody></table>";

											}
											contentarr.push(contentStringData);
											//console.log("2", headerTitle)
											var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')

											infowindow.setContent(contentString);
											currentwindow = infowindow;
											infowindow.open(map, marker);
											//console.log(marker.position.toJSON());
											//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
										}
									})(marker, l));
								}
							}
						}
						if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
								icon: require('../../assets/icons/stop_marker.png'),
								map: map,

							});


							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//console.log("jsondata", jsondata);
									var contentarr = []
									var headerTitle = "Last known location";
									var contentStringData = '';
									var typemode;
									if (jsondata.last_event.type == 1) {
										typemode = 'Rail/Truck';
									}
									if (jsondata.last_event.type == 2) {
										typemode = 'Air';
									}
									if (jsondata.last_event.type == 3) {
										typemode = 'Sea';
									}
									if (jsondata.last_event.type == 4) {
										typemode = 'Rail/Truck';
									}
									var d = jsondata.last_event.event_datetime_ist;
									var dat = d.split(" ");

									var contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";

									contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + jsondata.last_known_time;
									contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
									contentStringData = contentStringData + "</tbody></table>";

									contentarr.push(contentStringData);
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
					}
					if (this.props.mapFor == 'importcontainerlegs') {
						markersArray = arr;

						this.plotMultiColorPolyline(arr, map, bounds)
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						// window.google.maps.event.addListener(marker, 'click', (function(marker) {
						// 	return function() {
						// 		//	console.log(clusters[0])

						// 		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// 	var contentarr = []
						// 	//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// 	var header = "Starting Point"
						// 	contentarr.push({"key":"Started at", "value":routeinfo.start_time})

						// 	var contentString = infoBox(marker.icon, header, contentarr,'')

						// 	infowindow.setContent(contentString);
						// 	currentwindow = infowindow;
						// 	infowindow.open(map, marker);
						// 	console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						// 	}
						// })(marker));
					}


					//Exports Consingments Maps
					if (this.props.mapFor == 'exportsconsignment') {

						// view markers code
						markersArray = arr;

						this.plotMultiColorPolyline(arr, map, bounds)
						//   for (let i = 0; i < arr.length; i++) {

						// 	var linecolr = "#157254";
						// 	// if(arr[i].speed > 0)
						// 	// {
						// 	// 	var linecolr = "#157254";
						// 	// if(arr[i].set  == 2)
						// 	// {
						// 	// 	linecolr = "#0000ff";
						// 	// }
						// 	// if(arr[i].set  == 3)
						// 	// {
						// 	// 	linecolr = "#980000"
						// 	// }
						// 	line.setOptions({strokeColor: linecolr});
						// 		var path=line.getPath().getArray();
						// 		let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);




						//       //Change line color based on map type
						//     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						//         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#FFFFFF',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });
						//         }
						//         else
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#157254',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });

						//         }

						//           /*for (i = 0; i < arr.length; i++) {
						//                 var path=line.getPath().getArray();
						//                 let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 				path.push(latLng);
						//       			line.setPath(path);
						//                         //map.setCenter(latLng);
						// 			  }
						// 			*/

						//          var x = map.getZoom();
						//          var c = map.getCenter();
						//          window.google.maps.event.trigger(map, 'resize');
						//          map.setZoom(x);
						// 		 map.setCenter(c);

						// 		 var path=line.getPath().getArray();
						// 		let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));

						//   }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";

									contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
									contentarr.push({ "key": "Break End", "value": locations[l].break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })


									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));

					}

					/*GEOFENCE MAP*/
					if (this.props.mapFor == 'geofence' || this.props.mapFor == 'truckgps') {
						markersArray = arr2;
						var routeinfo = jsondata.route_details;
						this.plotMultiColorPolyline(arr2, map, bounds)
						// for (let i = 0; i < arr2.length; i++)
						// {
						// 	//if(arr2[i].speed > 0)
						// 	//{
						// 		var linecolr = "#157254";
						// 		if(arr2[i].set  == 2)
						// 		{
						// 			linecolr = "#0000ff";
						// 		}
						// 		if(arr2[i].set  == 3)
						// 		{
						// 			linecolr = "#980000"
						// 		}
						// 		line.setOptions({strokeColor: linecolr});
						// 	var path=line.getPath().getArray();

						// 	let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 	path.push(latLng);
						// 	line.setPath(path);

						// 	//Change line color based on map type
						// 	window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 		if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FFFFFF',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});
						// 		}
						// 		else
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#157254',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});

						// 		}

						// 		for (i = 0; i < arr2.length; i++) {
						// 				var path=line.getPath().getArray();
						// 				let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 				path.push(latLng);
						// 				line.setPath(path);
						// 						//map.setCenter(latLng);
						// 			}

						// 		var x = map.getZoom();
						// 		var c = map.getCenter();
						// 		window.google.maps.event.trigger(map, 'resize');
						// 		map.setZoom(x);
						// 		map.setCenter(c);
						// 	} );
						// 	bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
						// 	//}
						// }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";
									try {
										if (typeof this.props.mapFor != 'undefined') {
											if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											}
											else {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											}
										}
										else {
											contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
											contentarr.push({ "key": "Break End", "value": locations[l].break_end })
											contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
										}
									}
									catch (e) {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
									}
									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })
								var contentString = infoBox(marker.icon, header, contentarr, '')
								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));

						// view markers code
						try{
							//plant icon
							let plantLat = JSON.parse(this.props.mapinfo.consigner_coordinates).lat;
							let plantLng = JSON.parse(this.props.mapinfo.consigner_coordinates).lng;

							let plantMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(plantLat, plantLng),
								icon: require('../../assets/icons/business.png'),
								map
							})
							window.google.maps.event.addListener(plantMarker, 'mouseover', (function (plantMarker, index) {
								return function () {
									var contentarr = []
									var header = "Plant"
									// contentarr.push({"key":"Code", "value":consignee_code})
									var contentString = infoBox(plantMarker.icon, header, contentarr)
									infowindow.setContent(contentString);
									var currentwindow = infowindow;
									infowindow.open(map, plantMarker);
								}
							})(plantMarker,));
							window.google.maps.event.addListener(plantMarker, 'mouseout', function () {
								infowindow.close();
							});
							
							// if(locations.length > 0)
							// {
								//dealer icon
								let dealerLat = JSON.parse(this.props.mapinfo.consignee_coordinates).lat;
								let dealerLng = JSON.parse(this.props.mapinfo.consignee_coordinates).lng;
								let dealerMarker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(dealerLat, dealerLng),
									icon: require('../../assets/icons/customer_pin_38.png'),
									map
								})
								window.google.maps.event.addListener(dealerMarker, 'mouseover', (function (dealerMarker, index) {
									return function () {
										var contentarr = []
										var header = ""
										contentarr.push({"key":"", "value":"Dealer"})
										var contentString = infoBox(dealerMarker.icon, header, contentarr)
										infowindow.setContent(contentString);
										var currentwindow = infowindow;
										infowindow.open(map, dealerMarker);
									}
								})(dealerMarker,));
								window.google.maps.event.addListener(dealerMarker, 'mouseout', function () {
									infowindow.close();
								});
							// }							
						}
						catch(e){
							console.log("marker issue", e)
						}

					}

					/*Multi route deviation MAP*/
					if (this.props.mapFor == 'multiroutedeviation') {
						markersArray = arr2;
						var routeinfo = jsondata.route_details;
						plottedPolylines = [];
						// console.log(arr, "arrCheck")				
						arr2.map((e, index)=>{
							this.plotMultiColorPolyline(e, map, bounds,index)
						})
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						var z;
						// console.log("arr",arr)
						// console.log("this.props.mapinfo.coords",this.props.mapinfo.coords)
						for(z=0;z<deviationArr.length;z++)
						{
							// var locations = this.props.mapinfo.coords[z]['breaks']
							var route_wise_coords = deviationArr
							// for (l = 0; l < locations.length; l++) {
							// 	marker = new window.google.maps.Marker({
							// 		position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
							// 		icon: require('../../assets/icons/cf.png'),
							// 		map: map,
							// 	});
	
							// 	window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
							// 		return function () {
							// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
	
							// 			var contentarr = []
							// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							// 			var header = "Break";
							// 			try {
							// 				if (typeof this.props.mapFor != 'undefined') {
							// 					if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
							// 						contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
							// 						contentarr.push({ "key": "Break End", "value": locations[l].break_end })
							// 						contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
							// 					}
							// 					else {
							// 						contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
							// 						contentarr.push({ "key": "Break End", "value": locations[l].break_end })
							// 						contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
							// 					}
							// 				}
							// 				else {
							// 					contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
							// 					contentarr.push({ "key": "Break End", "value": locations[l].break_end })
							// 					contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
							// 				}
							// 			}
							// 			catch (e) {
							// 				contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
							// 				contentarr.push({ "key": "Break End", "value": locations[l].break_end })
							// 				contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
							// 			}	
							// 			var contentString = infoBox(marker.icon, header, contentarr, '')
	
							// 			infowindow.setContent(contentString);
							// 			currentwindow = infowindow;
							// 			infowindow.open(map, marker);
							// 			//console.log(marker.position.toJSON());
							// 			// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							// 		}
							// 	})(marker, l));
							// }
							// start location
							// console.log("route_ise",route_wise_coords)
							if(route_wise_coords !== undefined){
								if(route_wise_coords[0].lat !== undefined && route_wise_coords[0].lng !== undefined ){
									marker = new window.google.maps.Marker({
										position: new window.google.maps.LatLng(route_wise_coords[0].lat, route_wise_coords[0].lng),
										icon: require('../../assets/icons/track_start.png'),
										map: map,
									});
								}
								// console.log("marker",marker)
								if(marker !== undefined){
									window.google.maps.event.addListener(marker, 'click', (function (marker) {
										// console.log("checking")
										return function () {
											//	console.log(clusters[0])	
											//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
											var contentarr = []
											//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
											var header = "Starting Point"
											contentarr.push({ "key": "Started at", "value": routeinfo.start_time })		
											var contentString = infoBox(marker.icon, header, contentarr, '')		
											infowindow.setContent(contentString);
											currentwindow = infowindow;
											infowindow.open(map, marker);
											// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
										}
									})(marker));
								}
								// end location
								// console.log("route_wise_coords",route_wise_coords[(route_wise_coords.length - 1)])
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(route_wise_coords[0][(route_wise_coords[0].length - 1)].lat,
																								route_wise_coords[0][(route_wise_coords[0].length - 1)].lng),
									icon: require('../../assets/icons/truck-end.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										//	console.log(clusters[0])
	
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
										var contentarr = []
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
										var header = "End Point"
										contentarr.push({ "key": "End at", "value": routeinfo.end_time })	
										var contentString = infoBox(marker.icon, header, contentarr, '')	
										infowindow.setContent(contentString);
										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						// view markers code
						try{
							//plant icon
							let plantLat = JSON.parse(this.props.mapinfo.consigner_coordinates).lat;
							let plantLng = JSON.parse(this.props.mapinfo.consigner_coordinates).lng;

							let plantMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(plantLat, plantLng),
								icon: require('../../assets/icons/business.png'),
								map
							})
							window.google.maps.event.addListener(plantMarker, 'mouseover', (function (plantMarker, index) {
								return function () {
									var contentarr = []
									var header = "Plant"
									// contentarr.push({"key":"Code", "value":consignee_code})
									var contentString = infoBox(plantMarker.icon, header, contentarr)
									infowindow.setContent(contentString);
									var currentwindow = infowindow;
									infowindow.open(map, plantMarker);
								}
							})(plantMarker,));
							window.google.maps.event.addListener(plantMarker, 'mouseout', function () {
								infowindow.close();
							});
							
							//dealer icon
							this.props.mapinfo.consignee_coordinates.map((q)=>{
								let dealerLat = JSON.parse(q).lat;
								let dealerLng = JSON.parse(q).lng;
	
								let dealerMarker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(dealerLat, dealerLng),
									icon: require('../../assets/icons/business.png'),
									map
								})
								window.google.maps.event.addListener(dealerMarker, 'mouseover', (function (dealerMarker, index) {
									return function () {
										var contentarr = []
										var header = ""
										contentarr.push({"key":"", "value":"Dealer"})
										var contentString = infoBox(dealerMarker.icon, header, contentarr)
										infowindow.setContent(contentString);
										var currentwindow = infowindow;
										infowindow.open(map, dealerMarker);
									}
								})(dealerMarker,));
								window.google.maps.event.addListener(dealerMarker, 'mouseout', function () {
									infowindow.close();
								});
							})

						}
						catch(e){
							console.log("marker issue", e)
						}
					}

					if (this.props.mapFor == 'tpttruck') {
						markersArray = arr2;
						var routeinfo = jsondata.route_details;
						this.plotMultiColorPolyline(arr2, map, bounds)

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";
									try {
										if (typeof this.props.mapFor != 'undefined') {
											if (this.props.mapFor == 'tpttruck') {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
												contentarr.push({ "key": "Address", "value": locations[l].break_address })
												contentarr.push({ "key": "City", "value": locations[l].city })
												contentarr.push({ "key": "State", "value": locations[l].break_state })
											}
											else {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
												contentarr.push({ "key": "Address", "value": locations[l].break_address })
												contentarr.push({ "key": "City", "value": locations[l].city })
												contentarr.push({ "key": "State", "value": locations[l].break_state })
											}
										}
										else {
											contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
											contentarr.push({ "key": "Break End", "value": locations[l].break_end })
											contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											contentarr.push({ "key": "Address", "value": locations[l].break_address })
											contentarr.push({ "key": "City", "value": locations[l].city })
											contentarr.push({ "key": "State", "value": locations[l].break_state })
										}
									}
									catch (e) {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
										contentarr.push({ "key": "Address", "value": locations[l].break_address })
										contentarr.push({ "key": "City", "value": locations[l].city })
										contentarr.push({ "key": "State", "value": locations[l].break_state })
									}


									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));

						// view markers code



					}

					if (this.props.mapFor == 'tpttruckgps') {
						markersArray = arr2;
						var routeinfo = jsondata.route_details;
						this.plotMultiColorPolyline(arr2, map, bounds)

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";
									try {
										if (typeof this.props.mapFor != 'undefined') {
											if (this.props.mapFor == 'tpttruckgps') {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
												contentarr.push({ "key": "Address", "value": locations[l].break_address })
												contentarr.push({ "key": "City", "value": locations[l].city })
												contentarr.push({ "key": "State", "value": locations[l].break_state })
											}
											else {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
												contentarr.push({ "key": "Address", "value": locations[l].break_address })
												contentarr.push({ "key": "City", "value": locations[l].city })
												contentarr.push({ "key": "State", "value": locations[l].break_state })
											}
										}
										else {
											contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
											contentarr.push({ "key": "Break End", "value": locations[l].break_end })
											contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											contentarr.push({ "key": "Address", "value": locations[l].break_address })
											contentarr.push({ "key": "City", "value": locations[l].city })
											contentarr.push({ "key": "State", "value": locations[l].break_state })
										}
									}
									catch (e) {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
										contentarr.push({ "key": "Address", "value": locations[l].break_address })
										contentarr.push({ "key": "City", "value": locations[l].city })
										contentarr.push({ "key": "State", "value": locations[l].break_state })
									}


									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));

						// view markers code



					}

					/*LOAD Attachment MAP*/
					if (this.props.mapFor == 'loadattach') {
						// view markers code
						markersArray = arr2;
						this.plotMultiColorPolyline(arr2, map, bounds)
						// var routeinfo = jsondata.route_details;

						// 	for (let i = 0; i < arr2.length; i++) {
						// 		//if(arr2[i].speed > 0)
						// 			//{
						// 			var linecolr = "#157254";
						// 			if(arr2[i].set  == 2)
						// 			{
						// 				linecolr = "#0000ff";
						// 			}
						// 			if(arr2[i].set  == 3)
						// 			{
						// 				linecolr = "#980000"
						// 			}
						// 			line.setOptions({strokeColor: linecolr});
						// 		var path=line.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);

						// 		//Change line color based on map type
						// 		window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 			if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 			{
						// 				var line=new window.google.maps.Polyline(
						// 				{
						// 					map:map,
						// 					strokeColor: '#FFFFFF',
						// 					strokeOpacity: 1.0,
						// 					strokeWeight: 2.5,
						// 					icons: [{
						// 							icon: {
						// 									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 									strokeColor:'#ff8c52',
						// 									fillColor:'#ff8c52',
						// 									fillOpacity:1,
						// 									strokeWeight: 2
						// 									},
						// 							repeat:'100px',
						// 							path:[]
						// 						}]
						// 					});
						// 			}
						// 			else
						// 			{
						// 				var line=new window.google.maps.Polyline(
						// 				{
						// 					map:map,
						// 					strokeColor: '#157254',
						// 					strokeOpacity: 1.0,
						// 					strokeWeight: 2.5,
						// 					icons: [{
						// 							icon: {
						// 									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 									strokeColor:'#ff8c52',
						// 									fillColor:'#ff8c52',
						// 									fillOpacity:1,
						// 									strokeWeight: 2
						// 									},
						// 							repeat:'100px',
						// 							path:[]
						// 						}]
						// 					});

						// 			}

						// 			for (i = 0; i < arr2.length; i++) {
						// 					var path=line.getPath().getArray();
						// 					let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 					path.push(latLng);
						// 					line.setPath(path);
						// 							//map.setCenter(latLng);
						// 				}

						// 			var x = map.getZoom();
						// 			var c = map.getCenter();
						// 			window.google.maps.event.trigger(map, 'resize');
						// 			map.setZoom(x);
						// 			map.setCenter(c);
						// 		} );
						// 		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
						// 		//}
						// 	}

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							// console.log(locations,"loads")
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";
									try {
										if (typeof this.props.mapFor != 'undefined') {
											if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": secondsToString(locations[l].break_time_seconds) })
											}
											else {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": secondsToString(locations[l].break_time_seconds) })
											}
										}
										else {
											contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
											contentarr.push({ "key": "Break End", "value": locations[l].break_end })
											contentarr.push({ "key": "Break time", "value": secondsToString(locations[l].break_time_seconds) })
										}
									}
									catch (e) {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": secondsToString(locations[l].break_time_seconds) })
									}


									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));


					}

					// TIKCETS MAP
					if (this.props.mapFor == 'ticket') {
						// view markers code
						markersArray = arr2;
						this.plotMultiColorPolyline(arr, map, bounds)
						// for (let i = 0; i < arr.length; i++)
						// {

						// 	var linecolr = "#157254";
						// 	if(arr[i].set  == 2)
						// 	{
						// 		linecolr = "#0000ff";
						// 	}
						// 	if(arr[i].set  == 3)
						// 	{
						// 		linecolr = "#980000"
						// 	}
						// 	line.setOptions({strokeColor: linecolr});
						// 		var path=line.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);

						// 	//console.log("Check ",pointertime[arr[i].stime]);

						// 	//Change line color based on map type
						// 	window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 		if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FF0202',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});
						// 		}
						// 		else
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FF0202',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});

						// 		}

						// 		for (var ia = 0; ia < arr.length; ia++) {
						// 			if(arr[ia].speed > 0)
						// 			{
						// 				var path=line.getPath().getArray();
						// 				let latLng=new window.google.maps.LatLng(arr[ia].lat,arr[ia].lng);
						// 				path.push(latLng);
						// 				line.setPath(path);
						// 			}

						// 						//map.setCenter(latLng);
						// 			}

						// 		var x = map.getZoom();
						// 		var c = map.getCenter();
						// 		window.google.maps.event.trigger(map, 'resize');
						// 		map.setZoom(x);
						// 		map.setCenter(c);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));

						// }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
									contentarr.push({ "key": "Break End", "value": locations[l].break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
									var contentString = infoBox(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*DEALER*/
						if (this.props.consignercords != undefined) {
							if ((this.props.consignercords.lat != '' || this.props.consignercords.lat != 0) && (this.props.consignercords.lng != '' || this.props.consignercords.lng != 0)) {
								// console.log(this.props.consignercords.lat+" ==   "+ this.props.consignercords.lng);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(this.props.consignercords.lat, this.props.consignercords.lng),
									icon: require('../../assets/icons/business.png'),
									map: map,
								});

							}
						}


						if (this.props.consigneecords != undefined) {
							if (this.props.consigneecords.length > 0) {
								var consigneecrds = this.props.consigneecords;
								consigneecrds.map((item) => {
									//console.log("Coords itm ", item)
									try {
										//console.log(typeof(item))
										var coordinate = JSON.parse(item);
									}
									catch (e) {
										var coordinate = item;
									}
									if (coordinate.lat != 0 && coordinate.lng != 0) {
										marker = new window.google.maps.Marker({
											position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
											icon: require('../../assets/icons/customer_pin_38.png'),
											map: map,
										});

									}

								});

							}
						}


						/*START ICON*/
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = infoBox(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
									var contentString = infoBox(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) {

						}
						markersArray = arr1;

						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							//console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}
					// LOAD MANAGEMENT
					if (this.props.mapFor == "load_management") {
						// console.log(arr,"arr")

						if (this.props.mapType == 1) {
							this.plotMultiColorPolyline(arr, map, bounds);
						}
						arr.map(function (e) {
							var oemMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(e.lat, e.lng),
								icon: require('../../assets/icons/business.png'),
								map: map,
							});
						})
						map.setZoom(5);
					}

					// TRUCK MAP
					if (this.props.mapFor == 'truck' || this.props.mapFor == 'returntrips'
						|| this.props.mapFor == "returntripsdashboard") {
						this.plotMultiColorPolyline(arr2, map, bounds)
						if (competitor_geofences != undefined) {
							// competitor locations
							var compinfowindow = new window.google.maps.InfoWindow();
							var l;
							competitor_geofences.map(function (e, index) {
								var oemMarker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(e.centroid_lat, e.centroid_lng),
									icon: require('../../assets/icons/business.png'),
									map: map,
								});
								window.google.maps.event.addListener(oemMarker, 'mouseover', (function (oemMarker, index) {
									return function () {
										var contentarr = []
										var header = "OEM"
										contentarr.push({ "key": "Name", "value": e.geofence_name })
										var contentString = infoBox(oemMarker.icon, header, contentarr)
										compinfowindow.setContent(contentString);
										var currentwindow = compinfowindow;
										compinfowindow.open(map, oemMarker);
									}
								})(oemMarker, index));
								window.google.maps.event.addListener(oemMarker, 'mouseout', function () {
									compinfowindow.close();
								});
							})
							// competitor_geofences breaks clusters
						}

						var boundaries = this.props.boundaries
						try {
							if ((this.props.mapFor == 'returntrips') && (boundaries != undefined)) {
								this.plotBoundaryLines(boundaries);
							}

						}
						catch (e) {
							console.log(e)
						}

						// for (let i = 0; i < arr2.length; i++) {
						// 	if(arr2[i].speed > 0)
						// 	{
						// 		var path=line.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);

						// 	//console.log("Check ",pointertime[arr[i].stime]);

						// 	//Change line color based on map type
						// 	window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 		if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FF0202',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});
						// 		}
						// 		else
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FF0202',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 				});

						// 		}

						// 		for (var ia = 0; ia < arr2.length; ia++) {
						// 			if(arr2[ia].speed > 0)
						// 			{
						// 				var path=line.getPath().getArray();
						// 				if(arr2[ia].lat !=0 && arr2[ia].lng != 0)
						// 				{
						// 					let latLng=new window.google.maps.LatLng(arr2[ia].lat,arr2[ia].lng);
						// 					path.push(latLng);
						// 					line.setPath(path);
						// 				}

						// 			}

						// 						//map.setCenter(latLng);
						// 			}

						// 		var x = map.getZoom();
						// 		var c = map.getCenter();
						// 		window.google.maps.event.trigger(map, 'resize');
						// 		map.setZoom(x);
						// 		map.setCenter(c);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
						// 	}
						// 	// view markers code

						// }
						markersArray = arr2;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
									contentarr.push({ "key": "Break End", "value": locations[l].break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

									var contentString = infoBox(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						if (this.props.consignercords != undefined) {
							if (this.props.consignercords.length > 0) {
								var consignercords = this.props.consignercords;
								consignercords.map((item) => {
									//console.log("Coords itm ", item)
									try {
										//console.log(typeof(item))
										var coordinate = JSON.parse(item);
									}
									catch (e) {
										var coordinate = item;
									}
									if (coordinate.lat != 0 && coordinate.lng != 0) {
										marker = new window.google.maps.Marker({
											position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
											icon: require('../../assets/icons/business.png'),
											map: map,
										});
										window.google.maps.event.addListener(marker, 'click', (function (marker) {
											return function () {
												//	console.log(clusters[0])
												var contentarr = []
												//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
												var header = "Plant"
												contentarr.push({ "key": " ", "value": coordinate.name })
												var contentString = infoBox(marker.icon, header, contentarr, '')


												infowindow.setContent(contentString);
												currentwindow = infowindow;
												infowindow.open(map, marker);
												//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
											}
										})(marker));

									}


									// var markerLatlng1;
									// markerLatlng1 = new window.google.maps.LatLng(coordinate.lat,coordinate.lng);
									// //console.log("markerLatlng", markerLatlng1)
									// var circle = new window.google.maps.Circle({
									// 	strokeColor: '#71caab',
									// 	strokeOpacity: 0.7,
									// 	strokeWeight: 1,
									// 	fillColor: '#71caab',
									// 	fillOpacity: 0.25,
									// 	map: map,
									// 	center: markerLatlng1,
									// 	radius: 25000
									// });
								});

							}
						}

						//Consignees
						//console.log("this.props.consigneecords ", this.props.consigneecords)
						if (this.props.consigneecords != undefined) {
							if (this.props.consigneecords.length > 0) {
								var consigneecrds = this.props.consigneecords;
								consigneecrds.map((item) => {
									//console.log("Coords itm ", item)
									try {
										//console.log(typeof(item))
										var coordinate = JSON.parse(item);
									}
									catch (e) {
										var coordinate = item;
									}
									//console.log("consigneecords coordinate ", coordinate)
									if (coordinate.lat != 0 && coordinate.lng != 0) {
										marker = new window.google.maps.Marker({
											position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
											icon: require('../../assets/icons/customer_pin_38.png'),
											map: map,
										});
										window.google.maps.event.addListener(marker, 'click', (function (marker) {
											return function () {
												//	console.log(clusters[0])
												var contentarr = []
												//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
												var header = "Consignee"
												contentarr.push({ "key": " ", "value": coordinate.name })
												var contentString = infoBox(marker.icon, header, contentarr, '')


												infowindow.setContent(contentString);
												currentwindow = infowindow;
												infowindow.open(map, marker);
												//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
											}
										})(marker));
									}


									// var markerLatlng1;
									// markerLatlng1 = new window.google.maps.LatLng(coordinate.lat,coordinate.lng);
									// //console.log("markerLatlng", markerLatlng1)
									// var circle = new window.google.maps.Circle({
									// 	strokeColor: '#71caab',
									// 	strokeOpacity: 0.7,
									// 	strokeWeight: 1,
									// 	fillColor: '#71caab',
									// 	fillOpacity: 0.25,
									// 	map: map,
									// 	center: markerLatlng1,
									// 	radius: 25000
									// });
								});

							}
						}


						/*START ICON*/
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = infoBox(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
									var contentString = infoBox(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						// if(arr1.length > 0)
						// {

						// 	//console.log("allpoints ", allpoints)

						// 	for(var a=0;a<arr1.length;a++)
						// 	{
						// 		var marker = new window.google.maps.Marker({
						// 			position: new window.google.maps.LatLng(arr1[a].lat, arr1[a].lng),
						// 			icon: {
						// 				path: window.google.maps.SymbolPath.CIRCLE,
						// 				strokeColor:'#157254',
						// 				fillColor:'#157254',
						// 				fillOpacity:1,
						// 					strokeWeight: 3,
						// 					scale:2,
						// 				},
						// 			map: map,
						// 			content:arr1[a]
						// 		});

						// 		//console.log("Arr ",arr1[a])
						// 		window.google.maps.event.addListener(marker, 'click', (function(marker) {
						// 			return function() {
						// 				//console.log("Arr ",marker)
						// 				var contentarr = []
						// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						// 			var header = "Current Status"
						// 			contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
						// 			contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})

						// 			var contentString = infoBox("", header, contentarr,'')


						// 			infowindow.setContent(contentString);
						// 			currentwindow = infowindow;
						// 			infowindow.open(map, marker);
						// 			//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						// 			}
						// 		})(marker));

						// 	}
						// }

						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}

					// CONSIGNMENT SIDEBAR MAP
					if (this.props.mapFor == 'consignemntSidebarMap') {
						// view markers code
						markersArray = arr2;
						this.plotMultiColorPolyline(arr2, map, bounds)
						//   for (let i = 0; i < arr2.length; i++) {
						// 	  if(arr2[i].speed > 0)
						// 	  {
						// 		var linecolr = "#157254";
						// 		if(arr2[i].set  == 2)
						// 		{
						// 			linecolr = "#0000ff";
						// 		}
						// 		if(arr2[i].set  == 3)
						// 		{
						// 			linecolr = "#980000"
						// 		}
						// 		line.setOptions({strokeColor: linecolr});
						// 		var path=line.getPath().getArray();

						// 		let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
						// 		path.push(latLng);
						// 		line.setPath(path);

						// 	//console.log("Check ",pointertime[arr[i].stime]);

						//       //Change line color based on map type
						//     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						//         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#FF0202',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });
						//         }
						//         else
						//         {
						//             var line=new window.google.maps.Polyline(
						//               {
						//                 map:map,
						//                 strokeColor: '#FF0202',
						//                 strokeOpacity: 1.0,
						//                 strokeWeight: 2.5,
						//                 icons: [{
						//                           icon: {
						//                                   path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						//                                   strokeColor:'#ff8c52',
						//                                   fillColor:'#ff8c52',
						//                                   fillOpacity:1,
						//                                   strokeWeight: 2
						//                                 },
						//                           repeat:'100px',
						//                           path:[]
						//                        }]
						//                  });

						//         }

						//           for (var ia = 0; ia < arr2.length; ia++) {
						// 			  if(arr2[ia].speed > 0)
						// 			  {
						// 				var path=line.getPath().getArray();
						//                 let latLng=new window.google.maps.LatLng(arr2[ia].lat,arr2[ia].lng);
						// 				path.push(latLng);
						//       			line.setPath(path);
						// 			  }

						//                         //map.setCenter(latLng);
						//               }

						//          var x = map.getZoom();
						//          var c = map.getCenter();
						//          window.google.maps.event.trigger(map, 'resize');
						//          map.setZoom(x);
						//          map.setCenter(c);
						// 	} );

						// 	bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
						// 	}
						//   }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
									contentarr.push({ "key": "Break End", "value": locations[l].break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
									var contentString = infoBox(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*DEALER*/
						if ((dealer.lat != '' || dealer.lat != 0) && (dealer.lng != '' || dealer.lng != 0)) {
							// console.log(dealer.lat+" ==   "+ dealer.lng);
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
								icon: require('../../assets/icons/customer_pin_38.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = ""
									contentarr.push({ "key": "", "value": dealer.name })
									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

							var markerLatlng1;
							markerLatlng1 = new window.google.maps.LatLng(dealer.lat, dealer.lng);
							//console.log("markerLatlng", markerLatlng1)
							var circle = new window.google.maps.Circle({
								strokeColor: '#71caab',
								strokeOpacity: 0.7,
								strokeWeight: 1,
								fillColor: '#71caab',
								fillOpacity: 0.25,
								map: map,
								center: markerLatlng1,
								radius: 25000
							});
							//circle.setRadius(value);
							/*
							window.google.maps.event.addListener(marker, 'click', (function(marker) {
								return function() {
									//	console.log(clusters[0])
									var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Dealer"
								contentarr.push({"key":"Code ", "value":this.state.dealerCode})
								var contentString = infoBox(marker.icon, header, contentarr,'')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
							*/
						}


						/*START ICON*/
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = infoBox(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
								var contentString = infoBox(marker.icon, header, contentarr)

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						//view markers code
						markersArray = arr2;


						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}


					//Rake Schedule Maps
					if (this.props.mapFor == 'rakeschedule') {
						// view markers code
						markersArray = arr;

						var routeinfo = jsondata.route_details;
						this.plotMultiColorPolyline(arr, map, bounds)
						// for (let i = 0; i < arr.length; i++) {
						// 	//if(arr2[i].speed > 0)
						// 		//{
						// 		var linecolr = "#157254";
						// 		if(arr[i].set  == 2)
						// 		{
						// 			linecolr = "#0000ff";
						// 		}
						// 		if(arr[i].set  == 3)
						// 		{
						// 			linecolr = "#980000"
						// 		}
						// 		line.setOptions({strokeColor: linecolr});
						// 	var path=line.getPath().getArray();

						// 	let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 	path.push(latLng);
						// 	line.setPath(path);

						// 	//Change line color based on map type
						// 	window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
						// 		if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
						// 		{
						// 			var line=new window.google.maps.Polyline(
						// 			{
						// 				map:map,
						// 				strokeColor: '#FFFFFF',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 			});
						// 		}
						// 		else
						// 		{
						// 			var line=new window.google.maps.Polyline({
						// 				map:map,
						// 				strokeColor: '#157254',
						// 				strokeOpacity: 1.0,
						// 				strokeWeight: 2.5,
						// 				icons: [{
						// 						icon: {
						// 								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 								strokeColor:'#ff8c52',
						// 								fillColor:'#ff8c52',
						// 								fillOpacity:1,
						// 								strokeWeight: 2
						// 								},
						// 						repeat:'100px',
						// 						path:[]
						// 					}]
						// 			});

						// 		}

						// 		for (i = 0; i < arr.length; i++) {
						// 				var path=line.getPath().getArray();
						// 				let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
						// 				path.push(latLng);
						// 				line.setPath(path);
						// 						//map.setCenter(latLng);
						// 			}

						// 		var x = map.getZoom();
						// 		var c = map.getCenter();
						// 		window.google.maps.event.trigger(map, 'resize');
						// 		map.setZoom(x);
						// 		map.setCenter(c);
						// 	} );
						// 	bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
						// 	//}
						// }

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Break";
									try {
										if (typeof this.props.mapFor != 'undefined') {
											if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											}
											else {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

											}
										}
										else {
											contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
											contentarr.push({ "key": "Break End", "value": locations[l].break_end })
											contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

										}
									}
									catch (e) {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

									}


									var contentString = infoBox(marker.icon, header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
							icon: require('../../assets/icons/truck-end.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])

								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "End Point"
								contentarr.push({ "key": "End at", "value": routeinfo.end_time })

								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));

						/*hreer*/

						try {
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(loading_point_details.lat, loading_point_details.lng),
								icon: require('../../assets/icons/loadingyard.png'),
								map: map,
								content: loading_point_details.name
							});

							//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//console.log("Arr ",marker)
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Loading Yard"
									contentarr.push({ "key": "Name ", "value": marker.content })

									var contentString = infoBox("", header, contentarr, '')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) {

						}
						try {
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(delivery_point_details.lat, delivery_point_details.lng),
								icon: require('../../assets/icons/unloadingyard.png'),
								map: map,
								content: delivery_point_details.name
							});

							//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//console.log("Arr ",marker)
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Destination Yard"
									contentarr.push({ "key": "Name ", "value": marker.content })

									var contentString = infoBox("", header, contentarr, '')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) {

						}

					}

					//Deviation Route

					if (this.props.mapFor == "deviation") {
						//console.log("this.props.plantcoords ", this.props.plantcoords)
						if (this.props.plantcoords.coord != undefined && this.props.plantcoords.coord != "") {

							if ((this.props.plantcoords.coord.lat != '' || this.props.plantcoords.coord.lat != 0)
								&& (this.props.plantcoords.coord.lng != '' || this.props.plantcoords.coord.lng != 0)) {
								//console.log(this.props.consignercords.lat+" ==   "+ this.props.consignercords.lng);
								var marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(this.props.plantcoords.coord.lat, this.props.plantcoords.coord.lng),
									icon: require('../../assets/icons/business.png'),
									map: map,
								});
								// console.log("this.props.plantcoords.name ", this.props.plantcoords.name)
								var plantname = this.props.plantcoords.name
								window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
									return function () {
										//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
										var contentarr = []
										var header = "Plant"
										contentarr.push({ "key": "Location Name", "value": plantname })
										var contentString = infoBox(marker.icon, header, contentarr)
										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										//console.log(marker.position.toJSON());
										//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker, l));



							}
						}
						var routes = this.props.mapinfo.routes;
						// var routecnt = this.props.mainfo.routecnt;
						// var routes = []
						// for(var i =0; i<routecnt;i++)
						// {
						// 	routes.push(allroutes[i])
						// }
						// console.log("routes ", routes)
						if (routes.length > 0) {
							var routeinfo = [];
							//console.log(coords_list,"coords_list")
							// this.plotMultiColorPolyline(arr,map,bounds)
							var bounds = new window.google.maps.LatLngBounds();
							var checkedlist = this.props.checkedlist;
							//console.log("checkedlist ", checkedlist)
							if (checkedlist.length > 0) {
								// var schecked = []
								// checkedlist.map((item) => {
								// 	schecked.push({c:item})
								// })
								// let newArray = [];
								// // Declare an empty object
								// let totuniqueObject = {};
								// var totobjTitle;
								// // Loop for the array elements
								// for (let i in schecked) {

								// 	// Extract the title
								// 	totobjTitle = schecked[i]['c'];

								// 	// Use the title as the index
								// 	totuniqueObject[totobjTitle] = schecked[i];
								// }

								// // Loop to push unique object into array
								// for (var a in totuniqueObject) {
								// 	newArray.push(totuniqueObject[a].c);
								// }
								// console.log("newArray ", newArray)
								// if(newArray.length > 0)
								// {
								// 	newArray.map((item,index)=>{
								// 		console.log("index ", index)
								// 		console.log("route_deviation_polyline_dict[index+1] ", route_deviation_polyline_dict[index+1])
								// 		this.setRouteDeviationPathVisibility.bind(this,route_deviation_polyline_dict[index+1])
								// 	})
								// }
								//	this.setRouteDeviationPathVisibility(route_deviation_polyline_dict[index+1])
							}

							routes.map(function (coord, index) {
								var normalroute = coord.filter((e) => e.city_center_coords == undefined)
								var missing_coords = coord.filter((e) => e.city_center_coords == 1)
								route_deviation_polyline_dict[index + 1] = new window.google.maps.Polyline({
									map: map,
									strokeColor: pathcolor[index],
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									// icons: [{
									// 		icon: {
									// 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									// 				strokeColor:'#ff8c52',
									// 				fillColor:'#ff8c52',
									// 				fillOpacity:1,
									// 				strokeWeight: 2
									// 				},
									// 		repeat:'500px',
									// 		path:[]
									// 	}]
								});
								for (var i = 0; i < normalroute.length; i++) {
									var route_deviation_path = route_deviation_polyline_dict[index + 1].getPath().getArray();
									let latLng = new window.google.maps.LatLng(normalroute[i].lat, normalroute[i].lng);
									bounds.extend(new window.google.maps.LatLng(normalroute[i].lat, normalroute[i].lng));
									route_deviation_path.push(latLng);
									route_deviation_polyline_dict[index + 1].setPath(route_deviation_path);
									// if(coord[i].city_center_coords == 1)
									// {
									// 	console.log(coord[i].city_center_coords, "set options")
									// 	route_deviation_polyline_dict[index+1].setOptions({
									// 		strokeColor : "yellow",
									// 		strokeWeight: 5.5,
									// 	})
									// }
								}
								route_deviation_polyline_dict[index + 1] = new window.google.maps.Polyline({
									map: map,
									strokeColor: "yellow",
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									// icons: [{
									// 		icon: {
									// 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									// 				strokeColor:'#ff8c52',
									// 				fillColor:'#ff8c52',
									// 				fillOpacity:1,
									// 				strokeWeight: 2
									// 				},
									// 		repeat:'500px',
									// 		path:[]
									// 	}]
								});
								for (var i = 0; i < missing_coords.length; i++) {
									var route_deviation_path = route_deviation_polyline_dict[index + 1].getPath().getArray();
									let latLng = new window.google.maps.LatLng(missing_coords[i].lat, missing_coords[i].lng);
									bounds.extend(new window.google.maps.LatLng(missing_coords[i].lat, missing_coords[i].lng));
									route_deviation_path.push(latLng);
									route_deviation_polyline_dict[index + 1].setPath(route_deviation_path);
									// if(coord[i].city_center_coords == 1)
									// {
									// 	console.log(coord[i].city_center_coords, "set options")
									// 	route_deviation_polyline_dict[index+1].setOptions({
									// 		strokeColor : "yellow",
									// 		strokeWeight: 5.5,
									// 	})
									// }
								}
							})



							var infowindow = new window.google.maps.InfoWindow();
							var marker, l;
							/* BREAKS */
							location_lists.map(function (locations) {
								for (l = 0; l < locations.length; l++) {
									marker = new window.google.maps.Marker({
										position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
										icon: require('../../assets/icons/cf.png'),
										map: map,
									});

									window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
										return function () {
											//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

											var contentarr = []
											//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
											var header = "Break";
											try {
												if (typeof this.props.mapFor != 'undefined') {
													if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
														contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
														contentarr.push({ "key": "Break End", "value": locations[l].break_end })
														contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
													}
													else {
														contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
														contentarr.push({ "key": "Break End", "value": locations[l].break_end })
														contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
													}
												}
												else {
													contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
													contentarr.push({ "key": "Break End", "value": locations[l].break_end })
													contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
												}
											}
											catch (e) {
												contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
												contentarr.push({ "key": "Break End", "value": locations[l].break_end })
												contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
											}


											var contentString = infoBox(marker.icon, header, contentarr, '')

											infowindow.setContent(contentString);
											currentwindow = infowindow;
											infowindow.open(map, marker);
											//console.log(marker.position.toJSON());
											// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
										}
									})(marker, l));
								}

							})



							// view markers code
							markersArray = arr2;

						}

						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;




						// for (l = 0; l < locations.length; l++) {
						// marker = new window.google.maps.Marker({
						// 	position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
						// 	icon:require('../../assets/icons/cf.png'),
						// 	map: map,
						// });

						// window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
						// 		return function() {
						// 		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						// 		var contentarr = []
						// 		var header = "Break - "+(l+1)
						// 		contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
						// 		contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)})
						// 		contentarr.push({"key":"Break Duration", "value":timeConvert(locations[l].break_time_seconds)})
						// 		var contentString = infoBox(marker.icon, header, contentarr)
						// 		infowindow.setContent(contentString);
						// 		currentwindow = infowindow;
						// 		infowindow.open(map, marker);
						// 		//console.log(marker.position.toJSON());
						// 		//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						// 		}
						// 	})(marker, l));
						// }


						markersArray = arr2

						/*START ICON*/
						//console.log(arr2);


						/*Google Routes Starts*/
						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#FF0000',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#FF0000',
											fillColor: '#FF0000',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}


					}

					//Route Planning
					if (this.props.mapFor == "routeplan") {
						markersArray = arr;

						var arr2 = arr;
						var routeinfo = jsondata;
						//this.plotMultiColorPolyline(arr,map,bounds)
						//console.log("arr2 ", arr2)
						var legsarr = []
						if (arr2.length > 0) {
							arr2.map((item) => {
								legsarr.push(item.leg_no)
							})
						}
						var unique = legsarr.filter(function (value, index, self) {
							return self.indexOf(value) === index;
						});
						//var legs = groupBy(arr2, rdata => (rdata.leg_no));
						//console.log("legs here ", unique)
						var colors = []
						if (unique.length > 0) {
							//colors.push("#157254")
							unique.map((item) => {
								var scolor = setBg()
								colors.push(scolor)
							})
						}
						// try{

						// legs.forEach((item,key) => {
						// 	legsarr.push(key)
						// })
						// }
						// catch(e){
						// 	legsarr=[]
						// }

						for (var i = 0; i < arr2.length - 1; i++) {
							var locationLatLng = [];
							locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
							locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));

							var consignments_missing_route_line = new window.google.maps.Polyline({
								map: map,
								path: locationLatLng,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,

							});

							//console.log("legsarr ", legsarr)
							if (legsarr.indexOf(arr2[i].leg_no) >= 0) {

								consignments_missing_route_line.setOptions({
									strokeColor: colors[parseInt(arr2[i].leg_no) + 1],
									strokeWeight: 5.5,
								})
							}

							bounds.extend(new window.google.maps.LatLng(this.props.startlat, this.props.endlng));
						}

					}

					// if(this.props.mapFor == 'tpttruck')
					// {
					// 	var markers= []
					// 	  for (let i = 0; i < arr.length; i++) {
					// 	  //if(arr[i].speed > 0)
					// 	 // {

					// 		var markerLatlng = new window.google.maps.LatLng(parseFloat(arr[i].latitude), parseFloat(arr[i].longitude));
					// 		var image =  require('../../assets/icons/truck_na_24.png');

					// 		var mark = new window.google.maps.Marker({
					// 			position: markerLatlng,
					// 			map: map,
					// 			title:arr[i].truck_no,
					// 			icon: image
					// 		});
					// 		markers.push(mark)
					// 		mark.addListener('click', function() {
					// 			var contentarr = []
					// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					// 			var header = arr[i].truck_no
					// 			contentarr.push({"key":"Transporter Name", "value":arr[i].transporter_name})
					// 			contentarr.push({"key":"Address", "value":arr[i].google_address})
					// 			contentarr.push({"key":"Last Moved Time", "value":getDDMMYYYYHHMMSS(arr[i].last_moved_time)})
					// 			contentarr.push({"key":"City/Area", "value":arr[i].area})
					// 			contentarr.push({"key":"State", "value":arr[i].state})

					// 			var contentString = infoBox(mark.icon, header, contentarr,'')

					// 			infowindow.setContent(contentString);
					// 			infowindow.setContent(contentString);
					// 			currentwindow = infowindow;
					// 			infowindow.open(map, mark);
					// 		  });


					// 		mark.setMap(map);

					// 	bounds.extend(new window.google.maps.LatLng(arr[i].latitude,arr[i].longitude));
					// 	//}
					//   }
					//   var markerCluster = new MarkerClusterer(map, markers,
					// 	{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
					// 	  var infowindow = new window.google.maps.InfoWindow();
					// 	var marker, l;


					// 	if(locations.length > 0)
					// 	{
					// 		var infowindow = new window.google.maps.InfoWindow();
					// 		var marker, l;

					// 		for (l = 0; l < locations.length; l++) {
					// 		marker = new window.google.maps.Marker({
					// 			position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					// 			icon:require('../../assets/icons/cf.png'),
					// 			map: map,
					// 		});
					// 		window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
					// 				return function() {
					// 				//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
					// 				var contentarr = []
					// 				var header = "Break - "+(l+1)
					// 				contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
					// 				contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)})
					// 				contentarr.push({"key":"Break Duration", "value":timeConvert(locations[l].break_time_seconds)})
					// 				var contentString = infoBox(marker.icon, header, contentarr)
					// 				infowindow.setContent(contentString);
					// 				currentwindow = infowindow;
					// 				infowindow.open(map, marker);
					// 				//console.log(marker.position.toJSON());
					// 				//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					// 				}
					// 			})(marker, l));
					// 		}

					// 	}

					// }

				}

				map.fitBounds(bounds)
			}
			catch (e) {
				console.log(e, "error")
			}
		}
		// park dealers marker 
		try {
			if (parkdealers.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				parkdealers.map(function (e, index) {
					var parkDealerMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.consignee_lat, e.consignee_lng),
						icon: require('../../assets/icons/customer_pin_38.png'),
						map: map,
					});
					window.google.maps.event.addListener(parkDealerMarker, 'mouseover', (function (parkDealerMarker, index) {
						return function () {
							var contentarr = []
							var header = "Dealer - " + e.consignee_name
							contentarr.push({ "key": "Name", "value": e.consignee_name })
							contentarr.push({ "key": "Code", "value": e.consignee_code })
							var contentString = infoBox(parkDealerMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, parkDealerMarker);
						}
					})(parkDealerMarker, index));
					window.google.maps.event.addListener(parkDealerMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
			map.fitBounds(bounds)
		} catch (e) { }

		// tolls marker
		try {
			if (tolls.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				tolls.map(function (e, index) {
					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lon),
						icon: require('../../assets/icons/barrier.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Toll - " + e.name
							contentarr.push({ "key": "Address", "value": e.location })
							var contentString = infoBox(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
			map.fitBounds(bounds)
		} catch (e) { }

		//check points window
		try {
			if (chokepoints.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				chokepoints = chokepoints.filter(function (e) {
					return e.qualified_to_display == 1
				})
				chokepoints.map(function (e, index) {

					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lng),
						icon: require('../../assets/icons/cf.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Chokepoint"
							contentarr.push({ "key": "Avg. Time Waiting ", "value": e.avg_break_time_text })
							// avg_break_time_text
							if (e.near_state_border != undefined) {
								contentarr.push({ "key": "State Border ", "value": e.state_border_name })
								contentarr.push({ "key": "Choke Point Type", "value": "State Border" })
								contentarr.push({ "key": "Distance From State Border ", "value": e.distance_from_state_border })

							}
							if (e.near_toll != undefined) {
								contentarr.push({ "key": "toll_name", "value": e.toll_name })
								contentarr.push({ "key": "Choke Point Type", "value": "Toll" })
							}
							var contentString = infoBox(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
			map.fitBounds(bounds)
		} catch (e) { }

		this.setState({			
			loadshow:'show-n',
            overly:'show-n',
		})
	}


	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	onShowTimelines() {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		if (this.state.viewtimelinemarkers === true) {
			var arr = [];
			if (this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (this.props.mapFor == 'importsconsignment') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (arr.length > 0) {

				var allpoints = [];


				// if(arr.length < 50)
				// {
				// 	var ratio = (arr.length/5);
				// }
				// else if(arr.length > 50 && arr.length <200)
				// {
				// 	var ratio = 5;
				// }
				// else if(arr.length > 200 && arr.length <400)
				// {
				// 	var ratio = 20;
				// }
				// else{
				// 	var ratio = 100;
				// }

				// //console.log("Total ",arr.length);
				// var arr1 = arr.filter(function (value, index, arr) {
				// 	//return (index % ratio == 0);
				// 	return index;
				// } );
				var darr = [];
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				});
				if (darr.length < 50) {
					var ratio = (arr.length / 5);
				}
				else if (darr.length > 50 && arr.length < 200) {
					var ratio = 5;
				}
				else if (darr.length > 200 && darr.length < 400) {
					var ratio = 20;
				}
				else {
					var ratio = 100;
				}
				var arr2 = []
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				});

				this.setState({
					timelinesmarkers: arr2
				})
			}
		}
		else {
			this.setState({
				timelinesmarkers: []
			})
		}
		this.renderMap()

	}
	onViewTimelines(e) {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e) {
		if (this.props.mapFor == 'consignment' || this.props.mapFor == 'multiroutedeviation' || this.props.mapFor == 'truck' || this.props.mapFor == 'geofence') {
			this.setState({
				loadshow: 'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			// var breaks = []
			// if(locations.length > 0)
			// {
			// 	locations.map((i) => {
			// 		console.log("substr",(i.lat).toString().substr(0,8))
			// 		breaks.push((i.lat).toString().substr(0,8))
			// 	})
			// }
			var dataarr = []
			//console.log("coordinates ", coordinates)
			coordinates.map((item) => {
				// console.log("breaksincludes",breaks.includes(item.lat))
				// if(breaks.indexOf(item.lat) >= 0)
				// {
				// 	console.log("stopages registered")
				// 	item['stopages'] = 1
				// }else{
				// 	item['stopages'] = 0
				// }
				dataarr.push({ "truck_no": this.props.truckno, "lat": item.lat, "lng": item.lng, "speed": item.speed, "stime": item.timestamp, "dist_from_prev_point": item.dist_from_prev_point, "received_on": item.received_on, "time_from_prev_point": item.time_from_prev_point })
			});
			this.setState({
				rowData: dataarr,
				open: true,
				loadshow: 'show-n'
			})
		}
	}


	onShowGoogleRoutes() {
		this.setState({
			viewgoogleroutes: !this.state.viewgoogleroutes
		})
		if (this.state.viewgoogleroutes == true) {
			if (this.props.mapFor == 'consignment') {
				try {
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if (googleroutes == 0) {
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes: !this.state.viewgoogleroutes
						});
					}
					else {
						this.setState({
							googleroutepath: googleroutes
						})
					}
				}
				catch (e) {
					console.log(e)
				}

			}
		}
		else {
			this.setState({
				googleroutepath: [],
				viewgoogleroutes: !this.state.viewgoogleroutes
			})
		}

		this.renderMap()
	}

	showMarkers(event) {
		// console.log("event.target.value ", event.target.value)
		if (event.target.value == 0) {
			this.setState({
				showMarkers: 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			// console.log("markersArray ", markersArray)
			var infowindow = new window.google.maps.InfoWindow();
			if (markersArray != undefined) {
				if (markersArray.length > 0) {
					var stepby = Math.round(markersArray.length * 0.007)
					// var counter = ( stepby > 0)?stepby:1;
					// var incrementby = counter
					var counter = 1;
					var incrementby = 1;
					// console.log(markersArray.length,counter);
					while (counter < markersArray.length) {
						var marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
							icon: {
								path: window.google.maps.SymbolPath.CIRCLE,
								strokeColor: '#157254',
								fillColor: '#157254',
								fillOpacity: 1,
								strokeWeight: 5,
								scale: 1,
							},
							map: map,
							content: markersArray[counter]
						});
						// marker.setVisible(false);
						window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
							return function () {
								//console.log("Arr ",marker)
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Current Status"
								contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
								contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

								var contentString = infoBox("", header, contentarr, '')


								infowindow.setContent(contentString);
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						window.google.maps.event.addListener(marker, 'mouseout', function () {
							infowindow.close();
						});

						viewMarkersArr.push(marker);
						counter = counter + incrementby;
						// console.log("counter",counter)
					}
					// for(var a=0;a<markersArray.length;a++)
					// {

					// }
				}
			}

		}
		else {
			this.setState({
				showMarkers: 0
			});
			viewMarkersArr.map(function (e) {
				e.setVisible(false);
			});
		}
	}

	showMultiMarkers(route_no)
	{
		// console.log(plottedPolylines, "checking polyline")
		if(plottedPolylines.length !== 0){
			if(plottedPolylines[route_no] === 'Approved_Route'){
				let directionsDisplay = this.state.directionsDisplay;
				if (directionsDisplay.getMap() != null){
					directionsDisplay.setMap(null);
				}else{
					// console.log(this.state.mapObject, "mapObject")
					let directionsDisplay = new window.google.maps.DirectionsRenderer(this.state.mapObject);
					this.setState({directionsDisplay})
				};
			}else{
				plottedPolylines[route_no].map((e)=>{
					// console.log(e, "polyline")				
					var idname = route_no+"_check"
					$("#"+idname).prop("checked",!e.getVisible())
					e.setVisible(!e.getVisible())				
					// if(!e.getVisible())
					// {
					// 	$("#"+this.props.mapinfo.coords[route_no].consignment_code[0]+"_check").removeAttr("checked")
					// }
					// else{
					// 	$("#"+this.props.mapinfo.coords[route_no].consignment_code[0]+"_check").attr("checked", true)
					// }
				})
			}
		}else{

		}
	}

	enableCheckBoxes()
	{
		[0,1,2, 3].map((e)=>{
			try{
				var idname = e+"_check"
				$("#"+idname).prop("checked",true)
			}
			catch(e){
				console.log(e, "error in enabling check boxes")
			}
		})
	}


	setRouteDeviationPathVisibility(routeno) {
		routeno = routeno + 1
		// console.log(route_deviation_polyline_dict[routeno])
		if (route_deviation_polyline_dict[routeno].getVisible()) {
			route_deviation_polyline_dict[routeno].setVisible(false)
		}
		else {
			route_deviation_polyline_dict[routeno].setVisible(true)
		}
	}
	onRTDashboardRowClicked(row) {
		var breaklocations = [];
		var bounds = new window.google.maps.LatLngBounds();
		row.data.break_clusters.map(function (e) {
			bounds.extend(new window.google.maps.LatLng(e.lat, e.lng));
		})
		map.fitBounds(bounds)
	}
	onClickHideAll(){
		this.setState({
			loadshow: "show-n",
			overly: "show-n"
		})
	}


	// Google Direction service code to show the route map with given input of source and destination 
	plotGoogleDirections(start, end) {
        directionsDisplays.map((e)=>{
            e.setMap(null)
        })
        var directionsService = new window.google.maps.DirectionsService();
        var method = 'DRIVING';
        // console.log(waypoints, "waypoints")
        var request = {
          origin: start,
          destination: end,
          travelMode: window.google.maps.DirectionsTravelMode[method],
          provideRouteAlternatives: false,
          waypoints: waypoints,
          optimizeWaypoints: true,
        };
      
        directionsService.route(request, (response, status) =>{      
          if (status == window.google.maps.DirectionsStatus.OK) {
            var routes = response.routes;
			plottedPolylines.push("Approved_Route")
            // console.log(response, "responseMap")
            var colors = colorPickers[this.props.mapinfo.coords.length - 1];
            directionsDisplays = [];      
            // Reset the start and end variables to the actual coordinates
            // var start = response.routes[0].legs[0].start_location;
            // var end = response.routes[0].legs[0].end_location;      
            // Loop through each route
            for (var i = 0; i < routes.length; i++) {      
				let mapObject = {
					map: map,
					directions: response,
					routeIndex: i,
					draggable: false,
					// suppressMarkers: true,
					polylineOptions: {
					  strokeColor: colors,
					  strokeWeight: 4,
					  strokeOpacity: 1
					}
				  }       
              var directionsDisplay = new window.google.maps.DirectionsRenderer(mapObject);
			  //we are updating the directionsDisplay and mapObject so that we can clear and enable them on toggle in ui 
			  this.setState({directionsDisplay,mapObject})
			//   console.log("directionsService",directionsDisplay)
            } 
			// End route loop
          }
        });
    }

	render() {
		const modalStyles = {
			width: '500px !important',
		}
		const { open } = this.state;
		const columnwithDefs = [
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 100,
				// filter: false,
				resizable: true,
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.lat.toFixed(6);
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.lng.toFixed(6);
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				// filter: false,
				resizable: true,
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Packet Time",
				field: "timestamp",
				width: 140,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					console.log('timeStamp',params.data);
					if(getDDMMYYYYHHMMSS(params.data.stime) !== undefined) return getDDMMYYYYHHMMSS(params.data.stime)
						return params.data.stime
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
				// valueGetter:function(params){
				// 	console.log(params);
				// 	return params.data.timestamp;
				// }

			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				// filter: false,
				resizable: true,
				valueGetter: function (params) {
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.received_on);
				},
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},
			{
				headerName: "Distance From previous Point (Meters)",
				field: "dist_from_prev_point",
				width: 250,
				// filter: false,
				resizable: true,
				// cellClass : params =>{
				// 	if(params.data.stopages == 1) return 'red'
				// 		return 'green'
				// }
			},

		]
		return (

			<div className="col-xl-12 col-md-12">
				{(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket' || this.props.mapFor == 'truck' || this.props.mapFor == 'geofence') ?
					<ul>
						{/* <li className="float-left">
						<input type="checkbox" name="viewtimelinemarkers" value="1" onClick={this.onShowTimelines.bind(this)} /> <span style={{fontSize:"14px"}}>View Markers</span>
					</li>

					<li className="float-left ml-5p">
						&nbsp;<input type="checkbox" name="viewgoogleroutes" value="1" onClick={this.onShowGoogleRoutes.bind(this)} /> <span style={{fontSize:"14px"}}>View Google Route</span>
					</li>
					this.onShowTimelines.bind(this)
					*/}

						<li className="float-right">
							<button type="button" className="btn btn-success btn-xs float-right" onClick={this.onShowGridPop.bind(this)}> <span style={{ fontSize: "14px" }}> <i className="icofont icofont-download-alt"></i> Download Data</span></button>
						</li>

					</ul>
					: ""}

				{(this.props.mapFor != 'truckgps') ?
					<ul>
						{(this.props.mapFor == "route_deviation") ?
							<li class="float-left" style={{ marginLeft: "1em" }}>
								{this.props.consignments_list.map((e, index) => {
									return (<span style={{ marginRight: "1em" }}><input type="checkbox" onClick={this.setRouteDeviationPathVisibility.bind(this, index)} /> {e.label}</span>)
								})}
							</li>
							:
							(this.props.mapFor == "deviation" || this.props.mapFor == "multiroutedeviation") ? ""
								:
								<li className="float-left">
									<input type="checkbox" name="viewtimelinemarkers" value={this.state.showMarkers} onClick={this.showMarkers.bind(this)} /> <span style={{ fontSize: "14px" }}>View Markers</span>
								</li>
						}
					</ul>

				: ""}

				{(this.props.mapFor == 'multiroutedeviation') ?
					<ul>
						{(this.props.mapinfo.coords.map((e, index) =>{
							return <li className="float-left" key={index}>
										<input type="checkbox" id={index+"_check"} onClick={this.showMultiMarkers.bind(this, index)} /> <span style={{ fontSize: "14px" }}><i className='fa fa-circle' style={{color: colorPickers[index]}}></i>{typeof(e.consignment_code[0]) === "number" ? e.consignment_code[0] : e.consignment_code }</span> &nbsp;&nbsp;
									</li>
						}))}
					</ul>

				: ""}


				{/* map div  */}

				<div id="map" className="" style={{ width: '100%', height: "70vh" }}></div>

				{(this.state.data_type == "regular") ?
					<span>
						<i class="fa fa-minus fa-2x" style={{ color: "#157254", }}></i> <b style={{ "paddingRight": "10px" }}>Current Truck </b>
						<i class="fa fa-minus fa-2x" style={{ color: "#452a68", }}></i> <b style={{ "paddingRight": "10px" }}>Missed GPS Route </b>
					</span>
					:
					<span>
						{(this.state.data_type == "rake") ?
							<span>
								<i class="fa fa-minus fa-2x" style={{ color: "#157254", }}></i><b style={{ "paddingRight": "10px" }}> First Mile Truck </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#0000ff", }}></i><b style={{ "paddingRight": "10px" }}> Rake </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#980000", }}></i><b style={{ "paddingRight": "10px" }}> Last Mile Truck </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#452a68", }}></i> <b style={{ "paddingRight": "10px" }}>Missed GPS Route </b>
							</span>
							:
							<span>
								{(this.props.mapFor == "route_deviation") ?
									<span>
										{this.props.consignments_list.map((e, index) => {
											return <b style={{ "paddingRight": "10px" }}><i class="fa fa-minus fa-2x" style={{ color: pathcolor[index] }}></i> {e.value} </b>
										})}
									</span>
									:
									(this.props.mapFor == "deviation") ?
										<span>
											<i class="fa fa-minus fa-2x" style={{ color: "#157254", }}></i> <b style={{ "paddingRight": "10px" }}>Route 1 </b>
											<i class="fa fa-minus fa-2x" style={{ color: "#0000ff", }}></i> <b style={{ "paddingRight": "10px" }}>Route 2 </b>
											<i class="fa fa-minus fa-2x" style={{ color: "#ce0909", }}></i> <b style={{ "paddingRight": "10px" }}>Route 3 </b>
											<i class="fa fa-minus fa-2x" style={{ color: "yellow", }}></i> <b style={{ "paddingRight": "10px" }}>Missing Coords To City Center </b>
										</span>
										:
										<span>
											{
										/*((this.props.mapFor != "returntrips") && (this.props.mapFor != "returntripsdashboard"))?
									<span>
										<i class="fa fa-minus fa-2x" style={{color:"#157254",}}></i> <b style={{"paddingRight":"10px"}}>Old Truck </b>
										<i class="fa fa-minus fa-2x" style={{color:"#0000ff",}}></i> <b style={{"paddingRight":"10px"}}>New Truck </b>
										<i class="fa fa-minus fa-2x" style={{color:"#452a68",}}></i> <b style={{"paddingRight":"10px"}}>Missed GPS Route </b>
									</span>
									:""
								*/}

										</span>
								}

							</span>
						}
					</span>
				}
				{(this.props.mapFor == "multiroutedeviation")?
					<span>
						{(this.props.mapinfo.coords.map((e, index) =>{
							return <b style={{ "paddingRight": "10px" }}><i class="fa fa-minus fa-2x" style={{ color: colorPickers[index], }}></i>Route {index+1} </b>
						}))}
						<i class="fa fa-minus fa-2x" style={{ color: "yellow", }}></i> <b style={{ "paddingRight": "10px" }}>Missing Coords To City Center </b>
						<i class="fa fa-minus fa-2x" style={{ color: "red", }}></i> <b style={{ "paddingRight": "10px" }}>Straight Lines </b>
					</span>
				:""}
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
					<div className={"row"} style={{ height: '100vh', padding: '5px 0px', width: '1185px', overflow: 'hidden', zIndex: '9999', }} >
						<div className={"col-sm-8 col-lg-8 col-md-8"} style={{ padding: '20px', background: '#fff' }}>
							{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
							<div id="myGrid" style={{ height: "580px", width: "100%", marginTop: '10px' }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
								/>

								{/* <div className={"dataLoadpage " + (this.state.loadshow)}>
								</div>
								<div className={"dataLoadpageimg " + (this.state.loadshow)}>
									<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
								</div> */}

							</div>
						</div>
					</div>
				</Modal>
				{this.props.mapFor == "multiroutedeviation" ? 
				<>
				<div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} ></div> 
				</>: ""}
			
			</div>
		)
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function intervals(startString, endString) {
	var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
	var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

	start.minutes(Math.ceil(start.minutes() / 15) * 15);

	var result = [];

	var current = moment(start);

	while (current <= end) {
		result.push(current.format('DD-MM-YYYY HH:mm'));
		current.add(15, 'minutes');
	}

	return result;
}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	//  console.log(len);
	var htmlStr = "";
	coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}


function groupBy(list, keyGetter) {
	const map11 = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map11.get(key);
		if (!collection) {
			map11.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}
function setBg() {
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	return "#" + randomColor
}
export default NewDrawMap;
