
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const TruckPlantIn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClickTruckPlantIn(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");  
    console.log(props.data,"111")
    return (

        <div>
            {props.data.revere_logistics_status !=1 ?
            <button onClick={handleRouteClick} className="btn btn-warning custom-btn f12 label label-success" 
            title="" >Truck Plant In</button>
            :"Completed"}
        </div>
    );
};

export default TruckPlantIn;
