import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
import {Redirect} from 'react-router-dom';


var Constant = require('../common/Constant');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
const fenchtypeoptions = [
	  { value: 'Plant', label: 'Plant' },
	  { value: 'Loading Area', label: 'Loading Area' },
	  { value: 'Non Loading Area', label: 'Non Loading Area' },
	  { value: 'Railyard', label: 'Railyard' },
	//   { value: 'Destination Railyard', label: 'Destination Railyard' },
	  { value: 'Workshop', label: 'Workshop' },
	  { value: 'Weigh Bridge', label: 'Weigh Bridge' },
	  { value: 'Customer', label: 'Customer' },
	  { value: 'TPA', label: 'Plant Parking (TPA)' },
	  { value: 'Marshalling Yard', label: 'Marshalling Yard' },
	  { value: 'Pipe Mill', label: 'Pipe Mill' },
	  { value: 'FG', label: 'FG' },
	  { value: 'Loaded Vehicle Parking', label: 'Loaded Vehicle Parking' }
	];


export const validAlphaNumericSpaceSlash = new RegExp(/^[a-zA-Z0-9._&// ]{1,255}$/);
export default class AddGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Add Geofence",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
        	routemap:'',
        	departments:[],
        	coords:[],
        	geo_name:'',
        	geo_type:'',
        	fence_type:'',
        	dept_code:[],
        	notify:'',
			erroMessage:'',
			redirectpage:'',
			consignee_code:"",
			consigneecode:{"value":"","label":"Select"},
			consigneeshow:"show-n",
			plantshow:"show-m",
			errcode:"show-n",
			errcodemsg:"",
			railyardinp:"show-n",
			railyardtype:"3",
			show:false,
			basicTitle:"",
			basicType:"",
			allcustomers:[],
			allplants:[],

			plant_code:'',    	      
        };
        this.showMap = this.showMap.bind(this);
        this.applyCoords = this.applyCoords.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
        this.checkNotifyHandler = this.checkNotifyHandler.bind(this);
		this.onLoadCustomers = this.onLoadCustomers.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentWillMount(){
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		console.log("Constant ", Constant.ORG_NAME);
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		if(!currentDepartmentCode) {
    	setTimeout(
    		    function() {
    		    	redirectURL.post('/overview/departments')
    		    	.then((response) => {
						//console.log(response.data);
						this.setState({
							departments:response.data
						});
    		    		
    		    	})
    		    	.catch(function(error){
    		    		console.log(error)
    		    	});
    		    	
    		    }
    		    .bind(this),
    		    2000
    		);
		}
		if(localStorage.getItem("plant_code") ==undefined || localStorage.getItem("plant_code") =="")
		{
			this.onLoadPlants();
		}
		this.onLoadCustomers();
    	this.showMap();
    }
	async onLoadPlants(){
		redirectURL.post("/consignments/allplants",).then(async(response) =>{
			let responseData = response.data
			// console.log("responseData",responseData)
			if(responseData.length > 0)
			{
				responseData = await distinctArrayBy(responseData,"plant_code");
				this.setState({
					allplants : responseData
				})
			}
		})
	}
	onLoadCustomers(){
        redirectURL.post("/chettinad/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            this.setState({
                allcustomers:customersarr
            })
        })
    }
	changeConsignee(consigneecode){
		this.setState({consigneecode})
	}
	customers(){
		var items = [];
		var customerlist = this.state.allcustomers;
		if(customerlist.length > 0){
			customerlist.map((itm) => {
				items.push({
					value:itm.consignee_code,
					label:itm.consignee_code
				});
			})
		}
		return items;
	}
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		let departmentTitle = null;
		switch(pathToMatch) {
			case "/manage/sndaddgeofence":
				departmentCode='SNDG';
				departmentName = "Sales and Dispatch";
				departmentTitle = " (Sales and Dispatch) ";
				break;
			case "/manage/prtaddgeofence":
				departmentCode='LOG-PRT';
				departmentName = "Spare Parts";
				departmentTitle = " (Spare Parts) ";
				break;
			case "/manage/tnpaddgeofence":
				departmentCode='LOG-TNP';
				departmentName = "Train and Production";
				departmentTitle = " (Train and Production) ";
				break;
			case "/manage/scaddgeofence":
				departmentCode='LOG-SC';
				departmentName = "Supply Chain";
				departmentTitle = " (Supply Chain) ";
				break;
			case "/manage/mllsndaddgeofence":
				departmentCode='SNDG';
				departmentName = "Sales and Dispatch";
				departmentTitle = " ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentTitle,
			currentDepartmentCode:departmentCode,
			departments: [{"dept_code": departmentCode,"dept_name": departmentName}],
			dept_code: departmentCode
		});
		return departmentCode;
	}
    
    departmentDropDown(){
		let items = []; 
		//console.log("this.state.departments = ", this.state.departments);
    	this.state.departments.map(item=>{
    		items.push({ value: item.dept_code, label: item.dept_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
		});
		//console.log("IN departmentDropDown items= ", items);
    	return items;
    }
   
    initMap = () => {
    	var coordsvalues;
    	var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(21.141058047268885,72.65782317936426),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true
          });
  		
  		//added by nanda for places dropdown...
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });
  		
  		var markers = [];
  		// console.log("searchBox",searchBox);
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
  // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };

              // Create a marker for each place.
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location
              }));

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });		
  		//End of adding by nanda
  		
  		var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.70,
            editable: true
          };
          // Creates a drawing manager attached to the map that allows the user to draw
          // markers, lines, and shapes.
          drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
              drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON
              ]
            },
            markerOptions: {
              draggable: true
            },
            polylineOptions: {
              editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
          });

          window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
              if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
              // Switch back to non-drawing mode after drawing a shape.
              drawingManager.setDrawingMode(null);
              // To hide:
              drawingManager.setOptions({
                drawingControl: false
              });

              // Add an event listener that selects the newly-drawn shape when the user
              // mouses down on it.
              var newShape = e.overlay;
              newShape.type = e.type;
              window.google.maps.event.addListener(newShape, 'click', function() {
                setSelection(newShape);
              });
              setSelection(newShape);
              //console.log("selectedShape",newShape);
  			  fencingLength = selectedShape.getPath().getLength();
  			  
  				var latlngObj=[];
	  			var htmlStr = "";
	  	        for (var i = 0; i < fencingLength; i++) {
	  				var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
	  				var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	  				latlngObj.push(in_latlng_array);
	  				coordinates.push(in_latlng_array);
	  			}
	  			
            }
              
          });
          
          
          // Clear the current selection when the drawing mode is changed, or when the
          // map is clicked.
  		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
  			clearSelection()
  		});
  		window.google.maps.event.addListener(map, 'click', function(){
  			clearSelection()
  		});
        /*
         * window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
        		deleteSelectedShape()
		});
        */
        
  		
      //buildColorPalette();
  		map.addListener('zoom_changed', function() {
            mapZoom=map.getZoom();
          });
  		
  		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
  			getSelectedShape()
  		});*/
  		
  		console.log("coordinates", coordinates);
		  document.getElementById("crds").value=coordinates;
  		
    }
    
    
    
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
	}

    changeHandler = geo_type => {

    	this.setState(
	      { geo_type },
	      () => {console.log(`Option selected:`, this.state.geo_type)
				if(this.state.geo_type.value == "Customer"){
					this.setState({
						consigneeshow:"show-m",
						plantshow:"show-n",
						consignee_code:""
					})
				}
				else{
					this.setState({
						consigneeshow:"show-n",
						plantshow:"show-m",
					})
				}
				if(this.state.geo_type.value == "Railyard"){
					this.setState({
						railyardinp:"show-m"
					})
				}
				else{
					this.setState({
						railyardinp:"show-n"
					})
				}
			}
	    );
	}
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    changeNameHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		if(value != "")
		{
			if(validAlphaNumericSpaceSlash.test(value) == true)
			{
				this.setState({[name]:value});
			}
			
		}
		else{
			this.setState({[name]:value});
		}
    	
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    changeArrayHandler = dept_code => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { dept_code },
	      () => console.log(`Option selected:`, this.state.dept_code)
	    );
    	
    	
    }
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    
	formHandler = (event) => {
			
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata1 = new FormData(event.target);
		var crds = document.getElementById("crds").value
		// console.log("crds " , crds);
		if(coordinates.length > 3)
		{
			var flag = 0;
			if(this.state.geo_type.value == "Customer")
			{
				if(this.state.consigneecode.value == "")
				{
					flag = 1;
				}
			}
			if(flag == 0 && this.state.geo_type.value != "")
			{
				this.setState({
					
					errcode:"show-n",
					errcodemsg:""
				})
				var formdata ={
					geo_name: this.state.geo_name,
					dept_code: ["SNDG"],
					geo_type: this.state.geo_type,
					fence_type: "Both",
					notify: "Both",
					coordinates: coordinates,
					railyardtype:this.state.railyardtype,
					consignee_code:this.state.consigneecode.value
				}
				if(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="")
				{
					formdata.plant_code = localStorage.getItem("plant_code");
				}
				else
				{
					if(this.state.plant_code !=undefined && this.state.plant_code !="")
					{
						formdata.plant_code = this.state.plant_code.value;
					}
					else
					{
						formdata.plant_code = "";
					}
				} 
				// console.log("formdata",formdata);
				// console.log("coordinates", coordinates);
				
				redirectURL.post("/geofence/addGeo", formdata,{
					headers:{
						'Content-Type': 'application/json'
					}
				}).
				then(
					(response)=>{
						// console.log(response);
						if(response.data.message !="Geofence Already Exists"){
							if(response.data.message == 'Success')
						{
							this.setState({
								geo_name:'',
								dept_code:'',
								geo_type:'',
								fence_type:'',
								notify:'',
								redirectpage:'yes'
							});
							
						}
						//window.location.href='/manage/geofences';
						}
						else{
							this.setState({
								show:true,
								basicTitle:"Geofence Already Exists",
								basicType:"warning",
							})

						}
						
					}
				)
				.catch(function(error){
					console.log(error);
				});
			}
			else{
				if(this.state.geo_type.value == "")
				{
					this.setState({
						errcode:"show-m",
						errcodemsg:"Please select type."
					})
				}
				else{
					this.setState({
						errcode:"show-m",
						errcodemsg:"Please enter customer code."
					})
				}
				
			}
			
		}
		else
		{
			this.setState({
				errcode:"show-m",
				errcodemsg:"Please add atleast 3 coordinate points"
				// erroMessage:'Please add atleast 3 coordinate points'
			});
		}
		
	}
	
    onChangePlantCode = async plant_code => {
		await this.setState(
			{ plant_code },
			() => console.log(`Option selected:`, this.state.plant_code)
		);        
    }
	selectOptionsItems() {
		let items = [];     
		var consigners=this.state.allplants;
		if(consigners.length > 0)
		{
			consigners.forEach(item =>{
				items.push({value:item.plant_code,label:item.plant_name}); 
			});
		}
        return items;
	}	
    render(){
		if(this.state.redirectpage == 'yes')
		{
			
			return <Redirect to={'/manage/geofences'} push={true} />
			
		}
		
		var redurl = "/manage/geofences";
		
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'500px'
		}
    	 const { dept_code } = this.state;
    	 const { geo_type } = this.state;
        
        return (
            <div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
				   			<div className="row card-body">
				   			
							<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
					           			<div className="form-group">
					               			<label className="c-lbl">Type *</label>
					               			
					               			<Select 
											   value={this.state.geo_type} 
											   onChange={this.changeHandler.bind(this)} 
											   style={{borderRadius:"0px"}} 
											   options={fenchtypeoptions} required />
					               			
					               			
					               	   </div>
										<input type="hidden" id="crds" />
										{(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="") ? "" :										
										<div className={"form-group "+(this.state.plantshow)}>
					               			<label className="c-lbl">Select Plant *</label>
					               			<Select closeMenuOnSelect={true} name="plant_code" placeholder="Select Plant" className="rselect" value={this.state.plant_code} onChange={this.onChangePlantCode.bind(this)} options={this.selectOptionsItems()} required/>
					               	   </div>
									    }

					               		<div className="form-group">
					               			<label className="c-lbl">Geofence Name *</label>
					               			<input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeNameHandler.bind(this)} className="form-control" placeholder="Name" required />
					               		</div>
				               		{/*					           			<div className="form-group">
					               			<label className="c-lbl">Department *:</label>
					               			<Select value={this.state.dept_code} onChange={this.changeArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.departmentDropDown()} required />
					               		
					               	   </div>
				               	   	*/}
										<div className={"form-group "+(this.state.consigneeshow)}>
					               			<label className="c-lbl">Customer Code *</label>
					               			{/* <input type="text" name="consignee_code" value={this.state.consignee_code} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Customer Code"  /> */}
											   <Select 
											   value={this.state.consigneecode} 
											   onChange={this.changeConsignee.bind(this)} 
											   style={{borderRadius:"0px"}} 
											   options={this.customers()}  />
											   <span className={this.state.errcode} id="errccode" style={{fontSize:"11px",color:"#ff0000"}}>{this.state.errcodemsg}</span>
					               		</div>
										<div className={"form-group "+(this.state.railyardinp)}>
					               	   		<label className="c-lbl">Railyard Type *</label>
					               	   		<div className="row col-xl-12 col-lg-12">
							               	   	<div className="col col-xl-6 col-lg-6">
							               	   		<input onChange={this.checkHandler} name="railyardtype" type="radio" className="form-check-input" value="3" />
							               	   		<label  className="">Both</label>
							               		</div>
							               		<div className="col col-xl-6 col-lg-6">
							               	   		<input onChange={this.checkHandler} name="railyardtype" type="radio" className="form-check-input" value="1" />
							               	   		<label  className="">Loading Railyard</label>
							               		</div>
							               		<div className="col col-xl-6 col-lg-6">
							               	   		<input onChange={this.checkHandler} name="railyardtype" type="radio" className="form-check-input" value="2" />
							               	   		<label  className="">Destination Railyard</label>
							               		</div>
						          			</div>
										</div>
				               	  {/*
					               	   <div className="form-group">
					               	   		<label className="c-lbl">Fence Type *:</label>
					               	   		<div className="row col-xl-12 col-lg-12">
							               	   	<div className="radio radio-primary col col-xl-6 col-lg-6">
							               	   		<input id="radio11" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Both" />
							               	   		<label htmlFor="radio11" className="">Both</label>
							               		</div>
							               		<div className="radio radio-secondary col col-xl-6 col-lg-6">
							               	   		<input id="radio22" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Loading Bay" />
							               	   		<label htmlFor="radio22" className="">Loading Bay</label>
							               		</div>
							               		<div className="radio radio-warning col col-xl-6 col-lg-6">
							               	   		<input id="radio33" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Unloading Bay" />
							               	   		<label htmlFor="radio33" className="">Unloading Bay</label>
							               		</div>
						          			</div>
										</div>
									
					               	   <div className="form-group">
						          			<label className="c-lbl">Notify When *:</label>
						          			<div className="row col-xl-12 col-lg-12">
							          			<div className="radio radio-primary col col-xl-6 col-lg-6">
							               	   		<input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Comes in" />
							               	   		<label htmlFor="radio44" className="">Vehicle Comes in</label>
							               		</div>
							               		<div className="radio radio-info col col-xl-6 col-lg-6">
							               	   		<input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Goes out" />
							               	   		<label htmlFor="radio55" className="">Vehicle Goes out</label>
							               		</div>
							               		<div className="radio radio-warning col col-xl-6 col-lg-6">
							               	   		<input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Both" />
							               	   		<label htmlFor="radio66" className="">Both</label>
							               		</div>
							               		<div className="radio radio-danger col col-xl-6 col-lg-6">
							               	   		<input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Never" />
							               	   		<label htmlFor="radio77" className="">Never</label>
							               		</div>
						           			</div>
										</div>*/}
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save</button>
					               			<a href={redurl} className="btn btn-info cs-btn">Cancel</a>
					               			
					               		</div>
									</div>
									
				           		</form>
				           		<div className="col-xl-8 col-lg-8">
								
									{this.state.erroMessage == '' ? '':
											<div className="alert alert-danger"></div>
									}
									  <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
				
				
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
                
        );
    }
}
function loadScript(url){
	try{
		var index  =window.document.getElementsByTagName("script")[0]
		var script = window.document.createElement("script")
		script.src=url
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)
	}
	catch(e){

	}
    
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}

function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	//console.log("selectedShape",selectedShape);
	shape.setEditable(true);
	
	selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
	fencingLength=0;  
	if (selectedShape) {
		selectedShape.setMap(null);
	}
	// To show:
	drawingManager.setOptions({
		drawingControl: true
	});
}

	
function selectColor(color) {
	selectedColor = color;
	for (var i = 0; i < colors.length; ++i) {
	var currColor = colors[i];
	//console.log(colorButtons[currColor]);
	//colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	}

	// Retrieves the current options from the drawing manager and replaces the
	// stroke or fill color as appropriate.
	var polylineOptions = drawingManager.get('polylineOptions');
	polylineOptions.strokeColor = color;
	drawingManager.set('polylineOptions', polylineOptions);

	var rectangleOptions = drawingManager.get('rectangleOptions');
	rectangleOptions.fillColor = color;
	drawingManager.set('rectangleOptions', rectangleOptions);

	var circleOptions = drawingManager.get('circleOptions');
	circleOptions.fillColor = color;
	drawingManager.set('circleOptions', circleOptions);

	var polygonOptions = drawingManager.get('polygonOptions');
	polygonOptions.fillColor = color;
	drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
	if (selectedShape) {
		if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
			selectedShape.set('strokeColor', color);
		} else {
			selectedShape.set('fillColor', color);
		}
	}
}

function makeColorButton(color) {
	var button = document.createElement('span');
	button.className = 'color-button';
	button.style.backgroundColor = color;
	window.google.maps.event.addDomListener(button, 'click', function() {
	selectColor(color);
	setSelectedShapeColor(color);
	});

	return button;
}

function buildColorPalette() {
	var colorPalette = document.getElementById('color-palette');
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		var colorButton = makeColorButton(currColor);
		colorPalette.appendChild(colorButton);
		colorButtons[currColor] = colorButton;
	}
	selectColor(colors[0]);
}  
	
function getSelectedShape(coords){
	var lt=[]
	//console.log("coordsLatlngs ",coords);
	for (let value of Object.values(coords)) {
		//console.log("Val is ",value); // John, then 30
		lt.push(value)
	}
	//console.log("Combine ",lt);
	document.getElementById('coords').value=lt;
	//return lt;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}


