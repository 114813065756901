import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import { VariableSizeList as List } from 'react-window';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import UnloadColumnchart from './columnunloadingdashboard';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import DrillDownBarChart from "./drilldownBarChart"
import PieChartComponent from './pieChartForDashboard';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

var moment = require('moment');

export default class TripWiseSummaryDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: moment.parseZone(new Date(2023, 3, 1)).format("YYYY-MM-DD"),
            endDate: moment.parseZone().format("YYYY-MM-DD"),
            startDate2: moment.parseZone(new Date(2023, 3, 1)).format("YYYY-MM-DD"),
            endDate2: moment.parseZone().format("YYYY-MM-DD"),
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
            sndtab: 'show-n',
            prttab: 'show-n',
            tnptab: 'show-n',
            sndtabactive: '',
            prttabactive: '',
            tnptabactive: '',
            overly: "show-n",
            loadshow: "show-n",
            plantWiseChartData: [],
            plantwiseCategory: [],
            drilldownseries: [],
            plantList: [],
            consigner: '',
            selectedTnpPlant: '',
            distribution_channel: [],
            invoice_segment: [],
            filterStates: [],
            filterTransporters: [],
            filterCustomers: [],
            districtsList: [],
            selectedState: [],
            selectDistrict: [],
            flDistribution: [],
            flDeviation: [],
            selectsegment: [],
            original_district_list: [],
            griddata: [],
            flTransporter: [],
            flCustomer: [],
            flsegment: [],
            segmentrowData: [],
            trip_tab: "btn-danger",
            customer_tab: "btn-default",
            is_trip_active: true,
            is_customer_active: false,
            piechartSeriesData: [],
            piechartSeriesData2: [],
            piechartSeriesData3: [],
            piechartSeriesData4: [],
            plantWiseChartData2 : []


        }
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        // eslint-disable-next-line no-unreachable
        catch (e) { }
    }

    MenuList = ({ options, children, maxHeight, getValue }) => {
        const [value] = getValue();
        const minHeight = 40
        const getItemSize = index => {
            var baseItemHeight = options[index].label.length * 1.5 + 20;
            return Math.max(minHeight, baseItemHeight)
        };

        return (
            <List
                height={Math.min(options.length * 30, maxHeight)}
                itemCount={options.length}
                itemSize={getItemSize}
                width={'100%'}
            >
                {({ index, style }) => (
                    <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
                        {children[index]}
                    </div>
                )}
            </List>
        );
    };


    async componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var current_month  = new Date().getMonth()
        this.setState({
            startDate : moment.parseZone(new Date()).subtract(10 , 'days').format("YYYY-MM-DD"),
            startDate2:moment.parseZone(new Date()).subtract(10 , 'days').format("YYYY-MM-DD")
        })
        var from_date = moment.parseZone(new Date()).subtract(10 , 'days').format("YYYY-MM-DD HH:mm:ss")
        var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
        var params = {
            from_date,
            to_date
        }
        // this.getTripData(params)
        // this.getTripDatawrtCustomer(params)

        var dpt = 'SNDG';
        await redirectURL.post("/consignments/plants", { dept_code: (dpt) })
            .then((resp) => {
                let responseData = resp.data;
                if (responseData.length > 0) {
                    responseData = distinctArrayBy(responseData, "plant_code");
                }
                var plant_list = []
                plant_list.push({
                    "value": "All",
                    "label": "All",
                });
                responseData.map((t) => {
                    plant_list.push({
                        "value": t.plant_code,
                        "label": t.plant_name
                    });
                });
                this.setState({
                    plantList: plant_list
                });
            })
        await redirectURL.post("/consignments/getdistinctconsignments", { dept_code: (dpt) })
            .then((resp) => {
                let distribution_channel_desc = resp.data.distribution_channel_desc
                var distribution_channel = []
                if (distribution_channel_desc.length > 0) {
                    distribution_channel.push({
                        "value": "All",
                        "label": "All",
                    });
                    distribution_channel_desc.map((t) => {
                        distribution_channel.push({
                            "value": t,
                            "label": t
                        });
                    });

                }
                var invoice_segment_data = resp.data.invoice_segment
                var invoice_segment = []
                if (invoice_segment_data.length > 0) {
                    invoice_segment.push({
                        "value": "All",
                        "label": "All",
                    });
                    invoice_segment_data.map((t) => {
                        invoice_segment.push({
                            "value": t,
                            "label": t
                        });
                    });

                }
                this.setState({
                    distribution_channel: distribution_channel,
                    invoice_segment: invoice_segment
                })
            })
        var dpt = 'SNDG';
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        await redirectURL.post("/consignments/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: encode(dpt), email: localStorage.getItem('email'), role: localStorage.getItem('roles'), customer_code: localStorage.getItem('customer_code'), consigner_code: localStorage.getItem('pc') })
            .then((resp) => {

                var transporters = resp.data.transportersList;
                var data = resp.data.data
                let tlist = [];
                tlist.push({
                    "value": "All",
                    "label": "All",
                });
                transporters.map((t) => {
                    tlist.push({
                        "value": t._id.transporter_code,
                        "label": t._id.transporter_code + " - " + t._id.transporter_name,
                    });
                });
                var customers = data.customers_For_dahsboard;
                var filterCustomers = [];
                if (customers.length > 0) {
                    filterCustomers.push({
                        label: "All",
                        value: "",
                    });
                    customers.map(function (c) {
                        if (!["", undefined, "undefined", null].includes(c.customer_code)) {
                            filterCustomers.push({
                                label: c.customer_code + " - " + c.consignee_name,
                                value: c.customer_code,
                            });
                        }

                    });
                }
                var districts = data.districts

                var filteredDistricts =  [{"value": "All","label": "All",}];
                if (districts.length > 0) {
                    var actual_filteredDistricts = []
                    districts.map(function (d) {
                        actual_filteredDistricts.push({
                            label: d._id.consignee_district,
                            value: d._id.consignee_district
                        })
                    })
                    sortByLabel(actual_filteredDistricts)
                    filteredDistricts = [...filteredDistricts , ... actual_filteredDistricts]
                }
                var states = data.states;
                var filterStates = [{
                    label: "All",
                    value: "",
                }];
                if (states.length > 0) {
                    var actual_filterStates = []
                    states.map(function (s) {
                        actual_filterStates.push({
                            label: s,
                            value: s,
                        });
                    });
                    sortByLabel(actual_filterStates)
                    filterStates = [...filterStates ,...actual_filterStates]
                }

                this.setState({
                    loadshow: "show-n",
                    overly: 'show-n',
                    filterCustomers: filterCustomers,
                    filterStates:filterStates,
                    original_district_list: districts,
                    districtsList: filteredDistricts,
                    districtsList2:filteredDistricts,
                    filterTransporters: tlist,
                });
            })




    }
    getTripData(params) {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        redirectURL.post("/dashboard/getTripDataForDashbaord", params).then((resp) => {

            var category_plant_wise_data = resp.data.category_plant_wise_data
            var category_state_wise_data = resp.data.category_state_wise_data
            var segment_wise_data = resp.data.segment_wise_data
            var state_wise_data = JSON.parse(resp.data.state_wise_data)
            var plant_wise_data = JSON.parse(resp.data.plant_wise_data)
            if (localStorage.getItem("roles") == "SITE_ADMIN") {
                if (localStorage.getItem("pc") != localStorage.getItem("original_plant_code")) {
                    if (!["", undefined, "undefined", null].includes(localStorage.getItem("pc"))) {
                        var plantcode = JSON.parse(localStorage.getItem("pc"))
                        segment_wise_data = segment_wise_data.filter(e => e.consigner_code == plantcode[0])
                        category_plant_wise_data = category_plant_wise_data.filter(e => e.consigner_code === plantcode[0])
                        category_state_wise_data = category_state_wise_data.filter(e => e.consigner_code === plantcode[0])
                        plant_wise_data = plant_wise_data.filter(e => e.consigner_code === plantcode[0])
                        state_wise_data = state_wise_data.filter(e => e.consigner_code === plantcode[0])
                    }
                }
            }
            else {
                var plants = JSON.parse(localStorage.getItem("pc"));
                var categorywisedata = []
                var segmentwisedata = []
                plants.map((p) => {
                    var categorywisedatabasedonplants = category_state_wise_data.filter(e => e.consigner_code == p)
                    categorywisedata.push(...categorywisedatabasedonplants)
                    var segmentwisedatabasedonplants = segment_wise_data.filter(e => e.consigner_code == p)
                    segmentwisedata.push(...segmentwisedatabasedonplants)
                })
                category_state_wise_data = categorywisedata
                segment_wise_data = segmentwisedata
            }
            if (![undefined, "undefined", null, ""].includes(segment_wise_data)) {
                segment_wise_data = segment_wise_data
            } else {
                segment_wise_data = []
            }
            console.log(plant_wise_data , "plant_wise_data")
           
            // console.log(row_data , "row_data")
            var row_data = []
            var group_by_plant = groupBy(segment_wise_data , e => e.consigner_code)
            group_by_plant.forEach((values_pc , keys_pc) => {
                var group_by_state = groupBy(values_pc , f => f.consignee_state)
                group_by_state.forEach((values_state , keys_state) => {
                    values_state.map(e => {
                        row_data.push(e)
                    })
                    state_wise_data.map(e => {
                        if(e.consignee_state == keys_state && e.consigner_code == keys_pc){
                            e.state_total = 'Total'
                            row_data.push(e)
                        }
                    })
                    
                })

                plant_wise_data.map(e => {
                    if(e.consigner_code == keys_pc){
                        e.plant_total = "Total"
                        row_data.push(e)
                    }
                })

            })
            this.setState({
                segmentrowData: row_data
            })
            this.getChartData(segment_wise_data)
            if (![undefined, null, "undefined", ""].includes(localStorage.getItem("pc"))) {
                if (![undefined, null, "undefined", ""].includes(category_state_wise_data)) {
                    var transporter_wise_data = category_state_wise_data.filter(e => e.category == "transporter")
                    var customer_wise_data = category_state_wise_data.filter(e => e.category == "customer")
                    this.getPieChartData(transporter_wise_data, "consignee_state")
                    this.getPieChartData2(customer_wise_data, "consignee_state")
                } else {
                    this.getPieChartData([])
                    this.getPieChartData2([])
                }

            } else {
                if (![undefined, null, "undefined", ""].includes(category_plant_wise_data)) {

                    var transporter_wise_data = category_plant_wise_data.filter(e => e.category == "transporter")
                    var customer_wise_data = category_plant_wise_data.filter(e => e.category == "customer")
                    this.getPieChartData(transporter_wise_data, "consigner_name")
                    this.getPieChartData2(customer_wise_data, "consigner_name")
                }
                else {
                    this.getPieChartData([])
                    this.getPieChartData2([])
                }
            }

            this.setState({
                loadshow: "show-n",
                overly: 'show-n'
            })



        })
    }

    getTripDatawrtCustomer = async (params) => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        await redirectURL.post("/dashboard/getTripDatawrtCustomerForDashbaord", params).then((resp) => {
            var records = JSON.parse(resp.data.customer_wise_data)
            if (localStorage.getItem("roles") == "SITE_ADMIN") {
                if (!["", undefined, "undefined", null].includes(localStorage.getItem("pc"))) {
                    var plantcode = JSON.parse(localStorage.getItem("pc"))
                    records = records.filter(e => e.consigner_code === plantcode[0])
                }
            }
            else {
                var plants = JSON.parse(localStorage.getItem("pc"));
                var data = []
                plants.map((p) => {
                    var plantwiserowdata = records.filter(e => e.consigner_code == p)
                    data.push(...plantwiserowdata)
                })
                records = data
            }
            var state_wise_data = JSON.parse(resp.data.state_wise_data)
            var segment_wise_data = JSON.parse(resp.data.segment_wise_data)
            var plant_wise_data = JSON.parse(resp.data.plant_wise_data)
            if (localStorage.getItem("roles") == "SITE_ADMIN") {
                if (localStorage.getItem("pc") != localStorage.getItem("original_plant_code")) {
                    if (!["", undefined, "undefined", null].includes(localStorage.getItem("pc"))) {
                        var plantcode = JSON.parse(localStorage.getItem("pc"))
                        state_wise_data = state_wise_data.filter(e => e.consigner_code == plantcode[0])
                        segment_wise_data = segment_wise_data.filter(e => e.consigner_code == plantcode[0])
                        plant_wise_data = plant_wise_data.filter(e => e.consigner_code === plantcode[0])
                    }
                }
            }
            else {
                var plants = JSON.parse(localStorage.getItem("pc"));
                var statewisedata = []
                var segmentwisedata = []
                plants.map((p) => {
                    var statewisedatabasedonplants = state_wise_data.filter(e => e.consigner_code == p)
                    statewisedata.push(...statewisedatabasedonplants)
                    var segmentwisedatabasedonplants = segment_wise_data.filter(e => e.consigner_code == p)
                    segmentwisedata.push(...segmentwisedatabasedonplants)
                })
                state_wise_data = statewisedata
                segment_wise_data = segmentwisedata
            }

            console.log(state_wise_data , "state_wise_data")
            console.log(plant_wise_data , "plant_wise_data")
            var row_data = []
            var group_by_plant = groupBy(records , e => e.consigner_code)
            group_by_plant.forEach((values_pc , keys_pc) => {
                var group_by_state = groupBy(values_pc , f => f.consignee_state)
                group_by_state.forEach((values_state , keys_state) => {
                    values_state.map(e => {
                        row_data.push(e)
                    })
                    state_wise_data.map(e => {
                        if(e.consignee_state == keys_state && e.consigner_code == keys_pc){
                            e.state_total = 'Total'
                            row_data.push(e)
                        }
                    })
                    
                })

                plant_wise_data.map(e => {
                    if(e.consigner_code == keys_pc){
                        e.plant_total = "Total"
                        row_data.push(e)
                    }
                })

            })
            console.log(row_data , "row_data")
            this.setState({
                rowData: row_data,
                griddata: records,
            })
            if (![undefined, "undefined", null, ""].includes(segment_wise_data)) {
                segment_wise_data = segment_wise_data
            } else {
                segment_wise_data = []
            }
            this.getChartData2(segment_wise_data)
            if (!["", undefined, "undefined", null].includes(localStorage.getItem("pc"))) {
                if (![undefined, "undefined", null, ""].includes(state_wise_data)) {
                    this.getPieChartData3(state_wise_data, "consignee_state")
                    this.getPieChartData4(state_wise_data, "consignee_state")
                } else {
                    this.getPieChartData3([])
                    this.getPieChartData4([])
                }


            } else {
                if (![undefined, "undefined", null, ""].includes(plant_wise_data)) {
                    this.getPieChartData3(plant_wise_data, "consigner_name")
                    this.getPieChartData4(plant_wise_data, "consigner_name")
                } else {
                    this.getPieChartData3([])
                    this.getPieChartData4([])
                }


            }
            this.setState({
                loadshow: "show-n",
                overly: 'show-n'
            })
        })

    }

    getChartData = (records) => {
        var plantWiseChartData = []
        var plantwiseCategory = []
        var drilldownseries = []
        // var color = ["#003f5c", "#955196","#444e86",  "#dd5182", "#ff6e54", "#ffa600"]
        if (records.length > 0) {
            var group_by_delivery_state = groupBy(records, data => data.consignee_state)
            group_by_delivery_state.forEach((values_state, keys_state) => {
                var a = {
                    "name": keys_state,
                    "data": []
                }
                var count = 0
                var group_by_plant = groupBy(values_state, data => data.consigner_name)
                group_by_plant.forEach((values_plant, keys_plant) => {

                    var b = {
                        "name": keys_plant,
                        "drilldown": keys_state + "_" + keys_plant,
                        "showTooltip": 1,
                        // "color": color[count],
                    }
                    var total_trips = 0
                    values_plant.map(plant => {
                        total_trips += parseInt(plant.no_of_trips)
                    })
                    var drilldown_a = {
                        "name": keys_state,
                        "id": keys_state + "_" + keys_plant,
                        "data": []
                    }
                    var group_by_category = groupBy(values_plant, a => a.category)
                    group_by_category.forEach((values_category, keys_category) => {
                        var total = 0
                        values_category.map(e => {
                            total = total + e.amount_to_recover
                        })
                        drilldown_a.data.push([keys_category, Number(total)])
                    })
                    drilldownseries.push(drilldown_a)
                    b.y = total_trips
                    a.data.push(b)
                    a.data.push(b)
                    count = count + 1

                })
                plantWiseChartData.push(a)
                plantwiseCategory.push(keys_state)
            })
        }
        this.setState({
            plantWiseChartData: plantWiseChartData,
            plantwiseCategory: plantwiseCategory,
            drilldownseries: drilldownseries,
        });
    }

    getChartData2 = (records) => {
        var plantWiseChartData = []
        var plantwiseCategory = []
        var drilldownseries = []
        if (records.length > 0) {
            var group_by_delivery_state = groupBy(records, data => data.consignee_state)
            group_by_delivery_state.forEach((values_state, keys_state) => {
                var a = {
                    "name": keys_state,
                    "data": []
                }
                var count = 0
                var group_by_plant = groupBy(values_state, data => data.consigner_name)
                group_by_plant.forEach((values_plant, keys_plant) => {

                    var b = {
                        "name": keys_plant,
                        "drilldown": keys_state + "_" + keys_plant,
                        "showTooltip": 1,
                        // "color": color[count],
                    }
                    var total_trips = 0
                    values_plant.map(plant => {
                        total_trips += parseInt(plant.no_of_trips)
                    })
                    var drilldown_a = {
                        "name": keys_state,
                        "id": keys_state + "_" + keys_plant,
                        "data": []
                    }

                    values_plant.map((e, index) => {

                        drilldown_a.data.push([e.invoice_segment, Number(e.amount_to_recover)])
                    })
                    drilldownseries.push(drilldown_a)
                    b.y = total_trips
                    a.data.push(b)
                    a.data.push(b)
                    count = count + 1

                })
                plantWiseChartData.push(a)
                plantwiseCategory.push(keys_state)
            })
        }
        // var color = ["#003f5c", "#955196","#444e86",  "#dd5182", "#ff6e54", "#ffa600"]

        this.setState({
            plantWiseChartData2: plantWiseChartData,
            plantwiseCategory2: plantwiseCategory,
            drilldownseries2: drilldownseries,
        });
    }

    getPieChartData = (records, type) => {
        var color = ["#003f5c", "#955196", "#444e86", "#dd5182", "#ff6e54", "#ffa600"]

        var piechartSeriesData = []
        var piechartSeriesCategories = []
        if (records.length > 0) {
            records.map((e, index) => {
                piechartSeriesData.push({
                    name: e[type],
                    y: e.no_of_trips,
                    color: color[index]
                })
                piechartSeriesCategories.push(e.consigner_name)

            })
        }

        this.setState({
            piechartSeriesData: piechartSeriesData,
            piechartSeriesCategories: piechartSeriesCategories
        })
    }
    getPieChartData3 = (records, type) => {
        var color = ["#003f5c", "#955196", "#444e86", "#dd5182", "#ff6e54", "#ffa600"]

        var piechartSeriesData = []
        var piechartSeriesCategories = []
        if (records.length > 0) {
            records.map((e, index) => {
                piechartSeriesData.push({
                    name: e[type],
                    y: e.frieght_difference,
                    color: color[index]
                })
                piechartSeriesCategories.push(e.consigner_name)

            })

        }
        this.setState({
            piechartSeriesData3: piechartSeriesData,
            piechartSeriesCategories3: piechartSeriesCategories
        })
    }
    getPieChartData4 = (records, type) => {
        var color = ["#003f5c", "#955196", "#444e86", "#dd5182", "#ff6e54", "#ffa600"]

        var piechartSeriesData = []
        var piechartSeriesCategories = []
        if (records.length > 0) {
            records.map((e, index) => {
                piechartSeriesData.push({
                    name: e[type],
                    y: e.amount_to_recover,
                    color: color[index]
                })
                piechartSeriesCategories.push(e.consigner_name)

            })
        }
        this.setState({
            piechartSeriesData4: piechartSeriesData,
            piechartSeriesCategories4: piechartSeriesCategories
        })
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        if (d.getTime() <= new Date(this.state.endDate + " 23:59:59").getTime()) {
            var mm = (d.getMonth() + 1)
            if (mm < 10) {
                var month = "0" + mm
            }
            else {
                var month = mm
            }
            var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
            this.setState({
                startDate: startdate
            });
        } else {
            this.setState({
                startDate: this.state.startDate
            });
            alert("From Date should be less than equal to To Date");

        }

    }
    handlerStartDateTime2 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        if (d.getTime() <= new Date(this.state.endDate2 + " 23:59:59").getTime()) {
            var mm = (d.getMonth() + 1)
            if (mm < 10) {
                var month = "0" + mm
            }
            else {
                var month = mm
            }
            var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
            this.setState({
                startDate2: startdate
            });
        } else {
            this.setState({
                startDate2: this.state.startDate2
            });
            alert("From Date should be less than equal to To Date");

        }

    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        if (d.getTime() >= new Date(this.state.startDate + " 00:00:00").getTime()) {
            var mm = (d.getMonth() + 1)
            if (mm < 10) {
                var month = "0" + mm
            }
            else {
                var month = mm
            }

            var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

            this.setState({
                endDate: edate
            });
        } else {
            this.setState({
                endDate: this.state.endDate
            })
            alert("To Date should be greater than or equal to From Date");
        }

    }
    handlerEndDateTime2 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        if (d.getTime() >= new Date(this.state.startDate2+" 00:00:00").getTime()) {
            var mm = (d.getMonth() + 1)
            if (mm < 10) {
                var month = "0" + mm
            }
            else {
                var month = mm
            }

            var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

            this.setState({
                endDate2: edate
            });
        } else {
            this.setState({
                endDate2: this.state.endDate2
            })
            alert("To Date should be greater than or equal to From Date");
        }
    }

    newFilter = (filterType, selOption) => {
        this.setState({ [filterType]: selOption });
    }
    onSelectState = (tab, state) => {
        if (tab == "TRIPS") {
            var originalDistricts = this.state.original_district_list

            var filterdistricts = []
            if (!["", undefined, "undefined", null].includes(state)) {
                if (state.value != "" && state.value != null && state.value != undefined) {
                    state.map((f) => {
                        var districts = originalDistricts.filter(e => e._id.consignee_state == f.value)
                        districts.map((d) => {
                            filterdistricts.push({ label: d._id.consignee_district, value: d._id.consignee_district })
                        })
                    })
                    this.setState({
                        selectedState: state,
                        districtsList: filterdistricts
                    });
                }
                else {
                    var districts = this.state.districtsList
                    this.setState({
                        selectedState: state,
                        districtsList: districts
                    });
                }
            }
            else {
                var districts = this.state.districtsList
                this.setState({
                    selectedState: state,
                    districtsList: districts
                });
            }
        } else {
            var originalDistricts = this.state.original_district_list

            var filterdistricts = []
            if (!["", undefined, "undefined", null].includes(state)) {
                if (state.value != "" && state.value != null && state.value != undefined) {
                    state.map((f) => {
                        var districts = originalDistricts.filter(e => e._id.consignee_state == f.value)
                        districts.map((d) => {
                            filterdistricts.push({ label: d._id.consignee_district, value: d._id.consignee_district })
                        })
                    })
                    this.setState({
                        selectedState2: state,
                        districtsList2: filterdistricts
                    });
                }
                else {
                    var districts = this.state.districtsList
                    this.setState({
                        selectedState2: state,
                        districtsList2: districts
                    });
                }
            }
            else {
                var districts = this.state.districtsList
                this.setState({
                    selectedState2: state,
                    districtsList2: districts
                });
            }
        }


    }

    onClickGetData() {
        this.setState({
            segmentrowData: [],
            loadshow: "show-m",
            overly: "show-m"
        });
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if (startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "") {
            startDate = startDate + " 00:00:00";
            endDate = endDate + " 23:59:59";
        }
        else {
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD") + " 23:59:59";
        }
        var params = {
            from_date: startDate,
            to_date: endDate,
        }
        var selectedState = this.state.selectedState
        if (selectedState !== "" && (selectedState) !== undefined && selectedState !== null) {
            var flstate = [];
            selectedState.map((fl) => {
                if (fl.label != 'All') { flstate.push(fl.value) }
            });
            if (flstate.length > 0) {
                params['consignee_state'] = JSON.stringify(flstate)
            }
        }
        var selectDistrict = this.state.selectDistrict
        if (selectDistrict !== "" && (selectDistrict) !== undefined && selectDistrict !== null) {
            var fldistrict = [];
            selectDistrict.map((fl) => {
                if (fl.label != 'All') { fldistrict.push(fl.value) }
            });
            if (fldistrict.length > 0) {
                params['consignee_district'] = JSON.stringify(fldistrict)
            }
        }

        var flDistribution = this.state.flDistribution
        if (flDistribution !== "" && (flDistribution) !== undefined && flDistribution !== null) {
            var fldistri = [];
            flDistribution.map((fl) => {
                if (fl.label != 'All') { fldistri.push(fl.value) }
            });
            if (fldistri.length > 0) {
                params['distribution_channel'] = JSON.stringify(fldistri)
            }
        }

        var flDeviation = this.state.flDeviation
        if (flDeviation !== "" && (flDeviation) !== undefined && flDeviation !== null) {
            var fldevia = [];
            flDeviation.map((fl) => {
                if (fl.label != 'All') { fldevia.push(fl.value) }
            });
            if (fldevia.length > 0) {
                params['geofence_deviation'] = JSON.stringify(fldevia)
            }
        }

        var flTransporter = this.state.flTransporter
        if (flTransporter !== "" && (flTransporter) !== undefined && flTransporter !== null) {
            var fltrans = [];
            flTransporter.map((fl) => {
                if (fl.label != 'All') { fltrans.push(fl.value) }
            });
            if (fltrans.length > 0) {
                params['transporter_code'] = JSON.stringify(fltrans)
            }
        }
        var flCustomer = this.state.flCustomer
        if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
            var flList = [];
            flCustomer.map((fl) => {
                if (fl.label != 'All') { flList.push(fl.value) }
            });
            if (flList.length > 0) {
                params['consignee_code'] = JSON.stringify(flList)
            }
        }
        var flsegment = this.state.flsegment
        if (flsegment !== "" && (flsegment) !== undefined && flsegment !== null) {
            var flseg = [];
            flsegment.map((fl) => {
                if (fl.label != 'All') {
                    flseg.push(fl.value)
                }
            });
            if (flseg.length > 0) {
                params['material_segment'] = JSON.stringify(flseg)
            }
        }
        if(![undefined , "undefined" , null , ""].includes(localStorage.getItem("pc"))){
            params.consigner_code = localStorage.getItem("pc")
        }
        this.getTripData(params)
        // this.getTripDatawrtCustomer(params)

    }

    onClickGetData2() {
        this.setState({
            rowData: [],
            loadshow: "show-m",
            overly: "show-m"
        });
        var startDate = this.state.startDate2;
        var endDate = this.state.endDate2;
        if (startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "") {
            startDate = startDate + " 00:00:00";
            endDate = endDate + " 23:59:59";
        }
        else {
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD") + " 23:59:59";
        }
        var params = {
            from_date: startDate,
            to_date: endDate,
        }
        var selectedState = this.state.selectedState2
        if (selectedState !== "" && (selectedState) !== undefined && selectedState !== null) {
            var flstate = [];
            selectedState.map((fl) => {
                if (fl.label != 'All') { flstate.push(fl.value) }
            });
            if (flstate.length > 0) {
                params['consignee_state'] = JSON.stringify(flstate)
            }
        }
        var selectDistrict = this.state.selectDistrict2
        if (selectDistrict !== "" && (selectDistrict) !== undefined && selectDistrict !== null) {
            var fldistrict = [];
            selectDistrict.map((fl) => {
                if (fl.label != 'All') { fldistrict.push(fl.value) }
            });
            if (fldistrict.length > 0) {
                params['consignee_district'] = JSON.stringify(fldistrict)
            }
        }

        var flDistribution = this.state.flDistribution2
        if (flDistribution !== "" && (flDistribution) !== undefined && flDistribution !== null) {
            var fldistri = [];
            flDistribution.map((fl) => {
                if (fl.label != 'All') { fldistri.push(fl.value) }
            });
            if (fldistri.length > 0) {
                params['distribution_channel'] = JSON.stringify(fldistri)
            }
        }

        var flDeviation = this.state.flDeviation2
        if (flDeviation !== "" && (flDeviation) !== undefined && flDeviation !== null) {
            var fldevia = [];
            flDeviation.map((fl) => {
                if (fl.label != 'All') { fldevia.push(fl.value) }
            });
            if (fldevia.length > 0) {
                params['geofence_deviation'] = JSON.stringify(fldevia)
            }
        }

        var flTransporter = this.state.flTransporter2
        if (flTransporter !== "" && (flTransporter) !== undefined && flTransporter !== null) {
            var fltrans = [];
            flTransporter.map((fl) => {
                if (fl.label != 'All') { fltrans.push(fl.value) }
            });
            if (fltrans.length > 0) {
                params['transporter_code'] = JSON.stringify(fltrans)
            }
        }
        var flCustomer = this.state.flCustomer2
        if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
            var flList = [];
            flCustomer.map((fl) => {
                if (fl.label != 'All') { flList.push(fl.value) }
            });
            if (flList.length > 0) {
                params['consignee_code'] = JSON.stringify(flList)
            }
        }
        var flsegment = this.state.flsegment2
        if (flsegment !== "" && (flsegment) !== undefined && flsegment !== null) {
            var flseg = [];
            flsegment.map((fl) => {
                if (fl.label != 'All') {
                    flseg.push(fl.value)
                }
            });
            if (flseg.length > 0) {
                params['material_segment'] = JSON.stringify(flseg)
            }
        }

        if(![undefined , "undefined" , null , ""].includes(localStorage.getItem("pc"))){
            params.consigner_code = localStorage.getItem("pc")
        }

        // this.getTripData(params)
        this.getTripDatawrtCustomer(params)

    }

    getPieChartData2 = (records, type) => {
        var color = ["#6181FF", "#764248", "#DAA89B", "#F0C808", "#20FFBE", "#00100B"]

        var piechartSeriesData = []
        var piechartSeriesCategories = []
        var count = 0
        if (records.length > 0) {
            records.map((e, index) => {
                piechartSeriesData.push({
                    name: e[type],
                    y: e.no_of_trips,
                    color: color[index]
                })
                piechartSeriesCategories.push(e.consigner_name)

            })
        }
        this.setState({
            piechartSeriesData2: piechartSeriesData,
            piechartSeriesCategories2: piechartSeriesCategories
        })
    }




    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            breaksSlider: "",
            selectedRow: [],
        });

    }

    onClickTab = (e) => {
        var current_month = new Date().getMonth()
        if (e == "trip") {
            this.setState({
                trip_tab: "btn-danger",
                customer_tab: "btn-default",
                is_trip_active: true,
                is_customer_active: false,
                // loadshow:'show-m',
                // overly:'show-m',
                startDate : moment.parseZone(new Date()).subtract(10 , 'days').format("YYYY-MM-DD"),
                endDate: moment.parseZone().format("YYYY-MM-DD"),
                selectedState : [],
                selectDistrict: [],
                flDistribution: [],
                flDeviation: [],
                flTransporter: [],
                flCustomer: [],
                flsegment: [],
                segmentrowData : [],
                plantWiseChartData: [],
                plantwiseCategory: [],
                drilldownseries: [],
                piechartSeriesData: [],
                piechartSeriesCategories: [],
                piechartSeriesData2: [],
                piechartSeriesCategories2: []
                })
               var from_date = moment.parseZone(new Date(2023, current_month, 1)).format("YYYY-MM-DD HH:mm:ss")
               var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
            var params = {
                from_date,
                to_date
            }
            // this.getTripData(params)
            // this.getTripDatawrtCustomer(params)
        } else {
            this.setState({
                trip_tab: "btn-default",
                customer_tab: "btn-danger",
                is_trip_active: false,
                is_customer_active: true,
                // loadshow:'show-m',
                // overly:'show-m',
                startDate2:moment.parseZone(new Date()).subtract(10 , 'days').format("YYYY-MM-DD"),
                endDate2: moment.parseZone().format("YYYY-MM-DD"),
                selectedState2 : [],
                selectDistrict2: [],
                flDistribution2: [],
                flDeviation2: [],
                flTransporter2: [],
                flCustomer2: [],
                flsegment2: [],
                rowData: [],
                griddata: [],
                plantWiseChartData2: [],
                plantwiseCategory2: [],
                drilldownseries2: [],
                piechartSeriesData3: [],
                piechartSeriesCategories3: [],
                piechartSeriesData4: [],
                piechartSeriesCategories4: []

            })
            var from_date = moment.parseZone(new Date(2023, current_month, 1)).format("YYYY-MM-DD HH:mm:ss")
            var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
            var params = {
                from_date,
                to_date
            }
            // this.getTripData(params)
            // this.getTripDatawrtCustomer(params)
        }
    }


    render() {
        const { flCustomer, filterCustomers } = this.state;
        const columnwithDefs = [
            {
                headerName: "Plant",
                field: "consigner_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.plant_total == "Total"){
                            return params.data.consigner_name + " Total"
                        }else{
                            return params.data.consigner_name
                        }
                    }
                    else{
                        return params.data.consigner_name
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            
            {
                headerName: "Delivery State",
                field: "consignee_state",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.consignee_state + " Total"
                        }else{
                            return ""
                        }
                    }else{
                        return params.data.consignee_state
                    }
                    
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.transporter_name
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                // width:250,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.customer_code
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Customer Name",
                field: "consignee_name",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.consignee_name
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },

            {
                headerName: "Segment",
                field: "invoice_segment",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.invoice_segment
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Distribution Channel",
                field: "distribution_channel_desc",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                hide:true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.distribution_channel_desc
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Geofence Deviation",
                field: "geofence_deviation",
                // width :80,
                valueGetter: function (params) {
                    if (params.data.geofence_deviation == 1) {
                        return 'Deviated'
                    } else if (params.data.geofence_deviation == 0) {
                        return 'Non-Deviated'
                    } else {
                        return params.data.geofence_deviation
                    }
                },
                filter: true,
                editable: false,
                resizable: true,
                hide:true,
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "No Of Trips",
                field: "no_of_trips",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Quantity in MT",
                field: "qty",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return params.data.total_quantity
                    }else{
                        return params.data.qty
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Invoice Freight Cost",
                field: "invoice_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.invoice_freight_weighted_average
                        }else{
                            return params.data.plant_invoice_average
                        }
                    }else{
                        return params.data.invoice_freight_cost
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Actual Freight Cost",
                field: "actual_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.actual_freight_weighted_average
                        }else{
                            return params.data.plant_actual_average
                        }                    }else{
                        return params.data.actual_freight_cost
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Freight Difference",
                field: "freight_difference",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return params.data.frieght_difference
                    }else{
                        return params.data.freight_difference
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Amount to be Recovered",
                field: "amount_to_recover",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    return params.data.amount_to_recover
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }

            }
        ]
        const columnwithdefs = [
            {
                headerName: "Plant",
                field: "consigner_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.plant_total == "Total"){
                            return params.data.consigner_name + " Total"
                        }else{
                            return params.data.consigner_name
                        }
                    }
                    else{
                        return params.data.consigner_name
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Delivery State",
                field: "consignee_state",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.consignee_state + " Total"
                        }else{
                            return ""
                        }
                    }else{
                        return params.data.consignee_state
                    }
                    
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Transporter",
                field: "transporter_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true,
                hide: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.transporter_name
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Category",
                field: "category",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.category
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Segment",
                field: "invoice_segment",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return ""
                    }else{
                        return params.data.invoice_segment
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "No Of Trips",
                field: "no_of_trips",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Quantity in MT",
                field: "qty",
                // width :80,
                filter: true,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return params.data.total_quantity
                    }else{
                        return params.data.qty
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Invoice Freight Cost",
                field: "invoice_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.invoice_freight_weighted_average
                        }else{
                            return params.data.plant_invoice_average
                        }
                    }else{
                        return params.data.invoice_freight_cost
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Actual Freight Cost",
                field: "actual_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        if(params.data.state_total == "Total"){
                            return params.data.actual_freight_weighted_average
                        }else{
                            return params.data.plant_actual_average
                        }
                    }else{
                        return params.data.actual_freight_cost
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Freight Difference",
                field: "freight_difference",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.state_total == "Total" || params.data.plant_total == "Total"){
                        return params.data.frieght_difference
                    }else{
                        return params.data.freight_difference
                    }
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }
            },
            {
                headerName: "Amount to be Recovered",
                field: "amount_to_recover",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
                valueGetter : function(params){
                    return params.data.amount_to_recover
                },
                cellStyle:function(params){
                    if(params.data.state_total == "Total" ){
                        return { background: "#66c0e3", color: "#333", fontWeight :"bold"};
                    }
                    else if( params.data.plant_total == "Total"){
                        return { background: "#3fe74d", color: "#333", fontWeight :"bold"};
                    }
                }

            }
        ]


        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12"}>
                    <button className={'btn ' + this.state.trip_tab} style={{ marginLeft: "16px" }} onClick={this.onClickTab.bind(this, "trip")}>Summary Dashboard 1</button>
                    <button className={'btn ' + this.state.customer_tab} onClick={this.onClickTab.bind(this, "customer")}>Summary Dashboard 2</button>
                </div>
                {this.state.is_trip_active ?
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"}>
                        <div className="col-xl-12 col-lg-12">

                            <div className=" col-xl-12 col-lg-12 mt-35p">
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>From Date </label>
                                        <Datetime value={this.state.startDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' ,readOnly:true}}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            name="startDate"
                                            onChange={this.handlerStartDateTime.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>To Date: </label>
                                        <Datetime value={this.state.endDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off',readOnly:true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            name="endDate"
                                            onChange={this.handlerEndDateTime.bind(this)}
                                        />
                                    </div>
                                    <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                        <label>Select State  </label>
                                        <Select
                                            isMulti={true}
                                            closeMenuOnSelect={true}
                                            value={this.state.selectedState}
                                            className="border-radius-0"
                                            onChange={this.onSelectState.bind(this, "TRIPS")}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.filterStates}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select District </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedDistrict"
                                            value={this.state.selectDistrict}
                                            style={{ borderRadius: "0px" }}
                                            // value={this.state.flDistrict}
                                            onChange={this.newFilter.bind(this, "selectDistrict")}
                                            options={this.state.districtsList}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Distribution Channel   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectDistributionChannel"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flDistribution}
                                            onChange={this.newFilter.bind(this, "flDistribution")}
                                            options={this.state.distribution_channel}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Geofence Deviation   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flDeviation}
                                            onChange={this.newFilter.bind(this, "flDeviation")}
                                            options={[{ label: "Deviated", value: 1 }, { label: "Non-Deviated", value: 0 }]}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Segment</label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectSegment"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flsegment}
                                            onChange={this.newFilter.bind(this, "flsegment")}
                                            options={this.state.invoice_segment}
                                        />
                                        {/* <Select
                                        className="border-radius-0"
												isMulti={false}
												id="selectedsegment"
                                                value={this.state.selectsegment}
												style={{ borderRadius: "0px" }}
												// value={this.state.flDistrict}
												onChange={this.newFilter.bind(this, "flsegment")}
												options={this.state.invoice_segment}
											/> */}
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Transporter   </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedTransporter"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flTransporter}
                                            onChange={this.newFilter.bind(this, "flTransporter")}
                                            options={this.state.filterTransporters}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Customers   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectedCustomer"
                                            components={{ MenuList: this.MenuList }}
                                            value={flCustomer}
                                            onChange={this.newFilter.bind(this, "flCustomer")}
                                            options={filterCustomers}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>&nbsp;</label><br />
                                        <button type="button" className="btn btn-success" onClick={this.onClickGetData.bind(this)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.segmentrowData.length> 0?
                            <div className='row mt-20p'>

                                <div className="col-xl-12">
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Trip Wise Data</span>

                                    </h5>
                                    <div id="myGrid" style={{ width: "100%", height: "100vh" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithdefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.segmentrowData}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            // pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            rowSelection={'multiple'}
                                            masterDetail={true}
                                            suppressRowClickSelection={true}
                                        />

                                    </div>
                                </div>

                            </div>:""}
                            <div className='card' style={{ margin: '20px' }}>
                            {this.state.segmentrowData.length> 0?
                                <div className={"row mt-40p " + this.state.showFirstLevel}>
                                    <div className="col-xl-12">
                                        <DrillDownBarChart
                                            chartTitle={"Plant Wise Data"}
                                            seriesData={this.state.plantWiseChartData}
                                            seriesCategories={this.state.plantwiseCategory}
                                            chartType={1}
                                            drilldowndata={this.state.drilldownseries}
                                            chart_hit_count={0}
                                            drilldown_header={"Amount to be Recovered"}
                                        />
                                    </div>
                                </div>:""}

                            </div>
                            {this.state.piechartSeriesData.length > 0? 

                            <div className='card' style={{ margin: '20px' }}>
                            <div className={"row mt-20p"}>
                                <div className="col-xl-6" style={{ width: "500px", height: "500px" }}>
                                    <PieChartComponent
                                        chartType="pie"
                                        title={"Transporter Wise Trips"}
                                        subtitle={"As Per Plant"}
                                        context={this}
                                        series={this.state.piechartSeriesData}
                                        categories={this.state.piechartSeriesCategories}
                                        seriesName=""
                                        yaxistitle="Orders For Load Plan"
                                        drilldownseries={[]}
                                        innerSize="0%"
                                        format='<b>{point.name} - {point.y}</b>'
                                    />
                                </div>
                                <div className="col-xl-6" style={{ width: "500px", height: "500px" }}>
                                    <PieChartComponent
                                        chartType="pie"
                                        title={"Customer Wise Trips"}
                                        subtitle={"As Per Plant"}
                                        context={this}
                                        series={this.state.piechartSeriesData2}
                                        categories={this.state.piechartSeriesCategories2}
                                        seriesName=""
                                        yaxistitle="Orders For Load Plan"
                                        drilldownseries={[]}
                                        innerSize="0%"
                                        format='<b>{point.name} - {point.y}</b>'
                                    />
                                </div>
                                
                            </div>
                            </div>
                            : ""}
                        </div>

                    </div> : ""}
                {this.state.is_customer_active ?
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"}>
                        <div className="col-xl-12 col-lg-12">
                            <div className=" col-xl-12 col-lg-12 mt-35p">
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>From Date </label>
                                        <Datetime value={this.state.startDate2}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' , readOnly:true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            name="startDate"
                                            onChange={this.handlerStartDateTime2.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>To Date: </label>
                                        <Datetime value={this.state.endDate2}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' ,readOnly:true}}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            name="endDate"
                                            onChange={this.handlerEndDateTime2.bind(this)}
                                        />
                                    </div>
                                    <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                        <label>Select State  </label>
                                        <Select
                                            isMulti={true}
                                            closeMenuOnSelect={true}
                                            value={this.state.selectedState2}
                                            className="border-radius-0"
                                            onChange={this.onSelectState.bind(this, "CUSTOMER")}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.filterStates}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select District </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedDistrict"
                                            value={this.state.selectDistrict2}
                                            style={{ borderRadius: "0px" }}
                                            // value={this.state.flDistrict}
                                            onChange={this.newFilter.bind(this, "selectDistrict2")}
                                            options={this.state.districtsList2}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Distribution Channel   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectDistributionChannel"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flDistribution2}
                                            onChange={this.newFilter.bind(this, "flDistribution2")}
                                            options={this.state.distribution_channel}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Geofence Deviation   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flDeviation2}
                                            onChange={this.newFilter.bind(this, "flDeviation2")}
                                            options={[{ label: "Deviated", value: 1 }, { label: "Non-Deviated", value: 0 }]}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Segment</label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectSegment"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flsegment2}
                                            onChange={this.newFilter.bind(this, "flsegment2")}
                                            options={this.state.invoice_segment}
                                        />
                                        {/* <Select
                                        className="border-radius-0"
												isMulti={false}
												id="selectedsegment"
                                                value={this.state.selectsegment}
												style={{ borderRadius: "0px" }}
												// value={this.state.flDistrict}
												onChange={this.newFilter.bind(this, "flsegment")}
												options={this.state.invoice_segment}
											/> */}
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Transporter   </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedTransporter"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.flTransporter2}
                                            onChange={this.newFilter.bind(this, "flTransporter2")}
                                            options={this.state.filterTransporters}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Customers   </label>
                                        <Select
                                            className="border-radius-0"
                                            closeMenuOnSelect={true}
                                            isMulti={true}
                                            id="selectedCustomer"
                                            style={{ borderRadius: "0px" }}
                                            components={{ MenuList: this.MenuList }}
                                            value={this.state.flCustomer2}
                                            onChange={this.newFilter.bind(this, "flCustomer2")}
                                            options={this.state.filterCustomers}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>&nbsp;</label><br />
                                        <button type="button" className="btn btn-success" onClick={this.onClickGetData2.bind(this)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.rowData.length> 0 ? 
                            <div className='row mt-20p'>

                                <div className="col-xl-12">
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Customer Wise Trip Data</span>

                                    </h5>
                                    <div id="myGrid" style={{ width: "100%", height: "100vh" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            // pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            rowSelection={'multiple'}
                                            masterDetail={true}
                                            suppressRowClickSelection={true}
                                        />

                                    </div>
                                </div>

                            </div>:""}
                            <div className='card' style={{ margin: '20px' }}>
                            {this.state.plantWiseChartData2.length> 0?
                                <div className={"row mt-40p " + this.state.showFirstLevel}>
                                    <div className="col-xl-12">
                                        <DrillDownBarChart
                                            chartTitle={"Plant Wise Data"}
                                            seriesData={this.state.plantWiseChartData2}
                                            seriesCategories={this.state.plantwiseCategory2}
                                            chartType={1}
                                            drilldowndata={this.state.drilldownseries2}
                                            chart_hit_count={0}
                                            drilldown_header={"Amount to be Recovered"}
                                        />
                                    </div>
                                </div>
                                :""}
                            </div> 
                            {this.state.piechartSeriesData3.length?
                            <div className='card' style={{ margin: '20px' }}>
                            <div className={"row mt-20p"}>
                                <div className="col-xl-6" style={{ width: "100%", height: "500px" }}>
                                    <PieChartComponent
                                        chartType="pie"
                                        title={"Freight Difference Weighted Avg"}
                                        subtitle={"As Per Plant"}
                                        context={this}
                                        series={this.state.piechartSeriesData3}
                                        categories={this.state.piechartSeriesCategories3}
                                        seriesName=""
                                        yaxistitle="Orders For Load Plan"
                                        drilldownseries={[]}
                                        innerSize="0%"
                                        format='<b>{point.name} - {point.y:.2f}</b>'
                                    />
                                </div>

                                <div className="col-xl-6" style={{ width: "100%", height: "500px" }}>
                                    <PieChartComponent
                                        chartType="pie"
                                        title={"Amount to be Recoverd"}
                                        subtitle={"As Per Plant"}
                                        context={this}
                                        series={this.state.piechartSeriesData4}
                                        categories={this.state.piechartSeriesCategories4}
                                        seriesName=""
                                        yaxistitle="Orders For Load Plan"
                                        drilldownseries={[]}
                                        innerSize="0%"
                                        format='<b>{point.name} - {point.y:.2f}</b>'
                                    />
                                </div>
                            </div>
                            </div>
                            :""}
                        </div>

                    </div> : ""}

                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}

function sortByLabel(arr) {
    arr.sort((obj1, obj2) => {
      const label1 = obj1.label
      const label2 = obj2.label
      if (label1 < label2) {
        return -1;
      } else if (label1 > label2) {
        return 1;
      }
      return 0;
    });
  }


