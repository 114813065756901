import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';



import Highcharts from 'highcharts';

import drilldown from 'highcharts-drilldown';

import HighchartsReact from 'highcharts-react-official';

import $ from 'jquery';

drilldown(Highcharts);

window.jQuery = $;

window.$ = $;



var redirectURL = require('../redirectURL');

var criticaldata = []

export default class PieChartComponent extends Component {



    constructor(props) {

        super(props);

        this.state = {

            showgrid: 'display:none',

            showtransgrid: 'display:none',

            rowdata: [],

            test: "TEstng",



        }

        this.handleDrilldown = this.handleDrilldown.bind(this)



    }

    componentDidMount = async () => {



        $(".grid-" + this.props.code).hide();

        $(".grid-trans-" + this.props.code).hide();

        $(".dataLoadpagesum" + this.props.code).hide()

        $(".dataLoadpageimgsum" + this.props.code).hide()

        $(".overlay-partsum" + this.props.code).hide()

    }

    componentWillReceiveProps(newprops) {

        if (newprops.title != this.props.dept_code) {

            //console.log("newprops ",newprops);

        }



    }

    handleDrilldown = (params) => {

        console.log("Here ,", params)

    }

    render() {

        //console.log("this.props.code) ", this.props.code)





        const dStyles = {

            width: '100%',

            height: '450px',



        }

        var highchartoptions = {

            chart: {

                type: this.props.chartType,

                events: {

                    // click: (function(self) {

                    // return function() {

                    //     const point = {

                    //     x: this.x,

                    //     y: this.y

                    //     };

                    //     self.handleDrilldown(point);

                    // };

                    // })(this)



                },

                height: "450px"

            },

            title: {

                text: this.props.title,

                align:'center',
                verticalAlign: 'bottom'

            },

            subtitle: {

                text: ''

            },

            accessibility: {

                announceNewData: {

                    enabled: true

                }

            },

            xAxis: {

                type: 'category'

            },

            yAxis: {

                title: {

                    text: this.props.yaxistitle

                }



            },

            legend: {

                pie: {

                    enabled: true

                },

                column: {

                    enabled: false

                },

                spline: {

                    enabled: true

                },



                // enabled:false,

            },



            // point: {

            //     events:{

            //         click:function(e)

            //         {

            //             alert("test")

            //            return this.handleDrilldown(e)

            //         }

            //     }

            // },

            plotOptions: {

                column: {

                    dataLabels: {

                        enabled: false

                    },

                    stacked: false,

                    enableMouseTracking: true,

                    cropThreshold: 500,

                    showInLegend: true,

                },

                spline: {

                    dataLabels: {

                        enabled: true,

                        // format:'{point.y}'

                    },

                    showInLegend: true,

                },

                pie: {

                    dataLabels: {

                        enabled: true,

                        // format:'<b>{point.name}-{point.y}</b><br>{point.percentage:.1f} %',

                        format: this.props.format,

                    },

                    // showInLegend: true,

                }

            },



            tooltip: {

                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',

                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} {(point.unloadtext)}</b></b><br><span style="color:{point.color}">No of trips</span>: <b>{point.trips}</b>'

                formatter: function () {

                    // console.log(this.series)



                    return "<span style='font-size:11px'>" + this.series.name + "</span><br>\
                            <span style='color:{point.color}'>"+ this.point.name + "</span>: <b>" + this.point.y + "</b></b><br>"



                }

            },



            series: [

                {

                    innerSize: this.props.innerSize,

                    name: this.props.seriesName,

                    colorByPoint: true,

                    data: this.props.series

                },

            ],

            drilldown: this.props.drilldownseries,

            credits: false

        };

        // console.log(highchartoptions.drilldown,"drilldown");

        // console.log(this.props.series,"162")

        if (this.props.splineSeriesData != undefined) {

            highchartoptions['series'].push(this.props.splineSeriesData)

        }

        return (

            <div style={{ height: "250px" }}>



                <HighchartsReact

                    containerProps={{ style: { width: '100%', height: '450px' } }}

                    highcharts={Highcharts}

                    style={dStyles}

                    options={highchartoptions}

                    onClick={this.handleDrilldown.bind(this)}

                />

            </div>

        );

    }

}