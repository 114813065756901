import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import $ from 'jquery';

// import Grid from '../layouts/gridnosidebarComponent';
import Select from 'react-select';
// import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import EditItem from "./edittptconsignment";
import UpdateShipments from '../manage/updateShipments'
import { getHyphenDDMMMYYYY,  getHyphenDDMMYYYY, getHyphenDDMMMYYYYHHMM ,getHyphenHHMM ,getHyphenDDMMYYYYHHMMSS} from '../common/utils';
import Simtrackinglogs from "../chettinad/simtrackinglogs";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export const validPhone =new RegExp(/^[0-9]{10}$/);
export const validMobile = new RegExp(/^[6789]\d{9}$/);
export default class chettinadSimTrackDriverList extends Component {
	constructor(props){
		super(props);
		this.state={ 
			modules: AllModules,
			pageTitle: "SIM Tracking Numbers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:[],
			uploadDivWidth:'0%',
			sliderTranslate:'',
			loadshow:'show-n',
            overly:"show-n",
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false,
			sliderAddTransporter:"",
			sliderForSimLogs:"",
			transporter_name:"",
			transporter_code:"",
			sliderUpdateShipment : "",
			old_shipmentss : [],
			shipment_exist_msg:"",
			edit_clicked : false,
            allShipmentRows : [],
            oldShipmentIds:[],
            shipmentIds : [],
            originalValue : "",
            originalValueName : "",
			is_updateShipment : false,
			check_Dup : false,
			rowid:"",
			utransporter_name:"",
			utransporter_code:"",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				EditItem:EditItem,
				Simtrackinglogs:Simtrackinglogs,
				UpdateShipments : UpdateShipments,
			},

			rowModelType: "serverSide",
			paginationPageSize:50,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			sliderEditTransporter:"",
			
			consignee_name:"",
			consignee_code:"",
			lat:"",
			lng:"",
			check_domain : /enmovil\.in/,
		    email : localStorage.getItem("email"),
			for_code:"",
			driver_code: "",
			driver_name: "",
			assigned_truck_no: {"value":"","label":"Select"},
			is_active: "",
            trucks:[],
            mobile_no:"",
			err_mobile:"",
            rowDataActive:[],
			truckNoList:[],
			shipment_ids:[],
			allRows : [],
			filteredData : [],
			showTrucksList : "show-n",
			allrowData : [],
			sliderForceCloseTranslate:"",
			simoperator_value:"",
			resendcontent:"",
			errmsg:false ,
			mobile_network:"",
			// mobile_networks: [{ label: "Airtel", value: "airtel" }, { label: "Reliance Jio", value: "jio" }, { label: "BSNL ", value: "bsnl" },{ label: "Vodafone Idea ", value: "vi" }],
			sim_tracking_service_provider:[{ label: "JIO", value: "jio" }, { label: "NON-JIO", value: "non_jio" }],
			detailCellRendererParams:{},
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			search_mobile_number : '',
			search_lps_devivery_no:"",
		}
		this.OnLoadDrivers = this.OnLoadDrivers.bind(this);
        this.changeMobileNoHandler = this.changeMobileNoHandler.bind(this);
		this.onClickEditBlock = this.onClickEditBlock.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onClickShowSimTrackingLegs = this.onClickShowSimTrackingLegs.bind(this);
		this.onClickUpdateBtn = this.onClickUpdateBtn.bind(this);
		this.fetchAndSetMobileAndShipmentData = this.fetchAndSetMobileAndShipmentData.bind(this)
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		 /*Consignments List*/	
		 this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
		// redirectURL.post('/chettinad/trucks')
		// .then((response) => {
		// 	var records = response.data;
		// 	this.setState({
		// 		trucks: records
		// 	});
		// })
		// .catch(function (error) {
		// 	console.log(error);
		// });
		  redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
		  .then(async (resp) => {
			  await this.setState({
				  usergridstate: resp.data
			  });
			  await this.restoreGridStates();
		  });
	  this.OnLoadDrivers();
	

		if (this.state.check_domain.test(this.state.email.trim().toLowerCase()) || ["jayakumar.n@chettinadcement.com","elangovan.m@chettinadcement.com"].includes(this.state.email)) {
			this.showShipmentIdsPopup();
		}
	  this.intervalId = setInterval(() => this.OnLoadDrivers(), 60000);
	};
  
	componentWillUnmount() {
	  // Clean up the interval on component unmount
	  clearInterval(this.intervalId);
	}
	OnLoadDrivers(){
		this.setState({
			search_mobile_number:"",
			search_lps_devivery_no:""
		})
		// console.log(1234)
		var params = {}
		if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
		{
			params.plant_code = localStorage.getItem("pc");
		}
		redirectURL.post('/chettinad/activeShipmentIds', params)
		.then((response) => {
			var records = response.data.trucks;
			this.setState({
				shipment_ids: records
			});
		})
		.catch(function (error) {
			console.log(error);
		});
		redirectURL.post('/chettinad/simdrivers',params)    
		.then((response) => {
			var records = response.data.drivers;
			// console.log("records", records);
			this.setState({
			  	rowData:records,
				allrowData:records,
			  	loadshow:"show-n",
			  	// overly:"show-n"
		  	});
		})
		.catch(function (error) {
		  console.log(error);
		});
	}
	restoreGridStates() {
		if (this.state.usergridstate.length !== 0) {
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch (e) {

			}
		}
	}
	onShowUploadDiv = () => {
		// console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onGridReady1 = params => {
		this.gridApi1 = params.api;
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;        
	};
	

	onGridState1 = () =>{
        // console.log(this.gridApi1);
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

	getRowStyle = (params) => {
        if (params && params.data && params.data.existing_active_trip && params.data.existing_active_trip == 1) {
            return { backgroundColor: '#FFB9B9' }
        }
        else {
            return null
        }
    }

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderAddTransporter:"",
			sliderForSimLogs:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			sliderEditTransporter:""
		});
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderForSimLogs:"",
			sliderEditTransporter:"",
			sliderUpdateShipment : "",
			check_Dup : false,
			mobile_no : "",
			old_shipmentss : [],
			shipment_exist_msg:"",
			edit_clicked : false
		});
		
    }

	onClickUpdateBtn=(rowData)=>{
		this.setState({loadshow:'show-m',overly:'show-m'})
		var row = rowData.data 
		var shipment_id = row.shipment_id 
        var originalValue = this.state.originalValue
        var originalValueName = this.state.originalValueName
        var params = {
			shipment_id:shipment_id,
            driver_name : row.driver_name,
            mobile_no : row.driver_mobile_no,
            truck_no : row.truck_no,
            originalValue : originalValue,
            dept_code : localStorage.getItem("dept_code"),
			updated_by : localStorage.getItem('email')
		}
        if (this.state.oldShipmentIds.includes(shipment_id)) {
            params.old_shipment = 1;
        }
		
        // console.log(originalValueName,originalValue,"3333")

        if(originalValueName != "" || originalValue != ""){
            redirectURL.post("/consignments/updateRow", params).then((resp) => {
                if (resp.data.statusMessage == "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully Updated",
                        basicType: "success",
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Not Updated",
                        basicType: "danger",
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle: "No changes were made",
                basicType: "danger",
                loadshow: 'show-n',
                overly: 'show-n'
            })
        }
	}


	onClickGetSliderForUpdateShipment = () => {
        this.setState({
            sliderUpdateShipment: "slider-translate-75p",
            show: false,
            showDiv: 'show-m',
            loadshow: 'show-n',
            // sidebarSubHeader: "Truck Information",
            overly: 'show-m',
        })
    }

	showShipmentIdsPopup = () => {
		var allRows = []
		var params1 = {}
		if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
		{
			params1.plant_code = localStorage.getItem("pc");
		}
		redirectURL.post('/chettinad/activeShipmentIds', params1)
			.then((response) => {
				allRows = response.data.allrows;
				// console.log("allROws", allRows.length)
				this.setState({
					allRows: allRows
				});
				if (allRows) {
					const shipmentIds = allRows
						.filter(row => row.existing_active_trip === 1)
						.map(row => row.shipment_id);

					const newShipmentRows = allRows.filter(row => row.existing_active_trip === 1)
					const oldShipmentRows = newShipmentRows.flatMap(record => {
						if (record.existing_active_shipments && Array.isArray(record.existing_active_shipments)) {
							return record.existing_active_shipments;
						}
						return [];
					});
					const oldShipmentIds = oldShipmentRows

					const params = {
						oldShipmentRows: oldShipmentRows
					}

					redirectURL.post("/consignments/fetchDuplicateOldShipments", params)
						.then((response) => {
							const old_shipmentrecords = response.data.old_shipmentrecords

							const correctedshipments = [];

							newShipmentRows.forEach((item) => {
								correctedshipments.push(item);

								item.existing_active_shipments.forEach((shipment_id) => {
									const shipment = old_shipmentrecords.find(record => record.shipment_id === shipment_id);
									if (shipment) {
										correctedshipments.push(shipment);
									}
								});
							});


							this.setState({
								shipmentIds: shipmentIds,
								oldShipmentIds: oldShipmentIds,
								allShipmentRows: correctedshipments,
							});
						})
						.catch((error) => {
							console.error("Error fetching duplicate shipments:", error);
						});
					// console.log(shipmentIds.length, "length")
					if (shipmentIds.length > 0) {
						this.setState({
							show: true,
							check_Dup : true,
							// basicTitle: `Active shipments with the same mobile number found: ${shipmentIds.join(', ')}`,
							basicTitle: "Duplicate mobile numbers detected in active shipments. This can force close previous shipments, Please update the driver number and driver name for these duplicates",
							basicType: "success"
						});
					}
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	changeMobileNoHandler = async (event) => {
		const { value } = event.target;
		const re = /^[0-9\b]+$/;
	
		if (!isNaN(value) && re.test(value) && value.length <= 10) {
			if (value.length == 10) {
				const { assigned_shipment_id } = this.state;
				await this.fetchAndSetMobileAndShipmentData(assigned_shipment_id.value, value);
			}else{
				this.setState({
					old_shipmentss: "", shipment_exist_msg :"",
					edit_clicked:false
				})
			}
			this.setState({
				mobile_no: value,
				err_mobile: "",
			});
			this.setState({
				mobile_no: value,
				err_mobile: "",
			});
		} else {
			this.setState({
				mobile_no: value,
				err_mobile: "",
			});
		}
	};
	
	oldShipmentsEdit(event) {
		this.setState({
		  shipment_exist_msg: "",
		  edit_clicked: true
		});
	  }

	  oldShipmentsIgnore(event) {
		this.setState({
		  shipment_exist_msg: "",
		  edit_clicked: false
		});
	  }
	submitoldshipmentrecords = async (event) => {
		try {
			this.setState({ loadshow: 'show-m', overly: 'show-m' });

			const old_arr = this.state.old_shipmentss || [];
			const old_driver_details = old_arr.map(item => ({
				driver_mobile_no: item.driver_mobile_no,
				driver_name: item.driver_name,
				delivery_no: item.delivery_no,
				shipment_id: item.shipment_id,
			}));

			const params = {
				old_driver_details: JSON.stringify(old_driver_details),
				updated_by: localStorage.getItem('email')
			};

			// console.log(params, "params for update");

			const saveAndSubmitData = await redirectURL.post("/consignments/updateoldshipmentrecords", params);
			const response = saveAndSubmitData.data;

			if (response.message === "success") {
				if (event == "add_driver") {
					await this.saveDriverItem();
				}
				else if (event == "edit_driver"){
					await this.updateDriverItem();

				}
				this.setState({
					// show: true,
					// basicTitle: "Successfully Updated",
					// basicType: "success",
					old_shipmentss:[],
					loadshow: 'show-n',
					// overly: 'show-n'
				});
			} else {
				this.handleError(response.message);
			}
		} catch (e) {
			console.error("Error: ", e);
			this.handleError(e.message);
		}
	};

	handleError = (message) => {
		this.setState({
			show: true,
			basicTitle: message,
			basicType: "danger",
			loadshow: "show-n",
			overly: 'show-n'
		});
	};
	handleAlphanumericChange = (e, index) => {
		// console.log(e.target.value,"event ",index)
		const newValue = e.target.value;
		this.setState(prevState => {
		  const newShipments = [...prevState.old_shipmentss];
		  newShipments[index].driver_name = newValue; 
		  return { old_shipmentss: newShipments };
		});
	  };
	  
	  handleNumericChange = (e, index) => {
		// console.log(index,"mobil no",e.target.value)
		const value = e.target.value;
		if (/^\d*$/.test(value) && value.length <= 10) {
		  this.setState(prevState => {
			const newShipments = [...prevState.old_shipmentss];
			newShipments[index].driver_mobile_no = value; 
			return { old_shipmentss: newShipments };

		  });
		}
	  };
	  

	OnBlurMobile = async (event) => {
		const { value } = event.target;
		const re = /^[0-9\b]+$/;

		if (value === "") {
			this.setState({ err_mobile: "Please enter mobile no", mobile_no: "" });
		} else if (!re.test(value) || value.length !== 10) {
			this.setState({ err_mobile: "Invalid Mobile number", mobile_no: "" });
		} else {
			const { assigned_shipment_id } = this.state;

			await this.fetchAndSetMobileAndShipmentData(assigned_shipment_id.value, value);

			this.setState({
				mobile_no: value,
				err_mobile: "",
			});
		}
	}
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	};
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='SIM Tracking Numbers';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
	onCLickAddDriver(){
		this.setState({
			sliderAddTransporter:'slider-translate-50p',
			overly:"show-m",
			driver_code:"",
			mobile_no:"",
			driver_name:"",
			assigned_truck_no:"",
			assigned_shipment_id:"",
		});
	}
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({[name]:value});
	}
	showMarkers(event){
		// if(event.target.value == 0)
		// {
		// 	this.setState({
		// 		showMarkers : 1
		// 	});
		// 	// viewMarkersArr.map(function(e){
		// 	// 	e.setVisible(true);
		// 	// })
			
		// }
		// else
		// {
		// 	this.setState({
		// 		showMarkers : 0
		// 	});
		// }
        
        this.setState({simoperator_value:event.target.value})
	}
	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
	handleSubmit(){
        // var valuesArr = [this.state.reasonforceclose,this.state.selectedReason]
        // this.props.context.handleInput(valuesArr);
        
		var param = this.state.resendcontent 
		redirectURL.post("/chettinad/resendconsent",param).then((response)=>{
			if(response.data.status == "Success")
			{
				this.setState({
					show:true,
					basicTitle:response.data.message,
					basicType:"success",
					loadshow:"show-n",
					overly:"show-n",
					errmsg:false
				})
				this.OnLoadDrivers();
			}
			else{
				this.setState({
					show:true,
					basicTitle:response.data.message,
					basicType:"danger",
					loadshow:"show-n",
					overly:"show-n"
				})
			}
		})
	
    }
	onClickHideForForceClosure(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
	onClickEditForm(rowdata){
		var row = rowdata.data;
		// console.log("Row ", row)
		var cordinates = JSON.parse(row.coordinates);
		// console.log('cordinates ', cordinates);
		var lat = cordinates.lat;
		var lng = cordinates.lng;
		this.setState({
			uconsignee_name:row.consignee_name,
			uconsignee_code:row.consignee_code,
			ulat:lat,
			ulng:lng,
			ufor_code:row.for_code,
			uconsignee_city: row.consignee_city,
			uconsignee_addr1: row.consignee_addr1,
			uconsignee_addr2: row.consignee_addr2,
			uconsignee_addr3: row.consignee_addr3,
			rowid:row._id,
			sliderEditTransporter:'slider-translate-50p',
			overly:"show-m"
		})
	}
    truckslist(){
        var items = [];
        var trucks = this.state.trucks;
        if(trucks.length > 0){
            trucks.map((item) => {
                items.push({
                    value:item.truck_no,
                    label:item.truck_no
                })
            })
        }
        return items;
    }
    shipmentslist(){
        var items = [];
        var shipment_ids = this.state.shipment_ids;
        if(shipment_ids.length > 0){
            shipment_ids.map((item) => {
                items.push({
                    value:item.shipment_id,
                    label:item.shipment_id
                })
            })
        }
        return items;
    }
    onChangeTruck(assigned_truck_no){
        this.setState({assigned_truck_no});
    }

	onChangeShipment = async (assigned_shipment_id) => {
		this.setState({ assigned_shipment_id });

		const { mobile_no } = this.state;
		await this.fetchAndSetMobileAndShipmentData(assigned_shipment_id.value, mobile_no);
	};

	fetchAndSetMobileAndShipmentData = async (assigned_shipment_id, mobile_no) => {
		const params = {
			shipment_id: assigned_shipment_id,
			...(mobile_no && { mobile_no })
		};
		const plant_code = localStorage.getItem("pc");
		if (plant_code) {
			params.plant_code = plant_code;
		}
		try {
			const checkMobileAndShipmentsCombo = await redirectURL.post("/chettinad/mobilewithshipmentid", params);
			const response = checkMobileAndShipmentsCombo.data || [];
			let shipment_exist_msg = "";
			const old_shipmentss = mobile_no
				? response.filter(item => item.driver_mobile_no === mobile_no)
				: [];
				if(this.state.edit_clicked){

				} else {
					if (old_shipmentss.length > 0) {
						shipment_exist_msg = "Shipment exists with this mobile number. Please edit the details.";
					}
				}
			
			console.log(response, 'response check');
			this.setState({ old_shipmentss: response, shipment_exist_msg });
			return response;
		} catch (error) {
			console.error('Error in checking mobile and shipment combo:', error);
		}
	};

	
	saveDriverItem(event){
		if(event){
			event.preventDefault();
		}
		// var driver_code = this.state.driver_code;
		var driver_name = this.state.driver_name;
		var mobile_no = this.state.mobile_no;
		
        if(this.state.assigned_truck_no.value != "")
        {
            var assigned_truck_no = this.state.assigned_truck_no.value;
		}
        else{
            var assigned_truck_no = "";
		}
		if(this.state.assigned_shipment_id.value != "")
        {
            var assigned_shipment_id = this.state.assigned_shipment_id.value;
		}
        else{
            var assigned_shipment_id = "";
		}
		// var is_active = this.state.is_active;
        
		var params = {
			// driver_code:driver_code,
			driver_name:driver_name,
            mobile_no:mobile_no,
			assigned_truck_no:assigned_truck_no,
			assigned_shipment_id:assigned_shipment_id,
			// sim_operator:this.state.mobile_network.value
			// is_active:is_active
		}
		console.log(params,"512 param save driver")
		if(driver_name != "" && mobile_no !="")
		{
			this.setState({
				loadshow:"show-m",
				overly:"show-m"
			});
			redirectURL.post("/chettinad/adddriver",params)
			.then((response) => {
				var resp = response.data
				// console.log(resp.message,"reponse 339")
				
					if(response.data.status == "Success")
						{
							this.setState({
								show:true,
								basicTitle:response.data.message,
								basicType:"success",
								driver_code:"",
								driver_name:"",
								assigned_truck_no:"",
								assigned_shipment_id:"",
								is_active:0,
								sliderAddTransporter:"",
								loadshow:"show-n",
								overly:"show-n"
							},()=>{
								this.OnLoadDrivers();
							})							
						}
						else{
							this.setState({
								show:true,
								basicTitle:response.data.message,
								sliderAddTransporter:"",
								basicType:"danger",
								loadshow:"show-n",
								// overly:"show-n"
							})
						}
				
				
			})
			.catch(e=>{
				console.log("Error :",e)
			})
		}
	}
	onClickEditBlock(rownode){
		// console.log("rownode ", rownode);
		this.setState({
			sliderEditTransporter:'slider-translate-50p',
			overly:"show-m",
			driver_code: rownode.data.driver_code,
			driver_name: rownode.data.driver_name,
            mobile_no: rownode.data.driver_mobile_no,
			assigned_truck_no: {value:rownode.data.assigned_truck_no,label:rownode.data.assigned_truck_no},
			assigned_shipment_id: {value:rownode.data.shipment_id,label:rownode.data.shipment_id},
			is_active: rownode.data.is_active,
			rowid:rownode.data._id
		},async () => {
			// Fetch and set the mobile and shipment data after state is updated
			const { assigned_shipment_id, mobile_no } = this.state;
			const mobiles = await this.fetchAndSetMobileAndShipmentData(assigned_shipment_id.value, mobile_no);
			// Perform additional logic as needed after the fetch
			const old_shipments = mobiles.filter(item => item.driver_mobile_no === mobile_no);
			const shipment_exist_msg = old_shipments.length > 0 ? "Shipment exists with this mobile number. Please edit the details." : "";
			this.setState({
				shipment_exist_msg,
				old_shipmentss:old_shipments
			});
		});
	}
	updateDriverItem(event){
		if(event){
			event.preventDefault();
		}
		// var driver_code = this.state.driver_code;
		var driver_name = this.state.driver_name;
        var mobile_no = this.state.mobile_no;
        // if(this.state.assigned_truck_no.value != "")
        // {
        //     var assigned_truck_no = this.state.assigned_truck_no.value;
		// }
        // else{
        //     var assigned_truck_no = "";
		// }
		if(this.state.assigned_shipment_id.value != "")
        {
            var assigned_shipment_id = this.state.assigned_shipment_id.value;
		}
        else{
            var assigned_shipment_id = "";
		}
		// var is_active = this.state.is_active;
		var params = {
			// driver_code:driver_code,
			driver_name:driver_name,
            mobile_no:mobile_no,
			// assigned_truck_no:assigned_truck_no,
			assigned_shipment_id:assigned_shipment_id,
			// is_active:is_active,
			rowid:this.state.rowid
			// for_code:for_code
		}
         console.log(params,"params update driver ")
		if(driver_name != "" && mobile_no !="")
		{
			redirectURL.post("/chettinad/updatedriver",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully added driver",
						basicType:"success",
						driver_code:"",
						driver_name:"",
						assigned_truck_no:"",
						assigned_shipment_id:"",
						is_active:0,
						sliderEditTransporter:"",
						overly:"show-n"
					}, () => {
						this.OnLoadDrivers();
					})
				}
				else{
					this.setState({
						show:true,
						basicTitle:response.data.message,
						basicType:"danger"
					})
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"All fields are mandatory",
				basicType:"danger"
			})
		}
	}
	closeAlert = () => {
        this.setState({
            show: false,
			check_Dup : false,
        });
		
    }
	// closeAlert1 = async () => {
    //     await this.setState({
    //         show1: false
    //     });
    //     await window.location.reload()
    // }
    onCellClicked = async(params) =>{
        if(params.colDef.field == "resend")
        {
			redirectURL.post("/chettinad/resendconsent",params.data).then((response)=>{
				if(response.data.status == "Success")
				{
					this.setState({
						show:true,
						basicTitle:response.data.message,
						basicType:"success",
						loadshow:"show-n",
						overly:"show-n",
						errmsg:false
					})
					this.OnLoadDrivers();
				}
				else{
					this.setState({
						show:true,
						basicTitle:response.data.message,
						basicType:"danger",
						loadshow:"show-n",
						overly:"show-n"
					})
				}
			})
            // redirectURL.post("/chettinad/resendconsent",params.data).then((response)=>{
            //     // console.log(typeof(response.data),"typeof");
            //     if(response.data.status == "Success")
			// 	{
			// 		this.setState({
			// 			show:true,
			// 			basicTitle:response.data.message,
			// 			basicType:"success",
			// 			loadshow:"show-n",
			// 			overly:"show-n"
			// 		})
			// 		this.OnLoadDrivers();
			// 	}
			// 	else{
			// 		this.setState({
			// 			show:true,
			// 			basicTitle:response.data.message,
			// 			basicType:"danger",
			// 			loadshow:"show-n",
			// 			overly:"show-n"
			// 		})
			// 	}
            // })
			// this.setState({
			// 	// forceclosedata:params.data,
			// 	// forceCloseRowNode : params,
			// 	sliderForceCloseTranslate:"slider-translate-40p",
			// 	overly:'show-m',
			// 	reasonforceclose:""
			// })
			
			
		}
		if(params.colDef.field == "verified")
		{
			// if(params.data.verified != 1)
			// {
				this.setState({
					loadshow:"show-m",
					overly:"show-m"
				})
				redirectURL.post("/chettinad/verifyconsent",params.data).then((response)=>{
					// console.log(typeof(response.data),"typeof");
					if(response.data.message == "Success")
					{
						this.setState({
							show:true,
							basicTitle:response.data.message,
							basicType:"success",
							loadshow:"show-n",
							overly:"show-n"
						})
						this.OnLoadDrivers();
					}
					else{
						this.setState({
							show:true,
							basicTitle:response.data.message,
							basicType:"danger",
							loadshow:"show-n",
							overly:"show-n"
						})
					}
				})
			// }
		}

		if(params.colDef.field == "logs")
		{
			
				this.setState({
					loadshow:"show-m",
					overly:"show-m"
				})
			redirectURL.post("/chettinad/getSimLogsData", { mobile_no: params.data.driver_mobile_no }).then((response) => {
				var records = response.data
				if (records.length > 0) {
					var records = records.sort(GetSortDescOrder("created_date"))
					this.setState({
						sim_logs_data: records,
						sliderForSimLogs : "slider-translate-60p",
						loadshow: "show-n",
					})

				} else {
					this.setState({
						sim_logs_data: [],
						sliderForSimLogs : "slider-translate-60p",
						loadshow: "show-n",
					})
				}
			})
		}
		// if(params.colDef.field == "comment")
		// {
		// 	if(params.data.comment != "" && params.data.comment != undefined)
		// 	{
		// 		this.setState({
		// 			loadshow:"show-m",
		// 			overly:"show-m"
		// 		})
		// 		console.log("params.data ", params.data)
		// 		// redirectURL.post("/chettinad/updateSIMTrackComment",params.data).then((response)=>{
		// 		// 	// console.log(typeof(response.data),"typeof");
		// 		// 	if(response.data.message == "Success")
		// 		// 	{
		// 		// 		this.setState({
		// 		// 			show:true,
		// 		// 			basicTitle:response.data.message,
		// 		// 			basicType:"success",
		// 		// 			loadshow:"show-n",
		// 		// 			overly:"show-n"
		// 		// 		})
		// 		// 		this.OnLoadDrivers();
		// 		// 	}
		// 		// 	else{
		// 		// 		this.setState({
		// 		// 			show:true,
		// 		// 			basicTitle:response.data.message,
		// 		// 			basicType:"danger",
		// 		// 			loadshow:"show-n",
		// 		// 			overly:"show-n"
		// 		// 		})
		// 		// 	}
		// 		// })
		// 	}
		// }
	}
	initalList =(event)=>{
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList;
		dataset = dataset.slice(0,100);
		
		this.setState({
			filteredData : dataset,
			showTrucksList : "show-m",
		})
	}
	handlerForm = (event) => {
		//console.log("Select", event.target.value);
		// let name = event.target.name;
		// let value = event.target.value;
		// this.setState({[name]:value});
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList;
		var filteredResult = dataset.filter(function(e){
			if(e != "" && e != null)
			{
				return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
			}
			
		});
		filteredResult = filteredResult.slice(0,100);
		var allrowData = this.state.allrowData;
		// console.log(allrowData,"allrowData")
		var rowData = allrowData.filter(function(e){
			if(e.assigned_truck_no != "" && e.assigned_truck_no != null)
			{
				return e.assigned_truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
			}
			
		})
		this.setState({
			filteredData : filteredResult,
			rowData : rowData,
			showTrucksList : "show-m",
		})
	}
	changeGPSStatus = () => {    
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		});
		var allrowData = this.state.allrowData;
		var gps_status = $("#gps_status").val();
		var rowData = [];
		if(gps_status == 1 || gps_status == "1")
		{
			var rowData = allrowData.filter((ed) => ed.consent_status == "ACTIVE");
		}
		if(gps_status == 2 || gps_status == "2")
		{
			var rowData = allrowData.filter((ed) => ed.consent_status == "NOT FOUND");
		}
		if(gps_status == 3 || gps_status == "3")
		{
			var rowData = allrowData.filter((ed) => ed.consent_status == "PENDING");
		}
		if(gps_status == 0 || gps_status == "0")
		{
			var rowData = allrowData;
		}
		$("#inputTruck").val("")
		this.setState({
			rowData : rowData,
			loadshow : "show-n",
			overly : "show-n"
		});
	}
	formHandler = async (event) => {
		this.setState({
			loadshow:'show-m'
		})
		event.preventDefault();
		var truckNo = $("#inputTruck").val().toUpperCase();
		var params = {
		}
		if(truckNo !=undefined && truckNo !="")
		{
			params.assigned_truck_no = truckNo;
		}
		if(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="" && localStorage.getItem("plant_code") !="undefined")
		{
			params.plant_code = localStorage.getItem("plant_code");
		}
		redirectURL.post('/chettinad/simdrivers',params)    
		.then((response) => {
			var records = response.data.drivers;
			//console.log("records", records);
			this.setState({
			  	rowData:records,
			  	loadshow:"show-n",
			  	overly:"show-n"
		  	});
		})
		.catch(function (error) {
		  	console.log(error);
			this.setState({
				loadshow:"show-n",
				overly:"show-n"
			});
		});
	}
	updateCellData(rownode){
		if(rownode.colDef.field == "comment"){
			// console.log("rownode ", rownode)
			if(rownode.data.comment != "" && rownode.data.comment != undefined)
			{				
				redirectURL.post("/chettinad/updateSIMTrackComment",rownode.data).then((response)=>{
					// console.log(typeof(response.data),"typeof");
					if(response.data.message == "Success")
					{
						this.setState({
							show:true,
							basicTitle:response.data.message,
							basicType:"success",
							loadshow:"show-n",
							overly:"show-n"
						})
						this.OnLoadDrivers();
					}
					else{
						this.setState({
							show:true,
							basicTitle:response.data.message,
							basicType:"danger",
							loadshow:"show-n",
							overly:"show-n"
						})
					}
				})
			}
		}
	}
	onClickShowSimTrackingLegs= async (rownode) =>  {
        // console.log("rownode", rownode)
        var commentHeaders = [
			{
				headerName : "Driver Name",
				field : "driver_name",
				resizable : true,
				width:120,
				filter : true
			},
			{
				headerName : "Driver Mobile No",
				field : "driver_mobile_no",
				resizable : true,
				width:200,
				filter : true
			},
			{
				headerName: "Added On",
				field: "added_on",
				resizable:true,
				width: 150,
				filter: true,
				valueGetter:function(params){
				  try{
					  if(params.data.added_on != "" && params.data.added_on != undefined)
					  {
						  return getHyphenDDMMMYYYYHHMM(params.data.added_on);
					  }
					  else{
						  return "";
					  }
				  }
				  catch(e){

				  }
				}
			},
        ]
        await this.setState({
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    var parameters = {
                        driver_id : param.data._id,
                    }
                    await redirectURL.post("/chettinad/getSimTrackingLogs",parameters).then(async (response) =>{
                        var output=response.data;
                        param.successCallback(output);                        
                    }).catch(function(error){
                        console.log(error);
                    })                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'simtrackinglogs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }


	onFilterByPhoneNumber = (event) => {
		event.preventDefault()
		// console.log(this.state.search_mobile_number , this.state.search_lps_devivery_no)
		var allrowData = this.state.allrowData
		if(![undefined , "undefined" , null , ""].includes(this.state.search_mobile_number)|| ![undefined , "undefined" , null , ""].includes(this.state.search_lps_devivery_no )){
			if(![undefined , "undefined" , null , ""].includes(this.state.search_mobile_number)){
				var rowData = allrowData.filter(e => e.driver_mobile_no == this.state.search_mobile_number)
				this.setState({
					rowData : rowData
				})
			}
			else if(![undefined , "undefined" , null , ""].includes(this.state.search_lps_devivery_no)){
				var rowData = allrowData.filter(e => e.shipment_id == this.state.search_lps_devivery_no.toUpperCase())
				this.setState({
					rowData : rowData
				})
			}
		}else{
			this.setState({
				show:true , 
				basicType:"danger",
				basicTitle:"Please Enter Either Phone Number Or LPS delivery no",
				rowData : allrowData
			})
		}
		
	}

	changeHandlerForSearch = (event) => {
		var name = event.target.name;
		var value = event.target.value;
		// console.log(name , "name")
		if(name == "search_mobile_number"){
			// console.log("check_1729")
			const alphanumericRegex = /^\d+$/
			if ((alphanumericRegex.test(value) && value.length <11) || value == "") {
				this.setState({
					[name]: value
				})
			}else{
				this.setState(prevState => {
					[name] = [prevState]
				})
			}
		}else{
			this.setState({[name]:value});

		}
		
	}

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		var listData = [] 
        this.state.filteredData.map(function(e){
			if(e!=undefined){
				listData.push(<li className="dropdown-truck-no" id={e}>{e}</li>)
			}
        	
        })
		const columnwithDefs = [
			{
				headerName: "Action",
				field: "_id",
				pinned: 'left',
				resizable:true,
				width: 100,
				filter: false,
				cellRenderer:'EditItem',
				editable:false
			  },
			  {
				headerName: "IVR Logs",
				field: "logs",
				pinned: 'left',
				resizable:true,
				width: 100,
				filter: false,
				cellRenderer:function(params){
					var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-warning" title=" Logs">View</button>'
					return htmloption;
				},  
				editable:false
			  },
				{
					headerName: "",
					field: "simtrackinglogs",
					width: 50,
					pinned: 'left',
					cellRenderer:'Simtrackinglogs',
					filter: false,resizable: true
				},  
	  	        {
	  	              headerName: "Driver Name",
	  	              field: "driver_name",
	  	              width: 150,
	    	          editable:false,
					  resizable:true,
	    	          filter: true,
					  pinned: "left"
		        },
		        {
					headerName: "Mobile Number",
					field: "driver_mobile_no",
				  resizable:true,
					width: 110,
				  editable:false,
				  filter: true,
				  pinned: "left"
			  },
			  {
				headerName: "Mobile Operator",
				field: "mobile_operator_name_from_jio_recharge",
				resizable:true,
				width: 100,
				editable:false,
				filter: true,
				pinned: "left",
				valueGetter:function(params){
					if(params.data.mobile_operator_name_from_jio_recharge == "Jio" ){
						return "JIO"
					}else if(params.data.mobile_operator_name_from_jio_recharge == "non_jio"){
						return "NON-JIO"
					}else{
						return ""
					}
				}
			},
			  {
				headerName: "Truck No",
				field: "truck_no",
			  resizable:true,
				width: 110,
			  editable:false,
			  filter: true,
			  pinned: "left"
		  },
				{
                    headerName: "LPS (Delivery No)",
                    field: "shipment_id",
                    resizable:true,
                    width: 130,
                    editable:false,
                    filter: true,
					pinned: "left"
                },
				

				{
					headerName: "Delivery Created On Date",
					field: "shipment_time",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
						  {
							  return getHyphenDDMMMYYYY(params.data.shipment_time);
						  }
						  else{
							  return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				  },
				  {
					headerName: "Delivery Created On Time",
					field: "shipment_time",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
						  {
							  return getHyphenHHMM(params.data.shipment_time);
						  }
						  else{
							  return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				  },
				{
                    headerName: "Consent Status",
                    field: "last_consent_status",
                    resizable:true,
                    width: 150,
                    editable:false,
					valueGetter: function(params){
						try{
							if(params.data.last_consent_status == 1 || params.data.last_consent_status == 2||params.data.last_consent_status == 14 || params.data.last_consent_status == 5)
							{
								return "Not Registered";
							}
							if(params.data.last_consent_status == 3||params.data.last_consent_status == 18)
							{
								return "Approved";
							}
							if(params.data.last_consent_status == 4||params.data.last_consent_status == 7||params.data.last_consent_status == 12||
								params.data.last_consent_status == 15||params.data.last_consent_status == 16||
								params.data.last_consent_status == 17||params.data.last_consent_status == 19||
								params.data.last_consent_status == 20||params.data.last_consent_status == 21||
								params.data.last_consent_status == 23||params.data.last_consent_status == 24||
								params.data.last_consent_status == 25||params.data.last_consent_status == 26)
							{
								return "Consent Approved";
							}
							if(params.data.last_consent_status == 6 ||params.data.last_consent_status == 11||params.data.last_consent_status == 22)
							{
								return "Consent Pending";
							}
							if(params.data.last_consent_status == 8 ||params.data.last_consent_status ==10)
							{
								return "Consent Declined";
							}
							if(params.data.last_consent_status == 9 ||params.data.last_consent_status ==18)
							{
								return "Approved";
							}
							if(params.data.last_consent_status == 13)
							{
								return "Error";
							}
							if(params.data.last_consent_status == 14)
							{
								return "Not Registered";
							}
							
						}
						catch(e){
							// console.log(e)
						}
					},
                    filter: true
                },
				{
					headerName: "Resend Consent",
					field: "resend",
					resizable:true,
					width: 130,
					filter: false,
					cellRenderer:function(params){
						var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-info" title="Resend Consent">Resend</button>'
						return htmloption;
					},  
					editable:false
				  },
				  {
					headerName: "Resend Consent On",
					field: "resend_on",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.resend_on != "" && params.data.resend_on != undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.resend_on);
						  }
						  else{
							  return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				  },
				 
				  {
                    headerName: "Plant Code",
                    field: "consigner_code",
                    resizable:true,
                    width: 130,
                    editable:false,
                    filter: true,
					// pinned: "left"
                },
				 
				  {
					headerName: "Transporter Name",
					field: "transporter_name",
					resizable:true,
					width: 120,
					filter: false,
					editable:false
				  },
				  {
					headerName: "Transporter Code",
					field: "transporter_code",
					resizable:true,
					width: 120,
					filter: false,
					editable:false
				  },
				 
				  {
					headerName: "Comment",
					field: "comment",
					resizable:true,
					width: 130,
					filter: true,  
					editable:true,
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: [
							'',
							'Consent message not available',
							'Consent approved - No confirmation message',
							'Consent approved success',
							'Already approved',
							'Deactivated',
							'Error in resend',
							'Others',
							'Issue in approval process'
						],
					},
				},
				
                // {
                //     headerName: "Status",
                //     field: "is_active",
                //     resizable:true,
                //     width: 120,
                //     editable:false,
                //     filter: true,
                //     valueGetter:function(params){
                //         try{
                //             if(params.data.is_active == 1){
                //                 return "Active";
                //             }
                //             else{
                //                 return "Inactive";
                //             }
                //         }
                //         catch(e){}
                //     }
                // },
				{
                    headerName: "consent status value",
                    field: "last_consent_status",
                    resizable:true,
					filter:true,
                    hide:true
                },
                {
                    headerName: "Attended",
                    field: "verified",
                    resizable:true,
					filter:true,
                    width: 150,
                    editable:false,
					cellRenderer:function(params){
						// if(params.data.verified == 1)
						// {
						// 	var htmloption = 'Attended'
						// 	return htmloption;
						// }
						// else
						// {
							var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-warning" title="Marks As Attended">Attended</button>'
							return htmloption;
						// }
					},  
                },
				
				{
					headerName: "Attended On",
					field: "mark_as_verified_on",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.mark_as_verified_on != "" && params.data.mark_as_verified_on != undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.mark_as_verified_on);
						  }
						  else{
							  return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				},
				
				{
					headerName: "Commented On",
					field: "commented_on",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.commented_on != "" && params.data.commented_on != undefined)
						  {
							return getHyphenDDMMMYYYYHHMM(params.data.commented_on);
						  }
						  else{
							return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				},
				
				{
					headerName: "Created On",
					field: "created_on",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.created_on != "" && params.data.created_on != undefined)
						  {
							return getHyphenDDMMMYYYYHHMM(params.data.created_on);
						  }
						  else{
							return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				},
				
				{
					headerName: "Modified On",
					field: "modified_on",
					resizable:true,
					width: 150,
					filter: true,
					valueGetter:function(params){
					  try{
						  if(params.data.modified_on != "" && params.data.modified_on != undefined)
						  {
							return getHyphenDDMMMYYYYHHMM(params.data.modified_on);
						  }
						  else{
							return "";
						  }
					  }
					  catch(e){
  
					  }
					},
					editable:false
				},
				// {
                //     headerName: "JIO Status",
                //     field: "jio_status",
                //     resizable:true,
                //     width: 130,
                //     editable:false,
                //     filter: true
                // },
				// {
                //     headerName: "Telenity Status",
                //     field: "telenity_status",
                //     resizable:true,
                //     width: 130,
                //     editable:false,
                //     filter: true
                // },	  	        
	    ];

		var updateShipmentColumns = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                pinned: "left",
                valueGetter: function (params) {
                    if (params.data.truck_no != undefined && params.data.truck_no != "") {
                        return params.data.truck_no;
                    }
                    else {
                        return "No Data From API";
                    }
                }
            },
            {
                headerName: "Invoice No (API)",
                field: "invoice_no",
                width: 150,
            },
            {
                headerName: "LPS(Delivery No) (API)",
                field: "shipment_id",
                width: 140,
            },
            {
                headerName: "LPS Date (API)",
                field: "shipment_time",
                width: 100,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipment_time == "Yet to Invoice") {
                            return "Yet to Invoice"
                        }
                        if (params.data.shipment_time != "" && params.data.shipment_time != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipment_time);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
                // comparator: dateComparator,
            },
            {
                headerName: "LPS Time (API)",
                field: "shipment_time",
                width: 100,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipment_time == "Yet to Invoice") {
                            return "Yet to Invoice"
                        }
                        if (params.data.shipment_time != "" && params.data.shipment_time != undefined) {
                            return getHyphenHHMM(params.data.shipment_time);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
                // comparator: dateComparator,
            },
            {
                headerName: "Driver Name (API)",
                field: "driver_name",
                width: 140,
                editable: true, 
                cellEditorFramework: (props) => {
                    // Store the original value in a ref to avoid re-renders
                    const originalValueName = React.useRef(props.value);
                    const [value, setValue] = React.useState(props.value);

                    // Update value when the input changes
                    const handleChange = (event) => {
                        const newValue = event.target.value;
                        // Ensure the value contains only alphabetic characters and spaces
                        if (/^[a-zA-Z\s]*$/.test(newValue)) {
                            setValue(newValue);
                        }
                    };

                    // Handle key down to allow only alphabetic characters, spaces, and backspace
                    const handleKeyDown = (event) => {
                        const charCode = event.which ? event.which : event.keyCode;
                        if (
                            (charCode >= 65 && charCode <= 90) || // Uppercase alphabetic keys
                            (charCode >= 97 && charCode <= 122) || // Lowercase alphabetic keys
                            charCode === 8 || // Backspace key
                            charCode === 32 // Space key
                        ) {
                            return;
                        } else {
                            event.preventDefault();
                        }
                    };

                    // Update the cell value and stop editing
                    const onBlur = () => {
                        if (value !== originalValueName.current) {
                            props.node.setDataValue(props.column.colId, value);
                            props.api.stopEditing();
                        }
                    };

                    // Optional: You might want to handle key press 'Enter' to save changes
                    const handleKeyPress = (event) => {
                        if (event.key === 'Enter') {
                            onBlur();
                        }
                    };

                    return (
                        <input
                            type="text"
                            value={value}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onBlur={onBlur}
                            onKeyPress={handleKeyPress}
                            style={{ width: '100%' }}
                            autoFocus // Automatically focus the input when editing starts
                        />
                    );
                }
            },
            {
                headerName: "Driver Mobile No (API)",
                field: "driver_mobile_no",
                width: 140,
                editable: true,
                cellEditorFramework: (props) => {
                    // Store the original value in a ref to avoid re-renders
                    const originalValueRef = React.useRef(props.value);
                    const [value, setValue] = React.useState(props.value);
            
                    // Update value when the input changes
                    const handleChange = (event) => {
                        const newValue = event.target.value;
                        // Ensure the value is numeric
                        if (/^\d*$/.test(newValue)) {
                            setValue(newValue);
                            this.setState({ originalValue: originalValueRef.current });
                        }
                    };
            
                    // Handle key down to allow only numeric values and backspace
                    const handleKeyDown = (event) => {
                        const charCode = event.which ? event.which : event.keyCode;
                        if (
                            (charCode >= 48 && charCode <= 57) || // Numeric keys
                            charCode === 8 // Backspace key
                        ) {
                            return;
                        } else {
                            event.preventDefault();
                        }
                    };
            
                    // Update the cell value and stop editing
                    const onBlur = () => {
                        if (value !== originalValueRef.current) {
                            props.node.setDataValue(props.column.colId, value);
                            props.api.stopEditing();
                        }
                    };
            
                    // Optional: You might want to handle key press 'Enter' to save changes
                    const handleKeyPress = (event) => {
                        if (event.key === 'Enter') {
                            onBlur();
                        }
                    };
            
                    return (
                        <input
                            type="text"
                            value={value}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onBlur={onBlur}
                            onKeyPress={handleKeyPress}
                            style={{ width: '100%' }}
                        />
                    );
                }
            },
            {
				headerName: "Update",
				field: "_id",
				width: 100,
				cellRenderer: 'UpdateShipments'

			},
        ]

		var columnwithdefsForSimLogs = [
			{
				headerName: "Mobile No",
				field: "mobile_no",
				width: 120,
				filter: true,
				resizable: true,
				sortable:true,
			},
			{
				headerName: "Actual Consent Status",
				field: "actual_consent_status",            
				width: 150,
				filter: true,
				resizable: true,
				sortable:true,
				hide:true
			},
			{
				headerName: "Consent Status",
				field: "processed_consent_status",            
				width: 150,
				filter: true,
				resizable: true,
				sortable:true,
			},
			// {
			// 	headerName: "Expected Entity Status",
			// 	field: "expected_entity_status",            
			// 	width: 150,
			// 	filter: true,
			// 	resizable: true,
			// 	sortable:true,
			// },
			{
				headerName: "Consent Created at Service Provider",
				field: "consent_created_date_at_telenity",
				width: 200,
				filter: true,
				resizable: true,
				sortable:true,
				valueGetter:function(params){
					if(params.data.consent_created_date_at_telenity !=undefined && params.data.consent_created_date_at_telenity !="")
					{
						return getHyphenDDMMYYYYHHMMSS(params.data.consent_created_date_at_telenity);
					}
					else
					{
						return "";
					}
				}
			},
			// {
			// 	headerName: "Source From",
			// 	field: "source_from",
			// 	width: 150,
			// 	filter: true,
			// 	resizable: true,
			// 	sortable:true,
			// },
			{
				headerName: "Source Type",
				field: "source_type",
				width: 150,
				filter: true,
				resizable: true,
				sortable:true,
			},
			{
				headerName: "Created Date",
				field: "created_date",
				width: 150,
				filter: true,
				resizable: true,
				sortable:true,
				valueGetter:function(params){
					if(params.data.created_date !=undefined && params.data.created_date !="")
					{
						return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
					}
					else
					{
						return "";
					}
				}
			},
			{
				headerName: "Modified Date",
				field: "modified_date",
				width: 150,
				filter: true,
				resizable: true,
				sortable:true,
				valueGetter:function(params){
					if(params.data.modified_date !=undefined && params.data.modified_date !="")
					{
						return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
					}
					else
					{
						return "";
					}
				}
			},
		]
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
				{...(this.state.check_Dup && {
					onCancel: this.onClickGetSliderForUpdateShipment,
					showCancel: true,
					confirmBtnText: "Cancel",
					cancelBtnText: "Check Duplicate Drivers"
				  })}
	        > 
	        </SweetAlert>
			{/* <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert> */}
	            {this.state.alert}
				<div className="row">
					<div className="row col-sm-12">
						
						<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.onFilterByPhoneNumber.bind(this)}>
							<div className="col-xl-3 col-lg-3 form-group">
								<input type="text" placeholder="Enter Mobile No" onChange={this.changeHandlerForSearch} value={this.state.search_mobile_number} id="search_mobile_number" name="search_mobile_number" className="form-control"  />
							</div>
							<p style={{marginTop:"10px"}}> OR </p>
							<div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="search_lps_devivery_no" id="search_lps_devivery_no" value = {this.state.search_lps_devivery_no} placeholder="Search By LPS Delivery No" className="form-control" onChange={this.changeHandlerForSearch}/>
							</div>
							<div className="col-xl-2 col-lg-2 form-group">
								<button type="submit" className="btn btn-info">Find</button>
							</div>
						</form>
					</div>
				</div>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									{/*
									<button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Testing</button>
				       				*/}
									   <button className={"float-right btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        	<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className={"float-right btn btn-info f14 "} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									<button type="button" onClick={this.onCLickAddDriver.bind(this)} className="btn btn-warning pull-right">Add Driver</button>
									{this.state.shipmentIds.length > 0 && (this.state.check_domain.test(this.state.email.trim().toLowerCase()) || ["jayakumar.n@chettinadcement.com","elangovan.m@chettinadcement.com"].includes(this.state.email))? <button className={"float-right btn btn-success f14 "} onClick={this.onClickGetSliderForUpdateShipment.bind(this)}>
										Check Duplicate Drivers
									</button> : <></>}
								</h5>
				   			</div>				   			
		            		<div className="row card-body">
								<div className='col-xl-12'>
									<p style={{float:'right', marginBottom:"10px" ,fontWeight:'500' , fontSize:'14px' ,color:'#333333'}}> For Approving Consent use the mentioned numbers <i style={{color:'#333333'}} className="fa fa-arrow-right"></i>  <span style={{color:'#e52424'}}>NON-JIO 7303777791 (IVR NO)</span> | <span style={{color:'#3234a8'}}>JIO 9982256700 (MISSED CALL NO)</span> </p>
								</div>
								<div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={false}
										paginationPageSize={this.state.paginationPageSize}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										pagination={true}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										deleteRowHandler={this.deleteRowHandler}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										onCellClicked={this.onCellClicked}
										onCellEditingStopped={this.updateCellData.bind(this)}
									/>
								</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>

				 <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r " + (this.state.sliderAddTransporter)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Driver
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveDriverItem.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Driver Code*</label>
							    <input type="text" style={{marginLeft:'12px'}} placeholder="Enter Driver Code" onChange={this.changeHandler.bind(this)} value={this.state.driver_code} id="driver_code" name="driver_code" className="form-control" required />
							</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Name*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Name" onChange={this.changeHandler.bind(this)} value={this.state.driver_name} id="driver_name" name="driver_name" className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Mobile Number*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Mobile Number" onChange={this.changeMobileNoHandler} onBlur={this.OnBlurMobile.bind(this)} value={this.state.mobile_no} id="mobile_no" name="mobile_no" className="form-control" required />
									{(this.state.err_mobile != "") ?
										<span style={{ fontSize: "11px", color: "#ff0000" }}>{this.state.err_mobile}</span>
										: ""}
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Truck No*</label>
							    <Select 
                                    className="col-sm-12" 
                                    name="assigned_truck_no" 
                                    id="assigned_truck_no" 
                                    onChange={this.onChangeTruck.bind(this)} 
                                    value={this.state.assigned_truck_no} 
                                    options={this.truckslist()}
                                    required ={false}
                                />
							</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> LPS (Delivery No) / Shipment Id*</label>
									<Select
										className="col-sm-12"
										name="assigned_shipment_id"
										id="assigned_shipment_id"
										onChange={this.onChangeShipment.bind(this)}
										value={this.state.assigned_shipment_id}
										options={this.shipmentslist()}
										required={false}
									/>
								</div>
								<div className="col-xl-12 col-lg-12">
									{this.state.old_shipmentss.length > 0 && this.state.edit_clicked && (
										<div style={{ fontSize: "16px", color: "#a83262", marginBottom: '12px' }}>
											Existing Shipments
										</div>
									)}
									{this.state.old_shipmentss.length > 0 && this.state.edit_clicked ? (
										this.state.old_shipmentss.map((record, index) => (
											<div
												className="row form-group"
												style={{ marginBottom: '10px', marginTop: '12px' }}
												key={index}
											>
												<div className="col-xl-3 col-lg-3">
													<label>LPS No</label>
													<input
														type="text"
														value={record.shipment_id}
														readOnly
														className="form-control"
													/>
												</div>

												<div className="col-xl-3 col-lg-3">
													<label>Driver Name*</label>
													<input
														type="text"
														value={record.driver_name}
														onChange={(e) => this.handleAlphanumericChange(e, index)}
														className="form-control"
													/>
												</div>

												<div className="col-xl-3 col-lg-3">
													<label>Mobile No*</label>
													<input
														type="text"
														value={record.driver_mobile_no}
														onChange={(e) => this.handleNumericChange(e, index)}
														maxLength="10"
														className="form-control"
														pattern="\d*"
													/>
												</div>
												<div className="col-xl-3 col-lg-3">
													<label>Shipment Time</label>
													<input
														type="text"
														value={getHyphenDDMMMYYYYHHMM(record.shipment_time)}
														readOnly
														className="form-control"
													/>
												</div>

											</div>
										))
									) : (
										""
									)}
								</div>

								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> SIM Operator*</label>
							    <Select
								className="col-sm-12"
								name="mobile_network"
								id="mobile_network"
								value={this.state.mobile_network}
								onChange={this.newFilter.bind(this, "mobile_network")}
								options={this.state.sim_tracking_service_provider}
                                required ={true}
										/>
							</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-5 col-lg-5"> Is Active</label>
								<div className="col-sm-7">
									<input type="radio" onChange={this.changeHandler.bind(this)} value="1" id="is_active" name="is_active" required /> Yes&nbsp;
									<input type="radio" onChange={this.changeHandler.bind(this)} value="0" id="is_active" name="is_active" required /> No
								</div>
							</div> */}
								{(this.state.shipment_exist_msg !== "" && this.state.assigned_shipment_id != "") ? (
									<div style={{ fontSize: "14px", color: "#ff0000", marginBottom: '12px', marginLeft: '10px' }}>
										{this.state.shipment_exist_msg}
									</div>
								) : <></>}
								{this.state.shipment_exist_msg.length == 0 && this.state.edit_clicked != true ?
									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
									: this.state.shipment_exist_msg.length == 0 && this.state.edit_clicked == true ?
										<div className="col-xl-12 col-lg-12 form-group mt-12">
											<button type="button" className="btn btn-success" onClick={this.submitoldshipmentrecords.bind(this, "add_driver")}>Submit and Update</button>
										</div> :
										<div className="col-xl-12 col-lg-12 form-group d-flex">
											<button type="button" className="btn btn-danger mr-4" onClick={this.oldShipmentsIgnore.bind(this)}>Ignore and Submit</button>
											<button type="button" className="btn btn-success" onClick={this.oldShipmentsEdit.bind(this)}>Edit Details</button>
										</div>
								}
							</form>

						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderEditTransporter)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Edit Driver
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateDriverItem.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Driver Code*</label>
							    <input type="text" style={{marginLeft:'12px'}} placeholder="Enter Driver Code" onChange={this.changeHandler.bind(this)} value={this.state.driver_code} id="driver_code" name="driver_code" className="form-control" required />
							</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Name*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Driver Name" onChange={this.changeHandler.bind(this)} value={this.state.driver_name} id="driver_name" name="driver_name" className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Mobile Number*</label>
									<input type="text" style={{ marginLeft: '12px' }} placeholder="Enter Mobile Number" onChange={this.changeMobileNoHandler} onBlur={this.OnBlurMobile.bind(this)} value={this.state.mobile_no} id="mobile_no" name="mobile_no" className="form-control" required />
									{(this.state.err_mobile != "") ?
										<span style={{ fontSize: "11px", color: "#ff0000" }}>{this.state.err_mobile}</span>
										: ""}
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Truck No*</label>
							    <Select 
                                    className="col-sm-12" 
                                    name="assigned_truck_no" 
                                    id="assigned_truck_no" 
                                    onChange={this.onChangeTruck.bind(this)} 
                                    value={this.state.assigned_truck_no} 
                                    options={this.truckslist()}
                                    required ={false}
                                />
							</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> LPS (Delivery No) / Shipment Id*</label>
									<Select
										className="col-sm-12"
										name="assigned_shipment_id"
										id="assigned_shipment_id"
										onChange={this.onChangeShipment.bind(this)}
										value={this.state.assigned_shipment_id}
										options={this.shipmentslist()}
										required={false}
									/>
								</div>
								<div className="col-xl-12 col-lg-12">
									{this.state.old_shipmentss.length > 0 && this.state.edit_clicked && (
										<div style={{ fontSize: "16px", color: "#a83262", marginBottom: '12px' }}>
											Existing Shipments
										</div>
									)}
									{this.state.old_shipmentss.length > 0 && this.state.edit_clicked ? (
										this.state.old_shipmentss.map((record, index) => (
											<div
												className="row form-group"
												style={{ marginBottom: '10px', marginTop: '12px' }}
												key={index}
											>
												<div className="col-xl-3 col-lg-3">
													<label>LPS No</label>
													<input
														type="text"
														value={record.shipment_id}
														readOnly
														className="form-control"
													/>
												</div>

												<div className="col-xl-3 col-lg-3">
													<label>Driver Name*</label>
													<input
														type="text"
														value={record.driver_name}
														onChange={(e) => this.handleAlphanumericChange(e, index)}
														className="form-control"
													/>
												</div>

												<div className="col-xl-3 col-lg-3">
													<label>Mobile No*</label>
													<input
														type="text"
														value={record.driver_mobile_no}
														onChange={(e) => this.handleNumericChange(e, index)}
														maxLength="10"
														className="form-control"
														pattern="\d*"
													/>
												</div>
												<div className="col-xl-3 col-lg-3">
													<label>Shipment Time</label>
													<input
														type="text"
														value={getHyphenDDMMMYYYYHHMM(record.shipment_time)}
														readOnly
														className="form-control"
													/>
												</div>
											</div>
										))
									) : (
										""
									)}
								</div>

								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> SIM Operator*</label>
							    <Select
								className="col-sm-12"
								name="mobile_network"
								id="mobile_network"
								value={this.state.mobile_network}
								onChange={this.newFilter.bind(this, "mobile_network")}
								options={this.state.sim_tracking_service_provider}
                                required ={true}
										/>
							</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-5 col-lg-5"> Is Active</label>
								<div className="col-sm-7">
									<input type="radio" onChange={this.changeHandler.bind(this)} value="1" id="is_active" name="is_active" required /> Yes&nbsp;
									<input type="radio" onChange={this.changeHandler.bind(this)} value="0" id="is_active" name="is_active" required /> No
								</div>
							</div> */}
								{(this.state.shipment_exist_msg !== "" && this.state.assigned_shipment_id != "") ? (
									<div style={{ fontSize: "14px", color: "#ff0000", marginBottom: '12px', marginLeft: '10px' }}>
										{this.state.shipment_exist_msg}
									</div>
								) : <></>}
								{this.state.shipment_exist_msg.length == 0 && this.state.edit_clicked != true ?
									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
									: this.state.shipment_exist_msg.length == 0 && this.state.edit_clicked == true ?
										<div className="col-xl-12 col-lg-12 form-group mt-12">
											<button type="button" className="btn btn-success" onClick={this.submitoldshipmentrecords.bind(this, "edit_driver")}>Submit and Update</button>
										</div> :
										<div className="col-xl-12 col-lg-12 form-group d-flex">
											<button type="button" className="btn btn-danger mr-4" onClick={this.oldShipmentsIgnore.bind(this)}>Ignore and Submit</button>
											<button type="button" className="btn btn-success" onClick={this.oldShipmentsEdit.bind(this)}>Edit Details</button>
										</div>
								}
							</form>

						</div>
					</div>
				</div>
				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Select SIM Operator
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideForForceClosure.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
							<div className="col-xl-12 co-xl-12">
                    <div className="form-group col-xl-12 col-lg-12">
                            <label> </label>
							<Select
								className="border-radius-0"
								isMulti={false}
								id="mobile_network"
								style={{ borderRadius: "0px" }}
								value={this.state.mobile_network}
								onChange={this.newFilter.bind(this, "mobile_network")}
								options={this.state.sim_tracking_service_provider}
                                required ={true}
										/>
                    </div>
                    
                    <div className="form-group col-xl-12 col-lg-12">
                        <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
						{this.state.errmsg ? <p className="txt-danger">*Please select SIM Operator</p> : ""}
                    </div>
                  
                </div>
							
                        </div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.sliderForSimLogs)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							IVR Logs
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<div id="myGrid" style={{ height: "100vh", width: "100%" , padding:"15px"}} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithdefsForSimLogs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.sim_logs_data}
									enableCharts={false}
									paginationPageSize={this.state.paginationPageSize}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									pagination={true}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									deleteRowHandler={this.deleteRowHandler}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									onCellClicked={this.onCellClicked}
									onCellEditingStopped={this.updateCellData.bind(this)}
								/>
							</div>

                        </div>
					</div>
				</div>	

				{this.state.sliderUpdateShipment != '' ?
					<div className={"slide-r " + (this.state.sliderUpdateShipment)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Update Shipments
								<span className="float-right closebtn" style={{ marginRight: "25px" }}
									onClick={this.onClickHideAll.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<div id="myGrid1" style={{ width: "100%", height: "95vh" }} className={"ag-theme-balham"}>
									<AgGridReact
										modules={this.state.modules}
										columnDefs={updateShipmentColumns}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.allShipmentRows}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady1}
										onGridState={this.onGridState1}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										// detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										// enableCellChangeFlash={false}
										// rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										// masterDetail={true}
										getRowStyle={this.getRowStyle.bind(this)}
										// rowSelection={this.state.rowSelection}
										suppressRowClickSelection={false}
									// onPaginationChanged={this.resetPaginationSelection}

									/>
								</div>
							</div>
						</div>
					</div>
					: ""}
            </div>
              	
		);
	}
}


function GetSortASCOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 