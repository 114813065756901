import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import { 
	getDDMMYYYYHHMMSS, 
	getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYY,
    getHyphenHHMM
	
} from '../common/utils'; 
import MapRoutes from './maproute';
import LoadingAreaLegs from "./loadingarealegs";
import LegRouteMap from "./legroutemap";
import ShipmentLegs from "./shipmentlegs";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import DrawMap from '../common/drawmap';
import TruckPlantIn from './TruckPlantBtnComponent';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');
export default class ChettinadReturntoPlant extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
			rowModelType: "serverSide",
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                MapRoutes:MapRoutes,
                LoadingAreaLegs:LoadingAreaLegs,
                ShipmentLegs:ShipmentLegs,
                LegRouteMap:LegRouteMap,
                consignmentActions: ConsignmentActions,
                TruckPlantIn:TruckPlantIn
			},
            sliderRouteTranslate:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
				]
            },
            animateRows: true,
            
			detailCellRendererParams:{},
            totalcount:[],
            simcount:[],
            gpscount:[],
            intransitmy:[],
            insidemytpa:[],
            loadingstarted:[],
            loadingcompleted:[],
            travellingtocustomer:[],
            insidetpaafterloading:[],
            intransittocustomer:[],
            nearcustomer:[],
            reachedcustomer:[],
            leftcustomer:[],
            forceclose:[],
            insidetpa:[],
            simconsentpending:[],
            shipmentwithouttrips:[],
            dsc:[],
            crm:[],
            platemill:[],
            pipemill:[],
            mys:[],
            dsclc:[],
            crmlc:[],
            platemilllc:[],
            myslc:[],
            loadingarea:[],
           
            weighbridge:[],
            parkingarea:[],
            nonloadingarea:[],
            ma:[],
            mb:[],
            mc:[],
            md1:[],
            md2:[],
            md3:[],
            md4:[],
            me:[],
            nma:[],
            dsc1:[],
            dsc2:[],
            dsc3:[],
            dsc4:[],
            dsc5:[],
            dsc6:[],
            dsc7:[],
            dsc8:[],
            crm1:[],
            crm2:[],
            crm3:[],
            crm4:[],
            crm5:[],
            crm6:[], 
            crm7:[],       
            platemill1:[],
            platemill2:[],
            platemill3:[],
            platemill4:[],            
            pipemill1:[],
            pipemill2:[],
            pipemill3:[],
            pipemill4:[],
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
            shownonloadingarea:"show-n",
            emptyload:[],
            partialload:[],
            completeload:[],
            usergridstate:[],
			screenurl: "",
			screentitle: "",
            map_type_of_tracking: "",
            nonmys : [],
            noncrm : [],
            nondsc : [],
            nonplatemill : [],
            nonpipemill : [],
            nonnma : [],
            nonothers : [],
            expandNonLodingArea: "inline-show-m",
            closeNonLodingArea: "inline-show-n",
            plant_loading_areas:[],
            loadingareaCountersData:[],
            parkingyard:[],
            showLoadingArea:"show-n",
            baseRouteCoords: "",
            mapinvoice_no:"",
            consigner_coordinates:[]
        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
		// this.onClickTruckPlantIn = this.onClickTruckPlantIn.bind(this);
    }
    componentDidMount(){
        if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0)
		{
			this.setState({
				sideBar: {
					toolPanels: [
						{
							id: "columns",
							labelDefault: "Columns",
							labelKey: "columns",
							iconKey: "columns",
							toolPanel: "agColumnsToolPanel"
						},
						{
							id: "filters",
							labelDefault: "Filters",
							labelKey: "filters",
							iconKey: "filter",
							toolPanel: "agFiltersToolPanel"
						}
					]
				}
			});
		}
        this.onLoadGetShipments();

        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
        .then(async (resp) => {
            await this.setState({
                usergridstate: resp.data
            });
            await this.restoreGridStates();
        });
    }
    onLoadGetShipments(){
        this.setState({
            loadshow:"show-m"
        })
        var parameters = {}
        if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
        {
            parameters.plant_code = (localStorage.getItem("pc"));
        }
		if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
		{
			parameters.transporter_code = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			parameters.customer_code = localStorage.getItem("customer_code")
		}
        console.log(parameters , "parameters_1729")
        redirectURL.post("/chettinad/returntoplant",parameters)
        .then(async(response) => {
            var records = JSON.parse(response.data.truck_data_for_dispatch_plan)

            if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
        {
            var plant_code = JSON.parse(localStorage.getItem("pc"))
            records = records.filter(e => e.plant_code == plant_code[0])
        }
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
		{
			var transporter_code = JSON.parse(localStorage.getItem("transportercode"))
            records = records.filter(e => e.transporter_code == transporter_code[0])

		}

            console.log(records , "records")
            this.setState({
                rowData:records,
                loadshow:'show-n',
                overly:'show-n'
            })
            
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onShowRouteDiv = (e) => {		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no,
            mapinvoice_no:e.invoice_no,
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.timestamp != "" && e.timestamp != undefined)
        {
            var ptime = moment.parseZone(e.timestamp).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        
        var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		// var legs = JSON.parse(e.leg_details);
		// legs.map((item) => {
		// 	consignmentcodes.push(item.consignment_code)
		// })
		
        var reqData = {
            truck_no:e.truck_no,
			fromdate:ptime,
			todate:end_date,
			screen:"return_to_plant",
            email:localStorage.getItem("email")
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/trucks/truckmap',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)
			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            console.log(records , "records_1729")
                            var plant_coordiantes = JSON.parse(e.consigner_coordinates)
                            plant_coordiantes.name = e.plant_name
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                                map_type_of_tracking:records.type_of_tracking,
                                consigner_coordinates: plant_coordiantes,
                                baseRouteCoords: records.base_route

                            });
                        }
                    }
				}
		})
		.catch(function (error) {
			console.log(error);
		});
	}
    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			sideBar: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}

    onShowEnrouteRouteMap = (e) => {		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.first_inside_fence != "" && e.first_inside_fence != undefined)
        {
            var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        if(e.first_outside_fence != "" && e.first_outside_fence != undefined)
        {
            var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");        
        }
        else{
            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
		
        var reqData = {
            truck_no:e.truck_no,
			// consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"truck"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
    onClickCounter(parm){
        // this.setState({
        //     loadshow:"show-m"
        // })
        console.log("onClickCounter_parm",parm)
        if(parm == "0"){
            this.setState({
                rowData:this.state.totalcount,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        }
        if(parm == "simcount"){
            this.setState({
                rowData:this.state.simcount,
                loadshow:"show-n"
            })
        }
        if(parm == "gpscount"){
            this.setState({
                rowData:this.state.gpscount,
                loadshow:"show-n"
            })
        }
        
        if(parm == "1"){
            this.setState({
                rowData:this.state.insidetpa,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        } 
        if(parm == "2"){
            this.setState({
                rowData:this.state.intransitmy,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        }
        if(parm == "3"){
            this.setState({
                rowData:this.state.insidemytpa,
                showLoadingArea:"show-m",
                loadshow:"show-n"
            })
        }
        if(parm == "9"){
            this.setState({
                rowData:this.state.simconsentpending,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        }
        if(parm == "10"){
            this.setState({
                rowData:this.state.shipmentwithouttrips,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        }

        if(parm == "loadingarea"){
            this.setState({
                rowData:this.state.loadingarea,
                loadshow:"show-n"
            })
        }
        if(parm == "parkingyard"){
            this.setState({
                rowData:this.state.parkingyard,
                loadshow:"show-n"
            })
        }
        if(parm == "nonloadingarea"){
            this.setState({
                rowData:this.state.nonloadingarea,
                loadshow:"show-n"
            })
        }
        if(parm == "weighbridge"){
            this.setState({
                rowData:this.state.weighbridge,
                loadshow:"show-n"
            })
        }
        if(parm == "parkingarea"){
            this.setState({
                rowData:this.state.parkingarea,
                loadshow:"show-n"
            })
        }
        
        if(parm == "mys"){
            this.setState({
                rowData:this.state.mys,
                loadshow:"show-n",
                showmys:"show-m",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }
        if(parm == "ma"){
            this.setState({
                rowData:this.state.ma,
                loadshow:"show-n"
            })
        }
        
        if(parm == "mb"){
            this.setState({
                rowData:this.state.mb,
                loadshow:"show-n"
            })
        }
        if(parm == "mc"){
            this.setState({
                rowData:this.state.mc,
                loadshow:"show-n"
            })
        }
        if(parm == "md1"){
            this.setState({
                rowData:this.state.md1,
                loadshow:"show-n"
            })
        }
        if(parm == "md2"){
            this.setState({
                rowData:this.state.md2,
                loadshow:"show-n"
            })
        }
        if(parm == "md3"){
            this.setState({
                rowData:this.state.md3,
                loadshow:"show-n"
            })
        }
        if(parm == "md4"){
            this.setState({
                rowData:this.state.md4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "me"){
            this.setState({
                rowData:this.state.me,
                loadshow:"show-n"
            })
        }
        
        if(parm == "nma"){
            this.setState({
                rowData:this.state.nma,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }

        if(parm == "dsc"){
            this.setState({
                rowData:this.state.dsc,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-m",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }
        if(parm == "dsc1"){
            this.setState({
                rowData:this.state.dsc1,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc2"){
            this.setState({
                rowData:this.state.dsc2,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc3"){
            this.setState({
                rowData:this.state.dsc3,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc4"){
            this.setState({
                rowData:this.state.dsc4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "dsc5"){
            this.setState({
                rowData:this.state.dsc5,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc6"){
            this.setState({
                rowData:this.state.dsc6,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc7"){
            this.setState({
                rowData:this.state.dsc7,
                loadshow:"show-n"
            })
        }
        if(parm == "dsc8"){
            this.setState({
                rowData:this.state.dsc8,
                loadshow:"show-n"
            })
        }
        
        if(parm == "crm"){
            this.setState({
                rowData:this.state.crm,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-m",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }
        if(parm == "crm1"){
            this.setState({
                rowData:this.state.crm1,
                loadshow:"show-n"
            })
        }
        if(parm == "crm2"){
            this.setState({
                rowData:this.state.crm2,
                loadshow:"show-n"
            })
        }
        if(parm == "crm3"){
            this.setState({
                rowData:this.state.crm3,
                loadshow:"show-n"
            })
        }
        if(parm == "crm4"){
            this.setState({
                rowData:this.state.crm4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "crm5"){
            this.setState({
                rowData:this.state.crm5,
                loadshow:"show-n"
            })
        }
        if(parm == "crm6"){
            this.setState({
                rowData:this.state.crm6,
                loadshow:"show-n"
            })
        }
        
        if(parm == "crm7"){
            this.setState({
                rowData:this.state.crm7,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill"){
            this.setState({
                rowData:this.state.platemill,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }
        
        if(parm == "platemill1"){
            this.setState({
                rowData:this.state.platemill1,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill2"){
            this.setState({
                rowData:this.state.platemill2,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill3"){
            this.setState({
                rowData:this.state.platemill3,
                loadshow:"show-n"
            })
        }
        if(parm == "platemill4"){
            this.setState({
                rowData:this.state.platemill4,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill"){
            this.setState({
                rowData:this.state.pipemill,
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-m",
                shownonloadingarea:"show-n",            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
            })
        }
        if(parm == "pipemill1"){
            this.setState({
                rowData:this.state.pipemill1,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill2"){
            this.setState({
                rowData:this.state.pipemill2,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill3"){
            this.setState({
                rowData:this.state.pipemill3,
                loadshow:"show-n"
            })
        }
        if(parm == "pipemill4"){
            this.setState({
                rowData:this.state.pipemill4,
                loadshow:"show-n"
            })
        }
        
        if(parm == "7"){
            this.setState({
                rowData:this.state.insidetpaafterloading,
                loadshow:"show-n"
            })
        }

        if(parm == "8"){
            this.setState({
                rowData:this.state.intransittocustomer,
                loadshow:"show-n"
            })
        }

        if(parm == "travellingtocustomer"){
            this.setState({
                rowData:this.state.travellingtocustomer,
                showLoadingArea:"show-n",
                loadshow:"show-n"
            })
        }
        if(parm == "emptyload"){
            this.setState({
                rowData:this.state.emptyload,
                loadshow:"show-n"
            })
        }
        if(parm == "partialload"){
            this.setState({
                rowData:this.state.partialload,
                loadshow:"show-n"
            })
        }
        if(parm == "completeload"){
            this.setState({
                rowData:this.state.completeload,
                loadshow:"show-n"
            })
        }
        if(parm == "nonmys"){
            this.setState({
                rowData:this.state.nonmys,
                loadshow:"show-n"
            })
        }
        if(parm == "nondsc"){
            this.setState({
                rowData:this.state.nondsc,
                loadshow:"show-n"
            })
        }
        if(parm == "noncrm"){
            this.setState({
                rowData:this.state.noncrm,
                loadshow:"show-n"
            })
        }
        if(parm == "nonplatemill"){
            this.setState({
                rowData:this.state.nonplatemill,
                loadshow:"show-n"
            })
        }
        if(parm == "nonpipemill"){
            this.setState({
                rowData:this.state.nonpipemill,
                loadshow:"show-n"
            })
        }
        if(parm == "nonnma"){
            this.setState({
                rowData:this.state.nonnma,
                loadshow:"show-n"
            })
        }
        if(parm == "nonothers"){
            this.setState({
                rowData:this.state.nonothers,
                loadshow:"show-n"
            })
        }
        
        // if(parm == "6"){
        //     this.setState({
        //         rowData:this.state.forceclose,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "7"){
        //     this.setState({
        //         rowData:this.state.nearcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "8"){
        //     this.setState({
        //         rowData:this.state.reachedcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "9"){
        //     this.setState({
        //         rowData:this.state.leftcustomer,
        //         loadshow:"show-n"
        //     })
        // }
    }
    onClickLoadingAreaDataCounter = (loadingAreaName) => {
        var loadingareaCountersData = this.state.loadingareaCountersData;
        var clickedData = loadingareaCountersData.filter((lacd)=>lacd.loading_area_name == loadingAreaName);
        if(clickedData.length > 0)
        {
            this.setState({
                rowData:clickedData[0].truck_in_loading_area,
                loadshow:"show-n"
            })
        }
    }
    onClickShowLoadingAreaLegs = async (rownode) =>  {
        console.log("rownode", rownode)
        var commentHeaders = [        
            {
                headerName : Constant.COL_CONSIGNMENT_CODE,
                field : "consignment_code",
                resizable : true,
                width:140,
                filter : true
            },
            {
                headerName : Constant.COL_STATUS,
                field : "status",
                resizable : true,
                width:140,
                filter : true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
                    if(params.data.type_of_tracking == 0 || params.data.type_of_tracking == 4)
                    {
                        if(params.data.parking_in_time !=undefined && params.data.parking_in_time !="")
                        {
                            if(params.data.type_of_tracking == 0)
                            {   
                                return "Waiting For GPS";
                            }
                            else
                            {
                                return "SIM Issues";
                            }
                        }
                        else
                        {
                            if(localStorage.getItem("pc") == "HZ")
                            {
                                return "Truck Not Assigned";
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }
                    else
                    {
                        if(params.data.status == 1)
                        {
                            return Constant.INSIDE_TPA;
                        }
                        else if(params.data.status == 2)
                        {
                            return Constant.INSIDE_MY_PMILL;
                        }
                        
                        if(params.data.status == 1 || params.data.status == "1")
                        {
                            return Constant.INSIDE_TPA;
                        }
                        if(params.data.status == 2 || params.data.status == "2")
                        {
                            return  Constant.INTRANSIT_TO_MY;
                        }
                        if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
                        {
                            return  Constant.INSIDE_MY_PMILL;
                        }			
                        else if(params.data.status == 6)
                        {
                            return Constant.FORCE_CLOSE;
                        }
                        else if(params.data.status == 7)
                        {
                            return Constant.INSIDE_TPA_AFTER_LOADING;
                        }
                        else if(params.data.status == 8 )
                        {
                            return Constant.INTRANSIT;
                        }
                        else if(params.data.status == 9 )
                        {
                            return Constant.NEAR_CUSTOMER;
                        }
                        else if(params.data.status == 10 )
                        {
                            return Constant.REACHED_CUSTOMER;
                        }
                        else if(params.data.status == 11 )
                        {
                            return Constant.LEFT_CUSTOMER;
                        }
                        else{
                            return ""
                        }
                    }
				}
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                resizable : true,
                filter : true,
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                resizable : true,
                filter : true,
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.MATERIAL_INFO,
                field:"material_desc",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.SR_GRADE,
                field:"sr_grade",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                resizable : true,
                filter : true,
                width:200,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"consignee_city",
                resizable : true,
                filter : true,
                width:140,
            },
            {
                headerName:Constant.COL_CONSIGNEE_ADDRESS,
                field:"consignee_address",
                resizable : true,
                filter : true,
                width:140,
            },
            {
                headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                field:"distance_in_km",
                resizable : true,
                filter : true,
                width:140,
            },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    if(param.data.leg_details != "" && param.data.leg_details != undefined)
                    {
                        try{
                            var output = JSON.parse(param.data.leg_details);
                            output.map((e) => e.type_of_tracking = param.data.type_of_tracking);
                            if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		                    {
                                var customer_code = JSON.parse(localStorage.getItem("customer_code"));
								// console.log(customer_code,"customer_code--")
                                if(customer_code.length > 0)
                                {
									var output1 = [];
                                    var otpt = await output.map(async(e) => {
										var customer_codes = e.consignee_code;
										customer_codes = parseInt(customer_codes).toString();
										// console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
										if(customer_code.indexOf(customer_codes) >= 0)
										{
											output1.push(e);
										}
                                    })
									var output = output1;
                                }             
                            }             
                            console.log("output ", output)
                            param.successCallback(output);
                        }
                        catch(e){}
                    }
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'loadingarealegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClickTruckPlantIn(rowdata){
		var row = rowdata.data 
		var params = {
			shipment_id:row.shipment_id
		}
        redirectURL.post("/chettinad/savetruckplant", params).then((response) => {
            if (response.data.status == "success") {
                this.setState({
                    show: true,
                    basicTitle: "Successfully",
                    basicType: "success",
                })
            }

        })
	}
    
    onClickShowShipmentLegs= async (rownode) =>  {
        // console.log("rownode", rownode)
        var commentHeaders = [
            {
				headerName : "",
				field : "",
				resizable : true,
				width:60,
				filter : true,
                cellRendererFramework:LegRouteMap,
			  },
			{
				headerName : Constant.COL_TRUCK_NO,
				field : "truck_no",
				resizable : true,
				width:120,
				filter : true
			  },
			//   {
			// 	headerName : Constant.COL_CONSIGNMENT_CODE,
			// 	field : "consignment_code",
			// 	resizable : true,
			// 	width:140,
			// 	filter : true
			//   },
			  {
				headerName : Constant.COL_LOADING_AREA_NAME,
				field : "fence_name",
				resizable : true,
				width:200,
				filter : true
			  },
			  {
				headerName : Constant.COL_LEGT_START_TIME,
				field : "first_inside_fence",
				resizable : true,
				width:160,
				filter : true,
                sortable: true, 
                valueGetter:function(params){
                    try{
                        if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_LEGT_END_TIME,
				field : "first_outside_fence",
				resizable : true,
				width:160,
				filter : true,
                sortable: true, 
                valueGetter:function(params){
                    try{
                        if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_TIME_SPEND,
				field : "time_spent",
				resizable : true,
				width:200,
				filter : true,
                sortable: true, 
                valueGetter:function(params){
                    try{
                        
                        return params.data.time_spent;
                        
                    }
                    catch(e){}
                }
			  },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    // if(param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined){
                    //     var startdate = param.data.tpa_entry_time;
                    // }
                    // else{
                        var parkingTime = param.data.parking_in_time;
                        var startdate = moment.parseZone((parkingTime)).subtract(2,"h")
                        startdate = moment.parseZone(startdate).utcOffset("+00:00")._d;
                    
                    // }
                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;

                    if(param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11)
					{
						var enddate = param.data.recent_dealer_reported;
					}
					else{
						if(param.data.status == 6 || param.data.status == "6")
						{
							var enddate = param.data.force_closure_time;
						}
						else{
							var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
						}
						
					}
                    var parameters = {
                        consignment_code : param.data.consignment_code,
                        truck_no : param.data.truck_no,
                        startdate:startdate,
                        enddate:enddate
                    }
                    await redirectURL.post("/chettinad/loadingarealegsdata",parameters).then(async (response) =>{
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments=response.data;
                        var output=[]
                        if(comments.length > 0){
                            comments.map((item) => {
                                try{
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        var ste = item.first_inside_fence;
                                        var stsec = moment.parseZone(ste).format("x");
                                        item.orderpos = stsec;
                                    }
                                    if(item.first_outside_fence != "" && item.first_outside_fence != undefined){
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else{
                                        var etsec = moment.parseZone().format("x");
                                    }
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        // console.log("etsec ", etsec)
                                        // console.log("stsec ", stsec)
                                        var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                        var timespent = secondsToDhms(diff);
                                    }
                                    else{
                                        var timespent = "";
                                    }
                                    item.time_spent = timespent;
                                        
                                }
                                catch(e){

                                }
                                console.log("item ", item)
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);
                        
                        
                        //console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'shipmentlegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClicCloseCounterBlock(){
        this.setState({
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
            shownonloadingarea:"show-n",            
            expandNonLodingArea: "inline-show-m",
            closeNonLodingArea: "inline-show-n"
        })
    }
    
	onClickSaveGridState() {
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = 'active trips';

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: "active trips"
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}

	restoreGridStates() {
		if (this.state.usergridstate.length !== 0) {
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch (e) {

			}
		}
	}

	resetState = () => {
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
    onClickShowNonLoadingCounters = (clickType) => {
        if(clickType == 1)
        {
            this.setState({            
                expandNonLodingArea: "inline-show-n",
                closeNonLodingArea: "inline-show-m",
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-m"
            });
        }
        else
        {
            this.setState({            
                expandNonLodingArea: "inline-show-m",
                closeNonLodingArea: "inline-show-n",
                loadshow:"show-n",
                showmys:"show-n",
                showdsc:"show-n",
                showcrm:"show-n",
                showplatemill:"show-n",
                showpipemill:"show-n",
                shownonloadingarea:"show-n"
            });
        }
    }
    render(){
        var maptransporter='';
        var hideData="";
        var hideForbgs="";
        if(localStorage.getItem("pc") != "HZ")
        {
            hideForbgs = true;
        }
        if(localStorage.getItem('user_type') == "MARKETING")
        {
            var hideforsales = true;
        }
        else
        {
            var hideforsales = false;
        }
        var hideforAdmin ="";
		if(localStorage.getItem('email')=="nanda@enmovil.in")
		{
			hideforAdmin =false;
		}
		else{
			hideforAdmin =true;
		}
        if(localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0)
        {
            var hideData = false;
        }
        else
        {
            var hideData = true;
        }
        if(localStorage.getItem('email')=="dcp_chettinad@enmovil.in"){
            hideData = true
        }
        var columnwithDefs = [
            
			{
				headerName: "View Route",
				field: "_id",
				width: 40,
				pinned: 'left',
				cellRenderer: 'consignmentActions'

			},

            // {
            //     headerName: "",
            //     field: "shipmentlegs",
            //     width: 50,
            //     pinned: 'left',
            //      cellRenderer:'ShipmentLegs',
            //     filter: false,resizable: true
                
            // },
            // {
            //     headerName: "",
            //     field: "loadingarealegs",
            //     width: 50,
            //     pinned: 'left',
            //      cellRenderer:'LoadingAreaLegs',
            //     filter: false,resizable: true
                
            // },
            
            {
                headerName:"Truck No (API)",
                field:"truck_no",
                width:120,
                pinned:"left",
                valueGetter: function(params){
                    if(params.data.truck_no !=undefined && params.data.truck_no !="")
                    {
                        return params.data.truck_no;
                    }
                    else
                    {
                        return "No Data From API";
                    }
                }
            },
            // {
            //     headerName:"LPS(Delivery No) (API)",
            //     field:"shipment_id",
            //     width:140,
            //     hide:hideforsales,
            //     suppressColumnsToolPanel:hideforsales,
            // },
            // {
            //     headerName:"LPS Date (API)",
            //     field:"shipment_time",
            //     width:100,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.shipment_time == "Yet to Invoice"){
            //                 return "Yet to Invoice"
            //             }
            //             if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
            //             {
            //                 return getHyphenDDMMYYYY(params.data.shipment_time);
            //             }
            //             else{
            //                 return "Not Available";
            //             }
            //         }
            //         catch(e){}
            //     },
            //     comparator: dateComparator,
            // },
            // {
            //     headerName:"LPS Time (API)",
            //     field:"shipment_time",
            //     width:100,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.shipment_time == "Yet to Invoice"){
            //                 return "Yet to Invoice"
            //             }
            //             if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
            //             {
            //                 return getHyphenHHMM(params.data.shipment_time);
            //             }
            //             else{
            //                 return "Not Available";
            //             }
            //         }
            //         catch(e){}
            //     },
            //     comparator: dateComparator,
            // },
            // {
            //     headerName:"Plant Name (API)",
            //     field:"consigner_name",
            //     width:140,
                
            // },
            // {
			// 	headerName: "Invoice No (API)",
			// 	field: "invoice_no",
			// 	width: 150,
			// },
            {
                headerName:"Plant Code (API)",
                field:"plant_code",
                width:150,
            },
            {
                headerName:"Plant Name (API)",
                field:"plant_name",
                width:200,
            },
            {
                headerName:"Transporter Code (API)",
                field:"transporter_code",
                width:150,
            },
            {
                headerName:"Transporter Name (API)",
                field:"transporter_name",
                width:200,
            },

            // {
            //     headerName:"Driver Name (API)",
            //     field:"driver_name",
            //     width:140,
            // },               
            // {
            //     headerName:"Driver Mobile No (API)",
            //     field:"driver_mobile_no",
            //     width:140,
            //     hide:hideforsales,
            //     suppressColumnsToolPanel:hideforsales,
            // },
            {
                headerName:"Tracking Mode",
                field:"type_of_tracking",
                width:120,
                pinned:"left",
                valueGetter: function(params){
                    if(params.data.type_of_tracking !=undefined && params.data.type_of_tracking !="")
                    {
                        if(params.data.type_of_tracking == 0)
                        {
                            return "Waiting For GPS";
                        }
                        else if(params.data.type_of_tracking == 1)
                        {
                            return "GPS Tracking";
                        }
                        else if(params.data.type_of_tracking == 2)
                        {
                            return "SIM Tracking";
                        }
                        else if(params.data.type_of_tracking == 3)
                        {
                            return "GPS & SIM Tracking";
                        }
                        else if(params.data.type_of_tracking == 4)
                        {
                            return "SIM Tracking Consent Pending";
                        }else{
                            return params.data.type_of_tracking
                        }
                    }
                    else
                    {
                        return "Waiting For GPS";
                    }
                },
				
            },
            {
                headerName:"Sim Tracking Status",
                field:"sim_tracking_state",
                width:140,
                hide:true,
                
            },
            {
                headerName:"Sim Tracking Service Provider",
                field:"sim_tracking_service_provider",
                width:140,
                hide:true,
                
            },
            // {
			// 	headerName: "Freight Type (API)",
			// 	field: "truck_type",
			// 	width: 120,
			// 	resizable: true,
            //     valueGetter:function(params){
            //         if(params.data.truck_type=='M'){
            //             return 'Market'
            //         }
            //         else if(params.data.truck_type=='O'){
            //             return 'Own'
            //         }
            //         else{
            //             return params.data.truck_type
            //         }
            //     }
			// },
            // {
			// 	headerName: "Segment (API)",
			// 	field: "invoice_segment",
			// 	width: 120,
			// 	resizable: true,
			// },
            // {
			// 	headerName: "Freight Segment Code (API)",
			// 	field: "freight_segment",
			// 	width: 150,
			// 	resizable: true,
			// },
            // {
			// 	headerName: "Freight Distance (API)",
			// 	field: "distance_in_km",
			// 	width: 130,
			// 	resizable: true,
			// },
            // {
			// 	headerName: "Freight cost PMT (API)",
			// 	field: "freight_rs_pmt",
			// 	width: 130,
			// 	resizable: true,
			// },
            // {
			// 	headerName: "Destination Code (API)",
			// 	field: "consignee_destination_code",
			// 	width: 130,
			// 	resizable: true,
			// },
            // {
            //     headerName:"Customer Code (API)",
            //     field:"consignee_code",
            //     width:120
            // },
            // {
			// 	headerName:"Customer Name (API)",
			// 	field:"consignee_name",
			// 	width:200,
			// 	resizable : true,
			// 	filter : true,
			// },
            // {
			// 	headerName: "Customer State (API)",
			// 	field: "consignee_state",
			// 	width: 120,
			// 	resizable: true,
			// },
			// {
			// 	headerName:Constant.COL_CUSTOMER_CODE,
			// 	field:"consignee_code",
			// 	width:120,
			// 	resizable : true,
			// 	filter : true,
			// },
			// {
			// 	headerName: "Customer District (API)",
			// 	field: "consignee_district",
			// 	width: 120,
			// 	resizable: true,
			// },
			// {
			// 	headerName:"Customer State (API)",
			// 	field:"consignee_state",
			// 	width:140,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
			// 			{
			// 				return params.data.consignee_state;
			// 			}
			// 			else{
			// 				return "No Data Available";
			// 			}

			// 		}
			// 		catch(e){}
			// 	},
			// 	resizable : true,
			// },
			// {
			// 	headerName:"Customer Addess (API)",
			// 	field:"consignee_address",
			// 	width:200,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.consignee_address != "" && params.data.consignee_address != undefined)
			// 			{
			// 				return params.data.consignee_address;
			// 			}
			// 			else{
			// 				return "No Data Available";
			// 			}

			// 		}
			// 		catch(e){}
			// 	},
			// 	resizable : true,
			// },
			// {
            //     headerName:"Customer Pincode (API)",
            //     field:"consignee_pincode",
            //     width:150
            // },
            // {
			// 	headerName: Constant.COL_STATUS,
			// 	field: "status",
			// 	width: 150,
			// 	pinned: 'left',
			// 	valueGetter: function (params) {
			// 		try {
			// 			var status = Constant.INSIDE_TPA;
			// 			if (params.data.status == 1 || params.data.status == "1") {
			// 				status = Constant.INSIDE_TPA;
			// 			}
			// 			if (params.data.status == 2 || params.data.status == "2") {
			// 				status = Constant.INTRANSIT_TO_MY;
			// 			}
			// 			if (params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5") {
			// 				status = Constant.INSIDE_MY_PMILL;
			// 			}
			// 			if (params.data.status == 6 || params.data.status == "6") {
			// 				status = "Force Close"
			// 			}
			// 			if (params.data.status == 7 || params.data.status == "7") {
			// 				status = Constant.INSIDE_TPA_AFTER_LOADING;
			// 			}
			// 			if (params.data.status == 8 || params.data.status == "8") {
			// 				status = Constant.INTRANSIT;
			// 			}
			// 			if (params.data.status == 9 || params.data.status == "9") {
			// 				status = "In 40 KM Fence";
			// 			}
			// 			if (params.data.status == 12 || params.data.status == "12") {
			// 				status = "Left from 40 KM Fence";
			// 			} 
			// 			if (params.data.status == 11 || params.data.status == "11") {
			// 				status = "Left from 20 KM Fence";
			// 			} 
			// 			if (params.data.status == 13 || params.data.status == "13" || params.data.status == 14 || params.data.status == "14" ) {
			// 				status = "Unloaded In 40 KM Fence";
			// 			}
			// 			if (params.data.status == 16 || params.data.status == "16" || params.data.status == 17 || params.data.status == "17" ) {
			// 				status = "Unloaded during In Transit";
			// 			}
			// 			if (params.data.status == 10 || params.data.status == "10") {
			// 				status = "In 20 KM Fence";
			// 			}
			// 			if (params.data.status == 15 || params.data.status == "15") {
			// 				status = "Unloaded In 20 KM Fence";
			// 			}
			// 			if (params.data.status == 11 || params.data.status == "11") {
			// 				status = "Left Customer";
			// 			}
			// 			if (params.data.status == 12 || params.data.status == "12") {
			// 				status = "Proximity Trip Closure";
			// 			}
			// 			if (params.data.type_of_tracking == 0) {
			// 				if (params.data.type_of_tracking == 0) {
			// 					return "Waiting For GPS";
			// 				}
			// 				// else {
			// 				// 	return "SIM Consent Pending";
			// 				// }
			// 			}
			// 			else {
			// 				return status;
			// 			}
			// 		}
			// 		catch (e) { }
			// 	}
			// },
            // {
            //     headerName:"Tracking Available",
            //     field:"is_tracking_available",
            //     width:140,
            //     hide:hideData

            // },      
            // {
            //     headerName:"Truck Type",
            //     field:"truck_type",
            //     width:140, 
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.truck_type == "M")
            //             {
            //                 return "Market";
            //             }
            //             else if(params.data.truck_type == "O"){
            //                 return "Own";
            //             }
            //             else
            //             {
            //                 return params.data.truck_type;
            //             }
            //         }
            //         catch(e){}
            //     },               
            // },    
            // {
            //     headerName:"Item Description (API)",
            //     field:"item_description",
            //     width:140,
            // },    
            // {
            //     headerName:"Item Quantity (API)",
            //     field:"item_quantity",
            //     width:140,
            // },
            {
				headerName: "Last Packet Date",
				field: "timestamp",
				width: 140,				
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp != "" && params.data.timestamp != undefined)
						{
							return getHyphenDDMMYYYY(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},     
            {
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 140,				
				resizable: true,
				// comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp != "" && params.data.timestamp != undefined)
						{
							return getHyphenHHMM(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
            // {
			// 	headerName: "",
			// 	field: "truck_plant_in",
			// 	colId: "truck_plant_in",
			// 	width: 140,
			// 	cellRendererSelector: function (params) {
			// 		var rendComponent = {
			// 			component: 'TruckPlantIn'
			// 		};
			// 		return rendComponent;

			// 	},
			// 	filter: true, resizable: true,
			// },
        ]
        let class_dyn = "col-xl-8 col-lg-8 col-sm-12 beffect"
        if(localStorage.getItem("pc") !=="HZ" ){            
            if(this.state.loadingareaCountersData.length > 0)
            {
                class_dyn =  "col-xl-12 col-lg-12 col-sm-12 beffect"                
            }
            else
            {
                class_dyn = "show-n";
            }
        }
        var baseRouteCoordsData = this.state.baseRouteCoords;
        console.log(this.state.rowData)

        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 ">

                        {/* <h5 className='highlight-heading'>
                            <i className="icofont icofont-truck cus-i"></i>
                            <span style={{ fontWeight: '500' }}>Return to Plant</span>
                        </h5> */}
                        {/* <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer">
                                            <span className="f13" onClick={this.onClickCounter.bind(this, "0")}><i className="icofont icofont-truck-alt f22 txt-primary"></i><br /> {Constant.COUNTER_TOTAL_TRUCKS}</span>
                                            <h4 className="txt-primary f45"><span className="counter"><CountUp end={this.state.totalcount.length} /></span></h4>
                                            <div className="row">
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "simcount")}>
                                                    <span className="f12">SIM</span>
                                                    <h4 className="txt-primary f30"><span className="counter"><CountUp end={this.state.simcount.length} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "gpscount")}>
                                                    <span className="f12">GPS</span>
                                                    <h4 className="txt-primary f30"><span className="counter"><CountUp end={this.state.gpscount.length} /></span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "9")}>
                                            <span className="f13"><i className="fa fa-mobile f22 txt-danger"></i><br />SIM Issues
                                            </span>
                                            <h4 className="txt-danger f45"><span className="counter"><CountUp end={this.state.simconsentpending.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "parkingyard")}>
                                            <span className="f13"><i className="icofont icofont-truck-alt f22 text-warning"></i><br />Parking Yard
                                            </span>
                                            <h4 className="text-warning f45"><span className="counter"><CountUp end={this.state.parkingyard.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer " onClick={this.onClickCounter.bind(this, "2")}>
                                            <span className="f13"><i className="icofont icofont-truck-alt f22 txt-primary"></i><br /> Parking Yard To Plant</span>
                                            <h4 className="txt-primary f45"><span className="counter"><CountUp end={this.state.intransitmy.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer">
                                            <span className="f13" onClick={this.onClickCounter.bind(this, "3")}><i className="icofont icofont-map-pins f22 txt-success"></i><br /> {Constant.COUNTER_INSIDE_MY_PM}</span>
                                            <h4 className="txt-success f45" onClick={this.onClickCounter.bind(this, "3")}><span className="counter"><CountUp end={this.state.insidemytpa.length} /></span></h4>
                                            <div className="row">
                                        
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className={"card "+this.state.showLoadingArea}>
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "loadingarea")}>
                                            <span className="f10">Packing Area</span>
                                            <h4 className="txt-success f35"><span className="counter"><CountUp end={this.state.loadingarea.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "weighbridge")}>
                                            <span className="f10">Weigh Bridge</span>
                                            <h4 className="txt-success f35"><span className="counter"><CountUp end={this.state.weighbridge.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "nonloadingarea")}>
                                            <span className="f10">{Constant.COUNTER_NONLOADING_AREA}</span>
                                            <h4 className="txt-success f35"><span className="counter"><CountUp end={this.state.nonloadingarea.length} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "parkingarea")}>
                                            <span className="f10">Plant Parking</span>
                                            <h4 className="txt-success f35"><span className="counter"><CountUp end={this.state.parkingarea.length} /></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>    
                                <i className="icofont icofont-truck cus-i"></i><span style={{ fontWeight: '500' }}>Return to Plant</span>                            
                                     <span className="">

                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <span className="float-right f12">
                                            <i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route &nbsp;
                                            <i style={{color:"#FF0000"}} className="icofont icofont-site-map f25"></i> - Shipment Legs &nbsp;
                                        </span>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
                                consignercords={[this.state.consigner_coordinates]}
								mapFor={"consignment"} 
                                no_replay_for = {'return_to_plant'}
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno}
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={baseRouteCoordsData}
								geofence_coordinates={this.state.geofence_coordinates}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
                                            <div className="col route-block">
												<label className="sidebar-label">Plant</label>
												<div>{this.state.consigner_coordinates.name}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Invoice No</label>
												<div>{this.state.mapinvoice_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""} */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 {/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Tracking Type</label>
                                                {(this.state.map_type_of_tracking == 1)?
												<div>GPS Tracking</div>
                                                :
												<div>SIM Tracking</div>
                                                }
											</div> */}
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
            </div>
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
