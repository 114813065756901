import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import RemoveGridRow from './removegridrow';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 

import * as Datetime from 'react-datetime';

import Modal from 'react-responsive-modal';
var Constant = require('../common/Constant');

$.datetimepicker.setLocale('en');
var moment = require('moment');
var redirectURL = require('../redirectURL');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export const validRegistrationNumber = new RegExp(/^[a-zA-Z0-9]{1,11}$/);

// export const validRegistrationNumber = new RegExp(/^[A-Z|a-z]{2}[0-9]{1,2}[A-Z|a-z]{1,2}[0-9]{1,4}$/);

export const validAlphaNumericSpace = new RegExp(/^[a-zA-Z0-9._& ]{1,255}$/);
export const validAlphaNumeric = new RegExp(/^[a-zA-Z0-9]{1,50}$/);
export const validPhone = new RegExp(/^[789]\d{10}$/);
export const validCoords = new RegExp(/^[0-9.]{1,255}$/);
export const validNumber = new RegExp(/^[0-9]{1,30}$/);


export default class CreateShipment extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                RemoveGridRow:RemoveGridRow
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            truck_no:"",
            vehicle_type:{"value":"Truck","label":"Truck"},
            shipment_mode:{"value":"ROAD","label":"Road"},
            total_shipment_qty:"",
            driver_name:"",
            driver_mobile_number:"",
            parking_id:"",
            parking_date:"",
            parking_time:"",
            transporter_code:"",
            transporter_name:"",
            shipment_id:"",
            customer_address:"",
            customer_name:"",
            customer_pincode:"",
            shipment_date:"",
            shipment_time:"",
            mobile_network:"",
            shipment_source:{"value":"","label":"Select"},
            shipment_destination:{"value":"","label":"Select"},
            mobile_operation:"",
            railyards:[],
            shipsrcdestshow:"show-n",
            plants:[],
            plant_code:"",
             mobile_networks:[],
            sim_tracking_service_provider: [{ label: "JIO", value: "jio" }, { label: "NON-JIO", value: "non_jio" }],
            // mobile_networks: [{ label: "Airtel", value: "airtel" }, { label: "Reliance Jio", value: "jio" }, 
            // { label: "BSNL ", value: "bsnl" },{ label: "Vodafone Idea ", value: "vi" }],
            plant_name:"",
            driver_code:"",
            invoice_no:"",
            customers:[],
            allcustomers:[],
            isreadonly:false,
            newcustomer_code:"",
            newcustomer_name:"",
            newcustomer_city:"",
            newcustomer_latitude:"",
            newcustomer_longitude:"",
            open: false,
            curentRow:"",
            errtruckmsg:"",
            errtruck:"show-n",
            errmsg : false ,
            padding: 10,
            shipment_no:"",
            customer_address:"",
            customer_city:"",
            consignment_code:"",
            startDate:"",
            truck_type:"",
            truck_types:[{label: "OWN", value: "O" },{label: "MARKET", value: "M" }],
            item_quantity:"",
            item_desc:"",
            clr:""
			
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.changeVehicleHandler = this.changeVehicleHandler.bind(this);
        this.changeNumberHandler = this.changeNumberHandler.bind(this);
        this.changeAlphaNumericHandler = this.changeAlphaNumericHandler.bind(this);
        this.changeAlphaNumericNOSpeacialHandler = this.changeAlphaNumericNOSpeacialHandler.bind(this);
        this.changeCoords = this.changeCoords.bind(this);
        this.onLoadCustomers = this.onLoadCustomers.bind(this);
    }
    componentDidMount(){
		loadDateTimeScript();
        redirectURL.post("/chettinad/railyards")
        .then((response) => {
            var geofences = response.data.geofences;
            this.setState({
                railyards:geofences
            })
        })
        redirectURL.post("/chettinad/plants")
        .then((response) => {
            var plants = response.data.plants;
            this.setState({
                plants:plants,
                // plant_code:plants[0].plant_code,
                // plant_name:plants[0].plant_name
            })
        })
        this.onLoadCustomers();    
    }
    onLoadCustomers(){
        redirectURL.post("/chettinad/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            var customers = []
            
            customers.push("");
            if(customersarr.length > 0)
            {
                customersarr.map((item) => {
                    customers.push(item.consignee_name)
                })
            }
            customers.push(Constant.NEW_CUSTOMER);
            this.setState({
                customers:customers,
                allcustomers:customersarr
            })
        })
    }
   
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;

        this.setState({
            [name]:value
        })
    }
    changeCoords(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            console.log("validCoords.test(value) ", validCoords.test(value))
            if(validCoords.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
            
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    } 
    
    changeVehicleHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(value.length <= 10 && (validRegistrationNumber.test(value) == true))
            {
                this.setState({
                    [name]:value,
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
                if(value.length >= 8){
                    var pr = {
                        truck_no:value
                    }
                    redirectURL.post("/chettinad/truckdetails",pr)
                    .then((response) => {
                        var records = response.data.truckinfo;
                        if(records.length > 0)
                        {
                            try{
                                if(records[0].info.length > 0)
                                {
                                    var transporter_name = records[0].info[0].transporter_name;
                                }
                                else{
                                    var transporter_name = "";
                                }
                                if(records[0].transporter_code != "NA")
                                {
                                    this.setState({
                                        transporter_code:records[0].transporter_code,
                                        transporter_name:transporter_name,
                                        isreadonly:true,
    
                                    })
                                }
                                
                            }
                            catch(e){}
                        }
                        else{
                            this.setState({
                                isreadonly:false,

                            })
                        }
                    })
                }
                else{
                    
                }
            }
        }
        else{
            this.setState({
                [name]:value,
                // errtruckmsg:"Invalid Truck No",
                // errtruck:"show-m"
            })
        }
        
    }
    onBlurTruck(){
        var truck = this.state.truck_no;
        if(truck == "")
        {
            this.setState({
                errtruckmsg:"Please enter vehicle no",
                errtruck:"show-m"
            })
        }
        else{
            if(truck.length < 8){
                this.setState({
                    errtruckmsg:"Invalid Truck No",
                    errtruck:"show-m"
                })
            }
            else{
                this.setState({
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
                var params={
                    truck_no:truck
                }
                redirectURL.post("/chettinad/checktruckno",params)
                .then((response)=>{
                    var row = response.data[0] 
                    if(response.data.length!=0){
                        if(row.latest_gps_packet!="undefined" && row.latest_gps_packet!="" && row.latest_gps_packet!=undefined){
                            var latest_gps_packet = row.latest_gps_packet 
                    
                            var latest_gps = moment.parseZone(new Date(latest_gps_packet)).utcOffset("-05:30")._d;
                            console.log(latest_gps_packet,latest_gps,"latest")
                            const currentTime = new Date(); 
                            console.log(currentTime,"currentTime")
                            const timeDiffMillis = currentTime.getTime() - latest_gps.getTime(); 
                            console.log(timeDiffMillis,"timeDiffMillis")
                            const timeDiffHours = timeDiffMillis / (1000 * 60 * 60);
                            console.log(timeDiffHours,"123")

                            if(parseInt(timeDiffHours) < 6) {
                            this.setState({errtruckmsg : "GPS Available & Working",errtruck:"show-m",clr:"green"}) 
                            } else { 
                                this.setState({errtruckmsg : "GPS Available & Not Working",errtruck:"show-m",clr:"red"}) 
                            }
                        
                        }
                        else
                        {
                            this.setState({errtruckmsg : "GPS Not Available",errtruck:"show-m",clr:"red"}) 
                        }
                    } 
                    else{
                        this.setState({errtruckmsg : "GPS Not Available",errtruck:"show-m",clr:"red"})
                    }
                    
                })

            }
        }

    }
    changeAlphaNumericHandler(event){
        console.log(event.target.name,"431")
        var name= event.target.name;
        var value = event.target.value;
        // const re = /^[A-Za-z]+$/;
        // if(value != "")
        // {
        //     if(validAlphaNumericSpace.test(value) == true)
        //     {
                

                this.setState({
                    [name]:value
                })
        //     }
        // }
        // else{
            

        //     this.setState({
        //         [name]:value
        //     })
        // }
        
    }
    changeAlphaNumericNOSpeacialHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(validAlphaNumeric.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			shipment_time:getYYYYMMDD(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
    handlerStartDateTimeForOrderDate = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			startDate:getHyphenYYYYMMDDHHMMSS(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
    
    
    changeNumberHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        // console.log("Here ",validRegistrationNumber.test(value))
        if(isNaN(value) == true)
        {
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    
    createShipmentForm(event){
        event.preventDefault();
        var items=[];
       
        // console.log("AGGrid data ",items);
        var shipment_date = $("#shipment_date").val();
        var shipment_time = $("#shipment_time").val();
        var flag = 0;
        var itmerr=[];
        var err = [];
        var transiterr=[];
        var distanceerr=[];
        var customererr=[];
        if(items.length > 0)
        {
            items.map((item) => {
                if(item.consignment_code != "" && item.delivery_no != "" && item.sales_order != "" && item.item_no != "" && item.item_desc != "" && item.item_quantity != "" && item.consignee_code != "" && item.consignee_name != "" && item.consignee_city != "" && item.lat != "" && item.lng != "" && item.distance_in_km != "")
                {
                    // if(item.transit_time < 0)
                    // {
                    //     transiterr.push(1);
                    //     // err.push(1);
                    // }
                    if(item.distance_in_km < 0)
                    {
                        // err.push(1);
                        distanceerr.push(1);
                    }
                    if(item.consignee_name == Constant.NEW_CUSTOMER)
                    {
                        customererr.push(1);
                    }
                }
                else{
                    err.push(1);
                }
            })
        }
        else{
            itmerr.push(1);
        }
        
        
        {/*if(this.state.transporter_code  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter code can not be empty.",
                basicType:'danger'
            })
        }
        
        if(this.state.transporter_name  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter Name can not be empty.",
                basicType:'danger'
            })
        }*/}
        

       
        try{
            if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
            {
                if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                {
                    flag = 1;
                    
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Source can not be empty.",
                        basicType:'danger'
                    })
                }
                if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                {
                    flag = 1;
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Destination can not be empty.",
                        basicType:'danger'
                    })
                }
            }
        }
        catch(e){}
        if(flag == 0)
        {
            var pd = [];
            var pd1 = [];
            var pdate = "";
            
            // console.log("pd",pd)
            // console.log("pdate",pdate)

            var sd = [];
            var sd1 = [];
            var shdate = "";
            if(shipment_date != "" && shipment_date != undefined)
            {
                sd = shipment_date.split("/");
                if(sd.length == 3){
                    if(sd[0].length == 4)
                    {
                        shdate = sd[0]+"-"+sd[1]+"-"+sd[2];
                    }
                    else{
                        shdate = sd[1]+"-"+sd[0]+"-"+sd[2];
                    }
                     
                }
                else{
                    sd1 = shipment_date.split("-");
                    if(sd1.length == 3)
                    {
                        shdate = shipment_date;
                    }
                }
                
            }
            console.log(shdate,"shdate")
            var shipment_id = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            var shipmentDate = shdate.split("-")
            var order = shipmentDate[2]+"-"+shipmentDate[1]+"-"+shipmentDate[0]
            var date=order+" "+shipment_time+":00"
            console.log(date,"date")


            var params ={
                truck_no:this.state.truck_no,
                driver_code:this.state.driver_code,
                driver_name:this.state.driver_name,
                transporter_code:this.state.transporter_code,
                transporter_name:this.state.transporter_name,
                shipment_id:shipment_id,
                shipment_time:date,
                // shipment_time:shipment_time,
                sales_order:this.state.invoice_no,
                shipment_mode:this.state.shipment_mode,
                plant_code:this.state.plant_code,
                consignee_address :this.state.customer_address,
                customer_name:this.state.customer_name,
                consignee_pincode:this.state.customer_pincode,
                shipment_no:this.state.shipment_no,
                customer_city:this.state.customer_city,
                consignment_code:this.state.consignment_code,
                consignment_date:this.state.startDate,
                truck_type:this.state.truck_type,
                item_quantity:this.state.item_quantity,
                item_desc:this.state.item_desc
                

            }
            var drivernum = this.state.driver_mobile_number 
            var driver_num = parseInt(this.state.driver_mobile_number)

            
            if (drivernum.length==10 && drivernum!="" && drivernum!=undefined){
                params.driver_mobile_number=this.state.driver_mobile_number 
                this.setState({errmsg:false})
            }
            else{
                this.setState({errmsg:true,driver_mobile_number:""})



            }
            var mobileNetwork = this.state.mobile_network 
            if (mobileNetwork!="" && mobileNetwork!="undefined"){
                params.mobileNetwork = mobileNetwork.value
            }
            console.log(mobileNetwork,"603")

            console.log("Form params ", params);
            
            redirectURL.post("/chettinad/saveshipmentapi",params)
            .then((response) => {
                console.log(response.data);
                if(response.data.outputs[0].status == "Success")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Successfully created shipment",
                        basicType1:"success",
                        truck_no:"",
                        vehicle_type:{"value":"Truck","label":"Truck"},
                        shipment_mode:{"value":"ROAD","label":"Road"},
                        driver_code:"",
                        invoice_no:"",
                        driver_name:"",
                        driver_mobile_number:"",
                        parking_id:"",
                        parking_date:"",
                        parking_time:"",
                        transporter_code:"",
                        transporter_name:"",
                        shipment_id:"",
                        customer_address:"",
                        customer_city:"",
                        consignment_code:"",
                        truck_type:"",
                        startDate:"",
                        customer_name:"",
                        customer_pincode:"",
                        shipment_date:"",
                        shipment_time:"",
                        shipment_source:{"value":"","label":"Select"},
                        shipment_source_name:"",
                        shipment_destination:{"value":"","label":"Select"},
                        shipment_destination_name:"",
                        shipment_items:[],
                        plant_code:"",
                        plant_name:"",
                        shipsrcdestshow:"show-n"

                    })
                    console.log("Success","Success")
                    
                    // this.gridApi.setRowData([]);
                    // $("#parking_date").val("");
                    // $("#parking_time").val("");
                    // $("#shipment_date").val("");
                    // $("#shipment_time").val("");
                    
                }
                else{
                    this.setState({
                        show:true,
                        basicTitle:response.data.outputs[0].message,
                        basicType:"danger"
                        
                       
                    })
                }
            });
        }
        else{



            // console.log("S26");
            // this.setState({
            //     show:true,
            //     basicTitle:"Invalid Data",
            //     basicType:"danger"
            // })


            if(itmerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please add atleast one item.",
                    basicType:'danger'
                })
            }
            else if(distanceerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Expected Distance (Km) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(transiterr.length > 0){
                this.setState({
                    show:true,
                    basicTitle:"Transit Time (Days) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(customererr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please select customer from droppwn list.",
                    basicType:'danger'
                })
            }
            else{
                if(err.length > 0)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Invalid Data.",
                        basicType:'danger'
                    })
                }
            }
            if(this.state.truck_no  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle No can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.vehicle_type.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle Type can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.shipment_mode.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Mode can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_code == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver Code can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver name can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.parking_id  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Parking Entry no can not be empty.",
                    basicType:'danger'
                })
            }
            
            
            if(this.state.transporter_code  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter code can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(this.state.transporter_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter Name can not be empty.",
                    basicType:'danger'
                })
            }
    
            if(shipment_date  == "" || shipment_date  == "__-__-____")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Date can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(shipment_time  == "" || shipment_time  == "__:__")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Time can not be empty.",
                    basicType:'danger'
                })
            }
            try{
                if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
                {
                    if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Source can not be empty.",
                            basicType:'danger'
                        })
                    }
                    if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Destination can not be empty.",
                            basicType:'danger'
                        })
                    }
                }
            }
            catch(e){}



        }
        
    }
    
    vehicletypes()
    {
        var items= [];
        items.push({
            value:"Truck",
            label:"Truck"
        },{
            value:"Trailer",
            label:"Trailer"
        })
        return items;
    }
    
    onChangeVehicleType(vehicle_type){
        this.setState({vehicle_type},() => {

        })
    } 
    shipmentsources(){
        var items= [];
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_loading_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        
        return items;
    }
    onChangeSources(shipment_source){
        this.setState({shipment_source},() => {

        })
    }
    shipmentmodes(){
        var items= [];
        items.push({
            value:"ROAD",
            label:"Road"
        },{
            value:"RAIL",
            label:"Rail"
        })
        return items;
    }
    onChangeModeType(shipment_mode){
        this.setState({shipment_mode},() => {
            if(this.state.shipment_mode.value == Constant.SHIPMENT_MODE_ROAD)
            {
                this.setState({
                    shipsrcdestshow:"show-n"
                })
            }
            else{
                this.setState({
                    shipsrcdestshow:"show-m"
                })
            }
        })
    }
    shipmentdestinations(){
        var items= [];
        
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_delivery_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        return items;
    }
    onChangeDestination(shipment_destination){
        this.setState({shipment_destination},() => {

        })
    }
    addRow(){
    
        this.gridApi.updateRowData({
          add: [{ 
              consignment_code: '', 
              delivery_no: '', 
              sales_order: '',
              item_no:"", 
              item_desc:"",
              item_quantity:"",
              consignee_code:"",
              consignee_name:"", 
              consignee_city:"",
              lat:"",
              lng:"",
              distance_in_km : "", 
            //   transit_time:"" 
            }]
        });
        // gridOptions.singleClickEdit = true

    }
    
    onCellEditData = async (params) => {
        if(params.colDef.field == "consignee_name")
        {
            if(params.value == Constant.NEW_CUSTOMER)
            {
                // console.log("params: ", params)
                this.setState({
                    open:true,
                    curentRow:params.data
                })
                
            }
            else{
                
                // console.log("params here ", params)
                var selectcustomer = [];
                if(this.state.allcustomers.length > 0){
                   await this.state.allcustomers.map((item) => {
                        if(item.consignee_name == params.value)
                        {
                            selectcustomer.push(item);
                             
                        }
                    })
                }
                try{
                    // console.log("selectcustomer ", selectcustomer)
                    if(selectcustomer.length > 0)
                    {   
                        var coords = JSON.parse(selectcustomer[0].coordinates);
                        // console.log("coords ", coords)
                        params.data.consignee_code = selectcustomer[0].consignee_code;
                        params.data.consignee_name = selectcustomer[0].consignee_name;
                        params.data.consignee_city = selectcustomer[0].consignee_city;
                        params.data.lat = coords.lat;
                        params.data.lng = coords.lng;
                        // console.log("params here ", params)
                        await this.gridApi.updateRowData({ update: [params.data] });
                    }
                    
                }
                catch(e){

                }
                
            }
            
        }

    }
    deleteRow(params){
        let deletedRow = params.data;
        this.gridApi.updateRowData({ remove: [deletedRow] })
    }
    
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
        try{
            let deletedRow = this.state.curentRow;
            // console.log("deletedRow ", deletedRow)
            if(deletedRow.consignee_name == Constant.NEW_CUSTOMER)
            {
                deletedRow.consignee_name = "";
                deletedRow.consignee_code = "";
                deletedRow.consignee_city = "";
                deletedRow.lat = "";
                deletedRow.lng = "";
            }
            // console.log("After deletedRow ",deletedRow)
            this.gridApi.updateRowData({ update: [deletedRow] })
        } 
        catch(e){

        }
	   this.setState({ open: false });
	 };

     saveNewCustomerForm(event){
        event.preventDefault();
        var newcustomer_code = this.state.newcustomer_code;
        var newcustomer_name = this.state.newcustomer_name;
        var newcustomer_city = this.state.newcustomer_city;
        var newcustomer_latitude =this.state.newcustomer_latitude;
        var newcustomer_longitude = this.state.newcustomer_longitude;
        var parameters = {
            newcustomer_code:newcustomer_code,
            newcustomer_name:newcustomer_name,
            newcustomer_city:newcustomer_city,
            newcustomer_latitude:newcustomer_latitude,
            newcustomer_longitude:newcustomer_longitude,
        }
        redirectURL.post("/chettinad/savecustomer",parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var row = this.state.curentRow;
                row.consignee_code = this.state.newcustomer_code;
                row.consignee_name = this.state.newcustomer_name;
                row.consignee_city = this.state.newcustomer_city;
                row.lat = this.state.newcustomer_latitude;
                row.lng = this.state.newcustomer_longitude; 
                this.gridApi.updateRowData({ update: [row] })
                
                this.setState({
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
                this.onLoadCustomers();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to add customer",
                    basicType:"danger",
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
        
     }
     newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

     onClickHideAll(){
        // try{
        //     let deletedRow = this.state.curentRow;
        //     // console.log("deletedRow ", deletedRow)
        //     if(deletedRow.consignee_name == "New Customer")
        //     {
        //         deletedRow.consignee_name = "";
        //         deletedRow.consignee_code = "";
        //         deletedRow.consignee_city = "";
        //         deletedRow.lat = "";
        //         deletedRow.lng = "";
        //     }
        //     console.log("After deletedRow ",deletedRow)
        //     this.gridApi.updateRowData({ update: [deletedRow] })
        // } 
        // catch(e){

        // }
        
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			bulkslide:'',
		});
		
	}
    handleMouseOver = () => {
        this.setState({ padding: 10 });
      }
    
      // Reset padding when the cursor moves off of the component
      handleMouseOut = () => {
        this.setState({ padding: 8 });
      }
    
    

    render(){
        
		const { open } = this.state;
        var customerslist = this.state.customers;
        var columnwithDefs  = [
            {
                headerName:"Batch No",
                field:"consignment_code",
                editable:true,
                width:120
            },
            {
                headerName:"Delivery No",
                field:"delivery_no",
                editable:true,
                cellEditor:NumericCellEditor,
                width:140
            },
            {
                headerName:"SO Number",
                field:"sales_order",
                editable:true,
                width:140
            },
            {
                headerName:"Item No",
                field:"item_no",
                width:100,
                editable:true,
            },
            {
                headerName:"Item Desctiption",
                field:"item_desc",
                width:220,
                editable:true,
            },
            {
                headerName:"Quantity",
                field:"item_quantity",
                width:100,
                editable:true,
                cellEditor:NumericCellEditor
              
            },
            {
                headerName:"Customer Name",
                field:"consignee_name",
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: customerslist,
                }
            },
            {
                headerName:"Customer Code",
                field:"consignee_code",
                width:100,
                editable:false,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.customer_code != "")
                //         {
                //             return params.data.customer_code;
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            {
                headerName:"Customer City",
                field:"consignee_city",
                width:184,
                editable:false,
            },
            {
                headerName:"Customer Latitude",
                field:"lat",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Customer Longitude",
                field:"lng",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Expected Distance (KM)",
                field:"distance_in_km",
                width:140,
                editable:true,
                cellEditor:NumericCellEditor
            },
            // {
            //     headerName:"Transit Time (Days)",
            //     field:"transit_time",
            //     width:140,
            //     editable:true,
            //     cellEditor:NumericCellEditor
            // },
            {
                headerName:"",
                field:"",
                width:100,
                editable:false,
                cellRendererSelector:function(params){
                    return { 
                        component: 'RemoveGridRow'
                    };
                },
            }
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Create Shipment </span>
                                    {/* <a href={"/shipment"} className="btn btn-default pull-right">Back to List</a> */}
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                             
                            <form method="POST" className="form-theme col-xl-12 col-lg-12" onSubmit={this.createShipmentForm.bind(this)}>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_PARKING}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="form-group col-sm-2">
                                                <label>Truck No</label>
                                                <input type="text" className="form-control" name="truck_no" id="truck_no" onChange={this.changeVehicleHandler} value={this.state.truck_no} size="10" onBlur={this.onBlurTruck.bind(this)} required />
                                                <span className={" f12 "+(this.state.errtruck)+(this.state.clr)} style = {{color:this.state.clr}}>{this.state.errtruckmsg}</span>
                                            </div>
                                            
                                            {/*<div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_VEHICLE_TYPE}</label>
                                                <Select 
                                                    required ={true}
                                                    className="" 
                                                    name="vehicle_type" 
                                                    id="vehicle_type" 
                                                    onChange={this.onChangeVehicleType.bind(this)} 
                                                    value={this.state.vehicle_type} 
                                                    options={this.vehicletypes()}
                                                />
                                            </div>*/}
                                            
                                            {/* <div className="form-group col-sm-2">
                                                <label>Total Shipment Qty.</label>
                                                <input type="text" className="form-controle" name="total_shipment_qty" id="total_shipment_qty" onChange={this.changeHandler} value={this.state.total_shipment_qty} />
                                            </div> */}
                                            
                                            {/*<div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_ENTRY_NO}</label>
                                                <input type="text" className="form-control" name="parking_id" id="parking_id" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.parking_id} required />
                                        </div>*/}
                                            
                                            {/*<div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_DATE}</label>
                                                <input type="text" className="datepicker form-control" name="parking_date" id="parking_date" onChange={this.changeHandler}  required />
                                    </div>*/}
                                            {/*<div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_TIME}</label>
                                                <input type="text" className="datetimepicker_time form-control" name="parking_time" id="parking_time" onChange={this.changeHandler}  required />
                                </div>*/}
                                        
                                        
                                            <div className="form-group col-sm-4">
                                                <label>{Constant.LABEL_TPT_CODE}</label>
                                                <input type="text"  className="form-control" name="transporter_code" id="transporter_code" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.transporter_code} />
                                            </div>
                                            
                                            <div className="form-group col-sm-3">
                                                <label>{Constant.LABEL_TPT_NAME}</label>
                                                <input type="text"  className="form-control" name="transporter_name" id="transporter_name" onChange={this.changeAlphaNumericHandler} value={this.state.transporter_name} />
                                            </div>
                                            
                                            {/* <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_DRIVER_CODE}</label>
                                                <input type="text" className="form-control" name="driver_code" id="driver_code" onChange={this.changeAlphaNumericHandler} value={this.state.driver_code}  />
                                            </div> */}
                                            </div>
                                            <div className="row">
                                            
                                            <div className="form-group col-sm-3">
                                                <label>{Constant.LABEL_DRIVER_NAME}</label>
                                                <input type="text" className="form-control" name="driver_name" id="driver_name" onChange={this.changeAlphaNumericHandler} value={this.state.driver_name}  />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>Driver Mobile Number</label>
                                                <input type="text" className="form-control" name="driver_mobile_number" id="driver_mobile_number" onChange={this.changeAlphaNumericHandler} value={this.state.driver_mobile_number} required />
                                                {this.state.errmsg ? <p className="txt-danger">*Invalid Mobile Number</p>:""}
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
										<label>SIM Tracking Service Provider</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="mobile_network"
											style={{ borderRadius: "0px" }}
											value={this.state.mobile_network}
											onChange={this.newFilter.bind(this, "mobile_network")}
											options={this.state.sim_tracking_service_provider}
                                            required ={true}
										/>
									</div>

                                            

                                            
                                        </div>
                                        
                                    </div>
                                </fieldset>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_SHIPMENT}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            {/*<div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_MODE}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_mode" 
                                                    id="shipment_mode" 
                                                    onChange={this.onChangeModeType.bind(this)} 
                                                    value={this.state.shipment_mode} 
                                                    options={this.shipmentmodes()}
                                                    required={true}
                                                />
                                            </div>*/}
                                            <div className="form-group col-sm-3">
                                                <label>Invoice Number</label>
                                                <input type="text" className="form-control" name="invoice_no" id="invoice_no" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.invoice_no}  />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>LPS(DELIVERY NO)</label>
                                                <input type="text" className="form-control" name="shipment_no" id="shipment_no" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.shipment_no}  />
                                            </div>

                                            <div className={"form-group col-sm-3 "+(this.state.shipsrcdestshow)}>
                                                <label>{Constant.LABEL_SHIPMENT_SOURCE}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_source" 
                                                    id="shipment_source" 
                                                    onChange={this.onChangeSources.bind(this)} 
                                                    value={this.state.shipment_source} 
                                                    options={this.shipmentsources()}
                                                    required ={true}
                                                />
                                            </div>
                                            
                                            <div className={"form-group col-sm-3 "+(this.state.shipsrcdestshow)}>
                                                <label>{Constant.LABEL_SHIPMENT_DESTINATION}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_destination" 
                                                    id="shipment_destination" 
                                                    onChange={this.onChangeDestination.bind(this)} 
                                                    value={this.state.shipment_destination} 
                                                    options={this.shipmentdestinations()}
                                                    required ={true}
                                                />
                                            </div>
                                            
                                            {/* <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_DATE}</label>
                                                <input type="text" className="datepicker form-control" name="shipment_date" id="shipment_date" onChange={this.changeHandler}   />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_TIME}</label>
                                                <input type="text" className="datetimepicker_time form-control" name="shipment_time" id="shipment_time" onChange={this.changeHandler}  required />
                                            </div> */}
                                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                            <label>LPS Time</label>  
											<Datetime 
											inputProps={{  name: 'shipment_time', autoComplete:'off' }} 
											dateFormat="YYYY-MM-DD" 
											 
											name="shipment_time"
											value={this.state.shipment_time} 
											onChange={this.handlerStartDateTime.bind(this)} />
	
									        </div> */}
                                            <div className="form-group col-sm-2">
                                                <label>LPS Date</label>
                                                <input type="text" className="datepicker form-control" name="shipment_date" id="shipment_date" onChange={this.changeHandler}  required />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>LPS Time</label>
                                                <input type="text" className="datetimepicker_time form-control" name="shipment_time" id="shipment_time" onChange={this.changeHandler}  required />
                                            </div>


                                            {/* <div className="form-group col-sm-3">
                                                <label>Customer Name</label>
                                                <input type="text" disabled={this.state.isreadonly} className="form-control" name="customer_name" id="customer_name" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.customer_name} required />
                                            </div> */}
                                            <div className="form-group col-sm-3">
                                                <label>Customer Name</label>
                                                <input type="text"  className="form-control" name="customer_name" id="customer_name" onChange={this.changeAlphaNumericHandler} value={this.state.customer_name} required/>
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>Customer City</label>
                                                <input type="text" className="form-control" name="customer_city" id="customer_city" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.customer_city} />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>Customer Address</label>
                                                <input type="text" className="form-control" name="customer_address" id="customer_address" onChange={this.changeAlphaNumericHandler} value={this.state.customer_address} required />
                                              
                                            </div>

                                            {/* <div className="form-group col-sm-3">
                                                <label>Customer Address</label>
                                                <input type="text" className="form-control" name="customer_address" id="customer_address" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.customer_address} required />
                                            </div> */}
                                            <div className="form-group col-sm-3">
                                                <label>Customer Pincode</label>
                                                <input type="text" className="form-control" name="customer_pincode" id="customer_pincode" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.customer_pincode} required />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>Order No</label>
                                                <input type="text" className="form-control" name="consignment_code" id="consignment_code" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.consignment_code} required />
                                            </div>
                                            <div className="col-xl-3 col-lg-3 form-group">
                                            <label>Order Date</label>  
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: '----', name: 'startDate', autoComplete: 'off', readOnly: 'true'}}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTimeForOrderDate} />
	
									        </div>
                                            
                                            <div className="form-group col-xl-3 col-lg-3">
										<label>Freight Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="truck_type"
											style={{ borderRadius: "0px" }}
											value={this.state.truck_type}
											onChange={this.newFilter.bind(this, "truck_type")}
											options={this.state.truck_types}
                                            required ={true}
										/>
									</div>
                                    <div className="form-group col-sm-3">
                                                <label>Item Description</label>
                                                <input type="text"  className="form-control" name="item_desc" id="item_desc" onChange={this.changeAlphaNumericHandler} value={this.state.item_desc} />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>Item Quantity</label>
                                                <input type="text"  className="form-control" name="item_quantity" id="item_quantity" onChange={this.changeAlphaNumericHandler} value={this.state.item_quantity} />
                                            </div>
                                    
                                           


                                            
                                            
                                        </div>
                                    </div>
                                </fieldset>
                            
                                <div className="row mt-40p">
                                    <div className="col-sm-12">
                                    
                                        <button type="submit" className="btn btn-success" style={{ padding: `${this.state.padding}px`}} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>Create Shipment</button>
                                        
                                    </div>
                                </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				
				<Modal open={open} onClose={this.onCloseModal}  >
	                <div className={""} style={{padding:'5px 0px', overflow: 'hidden',zIndex:'9999',}} >
						<form className="row theme-form" method="post" onSubmit={this.saveNewCustomerForm.bind(this)}>

                            <div className="form-group col-sm-4">
                                <label>Customer Code</label>
                                <input type="text"  value={this.state.newcustomer_code} className="form-control" name="newcustomer_code" id="newcustomer_code" onChange={this.changeAlphaNumericNOSpeacialHandler} required />
                            </div>
                            
                            <div className="form-group col-sm-4">
                                <label>Customer Name</label>
                                <input type="text"  value={this.state.newcustomer_name} className="form-control" name="newcustomer_name" id="newcustomer_name" onChange={this.changeAlphaNumericHandler.bind(this)} required />
                            </div>
                            
                            <div className="form-group col-sm-4">
                                <label>Customer City</label>
                                <input type="text"  value={this.state.newcustomer_city} className="form-control" name="newcustomer_city" id="newcustomer_city" onChange={this.changeAlphaNumericHandler} required />
                            </div>
                            
                            <div className="form-group col-sm-6">
                                <label>Customer Latitude</label>
                                <input type="text"  value={this.state.newcustomer_latitude} className="form-control" name="newcustomer_latitude" id="newcustomer_latitude" onChange={this.changeCoords} required />
                            </div>
                            
                            <div className="form-group col-sm-6">
                                <label>Customer Longitude</label>
                                <input type="text"  value={this.state.newcustomer_longitude} className="form-control" name="newcustomer_longitude" id="newcustomer_longitude" onChange={this.changeCoords} required />
                            </div>
                            <div className="form-group col-sm-12">
                                <button type="submit" className="btn btn-success pull-right f12">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}
$( function() {
    $( ".datepicker" ).datepicker({dateFormat:"dd-mm-yy",maxDate:0});
  } );

function loadDatePicker(){
    $( ".datepicker" ).datepicker();
}
$('body').on('focus',".datepicker", function(){

    if( $(this).hasClass('hasDatepicker') === false )  {
        $(this).datepicker();
    }

});



window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        minDate:1
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
        minDate:0
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false,
        minDate:0
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function() {

     this.value = this.value.replace(/\D/g,'');
    
    })
    
    $(document).on("input", ".mobnum-cls", function() {
    
     this.value = this.value.replace(/^\d{11}$/g,'');
    
    })
    
    $(document).on("input", ".alfa-cls", function () {
    
     this.value = this.value.replace(/[^a-z\s]+$/gi, '');
    
     });
    
     $(document).on("input", ".dept-cls", function () {
    
     this.value = this.value.replace(/[^a-z-\s]/gi, '');
    
    });
    
     $(document).on("input", ".ringi-cls", function () {
    
     this.value = this.value.toUpperCase();
    
     this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
    
     });