import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
// import Select from 'react-select';
import { 
    // getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	// getYYYYMMDD, 
	// getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	// getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	// getHyphenDDMMYYYY,
	// getDDMMMYYYYHHMMDefault 
    
	
} from '../common/utils'; 

import MapRoutes from './maproute';

import DrawMap from '../common/drawmap';

var moment = require('moment');
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class chettinadShipmentRail extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                MapRoutes:MapRoutes
			},
            sliderRouteTranslate:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            totalcount:[],
            intransitmy:[],
            insidemytpa:[],
            loadingstarted:[],
            loadingcompleted:[],
            insidetpaafterloading:[],
            intransittocustomer:[],
            nearcustomer:[],
            reachedcustomer:[],
            leftcustomer:[],
            forceclose:[],
            insidetpa:[],
            dsc:[],
            crm:[],
            platemill:[],
            mys:[],
            dsclc:[],
            crmlc:[],
            platemilllc:[],
            myslc:[]

        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		
    }
    componentDidMount(){
        this.onLoadGetShipments();
    }
    onLoadGetShipments(){
        this.setState({
            loadshow:"show-m"
        })
        var parameters = {
        }
        redirectURL.post("/chettinad/shipments",parameters)
        .then((response) => {
            if(response.data.remark == "SUCCESS")
            {
                var shipments = response.data.shipments;
                
                var totalcount=[];
                var insidetpa = [];
                var intransitmy=[];
                var insidemytpa=[];
                var loadingstarted=[];
                var loadingcompleted=[]
                var insidetpaafterloading=[];
                var intransittocustomer = [];
                var nearcustomer = [];
                var reachedcustomer = [];
                var leftcustomer = [];
                var forceclose = [];
                var dsc=[];
                var crm=[];
                var platemill = [];
                var mys = [];
                 
                var dsclc=[];
                var crmlc=[];
                var platemilllc = [];
                var myslc = [];
                // console.log("shipments ", shipments)
                if(shipments.length > 0){
                    shipments.map((item)=> {
                        if(item.shipment_mode == "RAIL")
                        {
                            totalcount.push(item);
                            // console.log("item.status ",item.status)
                            if(item.status == 1 || item.status == "1")
                            {
                                insidetpa.push(item);
                            }
                            if(item.status == 2 || item.status == "2")
                            {
                                intransitmy.push(item);
                            }
                            
                            if(item.status == 3 || item.status == "3")
                            {
                                insidemytpa.push(item);
                            }
                            if(item.status == 4 || item.status == "4")
                            {
                                loadingstarted.push(item);
                                if(item.loading_area_name == "MY")
                                {
                                    mys.push(item);
                                }
                                if(item.loading_area_name == "DSC")
                                {
                                    dsc.push(item);
                                }
                                if(item.loading_area_name == "CRM")
                                {
                                    crm.push(item);
                                }
                                if(item.loading_area_name == "PLATE MILL")
                                {
                                    platemill.push(item);
                                }
                            }
                            
                            if(item.status == 5 || item.status == "5")
                            {
                                loadingcompleted.push(item);
                                if(item.loading_area_name == "MY")
                                {
                                    myslc.push(item);
                                }
                                if(item.loading_area_name == "DSC")
                                {
                                    dsclc.push(item);
                                }
                                if(item.loading_area_name == "CRM")
                                {
                                    crmlc.push(item);
                                }
                                if(item.loading_area_name == "PLATE MILL")
                                {
                                    platemilllc.push(item);
                                }
                            }
                            if(item.status == 7 || item.status == "7")
                            {
                                insidetpaafterloading.push(item);
                            }
                            
                            if(item.status == 8 || item.status == "8")
                            {
                                intransittocustomer.push(item);
                            }
                            if(item.status == 9 || item.status == "9")
                            {
                                nearcustomer.push(item);
                            }
                            if(item.status == 10 || item.status == "10")
                            {
                                reachedcustomer.push(item);
                            }
                            if(item.status == 11 || item.status == "11")
                            {
                                leftcustomer.push(item);
                            }
                            if(item.status == 6 || item.status == "6")
                            {
                                forceclose.push(item);
                            }
                        }

                    })
                }
                this.setState({
                    rowData:totalcount,
                    loadshow:"show-n",
                    totalcount:totalcount,
                    insidemytpa:insidemytpa,
                    intransitmy:intransitmy,
                    loadingstarted:loadingstarted,
                    loadingcompleted:loadingcompleted,
                    insidetpaafterloading:insidetpaafterloading,
                    intransittocustomer:intransittocustomer,
                    nearcustomer:nearcustomer,
                    reachedcustomer:reachedcustomer,
                    leftcustomer:leftcustomer,
                    forceclose:forceclose,
                    insidetpa:insidetpa,
                    dsc:dsc,
                    crm:crm,
                    platemill:platemill,
                    mys:mys,
                    dsclc:dsclc,
                    crmlc:crmlc,
                    platemilllc:platemilllc,
                    myslc:myslc
                })
            }
            
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.parking_in_time != "" && e.parking_in_time != undefined)
        {
            var ptime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        
        var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        
        
        var reqData = {
            truck_no:e.truck_no,
			consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"consignment"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if( records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Consignment : "+e.consignment_code,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Consignment Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                                // baseRouteCoords: baseRouteCoords
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}

    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
    onClickCounter(parm){
        this.setState({
            loadshow:"show-m"
        })
        if(parm == "0"){
            this.setState({
                rowData:this.state.totalcount,
                loadshow:"show-n"
            })
        }
        
        if(parm == "1"){
            this.setState({
                rowData:this.state.insidetpa,
                loadshow:"show-n"
            })
        }
        if(parm == "3"){
            this.setState({
                rowData:this.state.insidemytpa,
                loadshow:"show-n"
            })
        }
        
        if(parm == "2"){
            this.setState({
                rowData:this.state.intransitmy,
                loadshow:"show-n"
            })
        }
        
        if(parm == "4"){
            this.setState({
                rowData:this.state.loadingstarted,
                loadshow:"show-n"
            })
        }
        if(parm == "4a"){
            this.setState({
                rowData:this.state.mys,
                loadshow:"show-n"
            })
        }
        if(parm == "4b"){
            this.setState({
                rowData:this.state.dsc,
                loadshow:"show-n"
            })
        }
        if(parm == "4c"){
            this.setState({
                rowData:this.state.crm,
                loadshow:"show-n"
            })
        }
        if(parm == "4d"){
            this.setState({
                rowData:this.state.platemill,
                loadshow:"show-n"
            })
        }
        
        if(parm == "5"){
            this.setState({
                rowData:this.state.loadingcompleted,
                loadshow:"show-n"
            })
        }

        if(parm == "5a"){
            this.setState({
                rowData:this.state.myslc,
                loadshow:"show-n"
            })
        }
        if(parm == "5b"){
            this.setState({
                rowData:this.state.dsclc,
                loadshow:"show-n"
            })
        }
        if(parm == "5c"){
            this.setState({
                rowData:this.state.crmlc,
                loadshow:"show-n"
            })
        }
        if(parm == "5d"){
            this.setState({
                rowData:this.state.platemilllc,
                loadshow:"show-n"
            })
        }
        
        if(parm == "7"){
            this.setState({
                rowData:this.state.insidetpaafterloading,
                loadshow:"show-n"
            })
        }

        if(parm == "8"){
            this.setState({
                rowData:this.state.intransittocustomer,
                loadshow:"show-n"
            })
        }
        
        
        // if(parm == "6"){
        //     this.setState({
        //         rowData:this.state.forceclose,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "7"){
        //     this.setState({
        //         rowData:this.state.nearcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "8"){
        //     this.setState({
        //         rowData:this.state.reachedcustomer,
        //         loadshow:"show-n"
        //     })
        // }
        // if(parm == "9"){
        //     this.setState({
        //         rowData:this.state.leftcustomer,
        //         loadshow:"show-n"
        //     })
        // }
    }
    render(){
        var maptransporter='';
		
        var columnwithDefs = [
            
            {
                headerName: "",
                field: "_id",
                colId: "_id",
                width: 50,
                pinned: 'left',
                cellRenderer:'MapRoutes',
                filter: false,resizable: true
                
            },
            {
                headerName:Constant.COL_CONSIGNMENT_CODE,
                field:"consignment_code",
                width:120,
                pinned:'left'
            },
            {
                headerName:Constant.COL_STATUS,
                field:"status",
                pinned:'left',
                valueGetter:function(params){
                    try{
                        var status = Constant.INSIDE_TPA;
                        if(params.data.status == 1 || params.data.status == "1")
                        {
                            status = Constant.INSIDE_TPA;
                        }
                        if(params.data.status == 2 || params.data.status == "2")
                        {
                            status = Constant.INTRANSIT_TO_MY;
                        }
                        if(params.data.status == 3 || params.data.status == "3")
                        {
                            status = Constant.INSIDE_MY_PMILL;
                        }
                        if(params.data.status == 4 || params.data.status == "4")
                        {
                            status = Constant.LOADING_STARTED;
                        }
                        if(params.data.status == 5 || params.data.status == "5")
                        {
                            status = Constant.LOADING_COMPLETED;
                        }
                        
                        if(params.data.status == 7 || params.data.status == "7")
                        {
                            status = Constant.INSIDE_TPA_AFTER_LOADING;
                        }
                        return status;
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constant.COL_GROUP_HEADER_SHIPMENT_DATA,
                field:"transporter_code",
				headerClass:["cellstylegridB","cellTopHeader"],
                children:[
                    {
                        headerName:Constant.COL_SHIPMENT_DOC_NO,
                        field:"shipment_id",
                        width:120,
                    },
                    {
                        headerName:Constant.COL_SHIPMENT_DATE,
                        field:"shipment_time",
                        width:140,
                        valueGetter:function(params){
                            try{
                                if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_PARKING_ENTRY_NO,
                        field:"parking_id",
                        width:120
                    },
                    
                    {
                        headerName:Constant.COL_PARKING_DATE,
                        field:"parking_in_time",
                        width:140,
                        valueGetter:function(params){
                            try{
                                if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    
                    
                    {
                        headerName:Constant.COL_TRUCK_NO,
                        field:"truck_no",
                        width:120
                    },
                    {
                        headerName:Constant.COL_TRUCK_TYPE,
                        field:"vehicle_mode",
                        width:80
                    },
                    {
                        headerName:Constant.COL_SHIPMENT_MODE,
                        field:"shipment_mode",
                        width:80
                    },
                ]
            },
            
            {
                headerName:Constant.COL_GROUP_HEADER_TPA_TO_MY,
                field:"transporter_code",
				headerClass:["cellTopHeader"],
                children:[
                    // {
                    //     headerName:Constant.COL_MYLOADING_YARD_TYPE,
                    //     field:"loading_yard_type",
                    //     width:140,
                    //     headerClass:"cellstylegridB",
					// 	cellClass:'cellstylegridB',
                    //     valueGetter:function(params){
                    //         try{
                    //             // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                    //             // {
                    //             //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                    //             // }
                    //             // else{
                    //             //     return "";
                    //             // }
                    //         }
                    //         catch(e){}
                    //     }

                    // },
                    {
                        headerName:Constant.COL_MY_IN,
                        field:"loading_yard_entry_time",
                        width:140,
                        headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_yard_entry_time != "" && params.data.loading_yard_entry_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_entry_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }

                    },
                    {
                        headerName:Constant.COL_MY_OUT,
                        field:"loading_yard_exit_time",
                        width:140,
                        headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_yard_exit_time != "" && params.data.loading_yard_exit_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_TIME_SPEND_HRS,
                        field:"loading_yard_duration",
                        width:120,
                        headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_yard_duration != "" && params.data.loading_yard_duration != undefined)
                                {
                                    return secondsToDhms(params.data.loading_yard_duration);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    }
                ]
            },

            {
                headerName:Constant.COL_GROUP_HEADER_MY_TO_LOADING_AREA,
                field:"",
				headerClass:["cellstylegridG","cellTopHeader"],
                children:[
                    {
                        headerName:Constant.COL_LOADING_AREA_NAME,
                        field:"loading_area_name",
                        width:140,
                        headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
                        valueGetter:function(params){
                            try{
                                // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                                // {
                                //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                                // }
                                // else{
                                //     return "";
                                // }
                            }
                            catch(e){}
                        }

                    },
                    {
                        headerName:Constant.COL_LOADING_AREA_ENTRY_TIME,
                        field:"loading_area_entry_time",
                        width:140,
                        headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_area_entry_time != "" && params.data.loading_area_entry_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.loading_area_entry_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_LOADING_AREA_EXIT_TIME,
                        field:"loading_area_exit_time",
                        headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
                        width:120,
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_area_exit_time != "" && params.data.loading_area_exit_time != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.loading_area_exit_time);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_LOADING_AREA_DURATION,
                        field:"loading_area_duration",
                        headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
                        width:120,
                        valueGetter:function(params){
                            try{
                                if(params.data.loading_area_duration != "" && params.data.loading_area_duration != undefined)
                                {
                                    return secondsToDhms(params.data.loading_area_duration);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    }
                ]
            },

            
            {
                headerName:Constant.COL_GROUP_HEADER_MY_TO_TPA,
                field:"",
				headerClass:["cellstylegridM","cellTopHeader"],
                children:[
                    {
                        headerName:Constant.COL_TPA_ENTRY_AFTER_LOADING,
                        field:"tpa_entry_time_after_loading",
                        width:140,
                        headerClass:"cellstylegridM",
						cellClass:'cellstylegridM',
                        valueGetter:function(params){
                            try{
                                if(params.data.tpa_entry_time_after_loading != "" && params.data.tpa_entry_time_after_loading != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.tpa_entry_time_after_loading);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }

                    },
                    {
                        headerName:Constant.COL_TPA_EXIT_AFTER_LOADING,
                        field:"tpa_exit_time_after_loading",
                        width:140,
                        headerClass:"cellstylegridM",
						cellClass:'cellstylegridM',
                        valueGetter:function(params){
                            try{
                                if(params.data.tpa_exit_time_after_loading != "" && params.data.tpa_exit_time_after_loading != undefined)
                                {
                                    return getHyphenDDMMMYYYYHHMM(params.data.tpa_exit_time_after_loading);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                    {
                        headerName:Constant.COL_TPA_DURATION_AFTER_LOADING,
                        field:"tpa_duration_after_loading",
                        headerClass:"cellstylegridM",
						cellClass:'cellstylegridM',
                        width:120,
                        valueGetter:function(params){
                            try{
                                if(params.data.tpa_duration_after_loading != "" && params.data.tpa_duration_after_loading != undefined)
                                {
                                    return secondsToDhms(params.data.tpa_duration_after_loading);
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){}
                        }
                    },
                   
                ]
            },
            
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_UNAUTHORIZED_STOPPAGES,
            //     field:"",
			// 	headerClass:["cellstylegridY","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_WORKSHOP_NAME,
            //             field:"workshop_name",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_ENTRY_TIME,
            //             field:"workshop_entry_time",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_entry_time != "" && params.data.workshop_entry_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.workshop_entry_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_EXIT_TIME,
            //             field:"workshop_exit_time",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_exit_time != "" && params.data.workshop_exit_time != undefined)
            //                     {
            //                         return getHyphenDDMMMYYYYHHMM(params.data.workshop_exit_time);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_WORKSHOP_DURATION,
            //             field:"workshop_duration",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120,
            //             valueGetter:function(params){
            //                 try{
            //                     if(params.data.workshop_duration != "" && params.data.workshop_duration != undefined)
            //                     {
            //                         return secondsToDhms(params.data.workshop_duration);
            //                     }
            //                     else{
            //                         return "";
            //                     }
            //                 }
            //                 catch(e){}
            //             }
            //         },
                   
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_DSC,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridG","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridG",
			// 			cellClass:'cellstylegridG',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_CRM,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridM","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridM",
			// 			cellClass:'cellstylegridM',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_MY_TO_PLATE_MILL,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegridY","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegridY",
			// 			cellClass:'cellstylegridY',
            //             width:120
            //         }
            //     ]
            // },
            
            // {
            //     headerName:Constant.COL_GROUP_HEADER_TPA_TO_PIPE_MILL,
            //     field:"transporter_code",
			// 	headerClass:["cellstylegrid","cellTopHeader"],
            //     children:[
            //         {
            //             headerName:Constant.COL_MY_IN,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }

            //         },
            //         {
            //             headerName:Constant.COL_MY_OUT,
            //             field:"",
            //             width:140,
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             valueGetter:function(params){
            //                 try{
            //                     // if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
            //                     // {
            //                     //     return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
            //                     // }
            //                     // else{
            //                     //     return "";
            //                     // }
            //                 }
            //                 catch(e){}
            //             }
            //         },
            //         {
            //             headerName:Constant.COL_TIME_SPEND_HRS,
            //             field:"",
            //             headerClass:"cellstylegrid",
			// 			cellClass:'cellstylegrid',
            //             width:120
            //         }
            //     ]
            // },
            {
                headerName:Constant.COL_TPT_CODE,
                field:"transporter_code",
                width:120,
            },
            {
                headerName:Constant.COL_TPT_NAME,
                field:"transporter_name",
                width:200,
            },
            
            {
                headerName:Constant.COL_DRIVER_NAME,
                field:"driver_name",
                width:180,
            },
            
            {
                headerName:Constant.COL_RAILYARD_SOURCE,
                field:"rake_loading_point_name",
                width:200,
            },
            {
                headerName:Constant.COL_RAILYARD_DESTINATION,
                field:"rake_delivery_point_name",
                width:200,
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
            },
            
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                width:200,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"consignee_city",
                width:140,
            },
            {
                headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                field:"expected_distance_in_km",
                width:140,
            },
            // {
            //     headerName:"Track Order",
            //     field:"",
            //     cellRendererSelector:function(params){
            //         return { 
            //             component: "AWBTrackingAction"
            //         };
            //     },
            // }
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                        <ul className="ul-tabs">
                            <li className="">
                                <a href={"/shipment"}>Road Shipments</a>
                            </li>
                            <li>
                                <a className="active" href={"/railshipments"}>Rail Shipments</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                <div className="row">
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"0")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-primary"></i><br /> {Constant.COUNTER_TOTAL_CONSIGNMENTS}</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={this.state.totalcount.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"1")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> {Constant.COUNTER_INSIDE_TPA}</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.insidetpa.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"2")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-secondary"></i><br /> {Constant.COUNTER_INTRANSIT_TO_MY}</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.intransitmy.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"3")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-warning"></i><br /> {Constant.COUNTER_INSIDE_MY_PM}</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.insidemytpa.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer">
                                            <span className="f13" ><i className="fa fa-tachometer f22 txt-danger"></i><br /> {Constant.COUNTER_LOADING_STARTED}</span>
                                            <h4 className="txt-danger f40"  onClick={this.onClickCounter.bind(this,"4")} ><span className="counter"><CountUp end={this.state.loadingstarted.length}/></span></h4>
                                            <div className="row">
                                                <div className="col" onClick={this.onClickCounter.bind(this,"4a")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-success"></i><br /> {Constant.COUNTER_MYS}</span>
                                                    <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.mys.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"4b")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-danger"></i><br /> {Constant.COUNTER_DSC}</span>
                                                    <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.dsc.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"4c")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-info"></i><br /> {Constant.COUNTER_CRM}</span>
                                                    <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.crm.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"4d")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-warning"></i><br /> {Constant.COUNTER_PLATEMILL}</span>
                                                    <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.platemill.length}/></span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col cursorPointer">
                                            <span className="f13" ><i className="fa fa-tachometer f22 txt-secondary"></i><br /> {Constant.COUNTER_LOADING_COMPLETED}</span>
                                            <h4 className="txt-secondary f40"  onClick={this.onClickCounter.bind(this,"5")} ><span className="counter"><CountUp end={this.state.loadingcompleted.length}/></span></h4>
                                            <div className="row">
                                                <div className="col" onClick={this.onClickCounter.bind(this,"5a")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-success"></i><br /> {Constant.COUNTER_MYS}</span>
                                                    <h6 className="txt-success f32"><span className="counter"><CountUp end={this.state.myslc.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"5b")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-danger"></i><br /> {Constant.COUNTER_DSC}</span>
                                                    <h6 className="txt-danger f32"><span className="counter"><CountUp end={this.state.dsclc.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"5c")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-info"></i><br /> {Constant.COUNTER_CRM}</span>
                                                    <h6 className="txt-info f32"><span className="counter"><CountUp end={this.state.crmlc.length}/></span></h6>
                                                </div>
                                                <div className="col" onClick={this.onClickCounter.bind(this,"5d")}>
                                                    <span className="f13"><i className="fa fa-tachometer f16 txt-warning"></i><br /> {Constant.COUNTER_PLATEMILL}</span>
                                                    <h6 className="txt-warning f32"><span className="counter"><CountUp end={this.state.platemilllc.length}/></span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"7")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-info"></i><br /> {Constant.COUNTER_INSIDE_TPA_AFTER_LOADING}</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.insidetpaafterloading.length}/></span></h4>
                                        </div>
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"8")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-secondary"></i><br /> {Constant.COUNTER_INSTRANSIT_TO_CUSTOMER}</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.intransittocustomer.length}/></span></h4>
                                        </div> */}
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"7")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> {Constant.COUNTER_NEAR_CUSTOMER}</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.nearcustomer.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"8")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-warning"></i><br /> {Constant.COUNTER_REACHED_CUSTOMER}</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.reachedcustomer.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"9")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-info"></i><br /> {Constant.COUNTER_LEFT_CUSTOMER}</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.leftcustomer.length}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"9")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-danger"></i><br /> {Constant.COUNTER_FORCE_CLOSE}</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={this.state.forceclose.length}/></span></h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Rail Shipments</span>
                                    <a href={"/railconsignments"} className="btn btn-warning pull-right ml-10p" >Rail Consignments</a>
                                    {/* <a href={"/sndconsignments"} className="btn btn-info pull-right">Consignments</a> */}
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
            </div>
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
