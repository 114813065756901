import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import DrawMap from '../common/drawmap';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
export default class ShortURLMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			map_type_of_tracking:'',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			consignee_coordinates: [],
			baseRouteCoords:[],
			viewgoogleroutes:true,
			googleroutepath:[],
			drop_coordinates:'',
			unloading_address:'',
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  rownode:[], 
		  googelRoutes:[], 
		  maptruckno:'',
		  dealer:'',
		  mapinfo:[],
		  defTransitCoords:'',
		  showdetails:'show-n',
		  showsecond:'show-n',
          maplastlocation:'',
          mapeta:'',
		  mapinvoice_no:"",
			consigner_coordinates: [],
			tripDetails: {
				trip_ended_at: "",
				drop_coords_dist: 0,
			},
		};
		
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
	}
	componentDidMount = async() =>{
		this.setState({
			loadshow:'show-m'
		});
		var truck_no = this.props.match.params.truck_no;
		
        try{
			console.log(truck_no , "truck_no")
			if(![undefined , null , "undefined" , "" , 'no_invoice'].includes(truck_no)){
				var reqdata = {
					invoice_no:truck_no,
				}
				await redirectURL.post('/chettinad/truckshipment',reqdata)	
				.then( async (response) => {
						 console.log("response123 ", response);
						var e = response.data[0];
						if(e.parking_in_time != "" && e.parking_in_time != undefined)
						{
							var ptime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
						}
						else{
							var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
						}
						
						var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						if(e.status == "9" || e.status == "10" || e.status == 9 || e.status == 10 )
						{
							var end_date = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
						}
						else if (e.status == "11" || e.status == 11 || e.status == 12 || e.status == "12"  || e.status == "13" || e.status == 13 || e.status == 14 || e.status == "14"|| e.status == 15 || e.status == "15" || e.status == 16 || e.status == "16" || e.status == 17 || e.status == "17"|| e.status == 18 || e.status == "18"|| e.status == 19 || e.status == "19"|| e.status == 20 || e.status == "20" || e.status == 21 || e.status == "21") {
							var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
						}
						else{
							if(e.status == 6 || e.status == "6")
							{
								var end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
							}
							else{
								var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
							}
							
						}
						var reqData = {
							truck_no:e.truck_no,
							shipment_id:e.shipment_id,
							consignment_codes:[e.consignment_code],
							consignee_code:e.consignee_code,
							fromdate:ptime,
							todate:end_date,
							screen:"consignment",
							email:localStorage.getItem("email")
						}
						console.log(reqData,"reqDataaa")
						await redirectURL.post('/trucks/truckmap',reqData, {
							headers:{
								'content-type':'application/json'
							}
						})
						.then((response) => {
							var records = response.data;
	
							this.setState({
								loadshow:'show-m'
							});
							if(records.coords!="" && records.coords!=undefined){
								if(records.coords.length == 0)
								{
									//console.log("records", records.coords);
									this.setState({
										show: true,
										basicTitle:'No Route data available',
										basicType:"danger",
										loadshow:'show-n',overly:'show-n',
									});
								}
								else{
									if(records != '')
									{
										// var sdate = e.gate_out_time;
										// if(e.invoice_time)
										// {
										//     var sdate = e.invoice_time;
										// }
										// else{
										//     if(e.gate_in_time)
										//     {
										//         var sdate = e.gate_in_time;
										//     }
										//     else{
										//         //console.log("Step4")
										//         if(e.gate_out_time){
										//             //console.log("Step5")
										//             //console.log("came into gate out time", e.gate_out_time)
										//             var sdate = e.gate_out_time;
										//         }
										//     }
										// }
										if (e.parking_in_time != "" && e.parking_in_time != undefined) {
											var sdate = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm");
										}
										else {
											var sdate = moment.parseZone().format('YYYY-MM-DD') + " 00:00:00";
										}
										var edate ='';
										if (e.status == "9" || e.status == "10" || e.status == 9 || e.status == 10) {
											var edate = moment.parseZone(e.recent_dealer_reported).format("DD/MM/YYYY HH:mm");
										}
										else if (e.status == "11" || e.status == 11 || e.status == 12 || e.status == "12" || e.status == "13" || e.status == 13 || e.status == 14 || e.status == "14" || e.status == 15 || e.status == "15" || e.status == 16 || e.status == "16" || e.status == 17 || e.status == "17") {
											var edate = moment.parseZone(e.left_dealer_marked).format("DD/MM/YYYY HH:mm");
										}
										else {
											if (e.status == 6 || e.status == "6") {
												var edate = moment.parseZone(e.force_closure_time).format('DD/MM/YYYY HH:mm');
											}
											else {
												var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
											}
								
										}
										this.setState({
											sliderRouteTranslate:"slider-translate-60p",
											showDiv:'show-m',
											mapinfo:records,
											map_type_of_tracking:e.type_of_tracking,
											dealer:e.consignee_code,
											consignment_code:"Consignment : "+e.consignment_code,
											drop_coordinates:e.drop_coordinates,
											tripDetails:{
												trip_ended_at:e.recent_dealer_reported || "",
												drop_coords_dist:e.drop_coords_to_customer_loc_dist_km || 0,
											},
											unloading_address:e.unloading_address,
											maptruckno:e.truck_no,
											maplastlocation:e.last_known_address,
											consigner_coordinates: records.consigner_coordinates,
											consignee_coordinates: records.consignee_coordinates,
											baseRouteCoords: records.base_route,
											mapeta:e.revised_trip_end,
											routeTruck: { "truck_no": e.truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time },
											loadshow:'show-n',
											sidebarSubHeader:"Consignment Information",
											overly:'show-n',
											rownode:e,
											leg_no:0,
											mapinvoice_no:truck_no
										});
										console.log(this.state.maptruckno,"maptruckno")
										console.log(this.state.consignee_coordinates,"consignee_coordinates")
										console.log(this.state.baseRouteCoords,"baseRouteCoords")
		
										this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
		
									}
									else
									{
										this.setState({
											show: true,
											basicTitle:'No Route data available',
											basicType:"danger",
											loadshow:'show-n',overly:'show-n',
										});
									}
								}
							}
							else
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n',overly:'show-n',
								});
							}
						})
						.catch(function (error) {
							console.log(error);
							this.setState({
								show: true,
								basicTitle:'No Route data available',
								basicType:"danger",
								loadshow:'show-n',overly:'show-n',
							});
						});
	
				})
					.catch(function (e) {
						console.log(e)
					})
			}else{
				this.setState({
					show: true,
					basicTitle:'Invoice is not available',
					basicType:"danger",
					loadshow:'show-n',overly:'show-n',
				});
			}
            

        
        }
        catch(e){
            
        }
        
    
		
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}


	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			showsecond:'show-n'
		});
		
	}
	onClickShowDetails(){
		this.setState({
			//loadshow:'show-m',
			overly:'show-m',
			showdetails:'show-m',
			inboxSlider:'slider-translatey',
			showsecond:'show-m'
		});
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render () {
        const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  var baseRouteCoordsData = this.state.baseRouteCoords;
	  console.log(baseRouteCoordsData,"baseRouteCoordsData")
	  
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
					{this.state.alert}
					{(this.state.mapinfo != '')?
						<div className="col-xl-12 col-md-12 n-p-0">
							{/* <AndriodMapView
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"truck"} 
								tolls={this.state.tolls} 
								consigneecords={this.state.consignee_coordinates}
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								drop_coordinates={this.state.drop_coordinates}  
								unloading_address={this.state.unloading_address}  
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={baseRouteCoordsData}
								geofence_coordinates={this.state.geofence_coordinates}/> */}
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"truck"} 
								tolls={this.state.tolls} 
								consignercords={this.state.consigner_coordinates}
								consigneecords={this.state.consignee_coordinates}
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								drop_coordinates={this.state.drop_coordinates}  
								unloading_address={this.state.unloading_address}  
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
							   baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}
								tripDetails={this.state.tripDetails}
								/>


							<div className="col-xl-12 col-lg-12 n-p-0">
                                <div className="crm-numbers pb-0">
                                    <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                    <div className="col-xl-12 col-lg-12 row">
                                        <div className="col route-block">
                                            <label className="sidebar-label">Truck No</label>
                                            <div>{this.state.routeTruck.truck_no}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">Invoice No</label>
                                            <div>{this.state.mapinvoice_no}</div>
                                        </div>
										<div className="col route-block">
											<label className="sidebar-label">Start Time</label>
											<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
										</div>
										<div className="col route-block">
											<label className="sidebar-label">End Time</label>
											<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
										</div>
										<div className="col route-block">
											<label className="sidebar-label">GPS Distance</label>
											<div>{(this.state.mapinfo.route_details != '') ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
										</div>
										<div className="col route-block">
											<label className="sidebar-label">Tracking Type</label><br/>
											{(this.state.mapinfo.type_of_tracking == 1)?
												<div>GPS Tracking</div>
                                                :
												<div>SIM Tracking</div>
                                                }
											</div>
									{/* {(localStorage.getItem('roles') != "CUSTOMER") ?
										<div className="col route-block">
											<label className="sidebar-label">Tracking Type</label>
											{(this.state.map_type_of_tracking == 1) ?
												<div>GPS Tracking</div>
												:
												<div>SIM Tracking</div>
											}
										</div>
										: ""} */}
									{/* <div className="col route-block">
                                            <label className="sidebar-label">Last Location</label>
                                            <div>{this.state.maplastlocation}</div>
                                        </div> */}
                                        {/* <div className="col route-block">
                                            <label className="sidebar-label">ETA</label>
                                            <div>{(!isEmpty(this.state.mapeta))?getDDMMYYYYHHMMSS(this.state.mapeta):"-"}</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div>
							<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
					
					 </div>
					
					:''}
							
			</div>
        )
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
