import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import $ from 'jquery';
import Modal from 'react-responsive-modal';
import EditItem from "../manage/editaction";

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class masterSummaryData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            sliderEditTransporter:"",

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            created_date:"",
            unique_id:"",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            frameworkComponents: {
                // updateItem: UpdateItem,
                // deleteItem: DeleteItem
                //statusChange:StatusChange
                EditItem:EditItem,

            },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            rowid:"",
            consignee_state_code:"",
            consignee_state_name:"",
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            amount_payable: [],
            pinnedBottomRowData: [],
            csvcontent: [],
            billing_month: "",
            sliderBulkForceClose:"",
            freight_type:"",
            consignee_code:"",
            consignee_name:"",
            distance_in_km:"",
            consignee_district_name:"",
            consignee_district_code:"",
            remarks_history_cols: [
                {
                    headerName: "Truck No",
                    field: "truck_no",
                    width: 150,
                    editable: false,
                    filter: "agSetColumnFilter"
                },

                {
                    headerName: "Remark",
                    field: "individual_remarks",
                    width: 180,
                    editable: false,
                    filter: "agSetColumnFilter"
                },
                {
                    headerName: "Updated Time",
                    field: "remarktime",
                    width: 150,
                    editable: false,
                    filter: "agSetColumnFilter",
                    valueGetter: function (params) {
                        try {
                            if (params.data.remarktime != '' && params.data.remarktime != undefined) {
                                return getHyphenYYYYMMDDHHMMSS(params.data.remarktime)
                            }
                            else {
                                return "";
                            }
                        }
                        catch (e) {
                            return "";
                        }
                    }
                },

            ],
            remarks_data: [],
        };
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
    }
    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/tptoemmaster":
                department = 'SNDG';
                departmentName = " ";
                break;

            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    componentDidMount() {
        var unique_id = window.atob(this.props.match.params.unique_id);
        console.log(unique_id, "unique_id")
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); 
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction, unique_id: unique_id });

        }

        this.setState({
            loadshow: "show-m",
            overly: "show-m",
            unique_id
                })


        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        this.setState({
            departmentCode: currentDepartmentCode
        });
        /*Consignments List*/
        this.onLoadData(unique_id);

    };
    onLoadData = (unique_id) => {
        console.log(unique_id,"unique_id")
        this.setState({unique_id:unique_id})
        let bmonth = {
            unique_id,
        }
        redirectURL.post('/consignees/getMasterSummaryData', bmonth)
            .then((response) => {
                var records = response.data
                console.log("records", records)

                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    rowData: records,
                });
            })
            .catch(function (error) {
                console.log(error);
            });





        // pinnedBottomRowData['total_travel_time_mins'] = "total";

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }


    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;


    };


    onGridState1 = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState1 = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);


    }


    onClickUpdateRowHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Update OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Update OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                rowID: this.state.rowid,
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/updateoemitem", params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
                then(
                    (response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully update data',
                                basicType: "success",
                                sliderUpdate: "",
                                overly: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1);

        this.setState({
            billing_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    onClickBulkUpload()
	{
		this.setState({
			sliderBulkForceClose:'slider-translate',
			overly:"show-m"
		});
	}
    onClickBtn(){
        window.location.href="/freightmaster"
    }
    onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderRakeTranslate:'',
			bulkslide:'',
			sliderForceDestination:'',
			sliderForceLoadingYard:'',
			sliderBulkForceClose:"",
			bulkdeliverslide:""
		});
		
	}
    formBulkForceCloseHandler(event){
		event.preventDefault();
		var dept_code = this.state.deptcode;
		//console.log(this.state.csvcontent);
		if(this.state.file != '')
		{
			var csvdd = this.state.file;			
			var rparams = {
				uploadFile:this.state.file,
				userId:localStorage.getItem('userid'),
				csvcontent:this.state.csvcontent,
				dept_code : dept_code,
                email:localStorage.getItem("email")
			}
			
			//console.log('file',formData);
			console.log('rparams3267',rparams);
			this.setState({
				loadshow:'show-m'
			});
			var reasonFlag = 0;
			var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
			this.state.csvcontent.map(function(e,index){
				if(index != 0)
				{
					if(e.reason == "" || e.reason == null || e.reason == undefined)
					{
						reasonFlag = 1;
					}
					if(reasonFlag == 0)
					{
						if(format.test(e.reason)){reasonFlag=1;}
                    }
                }

            })
            console.log(reasonFlag)
            redirectURL.post("/chettinad/uploadfreightmasterdata", rparams)
                .then(
                    (response) => {
                        console.log(response, "3293");
                        if (response.data.status == 'success') {
                            console.log(response.data.message, "err")
                            this.setState({
                                show: true,
                                basicType: 'success',
                                basicTitle: "Bulk Uploaded Successfully",
                                uploadDivWidth: '0%',
                                sliderTranslate: '',
                                showDiv: 'show-n',
                                uploadFile: '',
                                loadshow: 'show-n',
                                file: '',
                                sliderBulkForceClose: "",
                                overly: "show-n"
                            });

                        }
                    }).catch(function (error) {
                        console.log(error);
                    })

        }
        window.location.href="/freightmaster";
    }
    changeBulkForceFileHandler = async (e) => {
		var dt = '';
		const config = {
			headers: [
				{ 
					name: 'state_code',
					inputName: 'state_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'state_name',
					inputName: 'state_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'destination_code',
					inputName: 'destination_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'destination_name',
					inputName: 'destination_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                 
                { 
					name: 'plant_code',
					inputName: 'plant_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'plant_name',
					inputName: 'plant_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'district_code',
					inputName: 'district_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'district_name',
					inputName: 'district_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'material_segment_code',
					inputName: 'material_segment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'material_segment_name',
					inputName: 'material_segment_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'freight_type',
					inputName: 'freight_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'freight_description',
					inputName: 'freight_description',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'distance_in_km',
					inputName: 'distance_in_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'primary_freight',
					inputName: 'primary_freight',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consignee_taluk_name',
					inputName: 'consignee_taluk_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'pincode',
					inputName: 'pincode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'EditItem',
                    editable:false
                  }


				
                
			]
		}
        if(e.target.files[0]!= undefined && e.target.files[0] != null && e.target.files !=""){
            var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                this.setState({
                    csvcontent:csvData.data
                });
            })
            .catch(err => {})
                
            var out = new Promise(function(reject, resolve){
                var reader =  new FileReader();
                reader.onload = async function(e) {
                    var contents = await e.target.result;
                    console.log("contents ", contents )
                    resolve(contents);
                };
                var tt =  reader.readAsText(e.target.files[0]);
                console.log("tt ",tt)
            });
            //console.log("Out ", out);
            
            this.setState({
                file:e.target.files[0]
            });
            //console.log("e.target.files[0].type ", e.target.files[0].type);
    
            if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
            
        }
		
	}

    onRowClicked(cell) {
        if (cell.colDef.field == "view_remarks") {
            // var transporter_code =localStorage.getItem('transportercode')
            var admin_mail = localStorage.getItem('email')
            this.setState({
                overly: "show-m",
            })
            let qry = {
                truck_no: cell.data.truck_no,
                admin_email: admin_mail,
            }
            // if(transporter_code !== undefined && transporter_code !== 'undefined'){
            //     qry.transporter_code = transporter_code
            // }else{
            //     qry.transporter_code = []
            // }
            // console.log(transporter_code,"transporterCode");

            redirectURL.post("/dashboard/getbillingdownloadremarks", qry).then((response) => {
                var records =response.data;

                // console.log(records,"output");

                //   records = records.sort(GetSortDescOrder("billing_id"));
                console.log("records ", records)
                this.setState({
                    sliderUpdate: 'slider-translate-40p',
                    remarks_data: records
                });

            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    onClickNewAddRow(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Add OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Add OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            var fdata = {
                rownode: params,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
                then(
                    (response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully added data',
                                basicType: "success",
                                sliderRso: "",
                                overly: "show-n",
                                loadshow: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickEditForm(rowdata){
		var row = rowdata.data;
		this.setState({
			utransporter_code:row.transporter_code,
			utransporter_name:row.transporter_name,
			rowid:row._id,
			sliderEditTransporter:'slider-translate',
			overly:"show-m"
		})
	}


    onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderRso: 'slider-translate',
            overly: 'show-m',
            oem_code: "",
            oem_name: "",
            industry_type: ""
        })

    }


    updateRowHandler = async (cell) => {

        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
            sliderUpdate: 'slider-translate',
            overly: 'show-m',
            oem_code: oem_code,
            oem_name: oem_name,
            industry_type: industry_type,
            rowid: rowid
        })

    }



    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: ""
        });

    }
    onClickHideForEdit(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
		
    }


    onChangeTruckType(trucktype) {
        this.setState(
            { trucktype },
            () => console.log(`Option selected:`, this.state.trucktype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    updateFreightMasterData(event){
        event.preventDefault();
        var params = {
            consignee_code:this.state.consignee_code,
            consignee_name:this.state.consignee_name,
            distance_in_km:this.state.distance_in_km,
            freight_type:this.state.freight_type,
            consignee_state_code:this.state.consignee_state_code,
            consignee_state_name:this.state.consignee_state_name ,
            rowid:this.state.rowid,
            consignee_district_name:this.state.consignee_district_name,
            consignee_district_code:this.state.consignee_district_code

        }
        redirectURL.post('/chettinad/updateFreightMasterData',params).then((resp)=>{
            console.log(resp.data)
            if(resp.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully update transporter",
						basicType:"success",
						utransporter_name:"",
						utransporter_code:"",
						sliderEditTransporter:"",
						overly:"show-n"
					})
                    var unique_id = this.state.unique_id
					this.onLoadData(unique_id);
				}
				else{
					this.setState({
						show:true,
						basicTitle:"Failed to add transporter",
						basicType:"danger"
					})
				}

        })

    }


    onChangeTransporter(transporter) {
        this.setState(
            { transporter },
            () => console.log(`Option selected:`, this.state.transporter)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeDepartment(deptcode) {
        this.setState(
            { deptcode },
            () => console.log(`Option selected:`, this.state.deptcode)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    deleteRowHandler(cell) {
        //  console.log("Here",cell);
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var fdata = {
            rownode: cell
        }
        redirectURL.post("/dashboard/deleteoemitem", fdata, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).
            then(
                (response) => {
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicTitle: 'Successfully delete item',
                            basicType: "success",
                            loadshow: "show-n",
                            overly: "show-n",
                            sliderRso: ""
                        });
                        this.onLoadData();
                    }

                    else {
                        this.setState({
                            show: true,
                            basicTitle: 'Something went wrong',
                            basicType: "danger",
                        });
                    }

                }
            )
            .catch(function (error) {
                console.log(error);
            });

    }

    onChangeSourceItem(sourceopt) {
        this.setState(
            { sourceopt },
            () => console.log(`Option selected:`, this.state.sourceopt)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onChangeOfficerType(officertype) {
        this.setState(
            { officertype },
            () => console.log(`Option selected:`, this.state.officertype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectsources() {
        let items = [];
        try {
            if (this.state.sources.length > 0) {
                this.state.sources.map((item) => {
                    items.push({ "value": item.geofence_name, "label": item.geofence_name })
                })
            }
        }
        catch (e) {

        }
        return items;
    }

    onClickShowBulk() {
        this.setState({
            overly: "show-m",
            sliderBulk: 'slider-translate',
        })
    }


    // changeFileHandler = async (e) => {
    // 	//console.log(e.target.files[0])
    // 	var dt = '';
    // 	// const config = {
    // 	// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
    // 	// }

    // 	const config = {
    // 		headers: [
    // 			{ 
    // 				name: 'oem_name',
    // 				inputName: 'oem_name',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    //             },
    //             { 
    // 				name: 'oem_code',
    // 				inputName: 'oem_code',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    //             },
    //             { 
    // 				name: 'industry_type',
    // 				inputName: 'industry_type',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    // 			}

    // 		]
    // 	}
    // 	var data = CSVFileValidator(e.target.files[0], config)
    // 	.then((csvData) => {
    // 		this.setState({
    // 			csvcontent:csvData.data
    // 		});
    // 	})
    // 	.catch(err => {})

    // 	var out = new Promise(function(reject, resolve){
    // 		var reader =  new FileReader();
    // 		reader.onload = async function(e) {
    // 			var contents = await e.target.result;
    // 			console.log("contents ", contents )

    // 			resolve(contents);
    // 		};
    // 		var tt =  reader.readAsText(e.target.files[0]);
    // 		console.log("tt ",tt)
    // 	});
    // 	//console.log("Out ", out);

    // 	this.setState({
    // 		file:e.target.files[0]
    // 	});

    // }
    onClickRejectBill = () => {
        this.setState({
            overly: "show-m",
            sliderBulk: 'slider-translate',
        })

        // window.location.href="/tptlogin/billingsummary"
    }
    onClickAcceptBill = () => {
        var acceptBilling = 1;
        var billing_id = this.state.billingId;
        console.log(billing_id, "BILLING ID");
        console.log(acceptBilling, "acceptbilling");
        var params = {
            billing_id: billing_id,
            billing_status_msil: 1,
        }
        redirectURL.post('/dashboard/acceptedbilling', params, {

        })
        window.location.href = "/billingsummary"
    }

    onBtnExportDataAsCsv() {
        var params = {
            columnKeys: ['billing_id', 'billing_year_month', 'truck_no', 'transporter_code', 'transporter_name', 'date_of_installation', 'voice_box_installed_on', 'freewheeling_installed_on', 'billing_start_date', 'billing_end_date', 'total_billing_days', 'total_uptime_days', 'uptime_days_added_manually', 'comments_if_uptime_is_added_manually', 'uptime_(%)', 'amount_payable', 'category']
        };

        this.gridApi.exportDataAsCsv(params);

    }
    onSubmitRejectBill(params) {
        params.preventDefault()
        var rejectedBill = 2
        var admin_mail = localStorage.getItem('email')
        console.log(rejectedBill, "rejectedBill")
        var data = {
            complete_remarks: $("#rejectremark").val(),
            billing_id: this.state.billingId,
            billing_status_msil: 2,
            admin_email:admin_mail,
        }
        console.log(data, "data");
        redirectURL.post("/dashboard/rejectedbilling", data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {

        })
        window.location.href = "/billingsummary"
    }
    render() {
        const modalStyles = {
            width: '1300px !important',
        }
        var transporters = []
        var trans = this.state.transporters;
        if (trans.length > 0) {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
        const { usermanualmodal } = this.state;
        var hideChilds = true;
        if (this.state.departmentCode == "LOG-PRT") {
            hideChilds = false
        }
        const columnwithDefs = [
            {
                headerName: "Destination Name",
                field: "consignee_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Destination Code",
                field: "consignee_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            
            {
                headerName: "Material Segment Code",
                field: "material_segment_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Material Segment Name",
                field: "material_segment_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            
            {
                headerName: "Freight Type",
                field: "freight_type",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            
            {
                headerName: "Freight Description",
                field: "freight_description",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Distance in km",
                field: "distance_in_km",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            
            {
                headerName: "Primary Freight",
                field: "primary_freight",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
            {
                headerName: "Customer taluk",
                field: "consignee_taluk_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
            {
                headerName: "Customer Pincode",
                field: "consignee_pincode",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
           
            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
            {
                headerName: "Customer District Name",
                field: "consignee_district_Name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
            {
                headerName: "Customer District Code",
                field: "consignee_district_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
             
            {
                headerName: "Customer State Name",
                field: "consignee_state_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            }, 
            {
                headerName: "Customer State Code",
                field: "consignee_state_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Created Date",
	  	          field: "created_date",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.created_date != '' && params.data.created_date != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.created_date)
                        }
                        else
                        {  
                            return "";
                        }
                    }
                          
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,
                cellRenderer:'EditItem',
                editable:false
              }




            // {
            //     headerName: "Action",
            //     field: "_id",
            //     width: 100,
            //     filter: false,
            //     cellRenderer:'deleteItem',
            //     editable:false
            // }
        ];
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Freight Master Data </span>
                                    <button type="button" style={{marginRight:'5px'}} onClick={this.onClickBulkUpload.bind(this)} className="btn btn-warning float-right">Bulk Upload</button>
                                     <button type="button" style={{marginRight:'5px'}} onClick={this.onClickBtn.bind(this)} className="btn btn-info float-right">Back</button>
                                    {/* <button type="button" onClick={() => this.onClickRejectBill()} className="btn btn-warning float-right">Disapprove Bill</button>
                                    <button type="button" onClick={() => this.onClickAcceptBill()} className="btn btn-success float-right">Approve Bill</button>  */}

                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
                            <div className="card-body">
                                <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        pinnedBottomRowData={this.state.amount_payable}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Sidebars
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="col-xl-12 col-lg-12">
                            <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Add OEM Item
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Code *</label>
                                    <input
                                        type="text"
                                        name="oem_code"
                                        value={this.state.oem_code}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Name *</label>
                                    <input
                                        type="text"
                                        name="oem_name"
                                        value={this.state.oem_name}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Industry Type *</label>
                                    <input
                                        type="text"
                                        name="industry_type"
                                        value={this.state.industry_type}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>


                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.sliderEditTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Edit Freight
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideForEdit.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateFreightMasterData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer State Code</label>
							    <input type="text" placeholder="Enter State Code" onChange={this.changeHandler.bind(this)} value={this.state.consignee_state_code} id="consignee_state_code" name="consignee_state_code" className="form-control" />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer State Name</label>
							    <input type="text" placeholder="Enter State Name" onChange={this.changeHandler.bind(this)} value={this.state.consignee_state_name} id="consignee_state_name" name="consignee_state_name" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer District Name</label>
							    <input type="text" placeholder="Enter District Name" onChange={this.changeHandler.bind(this)} value={this.state.consignee_district_name} id="consignee_district_name" name="consignee_district_name" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer District Code</label>
							    <input type="text" placeholder="Enter District Code" onChange={this.changeHandler.bind(this)} value={this.state.consignee_district_code} id="consignee_district_code" name="consignee_district_code" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer Code</label>
							    <input type="text" placeholder="Enter Customer Code" onChange={this.changeHandler.bind(this)} value={this.state.consignee_code} id="consignee_code" name="consignee_code" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer Name</label>
							    <input type="text" placeholder="Enter Customer Name" onChange={this.changeHandler.bind(this)} value={this.state.consignee_name} id="consignee_name" name="consignee_name" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Freight Type</label>
							    <input type="text" placeholder="Enter Freight Type" onChange={this.changeHandler.bind(this)} value={this.state.freight_type} id="freight_type" name="freight_type" className="form-control" />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Distance In KM</label>
							    <input type="text" placeholder="Enter Distance" onChange={this.changeHandler.bind(this)} value={this.state.distance_in_km} id="distance_in_km" name="distance_in_km" className="form-control" />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>



                <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Trucks Remarks List
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>

                                <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}

                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.sliderBulkForceClose)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHide.bind(this)}  >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkForceCloseHandler.bind(this)}>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeBulkForceFileHandler.bind(this)}   className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_freight_master_bulk_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


                <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bill Rejection Comments
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitRejectBill.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Reason for the Rejection *</label>
                                    <textarea
                                        type="file"
                                        rows="10"
                                        name="uploadfile"
                                        id="rejectremark"
                                        className="form-control" required />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>

                                {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
                            </form>


                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}
function getParams() {
    return {
        allColumns: true,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: true,
        skipGroups: true,
        skipHeader: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,

    };
}