import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { successLogin: 0 };
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);

            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin": 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });

                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userid', response.data.userid);
                        let deptCode = JSON.stringify(response.data.user.dept_code);
                        localStorage.setItem('dept_code', deptCode);
                        localStorage.setItem('is_admin', response.data.user.is_admin);
                        localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        localStorage.setItem('is_global', response.data.user.is_global);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('lastname', response.data.user.lastname);

                        if (response.data.user.plant_code != undefined) {
                            var plantCode = response.data.user.plant_code;
                            if (typeof (plantCode) == "string") {
                                var plantCode = [plantCode]
                            }
                            localStorage.setItem('pc', JSON.stringify(plantCode));
                            localStorage.setItem('original_plant_code', JSON.stringify(plantCode));
                        }
                        else if (response.data.user.roles.indexOf("SITE_ADMIN") >= 0) {
                            localStorage.setItem('pc', "");
                            localStorage.setItem('original_plant_code', "");
                        }
                        else {
                            localStorage.setItem('pc', "");
                            localStorage.setItem('original_plant_code', "");
                        }
                        if (response.data.user.customer_code != undefined) {
                            var customerCode = response.data.user.customer_code;
                            if (typeof (customerCode) == "string") {
                                var customerCode = [customerCode]
                            }
                            localStorage.setItem('customer_code', JSON.stringify(customerCode));
                            localStorage.setItem('original_customer_code', JSON.stringify(customerCode));
                            localStorage.setItem('customersData', JSON.stringify(response.data.customersData));
                        }
                        else {
                            localStorage.setItem('customer_code', "");
                            localStorage.setItem('original_customer_code', "");
                            localStorage.setItem('customersData', "");
                        }
                        localStorage.setItem('is_sales_type', response.data.user.is_sales_type);
                        localStorage.setItem('region', response.data.user.region);
                        localStorage.setItem('sales_type', response.data.user.sales_type);
                        var useremailat = response.data.user.email.replace("[at]", "@")
                        var useremail = useremailat.replace("[dot]", ".")

                        localStorage.setItem('email', useremail);
                        localStorage.setItem('user_type', response.data.user.user_type);

                        //console.log("response.data.menus ", response.data.menus);
                        localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                        //console.log("response.data.usermenucodes ", response.data.usermenucodes);
                        localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));

                        localStorage.setItem('roles', response.data.user.roles);
                        localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
                        localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
                        localStorage.setItem('cha_code', response.data.user.cha_code);
                        var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                        var m = md(JSON.stringify(response.data.user))
                        localStorage.setItem('m', m);
                        localStorage.setItem('supplier_code', response.data.user.supplier_code);
                        try {
                            if (response.data.user.ro_zone != undefined) {
                                localStorage.setItem('ro_zone', response.data.user.ro_zone);
                            }
                            else if (response.data.user.vehicle_group != undefined) {
                                localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
                            }
                            else {
                                localStorage.setItem('vehicle_group', "");
                            }
                        }
                        catch (e) { }

                        sessionStorage.setItem('ref', 1);

                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
 
		
		if(localStorage.getItem("roles").indexOf("chettinad_OPS") >= 0)
		{
			var dashboardUrl = "shipmenttrucks";		
		}
		else if(localStorage.getItem("roles").indexOf("CHETTINAD_SIM_TRACKING") >= 0)
		{
			var dashboardUrl = "simtrackdrivers";		
		}
		else if (localStorage.getItem("email") == "rajesh.a@chettinadcement.com") {
			dashboardUrl = "/deliveredtrips";
		}
		else{
			var dashboardUrl = "shipmenttrucks";		
		}

		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}
		return dashboardUrl;
	}

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    render() {
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            var successLogin = this.state.successLogin;
            if (successLogin == 1) {
                let dashboardUrl = this.getUserDashboardUrl();
                console.log(dashboardUrl, "dashboardUrl1143");
                //let dashboardUrl = this.state.landingpage;
                // return <Redirect to="dashboard/snddefault" push={true} />

                return <Redirect to={"/" + dashboardUrl} push={true} />
            }
        }
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}

export default EnmUserLogin;