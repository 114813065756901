import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import UnloadColumnchart from './columnunloadingdashboard';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import DrillDownBarChart from "./drilldownBarChart"
import PieChartComponent from './pieChartForDashboard';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

var moment = require('moment');

export default class EnmovilDashboardsummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '2020-05-01',
            endDate: moment.parseZone().format("YYYY-MM-DD"),
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
            sndtab: 'show-n',
            prttab: 'show-n',
            tnptab: 'show-n',
            sndtabactive: '',
            prttabactive: '',
            tnptabactive: '',
            overly: "show-n",
            loadshow: "show-n",
            plantWiseChartData: [],
            plantwiseCategory: [],
            drilldownseries: [],


        }
    }
   async componentDidMount() {
        // this.setState({
        //     loadshow : "show-m",
        //     overly : "show-m"
        // })
        var from_date = moment.parseZone(new Date(2023 , 3,1)).format("YYYY-MM-DD HH:mm:ss")
        var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
        var params = {
            from_date, 
            to_date
        }
        await redirectURL.post("/dashboard/getTripDataForDashbaord" , params).then((response) => {
            console.log(response.data , "TripData_1729")
            var state_wise_data = response.data.state_wise_data
            var segment_wise_data = response.data.segment_wise_data
            var plant_wise_data = response.data.plant_wise_data
            // this.getChartData(segment_wise_data)
        })
        
        await redirectURL.post("/dashboard/getTripDatawrtCustomerForDashbaord" , params).then((response) => {
            console.log(response.data , "TripDatawrtcustomer_1729")
            var records = response.data.data
            this.setState({
                rowData : records
            })
        })

        var records = [
            {
                "plant": "ARIYALUR",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1235,
                "actual_freight_as_per_delivery_location": 913,
            },
            {
                "plant": "ARIYALUR",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 2,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 908,
                "actual_freight_as_per_delivery_location": 716,
            },
            {
                "plant": "ARIYALUR",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 634,
                "actual_freight_as_per_delivery_location": 421,
            },
            {
                "plant": "DACHEPPALLI",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1235,
                "actual_freight_as_per_delivery_location": 913,
            },
            {
                "plant": "DACHEPPALLI",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 2,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 908,
                "actual_freight_as_per_delivery_location": 716,
            },
            {
                "plant": "DACHEPPALLI",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 44,
                "freight_as_per_invoice": 1481,
                "actual_freight_as_per_delivery_location": 491,
            },
            {
                "plant": "DACHEPPALLI",
                "delivery_state": "Karnataka",
                "delivery_to": "Transporter",
                "distibution_channel": "Non-Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1059,
                "actual_freight_as_per_delivery_location": 911,
            },
            {
                "plant": "DACHEPPALLI",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 634,
                "actual_freight_as_per_delivery_location": 421,
            },
            {
                "plant": "VIZAG",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1346,
                "actual_freight_as_per_delivery_location": 826,
            },
            {
                "plant": "VIZAG",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 958,
                "actual_freight_as_per_delivery_location": 816,
            },
            {
                "plant": "VIZAG",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 634,
                "actual_freight_as_per_delivery_location": 421,
            },
            {
                "plant": "KALLUR",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bag",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 689,
                "actual_freight_as_per_delivery_location": 456,
            },
            {
                "plant": "KALLUR",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 789,
                "actual_freight_as_per_delivery_location": 634,
            },
            {
                "plant": "KALLUR",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 2,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 634,
                "actual_freight_as_per_delivery_location": 421,
            },
            {
                "plant": "SALAPUR",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1395,
                "actual_freight_as_per_delivery_location": 928,
            },
            {
                "plant": "SALAPUR",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 1034,
                "actual_freight_as_per_delivery_location": 967,
            },
            {
                "plant": "SALAPUR",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 729,
                "actual_freight_as_per_delivery_location": 439,
            },
            {
                "plant": "KARIKKALI",
                "delivery_state": "Tamil Nadu",
                "delivery_to": "Transporter",
                "distibution_channel": "Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 1149,
                "actual_freight_as_per_delivery_location": 869,
            },
            {
                "plant": "KARIKKALI",
                "delivery_state": "Andhra Pradesh",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 1,
                "quantity_in_mt": 60,
                "freight_as_per_invoice": 789,
                "actual_freight_as_per_delivery_location": 674,
            },
            {
                "plant": "KARIKKALI",
                "delivery_state": "Karnataka",
                "delivery_to": "Customer",
                "distibution_channel": "Non-Trade",
                "segment": "Bulk",
                "no_of_trips": 2,
                "quantity_in_mt": 30,
                "freight_as_per_invoice": 454,
                "actual_freight_as_per_delivery_location": 328,
            },
        ]

        

        this.getChartData(records)
        this.getPieChartData(records)
        this.getPieChartData2(records)
        this.getChartData2(records)
    }

    getChartData = (records) => {
        var plantWiseChartData = []
        var plantwiseCategory = []
        var drilldownseries = []
        // var color = ["#003f5c", "#955196","#444e86",  "#dd5182", "#ff6e54", "#ffa600"]
        var group_by_delivery_state = groupBy(records, data => data.delivery_state)
        group_by_delivery_state.forEach((values_state, keys_state) => {
            var a = {
                "name": keys_state,
                "data": []
            }
            var count = 0
            var group_by_plant = groupBy(values_state, data => data.plant)
            group_by_plant.forEach((values_plant, keys_plant) => {

                var b = {
                    "name": keys_plant,
                    "drilldown": keys_state + "_" + keys_plant,
                    "showTooltip": 1,
                    // "color": color[count],
                }
                var total_trips = 0
                values_plant.map(plant => {
                    total_trips += parseInt(plant.no_of_trips)
                })
                var drilldown_a = {
                    "name": keys_state,
                    "id": keys_state + "_" + keys_plant,
                    "data": []
                }
                
                values_plant.map((e, index) => {
                    
                    drilldown_a.data.push([e.material_segment, Number(e.amount_to_recover)])
                })
                drilldownseries.push(drilldown_a)
                b.y = total_trips
                a.data.push(b)
                a.data.push(b)
                count = count + 1

            })
            plantWiseChartData.push(a)
            plantwiseCategory.push(keys_state)
        })
        console.log(plantWiseChartData, "plantWiseChartData")
        this.setState({
            plantWiseChartData: plantWiseChartData,
            plantwiseCategory: plantwiseCategory,
            drilldownseries: drilldownseries,
            loadshow: 'show-n',
            overly: "show-n",
        });
    }

    getChartData2 = (records) => {
        var plantWiseChartData = []
        var plantwiseCategory = []
        var drilldownseries = []
        var color = ["#003f5c", "#955196","#444e86", "#dd5182", "#ff6e54", "#ffa600"]
        var group_by_delivery_state = groupBy(records, data => data.segment)
        group_by_delivery_state.forEach((values_state, keys_state) => {
            var a = {
                "name": keys_state,
                "data": []
            }
            var count = 0
            var group_by_plant = groupBy(values_state, data => data.delivery_state)
            group_by_plant.forEach((values_plant, keys_plant) => {

                var b = {
                    "name": keys_plant,
                    "drilldown": keys_state + "_" + keys_plant,
                    "showTooltip": 1,
                    // "color": color[count],
                }
                var total_trips = 0
                values_plant.map(plant => {
                    total_trips += parseInt(plant.no_of_trips)
                })
                var drilldown_a = {
                    "name": keys_state,
                    "id": keys_state + "_" + keys_plant,
                    "data": []
                }
                values_plant.map((e, index) => {
                    // var freight_diff = e.freight_as_per_invoice - e.actual_freight_as_per_delivery_location
                    // var amount_to_recoverd = freight_diff * e.quantity_in_mt
                    drilldown_a.data.push([`Delivery ${index + 1}`, Number(e.freight_as_per_invoice)])
                })
                drilldownseries.push(drilldown_a)
                b.y = total_trips
                a.data.push(b)
                a.data.push(b)
                count = count + 1

            })
            plantWiseChartData.push(a)
            plantwiseCategory.push(keys_state)
        })
        console.log(plantWiseChartData, "plantWiseChartData2")
        this.setState({
            plantWiseChartData2: plantWiseChartData,
            plantwiseCategory2: plantwiseCategory,
            drilldownseries2: drilldownseries,
            loadshow: 'show-n',
            overly: "show-n",
        });
    }

    getPieChartData = (records) => {
        var color = ["#003f5c", "#955196","#444e86", , "#dd5182", "#ff6e54", "#ffa600"]
        console.log(color , "color")
        var piechartSeriesData = []
        var piechartSeriesCategories = []
        var count = 0
        var group_by_plant = groupBy(records , data => data.plant)
        group_by_plant.forEach((values , keys , index)=> {
             var data = {
                name : keys , 
             }
             var total_freight_diff = 0
             values.map(e => {
                var freght_diff = e.freight_as_per_invoice - e.actual_freight_as_per_delivery_location
                total_freight_diff+=parseInt(freght_diff)
             })
            //  data.y = parseInt(total_freight_diff/values.length)
            data.y = total_freight_diff
             data.color = color[count]
             piechartSeriesData.push(data)
             piechartSeriesCategories.push(keys)
             count = count + 1
        })
        console.log(piechartSeriesData , "piechartSeriesData")
        this.setState({
            piechartSeriesData : piechartSeriesData,
            piechartSeriesCategories:piechartSeriesCategories
        })
    } 

    getPieChartData2 = (records) => {
        var color = ["#6181FF" , "#764248" , "#DAA89B" , "#F0C808" , "#20FFBE" , "#00100B"]      
        console.log(color , "color2")
        var piechartSeriesData = []
        var piechartSeriesCategories = []
        var count = 0
        var group_by_plant = groupBy(records , data => data.plant)
        group_by_plant.forEach((values , keys , index)=> {
             var data = {
                name : keys , 
             }
             var total_amount_to_be_recovered = 0
             values.map(e => {
                var freght_diff = e.freight_as_per_invoice - e.actual_freight_as_per_delivery_location
                total_amount_to_be_recovered+=parseInt(freght_diff *e.quantity_in_mt)
             })
             data.y = total_amount_to_be_recovered
             data.color = color[count]
             piechartSeriesData.push(data)
             piechartSeriesCategories.push(keys)
             count = count + 1
        })
        console.log(piechartSeriesData , "piechartSeriesData")
        this.setState({
            piechartSeriesData2 : piechartSeriesData,
            piechartSeriesCategories2:piechartSeriesCategories
        })
    } 

    // onGridReady = params => {
    // 	this.gridApi = params.api;

    //     //console.log("Grid is: ", params);
    //     this.gridColumnApi = params.columnApi;
    // };


    // onGridState = () =>{
    // 	 //console.log(this.gridApi);

    // 	 /*Get  Current Columns State and Store in this.colState */
    // 	   this.colState = this.gridColumnApi.getColumnState();

    // 	 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    // 	   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    // 	 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    // 	  * User should enable the Pivot mode.*/
    // 	   this.pivotColumns = this.gridColumnApi.getPivotColumns();

    // 	 /*Get Current Filter State and Store in window.filterState */
    // 	   window.filterState = this.gridApi.getFilterModel();
    // 	   this.gridApi.setFilterModel(window.filterState);


    // }


    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            breaksSlider: "",
            selectedRow: [],
        });

    }


    render() {
        const columnwithDefs = [
            {
                headerName: "Plant",
                field: "consigner_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "Delivery State",
                field: "consignee_state",
                // width :80,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "Transporter",
                field:"transporter_name",
                // width :100,
                filter: true,
                editable: false,
                resizable: true,
                hide: true
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                // width:250,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "Customer Name",
                field: "consignee_name",
                // width :80,
                filter: true,
                editable: false,
                resizable: true
            },
            
            {
                headerName: "Segment",
                field: "material_segment",
                // width :80,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "No Of Trips",
                field: "no_of_trips",
                // width :80,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "Quantity",
                field: "qty",
                // width :80,
                filter: true,
                editable: false,
                resizable: true
            },
            {
                headerName: "Invoice Freight Cost",
                field: "invoice_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
            },
            {
                headerName: "Actual Freight Cost",
                field: "actual_freight_cost",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
            },
            {
                headerName: "Freight Difference",
                field: "freight_difference",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
            },
            {
                headerName: "Amount to be Recovered",
                field: "amount_to_recover",
                filter: true,
                // width:130,
                editable: false,
                resizable: true,
               
            }
        ]


        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12 "}>

                </div>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>Dashboard</span>

                        </h5>
                        <div className="col-xl-12">
                            <DrillDownBarChart
                                chartTitle={"Trip Wise Data"}
                                seriesData={this.state.plantWiseChartData}
                                seriesCategories={this.state.plantwiseCategory}
                                chartType={1}
                                drilldowndata={this.state.drilldownseries}
                                chart_hit_count={0}
                                drilldown_header = {"Amount to be Recovered"}
                            />
                        </div>
                        <div className="col-xl-12">
                            <DrillDownBarChart
                                chartTitle={"Dashbaord"}
                                seriesData={this.state.plantWiseChartData2}
                                seriesCategories={this.state.plantwiseCategory2}
                                chartType={1}
                                drilldowndata={this.state.drilldownseries2}
                                chart_hit_count={0}
                                drilldown_header = {"Freight As per Invoice"}

                            />
                        </div>
                        <div className={"row mt-20p"}>
                            <div className="col-xl-6" style={{width:"500px" , height : "500px"}}>
                                <PieChartComponent
                                    chartType="pie"
                                    title={"Freight Difference"}
                                    subtitle={"As Per Plant"}
                                    context={this}
                                    series={this.state.piechartSeriesData}
                                    categories={this.state.piechartSeriesCategories}
                                    seriesName=""
                                    yaxistitle="Orders For Load Plan"
                                    drilldownseries={[]}
                                    innerSize="0%"
                                    format='<b>{point.name}-{point.y}</b>'
                                />
                            </div>
                            <div className="col-xl-6" style={{width:"500px" , height : "500px"}}>
                                <PieChartComponent
                                    chartType="pie"
                                    title={"Amount to be Recoverd"}
                                    subtitle={"As Per Plant"}
                                    context={this}
                                    series={this.state.piechartSeriesData2}
                                    categories={this.state.piechartSeriesCategories2}
                                    seriesName=""
                                    yaxistitle="Orders For Load Plan"
                                    drilldownseries={[]}
                                    innerSize="0%"
                                    format='<b>{point.name}-{point.y}</b>'
                                />
                            </div>
                        </div>
                        <div className='row mt-20p'>

                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Customer Wise Trip Data</span>

                                </h5>
                                <div id="myGrid" style={{ width: "100%", height: "360px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={true}
                                        sideBar={false}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}







